import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import TextLink from 'components/component/BasicTextLink';
import CustomImage from 'components/component/BasicImage';
import Snackbar from 'components/component/BasicSnackbar';
import { Box, Typography, Link, Grid, Table, TableSortLabel, TableContainer, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getRequest } from 'utils/Request';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import { MetaSeriesVodTh } from 'pages/Inspect/Metadata/data/MetaData';
import DetailItem from "./component/DetailItem";
import DetailImageItem from "./component/DetailImageItem";
import PopSeriesDetail from "pages/Common/PopSeriesDetail";

// 메인 컨텐츠
function SeriesDetail(props) {
  const { darkMode, message } = props;
  const { contentId, countryCode, tabValue, filters, type } = message;
  const navigate = useNavigate();
  const [isDisplay, setIsDisplay] = useState(false);
  const [seriesData, setSeriesData] = useState({});
  const [vodData, setVodData] = useState({});
  const [value, setValue] = useState(0);
  const [activeButton, setActiveButton] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  // Detail Popup
  const [currentVodId, setCurrentVodId] = useState('');
  const [vodDetailOpen, setVodDetailOpen] = useState(false);

  const vodDetailTabs = [
    { label: 'Basic Information', badgeType: '' },
    { label: 'VOD', badgeType: '' },
    { label: 'Image', badgeType: '' },
  ];
  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Metadata',
    normalMenu: ['Series Detail'],
  };

  useEffect(() => {
    if (message) {
      searchSeriesDetail();
    }
    // eslint-disable-next-line
  }, [message]);

  const searchSeriesDetail = async () => {
    setIsDisplay(false);
    const body = await getRequest(`/metadata/series/${contentId}`, null);
    if (body && body.result === 'SUCCESS') {
      setSeriesData(body.data);
      if (body.data.vodList) {
        setVodData(body.data.vodList);
      }
      setIsDisplay(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUrlButtonClick = (url) => {
    window.open(url, '_blank');
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/Metadata', { replace: true, state:
      { move: true,
        targetComponent: 'Metadata',
        message: {
          filters: filters,
          tabValue: tabValue,
          type: type,
        },
      }
    });
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...seriesData.vodList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setVodData([...sortedData]);
  };

  const handlePopUpPage = (vodId) => {
    setCurrentVodId(vodId);
    setVodDetailOpen(true);
  };

  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '460px',
    overflowY: 'auto',
  }));

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href='#' onClick={handleBack}>
                Series Detail
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap vod-detail' component='div'>
          <Box className='white-box white-box-wrap single-box'>
            <div className='tab-content-area'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={vodDetailTabs} />
              <div className='box-content'>
                {isDisplay && value === 0 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='detail-list-container' pt={2.5}>
                          <Grid className='section' container columnSpacing={2.5}>
                            <Grid item xs={6}>
                              <DetailItem label='Series ID' className='' value={seriesData.seriesId} />
                              <DetailItem label='Series Title' className='' value={seriesData.seriesTitle} />
                              <DetailItem label='Season ID' className='' value={seriesData.seasonId} />
                              <DetailItem label='Season Title' className='' value={seriesData.seasonTitle} />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomImage
                                src={seriesData.imageUrl}
                                fit={false}
                                wrapWidth='768px'
                                wrapHeight='432px'
                                alt=''
                                rounded
                              />
                              <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                <Typography className='img-size'>{seriesData.imageWidth}px X {seriesData.imageHeight}px</Typography>
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Image URL'
                                  isIcon={true}
                                  Icon={BtnTeritaryOutlineRight}
                                  iconPos='right'
                                  onClick={() => handleUrlButtonClick(seriesData.imageUrl)}
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid container columnSpacing={2.5}>
                            <Grid item xs={12}>
                              <DetailItem label='CP Name' className='' value={seriesData.cpName} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Description' className='' value={seriesData.description} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Season Number' className='' value={seriesData.seasonNumber} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Total Episode' className='' value={seriesData.totalEpisodes} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Genre Code' className='' value={seriesData.genreCode} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Caption Flag' className='' value={seriesData.captionFlag} />
                            </Grid>
                          </Grid>

                          <Grid container component='section' className='section-container' sx={{ mt: 5 }}>
                            {/* Multi Language List */}
                            <Grid item xs={12}>
                              <Typography className='sub-title'>Multi Language List</Typography>
                            </Grid>
                            <Grid item mt={3}>
                              <Box className='toggle-btn-wrap' display='flex' gap={1}>
                                {seriesData.multiLanguageList.map((item, index) => (
                                  <Button
                                    key={index}
                                    color={activeButton === index ? 'primary' : 'tertiary'}
                                    type={activeButton === index ? 'box' : 'outline'}
                                    size='small'
                                    label={item.language}
                                    isIcon={false}
                                    autosize
                                    onClick={() => handleButtonClick(index)}
                                  />
                                ))}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Title' className='' value={seriesData.multiLanguageList[activeButton].title} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Description' className='' value={seriesData.multiLanguageList[activeButton].description} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
                {isDisplay && value === 1 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaSeriesVodTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {vodData.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaSeriesVodTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        <TextLink href = '#' onClick={() => handlePopUpPage(td['vodId'])} text={td[column.id]} />
                                      ) : colIndex === 3 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                          onClick={() => handlePopUpPage(td['vodId'])}
                                        />
                                      ) : (
                                        td[column.id]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>
                    </div>
                  </div>
                )}

                {isDisplay && value === 2 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='media-detail-container'>
                          <Box className='media-inner-wrap'>
                            {/* Image List */}
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Image List
                                </Typography>
                              </div>
                            </Box>
                            <Box className='media-wrap'>
                              <Grid container direction="column" spacing={5}>
                                {seriesData.stillCutImageList.map((item, index) => (
                                    <Grid item xs={6} key={index}>
                                      <Box className='media-item-wrap'>
                                        <div className='item-image-area'>
                                          <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                           src={seriesData.stillCutImageList && seriesData.stillCutImageList.length > 0 ? seriesData.stillCutImageList[index].originUrl : ''}
                                                           uiWidth='768px'
                                                           uiHeight='432px'
                                                           actualWidth={seriesData.stillCutImageList && seriesData.stillCutImageList.length > 0 ? seriesData.stillCutImageList[index].width : 0}
                                                           actualHeight={seriesData.stillCutImageList && seriesData.stillCutImageList.length > 0 ? seriesData.stillCutImageList[index].height : 0}
                                                           type={seriesData.stillCutImageList[index].type}
                                                           ratio={seriesData.stillCutImageList[index].ratio}
                                                           group={seriesData.stillCutImageList[index].imageGroup}
                                          />
                                        </div>
                                      </Box>
                                    </Grid>
                                ))}
                                <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                  {seriesData.thumbnailImageList.map((item, index) => (
                                      <Grid item xs={6} key={index}>
                                        <Box className='media-item-wrap'>
                                          <div className='item-image-area'>
                                            <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                             src={seriesData.thumbnailImageList && seriesData.thumbnailImageList.length > 0 ? seriesData.thumbnailImageList[index].originUrl : ''}
                                                             uiWidth='768px'
                                                             uiHeight='432px'
                                                             actualWidth={seriesData.thumbnailImageList && seriesData.thumbnailImageList.length > 0 ? seriesData.thumbnailImageList[index].width : 0}
                                                             actualHeight={seriesData.thumbnailImageList && seriesData.thumbnailImageList.length > 0 ? seriesData.thumbnailImageList[index].height : 0}
                                                             type={seriesData.thumbnailImageList[index].type}
                                                             ratio={seriesData.thumbnailImageList[index].ratio}
                                                             group={seriesData.thumbnailImageList[index].imageGroup}
                                            />
                                          </div>
                                        </Box>
                                      </Grid>
                                  ))}
                                </Grid>
                                <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                  {seriesData.posterImageList.map((item, index) => (
                                      <Grid item xs={6} key={index}>
                                        <Box className='media-item-wrap'>
                                          <div className='item-image-area'>
                                            <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                             src={seriesData.posterImageList && seriesData.posterImageList.length > 0 ? seriesData.posterImageList[index].originUrl : ''}
                                                             uiWidth='360px'
                                                             uiHeight='540px'
                                                             actualWidth={seriesData.posterImageList && seriesData.posterImageList.length > 0 ? seriesData.posterImageList[index].width : 0}
                                                             actualHeight={seriesData.posterImageList && seriesData.posterImageList.length > 0 ? seriesData.posterImageList[index].height : 0}
                                                             type={seriesData.posterImageList[index].type}
                                                             ratio={seriesData.posterImageList[index].ratio}
                                                             group={seriesData.posterImageList[index].imageGroup}
                                            />
                                          </div>
                                        </Box>
                                      </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
      {vodDetailOpen && <PopSeriesDetail vodId={currentVodId} countryCode={countryCode} open={vodDetailOpen} close={() => setVodDetailOpen(false)} />}
    </Box>
  );
}

export default SeriesDetail;
