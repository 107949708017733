import {
  Box, IconButton, ListItemText,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import SelectBox from "components/component/BasicSelectBox";
import {statusData} from "pages/Organization/DeviceNotification/data/DeviceNotificationData";
import DatePicker from "components/component/BasicDatePicker";
import CheckBox from "components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "components/component/BasicTooltip";
import {PrimaryInfoIcon} from "components/component/BasicIcon";
import TextLink from "components/component/BasicTextLink";
import Button from "components/component/BasicButton";
import Pagination from "components/component/BasicPagination";
import React, {useEffect, useState} from "react";

import { styled } from '@mui/material/styles';
import { connect } from "react-redux";
import BasicSelectCheckBox
  from "components/component/BasicSelectCheckBox";
import dayjs from "dayjs";
import { BASE_URL } from "constants/index";
import {getHeaders} from "utils/Header";
import PopAddNotificationTV from "pages/Organization/DeviceNotification/PopAddNotificationTV";
import {useNavigate} from "react-router-dom";
import Dialog from "components/component/BasicDialog";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Tooltip 내부에 출력될 자식 컴포넌트 //
const InfoTooltip = (props, ref) => {
  return (
      <Box className='tooltip-info-box' sx={{ padding: '0 12px' }}>
        {props.list.map((infoField, i) => (
            <Box key={i} className='info'>
              <ListItemText className='label'>{`${infoField} `}</ListItemText>
            </Box>
        ))}
      </Box>
  );
};

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function DeviceNotificationTV({ country, message }) {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...deviceNotifications].sort((a, b) => {
      let left = a[property] ? a[property].toString().toLowerCase() : '';
      let right = b[property] ? b[property].toString().toLowerCase() : '';
      if (property === 'country') {
        left = a['countryList'][0] ? a['countryList'][0].toString().toLowerCase() : '';
        right = b['countryList'][0] ? b['countryList'][0].toString().toLowerCase() : '';
      }
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDeviceNotifications([...sortedData])
  };

  const [paramCountry, setParamCountry] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(['US']);
  const [paramChannelMap, setParamChannelMap] = useState([]);
  const [selectedChannelMaps, setSelectedChannelMaps] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Save for Test');
  const [paramDate, setParamDate] = useState(dayjs());
  const [deviceNotifications, setDeviceNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const [checkedItem, setCheckedItem] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const [isEmpty, setIsEmpty] = useState(false);

  const handleCheckBoxChange = (notificationId) => {
    setCheckedItem(notificationId);
  };

  const navigateDeviceNotificationDetail = (notificationId) => {
    navigate('/DeviceNotification', {
      replace: true, state:
        { move: true,
          targetComponent: 'DeviceNotificationDetail',
          message:
            {
              notification: notificationId,
              currentFilter: {
                selectedCountries: selectedCountries,
                selectedChannelMaps: selectedChannelMaps,
                selectedStatus: selectedStatus,
                paramDate: paramDate.format('YYYY-MM-DD')
              }
            }
        }
    })
  }

  const getPublishStatusAcronym = (status) => {
    switch (status) {
      case 'Save for Test':
        return 'S-T';
      case 'Save for Publish':
        return 'S-P';
      case 'Testing':
        return 'T';
      case 'Published':
        return 'P';
      default:
        return status;
    }
  }

  useEffect(() => {
    setPage(1);
  }, [selectedStatus]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (deviceNotifications.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(deviceNotifications.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [deviceNotifications, page, itemsPerPage]);

  const getNotifications = () => {
    let requestUrl = `${BASE_URL}/device-notification/notifications`;
    requestUrl += '?deviceType=tv';
    requestUrl += `&status=${getPublishStatusAcronym(selectedStatus)}`;
    requestUrl += `&date=${paramDate.format('YYYY-MM-DD')}`;
    requestUrl += `&countries=${selectedCountries.join(',')}`;
    requestUrl += `&channelMaps=${selectedChannelMaps.join(',')}`;

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const data = body.data;
        data.forEach(item => {
          item.countryList.sort();
          item.country = {label: item.countryList[0]? item.countryList[0] : '', tooltip: false};
          if (item.countryList.length > 1) {
            item.country = {label: `${item.countryList[0]}+${item.countryList.length-1}`, tooltip: true};
          }
        })
        setDeviceNotifications(data);
        if (data.length === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
      }
    })
  }

  useEffect(() => {
    getNotifications();
    setCheckedItem('');
    // eslint-disable-next-line
  }, [selectedCountries, selectedChannelMaps, selectedStatus, paramDate]);

  useEffect(() => {
    if (country.serviceCountry) {
      let messageFilterCountry = [];
      if (message && message.currentFilter) {
        messageFilterCountry = message.currentFilter.selectedCountries;
      }

      setParamCountry([{id: 'all', value: 'all', option: 'All', type: 'checkbox'},
        ...country.serviceCountry.map(item => ({
          id: item.countryCode,
          value: item.countryCode,
          option: item.countryCode,
          type: 'checkbox',
          defaultChecked: messageFilterCountry && messageFilterCountry.length > 0
              ? messageFilterCountry.includes(item.countryCode)
              : item.countryCode === 'US'
        }))
      ]);

      const defaultChecked = country.serviceCountry.find(item => item.countryCode === 'US').channelMapList[0].channelMapId;
      setSelectedChannelMaps([defaultChecked]);
    }
  }, [country, message]);

  const handleSelectedCountries = (country) => {
    if (message && message.currentFilter) {
      message.currentFilter = null;
    }
    if (country) {
      setSelectedCountries([...country]);
    }
  }
  const handleSelectedChannelMaps = (channelMap) => {
    if (message && message.currentFilter) {
      message.currentFilter = null;
    }
    if (channelMap) {
      setSelectedChannelMaps([...channelMap]);
    }
  }

  useEffect(() => {
    if (country.serviceCountry) {
      let channelMaps = country.serviceCountry.filter(item => selectedCountries.includes(item.countryCode))
      .reduce((acc, cur) => {
        acc.push(...cur.channelMapList);
        return acc;
      }, []);
      let checkedChannelMaps = country.serviceCountry.find(item => item.countryCode === selectedCountries[0])?.channelMapList[0].channelMapId
      if (selectedChannelMaps.length === 0 || !selectedChannelMaps.some(channelMap => channelMaps.map(cm => cm.channelMapId).includes(channelMap))) {
        checkedChannelMaps = [country.serviceCountry.find(item => item.countryCode === selectedCountries[0])?.channelMapList[0].channelMapId]
      } else {
        checkedChannelMaps = selectedChannelMaps;
      }
      let messageFilterChannelMap = [];
      if (message && message.currentFilter) {
        messageFilterChannelMap = message.currentFilter.selectedChannelMaps;
      }

      setParamChannelMap([{id: 'all', value: 'all', option: 'All', type: 'checkbox'},
        ...channelMaps.map(item => ({
          id: item.channelMapId,
          value: item.channelMapId,
          option: item.channelMapId,
          type: 'checkbox',
          defaultChecked: messageFilterChannelMap && messageFilterChannelMap.length > 0
              ? messageFilterChannelMap.includes(item.channelMapId)
              : checkedChannelMaps.includes(item.channelMapId)
        }))
      ]);
    }
    // eslint-disable-next-line
  }, [selectedCountries, country]);

  const handleMoveToHistory = (notificationId, title) => {
    navigate('/DeviceNotification', {
      replace: true, state:
        { move: true,
          targetComponent: 'DeviceNotificationHistory',
          message:
            {
              notificationId: notificationId,
              title: title,
              currentFilter: {
                selectedCountries: selectedCountries,
                selectedChannelMaps: selectedChannelMaps,
                selectedStatus: selectedStatus,
                paramDate: paramDate.format('YYYY-MM-DD')
              }
            }
        }
    })
  }

  const handlePublish = () => {
    const request = {
      notificationId: checkedItem,
      publishStatus: selectedStatus === 'Save for Test' ? 'T' : 'P'
    }
    fetch(`${BASE_URL}/device-notification/notifications/publish`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(request)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Notification published successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Failed');
        setAlertMessage('Failed to publish notification.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      handleConfirmClose();
      setCheckedItem('');
    })
  }

  const handlePublishConfirmOpen = () => {
    setConfirmMessage(`Do you want to publish the below notification?\n\n` + checkedItem);
    setConfirmOpen(true);
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handlePublish
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  useEffect(() => {
    if (message && message.currentFilter) {
      setSelectedCountries(message.currentFilter.selectedCountries);
      setSelectedChannelMaps(message.currentFilter.selectedChannelMaps);
      setSelectedStatus(message.currentFilter.selectedStatus);
      setParamDate(dayjs(message.currentFilter.paramDate));
    }
  }, [message]);

  return (
      <div className='tab-panel pr-page'>
        <div className='panel-wrap'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <BasicSelectCheckBox
                        id='deviceNoti-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        required={false}
                        selectOptions={paramCountry}
                        disabled={false}
                        placeholder=''
                        multiple={true}
                        onChange={handleSelectedCountries}
                    />
                  </Box>
                  {/* Channel Map */}
                  <Box component='div' className='col'>
                    <BasicSelectCheckBox
                        id='deviceNoti-ChannelMap'
                        size='large'
                        shrink={true}
                        label='Channel Map'
                        required={true}
                        selectOptions={paramChannelMap}
                        disabled={false}
                        placeholder=''
                        multiple={true}
                        onChange={handleSelectedChannelMaps}
                    />
                  </Box>
                  {/* status */}
                  <Box component='div' className='col'>
                    <SelectBox
                        id='deviceNoti-statusData'
                        size='large'
                        shrink={true}
                        label='Status'
                        required={false}
                        selectOptions={statusData}
                        disabled={false}
                        placeholder=''
                        selected={selectedStatus}
                        onChange={setSelectedStatus}
                    />
                  </Box>
                  <Box component='div' className='col auto'>
                    <DatePicker
                        format='MM/DD/YYYY'
                        size='large'
                        singleDate={true}
                        maxDate={'2099-12-31'}
                        rangeDate={false}
                        wave={true}
                        value={paramDate}
                        onChange={(newValue) => setParamDate(newValue)}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='right-area'>
                  {(selectedStatus === 'Save for Test' || selectedStatus === 'Save for Publish') && (
                    <Button
                      color={'primary'}
                      type={'box'}
                      size={'small'}
                      label={'Add'}
                      autosize={false}
                      onClick={() => {setOpenAddNotification(true)}}
                    />
                  )}
                  {selectedStatus === 'Save for Test' && (
                    <Button
                      color={checkedItem === '' ? 'tertiary' : 'primary'}
                      type={'box'}
                      size={'small'}
                      label={'Testing'}
                      autosize={true}
                      disabled={checkedItem === ''}
                      onClick={handlePublishConfirmOpen}
                    />
                  )}
                  {selectedStatus === 'Save for Publish' && (
                    <Button
                      color={checkedItem === '' ? 'tertiary' : 'primary'}
                      type={'box'}
                      size={'small'}
                      label={'Publish'}
                      autosize={true}
                      disabled={checkedItem === ''}
                      onClick={handlePublishConfirmOpen}
                    />
                  )}

                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table'
                         stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        <TableCell
                            key={'notificationId'}
                            className='th'
                            align={'left'}
                            sx={{width: '22%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'notificationId'}
                              direction={orderBy === 'notificationId' ? order : 'desc'}
                              onClick={() => handleRequestSort('notificationId')}
                          >
                            Notification ID
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'country'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'country'}
                              direction={orderBy === 'country' ? order : 'desc'}
                              onClick={() => handleRequestSort('country')}
                          >
                            Country
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'version'}
                            className='th'
                            align={'left'}
                            sx={{width: '12%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'version'}
                              direction={orderBy === 'version' ? order : 'desc'}
                              onClick={() => handleRequestSort('version')}
                          >
                            Version
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'enable'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'enable'}
                              direction={orderBy === 'enable' ? order : 'desc'}
                              onClick={() => handleRequestSort('enable')}
                          >
                            Enable
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'title'}
                            className='th'
                            align={'left'}
                            sx={{width: '24%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'title'}
                              direction={orderBy === 'title' ? order : 'desc'}
                              onClick={() => handleRequestSort('title')}
                          >
                            Title
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'startDate'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'startDate'}
                              direction={orderBy === 'startDate' ? order : 'desc'}
                              onClick={() => handleRequestSort('startDate')}
                          >
                            Start Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'endDate'}
                            className='th'
                            align={'left'}
                            sx={{width: '6%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'endDate'}
                              direction={orderBy === 'endDate' ? order : 'desc'}
                              onClick={() => handleRequestSort('endDate')}
                          >
                            End Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'lastChgDate'}
                            className='th'
                            align={'left'}
                            sx={{width: '10%'}}
                        >
                          <TableSortLabel
                              active={orderBy === 'lastChgDate'}
                              direction={orderBy === 'lastChgDate' ? order : 'desc'}
                              onClick={() => handleRequestSort('lastChgDate')}
                          >
                            Last Chg Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key={'action'}
                            className='th'
                            align={'left'}
                            sx={{width: 'auto'}}
                        >
                            Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {isEmpty ? (
                      <NoRecords>
                        No records to display
                      </NoRecords>
                    ) : (
                      <TableBody className='tbody'>
                        {paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              <TableCell key={'notificationId'} className='td' align={'left'}>
                                <Box display='flex'>
                                  {(selectedStatus === 'Save for Test' || selectedStatus === 'Save for Publish') &&
                                    <CheckBox label='' value={td['notificationId']}
                                              checked={checkedItem === td['notificationId']}
                                              onChange={() => handleCheckBoxChange(td['notificationId'])}/>
                                  }
                                  <Typography>{td['notificationId']}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell key={'country'} className='td' align={'left'}>
                                <Box display='flex'>

                                  <Typography>{td.country.label}</Typography>
                                  {td.country.tooltip && (
                                      <div className='td-tooltip-wrap'>
                                        <Tooltip title={<Box sx={{ maxHeight: 200, overflowY: 'auto' }}><InfoTooltip list={td.countryList}/></Box>}
                                                 placement='bottom'>
                                          <IconButton
                                              size='small'
                                              sx={{
                                                width: '24px',
                                                height: '24px',
                                                marginLeft: '4px'
                                              }}>
                                            <PrimaryInfoIcon/>
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell key={'version'} className='td' align={'left'}>
                                <TextLink href='' onClick={() => navigateDeviceNotificationDetail(td)}
                                          text={td[`version`]}/>
                              </TableCell>
                              <TableCell key={'enable'} className='td' align={'left'}>
                                <span>{td[`enable`]}</span>
                              </TableCell>
                              <TableCell key={'title'} className='td' align={'left'}>
                                <span>{td[`title`]}</span>
                              </TableCell>
                              <TableCell key={'startDate'} className='td' align={'left'}>
                                <span>{td[`startDate`]}</span>
                              </TableCell>
                              <TableCell key={'endDate'} className='td' align={'left'}>
                                <span>{td[`endDate`]}</span>
                              </TableCell>
                              <TableCell key={'lastChgDate'} className='td' align={'left'}>
                                <span>{td[`lastChgDate`]?.replace("T", " ").replace("Z", "")}</span>
                              </TableCell>
                              <TableCell key={'action'} className='td' align={'left'}>
                                <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='HISTORY'
                                    isIcon={false}
                                    autosize
                                    onClick={() => handleMoveToHistory(td['notificationId'], td['title'])}
                                />
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={Math.ceil(deviceNotifications.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              {openAddNotification && (
                  <PopAddNotificationTV onClose={() => setOpenAddNotification(false)} publishStatus={getPublishStatusAcronym(selectedStatus)} afterSave={getNotifications}/>
              )}
            </div>
          </Box>
        </div>
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
            style={{ whiteSpace: 'pre-line' }}
        />
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </div>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotificationTV);