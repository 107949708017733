import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
import FileUpload from 'components/component/BasicFileUpload';
import { saveSliderImage } from 'pages/Organization/TabCategory/api/restApi';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import { isEmpty } from 'utils/Valid';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;
const MediaDetailContainer = styled('div')`
  max-height: 420px;
  overflow-y: auto;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// Data
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

// 메인 컨텐츠
function PopSliderUploadImage(props) {
  const { tab, category, country, checkedResultContent, handleResultContentImage, onClose, handelSetShowSnakBar } = props;

  const [horizontalImg, setHorizontalImg] = useState(checkedResultContent[0].horizontalImg);
  const [bigImg, setBigImg] = useState(checkedResultContent[0].bigImg);

  // Tab Image 임시저장 객체
  const [horizontalImgTemp, setHorizontalImgTemp] = useState(null);
  const [horizontalImgActualSize, setHorizontalImgActualSize] = useState({ width: 0, height: 0 });
  // Background Image 임시저장 객체
  const [bigImgTemp, setBigImgTemp] = useState(null);
  const [bigImgActualSize, setBigImgActualSize] = useState({ width: 0, height: 0 });

  // 저장된 horizontal image 랜더링 시 사이즈 체크
  useEffect(() => {
    const img = new Image();
    img.src = checkedResultContent[0].horizontalImg;
    img.onload = () => {
      setHorizontalImgActualSize({ width: img.width, height: img.height });
    };
    // eslint-disable-next-line
  }, [horizontalImg]);

  // 저장된 big image 랜더링 시 사이즈 체크
  useEffect(() => {
    const img = new Image();
    img.src = checkedResultContent[0].bigImg;
    img.onload = () => {
      setBigImgActualSize({ width: img.width, height: img.height });
    };
    // eslint-disable-next-line
  }, [bigImg]);

  const handleClose = () => {
    onClose();
  };

  const onCancelBtn = () => {
    handleClose();
  };

  // Horizontal Image 값
  const onChangeHorizontalImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setHorizontalImgTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setHorizontalImgActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setHorizontalImg(file);
  };

  // Big Image 값
  const onChangeBigImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBigImgTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setBigImgActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setBigImg(file);
  };

  // Save버튼 클릭 시 이미지 생성
  const clickSave = async event => {
    event.preventDefault();

    const data = { categoryIdx: category.idx, sliderIdx: checkedResultContent[0].sliderIdx, contentId: checkedResultContent[0].contentId, horizontalImage: horizontalImg, bigImage: bigImg };
    const res = await saveSliderImage(data);

    if (res.isSuccess) {
      const content = {
        categoryIdx: category.idx,
        sliderIdx: checkedResultContent[0].sliderIdx,
        contentId: checkedResultContent[0].contentId,
        horizontalImg: res.data.horizontalImage ? res.data.horizontalImage : horizontalImg,
        bigImg: res.data.bigImage ? res.data.bigImage : bigImg,
      };
      handleClose();
      handleResultContentImage(content);
    }

    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  }

  // Image URL에서 파일 명 추출
  const fileNameParsing = (imageUrl) => {
    const params = new URLSearchParams(imageUrl);
    const filePath = params.get('file_path');
    const imageName = filePath ? filePath.split('/').pop() : '';
    return imageName;
  }

  // Button Data
  const buttons = [
    {
      minWidth: '150px',
      color: 'tertiary',
      type: 'outline',
      size: 'large',
      label: 'Cancel',
      onClick: onCancelBtn,
    },
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'Save',
      onClick: clickSave,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={true} size='large' className='pop-program-detail'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Content Image Upload</DialogTitle>
        </HeaderArea>

        <DialogContent className='pop-content pop-upload-image'>
          <BoxContainer>
            <Box className='detail-content'>
              <Box className='detail-list-container'>
                <Grid container columnSpacing={2.5}>
                  <Grid item xs={6}>
                    <DetailItem label='Tab/Category' value={tab.tabName.concat('/' + category.categoryName)} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Country' value={country} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content ID' value={checkedResultContent[0].contentId} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content Name' value={checkedResultContent[0].contentName} />
                  </Grid>
                </Grid>
              </Box>
              <MediaDetailContainer className='media-detail-container' sx={{ mt: '40px' }}>
                <Box className='media-inner-wrap'>
                  {/* horizontal image */}
                  <Box className='media-wrap'>
                    <div className='title-area'>
                      <Typography className='field-label bold required'>Horizontal Image</Typography>
                    </div>

                    <Box className='media-item-wrap'>
                      <div className='item-image-area'>
                        <Box component='div' className='upload-file-list' key={`uplload-field-0`}>
                          <Box className='box-content' display='flex'>
                            <Box className='left-area'>
                              <CustomImage
                                src={horizontalImgTemp ? horizontalImgTemp : checkedResultContent[0].horizontalImg}
                                fit={false}
                                wrapWidth='604px'
                                wrapHeight='340px'
                                alt=''
                                rounded
                              />
                            </Box>
                          </Box>
                        </Box>
                      </div>
                      <Box className='item-info-area'>
                        <Box>
                          <Box className='item-row'>
                            <Typography className='text label'>Recommanded Size :</Typography>
                            <Typography className='text value'>1920px x 1080px</Typography>
                          </Box>
                          <Box className='item-row'>
                            <Typography className='text label'>Actual Image Size :</Typography>
                            <Typography className='text value'>{`${horizontalImgActualSize.width}px X ${horizontalImgActualSize.height}px`}</Typography>
                          </Box>
                        </Box>
                        <Box className='link-url'>
                          <FileUpload
                            id='horizontalImg'
                            buttonLabel='Upload'
                            size='large'
                            label='horizontal Icon'
                            placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                            onFileChange={(file) => onChangeHorizontalImageUpload(file)}
                            accept='.jpg,.png,'
                            isSubText
                            subText=''
                            value={ !isEmpty(horizontalImg) ? fileNameParsing(horizontalImg) : !isEmpty(horizontalImgTemp) ? horizontalImg.name : '' }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* big image */}
                  <Box className='media-wrap'>
                    <div className='title-area'>
                      <Typography className='field-label bold required'>Big Image</Typography>
                    </div>

                    <Box className='media-item-wrap'>
                      <div className='item-image-area'>
                        <Box component='div' className='upload-file-list' key={`uplload-field-0`}>
                          <Box className='box-content' display='flex'>
                            <Box className='left-area'>
                              <CustomImage
                                src={ bigImgTemp ? bigImgTemp : checkedResultContent[0].bigImg }
                                fit={false}
                                wrapWidth='604px'
                                wrapHeight='340px'
                                alt=''
                                rounded
                              />
                            </Box>
                          </Box>
                        </Box>
                      </div>
                      <Box className='item-info-area'>
                        <Box>
                          <Box className='item-row'>
                            <Typography className='text label'>Recommanded Size :</Typography>
                            <Typography className='text value'>1920px x 1080px</Typography>
                          </Box>
                          <Box className='item-row'>
                            <Typography className='text label'>Actual Image Size :</Typography>
                            <Typography className='text value'>{`${bigImgActualSize.width}px X ${bigImgActualSize.height}px`}</Typography>
                          </Box>
                        </Box>
                        <Box className='link-url'>
                          <FileUpload
                            id='bigImg'
                            buttonLabel='Upload'
                            size='large'
                            label='big Icon'
                            placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                            onFileChange={(file) => onChangeBigImageUpload(file)}
                            accept='.jpg,.png,'
                            isSubText
                            subText=''
                            value={ !isEmpty(bigImg) ? fileNameParsing(bigImg) : !isEmpty(bigImgTemp) ? bigImg.name : '' }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </MediaDetailContainer>
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopSliderUploadImage;
