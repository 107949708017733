import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Tooltip from "../../../components/component/BasicTooltip";
import HelpIcon from '@mui/icons-material/Help';
import BasicSnackbar from 'components/component/BasicSnackbar';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

import { styled } from '@mui/material/styles';
// Icon

import {
  columns,
  columnsWithoutC,
  columns2,
  columns2WithoutC,
  columns3,
  columns3WithoutC,
  columns4,
  columns4WithoutC,
  chartSetting,
  seriesData,
  seriesDataWithoutC,
  udsColumn,
  udsColumnWithoutC,
  populationColumns,
  populationColumnsWithoutC
} from 'pages/Organization/ANTestService/data/ANTestServiceResultdata';
import { useNavigate } from 'react-router-dom';
import { getSegmentDailyData, getSegmentDetails, getDailyUdsDataAnTestService } from '../ANTestService/api/restApi';
import PopEditValidReason from './PopEditValidReason';

// 메인 컨텐츠
function ANTestServiceResult(props) {
  const navigate = useNavigate();
  const { message, type } = props;
  const {
    selectedTestService,  // selected antest
    selectedSegment, // selected segment
    mainFilteringData,
    tComponent
  } = message;
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
    normalMenu: ['Segment', 'Segment Organize', type === 'antest' ? 'AN Test Result' : 'AN Service Result'],
  };
  const [aGroupDescription, setAGroupDescription] = useState('');
  const [bGroupDescription, setBGroupDescription] = useState('');
  const [cGroupDescription, setCGroupDescription] = useState('');
  const [durationCumulativeBOverA, setDurationCumulativeBOverA] = React.useState('');
  const [durationCumulativeBOverC, setDurationCumulativeBOverC] = React.useState('');
  const [clickCumulativeBOverA, setClickCumulativeBOverA] = React.useState('');
  const [clickCumulativeBOverC, setClickCumulativeBOverC] = React.useState('');
  const [udCumulativeBOverA, setUdCumulativeBOverA] = React.useState('');
  const [udCumulativeBOverC, setUdCumulativeBOverC] = React.useState('');
  const [dailyUdsData, setDailyUdsData] = React.useState(null);
  const [actionDialog, setActionDialog] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleValidityReasonEdit = () => {
    setActionDialog(true)
  };

  const handelSetShowSnackBar = ({ open, message, severity }) => {
    setShowSnackBar({
      open,
      message,
      severity,
    });
  };

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  };

  // 테이블
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  // DetailItem 컴포넌트 생성
  const DetailItem = ({ label, value, maxThWidth = false, isButton }) => (
    <Box className='list-wrap'>
      <Grid display='flex' item xs={12} alignItems='center'>
        {maxThWidth === true ? (
          <Grid item xs={3}>
            <Typography component='span' className='label'>
              {label}
            </Typography>
          </Grid>
        ) : (
          <Typography component='span' className='label'>
            {label}
          </Typography>
        )}
        <Box className='value-wrap' display={'flex'}>
          <Box className='value'>
            <Typography component='span' className='text'>
              {value}
            </Typography>
          </Box>
          {isButton && <Button color='primary' type='box' size='small' label='Edit' isIcon={false} autosize onClick={() => handleValidityReasonEdit()} />}
        </Box>
      </Grid>
    </Box>
  );

  const ThDivWithHelpIcon = ({ thText, tooltipContent }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ alignSelf: 'center' }}>
        {thText}
      </div>
      <div style={{ alignSelf: 'flex-start', marginTop: '-4px' }}>
        <Tooltip title={tooltipContent} placement='top' width='300'>
          <HelpIcon sx={{ color: '#808080', fontSize: '80%' }} />
        </Tooltip>
      </div>
    </div>
  );

  const PValueTooltipContent = () => (
    <div style={{ display: 'table' }}>
      <div style={{ display: 'table-row' }}>
        <p style={{ display: 'table-cell' }}>{`P-value`}</p>
        <p style={{ display: 'table-cell', textAlign: 'center', padding: '0 2px' }}>{` <= `}</p>
        <p style={{ display: 'table-cell' }}>{`0.05 (α)`}</p>
        <p style={{ display: 'table-cell', paddingRight: '5px' }}>{`:`}</p>
        <p style={{ display: 'table-cell' }}>{`green texts`}</p>
      </div>
      <div style={{ display: 'table-row' }}>
        <p style={{ display: 'table-cell' }}>{`P-value`}</p>
        <p style={{ display: 'table-cell', textAlign: 'center', padding: '0 2px' }}>{`>`}</p>
        <p style={{ display: 'table-cell' }}>{`0.05 (α)`}</p>
        <p style={{ display: 'table-cell', paddingRight: '5px' }}>{`:`}</p>
        <p style={{ display: 'table-cell' }}>{`red texts`}</p>
      </div>
    </div>
  );

  const [segmentDailyDataList, setSegmentDailyDataList] = useState({
    data: [],
    total: 0,
  });

  const startDate = type === 'antest' ? selectedTestService.startTime ? selectedTestService.startTime.split(' ')[0] : '' : selectedTestService.startTimeLocal ? selectedTestService.startTimeLocal.split(' ')[0] : '';
  const endDate = type === 'antest' ? selectedTestService.endTime ? selectedTestService.endTime.split(' ')[0] : '' : selectedTestService.endTimeLocal ? selectedTestService.endTimeLocal.split(' ')[0] : '';
  const [dayOfWeek, setDayOfWeek] = React.useState([]);

  const [validity, setValidity] = useState(selectedSegment.validity);
  const [reason, setReason] = useState(selectedSegment.reason);

  const finalColumns4 = selectedSegment.defaultContent === 'Y' ? columns4 : columns4WithoutC;
  const finalColumns3 = selectedSegment.defaultContent === 'Y' ? columns3 : columns3WithoutC;
  const finalColumns2 = selectedSegment.defaultContent === 'Y' ? columns2 : columns2WithoutC;
  const finalColumns = selectedSegment.defaultContent === 'Y' ? columns : columnsWithoutC;
  const seriesBar = selectedSegment.defaultContent === 'Y' ? seriesData : seriesDataWithoutC;

  const populationCol = selectedSegment.defaultContent === 'Y' ? populationColumns : populationColumnsWithoutC;
  const noOfUds = selectedSegment.defaultContent === 'Y' ? udsColumn : udsColumnWithoutC;

  const getDailyUdsData = async () => {
    const dailyUdsData = await getDailyUdsDataAnTestService(selectedTestService.idx, type);
    let dailyUds = null;
    if (selectedSegment.segmentType === 'CMP') {
      const key = `total_unique_users_${selectedSegment.segmentTarget}_adts`;
      dailyUds = dailyUdsData.data.reduce((acc, item) => {
        const noConsents = item.total_unique_users_no_consent;
        const bValue = item[key] ? Math.floor((((item[key] * selectedSegment.segmentTargetRate) / 100) * (100 - selectedSegment.comparativeSegmentSampleRate)) / 100) : null;
        const cValue = item[key] ? Math.floor((((item[key] * selectedSegment.segmentTargetRate) / 100) * selectedSegment.comparativeSegmentSampleRate) / 100) : null;
        acc[item.DT] = {
          A: bValue && cValue && item.total_unique_users_beacon ? item.total_unique_users_beacon - bValue - cValue : 'N/A',
          B: bValue ? bValue : 'N/A',
          C: cValue ? cValue : 'N/A',
          noConsents: noConsents ? noConsents : null
        };
        return acc;
      }, {});
    } else if (selectedSegment.segmentType === 'PER') {
      dailyUds = dailyUdsData.data.reduce((acc, item) => {
        const noConsents = item.total_unique_users_no_consent;
        const total = item.total_unique_users_beacon;
        const bValue = total ? Math.floor((((total * selectedSegment.segmentTargetRate) / 100) * (100 - selectedSegment.comparativeSegmentSampleRate)) / 100) : null;
        const cValue = total ? Math.floor((((total * selectedSegment.segmentTargetRate) / 100) * selectedSegment.comparativeSegmentSampleRate) / 100) : null;
        acc[item.DT] = {
          A: bValue && cValue && total ? total - bValue - cValue : 'N/A',
          B: bValue ? bValue : 'N/A',
          C: cValue ? cValue : 'N/A',
          noConsents: noConsents ? noConsents : null
        };
        return acc;
      }, {});
    }
    setDailyUdsData(dailyUds);
  };
  useEffect(() => {
    getDailyData();
    getDescription();
    getDailyUdsData()
    if (type === 'anservice') {
      setDayOfWeek(selectedSegment.weekdays);
    }
    // eslint-disable-next-line
  }, [selectedTestService.idx, selectedSegment.idx]);

  const getAGroupDescription = () => {
    let p1 = `This group is the rest of users who had their own recommendation lists but assigned to neither the B group nor the C group. From  ${startDate} to ${endDate} (in local time), those users commonly watches the same list of contents added by the content organization team from the category/tab page.`
    setAGroupDescription(<>
      <p>{p1}</p>
    </>)
  }

  const getBGroupDescription = (bSegment) => {
    let p1 = '', p2 = '';
    const bGroupRate = Math.floor(
      selectedSegment.segmentTargetRate * (selectedSegment.defaultContent === 'Y' ? 100 - selectedSegment.comparativeSegmentSampleRate : 100) / 100
    );

    if (selectedSegment.segmentType === 'UPS' || selectedSegment.segmentType === 'CMP') {
      if (selectedSegment.mappingType === 'manual') {
        if (selectedSegment.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${selectedTestService.countryCode} -- randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly watch the same list of contents that have been manually inserted from the segment organize page.`;
        } else {  // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${bSegment?.segmentTarget} segment (${bSegment?.description}) -- randomly sampled in ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly watch the same list of contents that have been manually inserted from the segment organize page.`;
        }
      } else if (selectedSegment.mappingType === 'auto') {
        if (selectedSegment.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${selectedTestService.countryCode} -- randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly  watches the same list of contents of ${bSegment?.contentPolicyAuto}.`;
        } else {  // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${bSegment?.segmentTarget} segment (${bSegment?.description}) -- randomly sampled in ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly  watches the same list of contents of ${bSegment?.contentPolicyAuto}. `;
        }
      } else {
        setCGroupDescription('Unsupported mapping type.');
        return;
      }
    } else if (selectedSegment.segmentType === 'PER') {
      if (selectedSegment.mappingType === 'manual') {
        setBGroupDescription('Unsupported mapping type.');
        return;
      } else if (selectedSegment.mappingType === 'auto') {
        if (selectedSegment.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${selectedTestService.countryCode} --  randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), each user watches his/her own personalized contents (${bSegment?.contentPolicyAuto}) in different orders.`;
        } else {  // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${bSegment?.segmentTarget} segment (${bSegment?.description}) --  randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), each user watches his/her own personalized contents (${bSegment?.contentPolicyAuto}) in different orders.`;
        }
      } else {
        setCGroupDescription('Unsupported mapping type.');
        return;
      }
    } else {
      setBGroupDescription('Unsupported segment type.');
      return;
    }

    setBGroupDescription(<>
      <p>{p1} {p2}</p>
    </>);
  }

  const getCGroupDescription = (cSegment) => {
    let p1 = '', p2 = '';

    const cGroupRate = Math.floor(
      selectedSegment.segmentTargetRate * selectedSegment.comparativeSegmentSampleRate / 100
    );

    if (selectedSegment.comparativeSegmentType === 'NONE') {
      if (cSegment?.segmentMappingType === 'manual') {
        setCGroupDescription('Unsupported mapping type.');
        return;
      } else if (cSegment?.segmentMappingType === 'auto') {
        if (selectedSegment.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${selectedTestService.countryCode} -- randomly sampled with a ${cGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly  watches the same list of contents of ${cSegment?.contentPolicyAuto}.`;
        } else {  // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${cSegment?.segmentTarget} segment (${cSegment?.description}) -- randomly sampled with a ${cGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), those users commonly  watches the same list of contents of ${cSegment?.contentPolicyAuto}.`;
        }
      } else {
        setCGroupDescription('Unsupported mapping type.');
        return;
      }
    } else if (selectedSegment.comparativeSegmentType === 'PER') {
      if (cSegment?.segmentMappingType === 'manual') {
        setCGroupDescription('Unsupported mapping type.');
        return;
      } else if (cSegment?.segmentMappingType === 'auto') {
        if (selectedSegment.segmentTarget === 'All') {
          p1 = `This group has no segment but covers all the users in ${selectedTestService.countryCode} -- randomly sampled with a ${cGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), each user watches his/her own personalized contents (${cSegment?.contentPolicyAuto}) in different orders.`;
        } else {  // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${cSegment?.segmentTarget} segment (${cSegment?.description}) -- randomly sampled with a ${cGroupRate} sample rate.`;
          p2 = `From ${startDate} to ${endDate} (in local time), each user watches his/her own personalized contents (${cSegment?.contentPolicyAuto}) in different orders.`
        }
      } else {
        setCGroupDescription('Unsupported mapping type.');
        return;
      }
    } else {
      setCGroupDescription('Unsupported segment type.');
      return;
    }

    setCGroupDescription(<>
      <p>{p1} {p2}</p>
    </>);
  }

  const getDescription = async () => {
    const segmentDetails = await getSegmentDetails(selectedTestService.countryCode, type);
    getAGroupDescription(segmentDetails.data.filter(item =>
      item.segmentGroup === 'A'
      && item.segmentId === selectedSegment.segmentId
      && item.segmentTarget === selectedSegment.segmentTarget)[0])
    getBGroupDescription(segmentDetails.data.filter(item =>
      item.segmentGroup === 'B'
      && item.segmentId === selectedSegment.segmentId
      && item.segmentTarget === selectedSegment.segmentTarget)[0]);
    getCGroupDescription(segmentDetails.data.filter(item =>
      item.segmentGroup === 'C'
      && item.segmentId === selectedSegment.comparativeSegmentId
      && item.segmentTarget === selectedSegment.segmentTarget)[0]);
  }

  const getDailyData = async () => {
    const res = await getSegmentDailyData(selectedTestService.idx, selectedSegment.idx, type);
    setSegmentDailyDataList(res);
    setRequiredDetails(res);
  };

  const setRequiredDetails = (res) => {
    const dailyData = res.data.filter(data => (type === 'antest' ? data.abtest_id : data.recmd_segment_id) != null);

    if (dailyData.length > 0) {
      // set value by last Data
      const lastData = dailyData[dailyData.length - 1];

      // Average Duration Cumulative B over A, B over C
      setDurationCumulativeBOverA(type === 'antest' ? calImprovedRate(lastData.agg_all_content_B, lastData.agg_all_content_A) : calImprovedRate(lastData.agg_recmd_content_B, lastData.agg_recmd_content_A));
      setDurationCumulativeBOverC(type === 'antest' ? calImprovedRate(lastData.agg_all_content_B, lastData.agg_all_content_C) : calImprovedRate(lastData.agg_recmd_content_B, lastData.agg_recmd_content_C));

      // Content Click Rate Cumulative B over A, B over C
      setClickCumulativeBOverA(type === 'antest' ? calImprovedRate(lastData.agg_content_B, lastData.agg_content_A) : calImprovedRate(lastData.agg_click_per_tab_B, lastData.agg_click_per_tab_A));
      setClickCumulativeBOverC(type === 'antest' ? calImprovedRate(lastData.agg_content_B, lastData.agg_content_C) : calImprovedRate(lastData.agg_click_per_tab_B, lastData.agg_click_per_tab_C));

      // Clicked Unique Device Rate Cumulative B over A, B over C
      setUdCumulativeBOverA(calImprovedRate(lastData.agg_uniquedevice_B, lastData.agg_uniquedevice_A));
      setUdCumulativeBOverC(calImprovedRate(lastData.agg_uniquedevice_B, lastData.agg_uniquedevice_C));
    }
  }

  const formatNum = (value) => {
    if (value == null || isNaN(value)) {
      return 'N/A';
    }
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const calImprovedRate = (content_one, content_two) => {
    if (content_one == null || content_two == null || isNaN(content_one) || isNaN(content_two) || content_one <= 0 || content_two <= 0) {
      return 'N/A';
    }
    const improvedRate = (content_one - content_two) * 100 / content_two === 0 ? 0 : ((content_one - content_two) * 100 / content_two).toFixed(1)
    if (isNaN(improvedRate)) {
      return ''
    }
    return improvedRate + ' %';
  }

  const getPValue = (pValue) => {
    if (pValue == null || isNaN(pValue)) {
      return <b>N/A</b>;
    }
    if (pValue < 0.05) {
      return <b style={{ color: '#0d9488' }}>{pValue.toFixed(3)}</b>;
    }
    else {
      return <b style={{ color: '#b91c1c' }}>{pValue.toFixed(3)}</b>;
    }
  }

  const contentClickRate = (content) => {
    content = content && (content).toFixed(1)
    if (content == null || isNaN(content)) {
      return 'N/A'
    }
    return content + ' %';
  }

  const days = ['(SUN)', '(MON)', '(TUE)', '(WED)', '(THU)', '(FRI)', '(SAT)'];

  const datasetContentDuration = segmentDailyDataList.data.map((data, index) => {
    return {
      A: type === 'antest' ? data.all_content_A : data.avg_duration_A,
      B: type === 'antest' ? data.all_content_B : data.avg_duration_B,
      C: type === 'antest' ? data.all_content_C : data.avg_duration_C,
      ave: data.DT.substr(5, 9) + ' ' + days[new Date(data.DT).getDay()]
    };
  });

  const datasetContentClickRate = segmentDailyDataList.data.map((data, index) => {
    return {
      A: type === 'antest' ? data.content_A : data.avg_click_per_tab_A,
      B: type === 'antest' ? data.content_B : data.avg_click_per_tab_B,
      C: type === 'antest' ? data.content_C : data.avg_click_per_tab_C,
      ave: data.DT.substr(5, 9) + ' ' + days[new Date(data.DT).getDay()]
    };
  });

  const datasetUniqueDeviceRate = segmentDailyDataList.data.map((data, index) => {
    return {
      A: type === 'antest' ? data.uniquedevice_A : data.avg_uniquedevice_A,
      B: type === 'antest' ? data.uniquedevice_B : data.avg_uniquedevice_B,
      C: type === 'antest' ? data.uniquedevice_C : data.avg_uniquedevice_C,
      ave: data.DT.substr(5, 9) + ' ' + days[new Date(data.DT).getDay()]
    };
  });

  const getPopulationUdData = (date, group) => {
    return formatNum(dailyUdsData[date][group]);
  }

  const tooltipContent = { 'A': aGroupDescription, 'B': bGroupDescription, 'C': cGroupDescription }

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: tComponent,
                      message: {
                        selectedRow: selectedTestService,
                        selectedTestService: selectedTestService,
                        segmentIdx: selectedSegment.idx,
                        selectedSegment: selectedSegment,
                        mainFilteringData: mainFilteringData
                      }
                    }
                  });
                }}
              >
                {type === 'antest' ? 'AN Test Result' : 'AN Service Result'}
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap height-auto' component='div'>
          {/* First */}
          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <Box className='detail-list-container'>
                  <Grid container columnSpacing={2.5}>
                    <Grid item xs={6}>
                      <DetailItem label='title' className='' value={selectedTestService.title} />
                    </Grid>
                    <Grid item xs={6}>
                      {type === 'antest' ?
                        <DetailItem label='Time (UTC)' className='' value={selectedTestService.startTime + ' ~' + selectedTestService.endTime} />
                        : <DetailItem label='Time (Local Time)' className='' value={selectedTestService.startTimeLocal + ' ~' + selectedTestService.forceEndTimeLocal} />
                      }
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Country' className='' value={selectedTestService.countryCode} />
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Tab' className='' value={`${selectedTestService.tabCode} ${selectedTestService.tabName}`} />
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Category' className='' value={`${selectedTestService.categoryCode} ${selectedTestService.categoryName}`} />
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Segment Target' className='' value={selectedSegment.segmentTarget} />
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Segment Sample Rate' className='' value={selectedSegment.segmentTargetRate} />
                    </Grid>
                    <Grid item xs={4}>
                      <DetailItem label='Segment Description' className='' value={selectedSegment.description} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem label='B Group Recommendation Content' className='' value={selectedSegment.recommendationContentsBType} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem label='B Group Mapping Type' className='' value={selectedSegment.mappingType} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem label='B Group Rate' className='' value={selectedSegment.defaultContent === 'Y' ? 100 - selectedSegment.comparativeSegmentSampleRate : 100} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailItem label='B Group Content Policy' className='' value={selectedSegment.contentPolicy} />
                    </Grid>
                    {selectedSegment.defaultContent === 'Y' &&
                      <>
                        <Grid item xs={3}>
                          <DetailItem label='C Group Recommendation Content' className='' value={selectedSegment.recommendationContentsCType} />
                        </Grid>
                        <Grid item xs={3}>
                          <DetailItem label='C Group Mapping Type' className='' value={selectedSegment.comparativeMappingType} />
                        </Grid>
                        <Grid item xs={3}>
                          <DetailItem label='C Group Rate' className='' value={selectedSegment.comparativeSegmentSampleRate} />
                        </Grid>
                        <Grid item xs={3}>
                          <DetailItem label='C Group Content Policy' className='' value={selectedSegment.comparativeContentPolicy} />
                        </Grid>
                      </>
                    }
                    {type === 'antest' &&
                      <>
                        <Grid item xs={6}>
                          <DetailItem label='Validity' className='' value={validity} />
                        </Grid>
                        <Grid item xs={6}>
                          <DetailItem
                            label='Reason'
                            value={reason}
                            isButton={true}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <CustomTableContainer className='table-container'>
                  <h3>{`Population Size of ${selectedSegment.defaultContent === 'Y' ? 'Group A, Group B, and Group C' : 'Group A and Group B'}`}</h3>
                  <Table className='custom-table data-table' stickyHeader style={{ width: 'inherit' }}>
                    <TableHead className='thead'>
                      <TableRow>
                        {populationCol.map((col, index) => (
                          <TableCell
                            className='th'
                            key={index}
                            rowSpan={col.rowspan}
                            colSpan={col.colspan}
                            align='center'
                            style={{
                              position: 'sticky',
                              top: 0,
                              minWidth: col.minWidth,
                              fontWeight: col.fontWeight
                            }}
                          >
                            {col.label}
                          </TableCell>
                        ))}
                      </TableRow>

                      {/* Daily / Cumulative */}
                      <TableRow>
                        {noOfUds.map((col, index) => (
                          ////
                          <TableCell
                            className='th'
                            key={index}
                            rowSpan={col.rowspan}
                            colSpan={col.colspan}
                            align='center'
                            style={{
                              position: 'sticky',
                              top: 36,
                              minWidth: col.minWidth
                            }}
                          >
                            {col.tooltip ? <ThDivWithHelpIcon thText={col.label} tooltipContent={tooltipContent[col.label]} styles={{ width: '300px' }} /> : col.label}
                            {/* {col.label} */}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className='tbody'>
                      {
                        type === 'antest' ?
                          segmentDailyDataList.data.filter(data => data.abtest_id != null).map((data, index, bgColor) => (
                            <TableRow key={index}>
                              <TableCell className='td' align='center'>
                                {'Day ' + (index + 1)}
                              </TableCell>
                              <TableCell className='td' align='left'>
                                {data.week_day}
                              </TableCell>
                              <TableCell className='td' align='center'>
                                <span className='greenCircle'></span>
                              </TableCell>
                              <TableCell className='td'>{data.start_date.slice(0, 19)}</TableCell>
                              <TableCell className='td'>{data.end_date.slice(0, 19)}</TableCell>

                              {/* Daily data */}
                              <TableCell className='td yellow' align='right'>
                                {formatNum(data.all_content_A)}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {formatNum(data.all_content_B)}
                              </TableCell>
                              {selectedSegment.defaultContent === 'Y' &&
                                <TableCell className='td green' align='right'>
                                  {formatNum(data.all_content_C)}
                                </TableCell>}

                              <TableCell align='right'>
                                {getPopulationUdData(data.DT, 'noConsents')}
                              </TableCell>
                              <TableCell align='right'>
                                {data.no_recommendation_hashed_macs}
                              </TableCell>

                            </TableRow>
                          ))
                          :
                          // for AN Service
                          segmentDailyDataList.data.filter(data => data.recmd_segment_id != null).map((data, index, bgColor) => (
                            <TableRow key={index}>
                              <TableCell className='td' align='center'>
                                {'Day ' + (index + 1)}
                              </TableCell>
                              <TableCell className='td' align='left'>
                                {data.week_day}
                              </TableCell>
                              <TableCell className='td' align='center'>
                                {type === 'antest' ? <span className='greenCircle'></span> :
                                  dayOfWeek.includes(data.week_day.substring(0, 3).toUpperCase()) ? <span className='greenCircle'></span> : <span className='greenCircle edit'></span>
                                }
                              </TableCell>
                              <TableCell className='td'>{data.start_date.slice(0, 19)}</TableCell>
                              <TableCell className='td'>{data.end_date.slice(0, 19)}</TableCell>

                              {/* Daily data */}
                              <TableCell className='td yellow' align='right'>
                                {getPopulationUdData(data.DT, 'A')}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {getPopulationUdData(data.DT, 'B')}
                              </TableCell>
                              {selectedSegment.defaultContent === 'Y' &&
                                <TableCell className='td green' align='right'>
                                  {getPopulationUdData(data.DT, 'C')}
                                </TableCell>}
                              <TableCell align='right'>
                                {getPopulationUdData(data.DT, 'noConsents')}
                              </TableCell>
                              <TableCell align='right'>
                                {data.no_recommendation_hashed_macs}
                              </TableCell>
                            </TableRow>
                          ))
                      }

                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <Box className='data-table-container'>
                  <Box className='data-table-view'>
                    {/* Data */}
                    <CustomTableContainer className='table-container' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      <h3>{`Engagement rate of ${selectedSegment.defaultContent === 'Y' ? 'Group A, Group B, and Group C' : 'Group A and Group B'}`}</h3>
                      <Table className='custom-table data-table' stickyHeader>
                        <TableHead className='thead'>
                          <TableRow>
                            {finalColumns.map((col, index) => (
                              <TableCell
                                className='th'
                                key={index}
                                rowSpan={col.rowspan}
                                colSpan={col.colspan}
                                align='center'
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  minWidth: col.minWidth,
                                  fontWeight: col.fontWeight
                                }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>

                          {/* Daily / Cumulative */}
                          <TableRow>
                            {finalColumns2.map((col, index) => (
                              <TableCell
                                className='th'
                                key={index}
                                rowSpan={col.rowspan}
                                colSpan={col.colspan}
                                align='center'
                                style={{
                                  position: 'sticky',
                                  top: 36,
                                  minWidth: col.minWidth
                                }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>

                          {/* Header 3 */}
                          <TableRow>
                            {finalColumns3.map((col, index) => (
                              <TableCell
                                className='th'
                                colSpan={col.colspan}
                                key={`ind-${index}`}
                                align='center'
                                style={{
                                  position: 'sticky',
                                  top: 70,
                                  minWidth: col.minWidth
                                }}
                              >
                                {col.label === 'Category' ? <>{col.label}<br />({selectedTestService.categoryName})</>
                                  : col.label === 'Tab' ? <>{col.label}<br />({selectedTestService.tabName})</>
                                    : col.label === 'CTR (Click-Through Rate)' ? <ThDivWithHelpIcon thText={col.label} tooltipContent={'(Category / Tab) * 100'} />
                                      : col.label === 'UD Rate' ? <ThDivWithHelpIcon thText={col.label} tooltipContent={'(Category / Home-App) * 100'} />
                                        : col.label
                                }
                              </TableCell>
                            ))}
                          </TableRow>
                          {/* Header 4 */}
                          <TableRow>
                            {finalColumns4.map((col, index) => (
                              <TableCell
                                className='th'
                                key={`ind-${index}`}
                                align='center'
                                style={{
                                  position: 'sticky',
                                  top: 119,
                                  minWidth: col.minWidth
                                }}
                              >
                                {(() => {
                                  let tooltipContent = null;

                                  if (col.label === 'P-Value') {
                                    tooltipContent = <PValueTooltipContent />;
                                  } else if (col.parentLabel === 'Average Duration') {
                                    if (col.label === 'B over A') {
                                      tooltipContent = '(Average duration B - Average duration A) / Average duration A * 100';
                                    } else if (col.label === 'B over C') {
                                      tooltipContent = '(Average duration B - Average duration C) / Average duration C * 100';
                                    }
                                  } else if (col.parentLabel === 'Content Clicks') {
                                    if (col.label === 'A') {
                                      tooltipContent = '(Category A / Tab A) * 100';
                                    } else if (col.label === 'B') {
                                      tooltipContent = '(Category B / Tab B) * 100';
                                    } else if (col.label === 'C') {
                                      tooltipContent = '(Category C / Tab C) * 100';
                                    } else if (col.label === 'B over A') {
                                      tooltipContent = '(Click rate B - Click rate A) / Click rate A * 100';
                                    } else if (col.label === 'B over C') {
                                      tooltipContent = '(Click rate B - Click rate C) / Click rate C * 100';
                                    }
                                  } else if (col.parentLabel === 'Clicked Unique Device') {
                                    if (col.label === 'A') {
                                      tooltipContent = '(Category A / Home-App A) * 100';
                                    } else if (col.label === 'B') {
                                      tooltipContent = '(Category B / Home-App B) * 100';
                                    } else if (col.label === 'C') {
                                      tooltipContent = '(Category C / Home-App C) * 100';
                                    } else if (col.label === 'B over A') {
                                      tooltipContent = '(UD rate B - UD rate A) / UD rate A * 100';
                                    } else if (col.label === 'B over C') {
                                      tooltipContent = '(UD rate B - UD rate C) / UD rate C * 100';
                                    }
                                  }

                                  return tooltipContent ? (
                                    <ThDivWithHelpIcon thText={col.label} tooltipContent={tooltipContent} />
                                  ) : (
                                    col.label
                                  );
                                })()}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className='tbody'>
                          {
                            type === 'antest' ?
                              segmentDailyDataList.data.filter(data => data.abtest_id != null).map((data, index, bgColor) => (
                                <TableRow key={index}>
                                  <TableCell className='td' align='center'>
                                    {'Day ' + (index + 1)}
                                  </TableCell>
                                  <TableCell className='td' align='left'>
                                    {data.week_day}
                                  </TableCell>
                                  <TableCell className='td' align='center'>
                                    <span className='greenCircle'></span>
                                  </TableCell>
                                  <TableCell className='td'>{data.start_date.slice(0, 19)}</TableCell>
                                  <TableCell className='td'>{data.end_date.slice(0, 19)}</TableCell>

                                  {/* Daily data */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.all_content_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.all_content_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.all_content_C)}
                                    </TableCell>}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.all_content_B, data.all_content_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.all_content_B, data.all_content_A)}</span>
                                    ) : (
                                      calImprovedRate(data.all_content_B, data.all_content_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_duration_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.all_content_B, data.all_content_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.all_content_B, data.all_content_C)}</span>
                                      ) : (
                                        calImprovedRate(data.all_content_B, data.all_content_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_duration_B_C)}
                                    </TableCell>}

                                  {/* Cumulative data */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_all_content_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_all_content_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_all_content_C)}
                                    </TableCell>}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_all_content_B, data.agg_all_content_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_all_content_B, data.agg_all_content_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_all_content_B, data.agg_all_content_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_average_duration_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_all_content_B, data.agg_all_content_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_all_content_B, data.agg_all_content_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_all_content_B, data.agg_all_content_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_average_duration_B_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Category  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.abtest_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.abtest_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.abtest_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Tab  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.tab_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.tab_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.tab_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Home-App  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.all_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.all_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.all_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Clicks Rate  */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.content_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.content_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.content_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Improved  */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.content_B, data.content_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.content_B, data.content_A)}</span>
                                    ) : (
                                      calImprovedRate(data.content_B, data.content_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_content_click_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.content_B, data.content_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.content_B, data.content_C)}</span>
                                      ) : (
                                        calImprovedRate(data.content_B, data.content_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_content_click_B_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_abtest_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_abtest_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_abtest_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_tab_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_tab_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_tab_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Home-App */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_all_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_all_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_all_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Clicks Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.agg_content_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.agg_content_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.agg_content_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_content_B, data.agg_content_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_content_B, data.agg_content_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_content_B, data.agg_content_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_content_click_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_content_B, data.agg_content_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_content_B, data.agg_content_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_content_B, data.agg_content_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_content_click_B_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.abtest_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.abtest_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.abtest_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.tab_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.tab_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.tab_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Home-App */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.homeapp_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.homeapp_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.homeapp_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > UD Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.uniquedevice_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.uniquedevice_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.uniquedevice_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.uniquedevice_B, data.uniquedevice_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.uniquedevice_B, data.uniquedevice_A)}</span>
                                    ) : (
                                      calImprovedRate(data.uniquedevice_B, data.uniquedevice_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_unique_device_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.uniquedevice_B, data.uniquedevice_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.uniquedevice_B, data.uniquedevice_C)}</span>
                                      ) : (
                                        calImprovedRate(data.uniquedevice_B, data.uniquedevice_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_unique_device_B_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_abtest_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_abtest_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_abtest_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_tab_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_tab_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_tab_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > HomeApp */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_homeapp_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_homeapp_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_homeapp_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > UD Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.agg_uniquedevice_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.agg_uniquedevice_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.agg_uniquedevice_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_unique_device_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_unique_device_B_C)}
                                    </TableCell>}
                                </TableRow>
                              ))
                              :
                              // for AN Service
                              segmentDailyDataList.data.filter(data => data.recmd_segment_id != null).map((data, index, bgColor) => (
                                <TableRow key={index}>
                                  <TableCell className='td' align='center'>
                                    {'Day ' + (index + 1)}
                                  </TableCell>
                                  <TableCell className='td' align='left'>
                                    {data.week_day}
                                  </TableCell>
                                  <TableCell className='td' align='center'>
                                    {type === 'antest' ? <span className='greenCircle'></span> :
                                      dayOfWeek.includes(data.week_day.substring(0, 3).toUpperCase()) ? <span className='greenCircle'></span> : <span className='greenCircle edit'></span>
                                    }
                                  </TableCell>
                                  <TableCell className='td'>{data.start_date.slice(0, 19)}</TableCell>
                                  <TableCell className='td'>{data.end_date.slice(0, 19)}</TableCell>

                                  {/* Daily data */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.avg_duration_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.avg_duration_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.avg_duration_C)}
                                    </TableCell>}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.avg_duration_B, data.avg_duration_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.avg_duration_B, data.avg_duration_A)}</span>
                                    ) : (
                                      calImprovedRate(data.avg_duration_B, data.avg_duration_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_duration_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.avg_duration_B, data.avg_duration_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.avg_duration_B, data.avg_duration_C)}</span>
                                      ) : (
                                        calImprovedRate(data.avg_duration_B, data.avg_duration_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_duration_B_C)}
                                    </TableCell>}

                                  {/* Cumulative data */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_recmd_content_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_recmd_content_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_recmd_content_C)}
                                    </TableCell>}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_average_duration_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_recmd_content_B, data.agg_recmd_content_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_average_duration_B_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Category  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.recmd_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.recmd_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.recmd_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Tab  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.tab_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.tab_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.tab_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Home-App  */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.all_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.all_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.all_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Clicks Rate  */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.avg_click_per_tab_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.avg_click_per_tab_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.avg_click_per_tab_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Improved  */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_A)}</span>
                                    ) : (
                                      calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_content_click_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_C)}</span>
                                      ) : (
                                        calImprovedRate(data.avg_click_per_tab_B, data.avg_click_per_tab_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_content_click_B_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_recmd_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_recmd_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_recmd_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_tab_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_tab_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_tab_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Home-App */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_all_content_click_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_all_content_click_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_all_content_click_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Clicks Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.agg_click_per_tab_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.agg_click_per_tab_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.agg_click_per_tab_C)}
                                    </TableCell>}

                                  {/* Content Clicks > Cumulative data > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_content_click_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_click_per_tab_B, data.agg_click_per_tab_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_content_click_B_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.recmd_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.recmd_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.recmd_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.tab_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.tab_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.tab_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Home-App */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.homeapp_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.homeapp_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.homeapp_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > UD Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.avg_uniquedevice_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.avg_uniquedevice_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.avg_uniquedevice_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Daily > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_A)}</span>
                                    ) : (
                                      calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_unique_device_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_C)}</span>
                                      ) : (
                                        calImprovedRate(data.avg_uniquedevice_B, data.avg_uniquedevice_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_unique_device_B_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Category */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_recmd_content_click_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_recmd_content_click_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_recmd_content_click_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Tab */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_tab_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_tab_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_tab_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > HomeApp */}
                                  <TableCell className='td yellow' align='right'>
                                    {formatNum(data.agg_homeapp_unique_device_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {formatNum(data.agg_homeapp_unique_device_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {formatNum(data.agg_homeapp_unique_device_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > UD Rate */}
                                  <TableCell className='td yellow' align='right'>
                                    {contentClickRate(data.agg_uniquedevice_A)}
                                  </TableCell>
                                  <TableCell className='td red' align='right'>
                                    {contentClickRate(data.agg_uniquedevice_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td green' align='right'>
                                      {contentClickRate(data.agg_uniquedevice_C)}
                                    </TableCell>}

                                  {/* Clicked Unique Device > Cumulative > Improved */}
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {parseFloat(calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A).replace(" %", "")) < 0 ? (
                                      <span className='zero-under'>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A)}</span>
                                    ) : (
                                      calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A)
                                    )}
                                  </TableCell>
                                  <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                    {getPValue(data.pvalue_cumulative_unique_device_A_B)}
                                  </TableCell>
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {parseFloat(calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C).replace(" %", "")) < 0 ? (
                                        <span className='zero-under'>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C)}</span>
                                      ) : (
                                        calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C)
                                      )}
                                    </TableCell>}
                                  {selectedSegment.defaultContent === 'Y' &&
                                    <TableCell className='td blue' align='right' style={{ fontWeight: 'bold' }}>
                                      {getPValue(data.pvalue_cumulative_unique_device_B_C)}
                                    </TableCell>}
                                </TableRow>
                              ))
                          }

                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {actionDialog &&
            <PopEditValidReason
              onClose={() => setActionDialog(false)}
              handelSetShowSnackBar={handelSetShowSnackBar}
              id={selectedTestService.idx}
              sid={selectedSegment.idx}
              validity={validity}
              reasonData={reason}
              setValidity={setValidity}
              setReason={setReason}
              type={type}
            />}

          {/* Chart */}
          <Box display='flex' gap={1.5} flexWrap={'nowrap'} pb={3}>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={datasetContentDuration}
                    height={350}
                    xAxis={[
                      {
                        label: 'Average Duration (in mins)',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        stepSize: 0.5, // 간격
                        valueFormatter: (value) => `${value}`, // 단위 수정
                      },
                    ]}
                    series={seriesBar}
                    {...chartSetting}
                  />
                  <Box pt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: '14px' }}>{`Cumulative B over A: ${durationCumulativeBOverA}`}</p>
                    {selectedSegment.defaultContent !== 'N' &&
                      <p style={{ fontSize: '14px' }}>{`Cumulative B over C: ${durationCumulativeBOverC}`}</p>}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={datasetContentClickRate}
                    height={350}
                    xAxis={[
                      {
                        label: 'CTR (Click-Through Rate) (%)',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        stepSize: 5,
                        valueFormatter: (value) => `${value}%`, // 단위 수정
                      },
                    ]}
                    series={seriesBar}
                    {...chartSetting}
                  />
                  <Box pt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: '14px' }}>{`Cumulative B over A: ${clickCumulativeBOverA}`}</p>
                    {selectedSegment.defaultContent !== 'N' &&
                      <p style={{ fontSize: '14px' }}>{`Cumulative B over C: ${clickCumulativeBOverC}`}</p>}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={datasetUniqueDeviceRate}
                    height={350}
                    xAxis={[
                      {
                        label: 'Click Unique Device Rate (%)',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        stepSize: 2,
                        valueFormatter: (value) => `${value}%`, // 단위 수정
                      },
                    ]}
                    series={seriesBar}
                    {...chartSetting}
                  />
                  <Box pt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ fontSize: '14px' }}>{`Cumulative B over A: ${udCumulativeBOverA}`}</p>
                    {selectedSegment.defaultContent !== 'N' &&
                      <p style={{ fontSize: '14px' }}>{`Cumulative B over C: ${udCumulativeBOverC}`}</p>}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div>
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={1000} />
      </div>
    </Box>
  );
}
export default ANTestServiceResult;
