import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Pagination from 'components/component/BasicPagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextLink from 'components/component/BasicTextLink';

import { Box, Typography, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import {BASE_URL} from "constants/index";
import {getHeaders} from "utils/Header";
import PopDeviceNotificationHistoryDetailTV from "pages/Organization/DeviceNotification/PopDeviceNotificationHistoryDetailTV";
import {useNavigate} from "react-router-dom";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

function DeviceNotificationHistory({ message }) {

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Device Notification',
    normalMenu: ['History - Device Notification'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...historyList].sort((a, b) => {
      let left = a[property] ? a[property].toString().toLowerCase() : '';
      let right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setHistoryList([...sortedData])
  };

  const [title, setTitle] = useState('');
  const [historyList, setHistoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isOpenHistoryDetail, setIsOpenHistoryDetail] = useState(false);
  const [detailVersion, setDetailVersion] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (message) {
      setTitle(message.title)
      getHistoryList(message.notificationId)
    }
  }, [message]);

  const getHistoryList = (notificationId) => {
    fetch(`${BASE_URL}/device-notification/notifications/history/${notificationId}`,{
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setHistoryList(body.data);
      }
    })
  }

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (historyList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(historyList.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [historyList, page, itemsPerPage]);

  const handleDetailOpen = (version) => {
    setDetailVersion(version);
    setIsOpenHistoryDetail(true);
  }

  const navigateBack = (currentFilter) => {
    navigate('/DeviceNotification', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'DeviceNotification',
        message: {
          currentFilter: currentFilter
        }
      }
    })
  }

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' style={{cursor: "pointer"}} onClick={() => navigateBack(message.currentFilter)}>
                History-Device Notification
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='white-box white-box-wrap'>
            <Box className='box-content'>
              <Box className='version-number' display={'flex'}>
                <Box display={'flex'}>
                  <Typography component={'span'} className='label'>
                    Title
                  </Typography>
                  <Typography component={'span'} className='value-black'>
                    {title}
                  </Typography>
                </Box>
              </Box>
              <Box className='white-box-content-top-area' mt={3} mb={1}>
                <Box className='left-area'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {historyList.length}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        <TableCell key={'version'} className='th' align={'left'} sx={{ width: '40%' }}>
                          <TableSortLabel
                              active={orderBy === 'version'}
                              direction={orderBy === 'version' ? order : 'desc'}
                              onClick={() => handleRequestSort('version')}
                          >
                            Version
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'crtDate'} className='th' align={'left'} sx={{ width: '60%' }}>
                          <TableSortLabel
                              active={orderBy === 'crtDate'}
                              direction={orderBy === 'crtDate' ? order : 'desc'}
                              onClick={() => handleRequestSort('crtDate')}
                          >
                            Last Chg Date
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          <TableCell className='td' align={'left'}>
                            <TextLink text={td[`version`]} onClick={() => handleDetailOpen(td['version'])} style={{cursor: 'pointer'}} />
                          </TableCell>
                          <TableCell className='td' align={'left'}>
                            {td[`crtDate`].replace('T', ' ').replace('Z', '')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={Math.ceil(historyList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </Box>
          </Box>
        </Box>
      </Box>
      {isOpenHistoryDetail && (
          <PopDeviceNotificationHistoryDetailTV notificationId={message.notificationId} version={detailVersion} handleClose={() => setIsOpenHistoryDetail(false)} />
      )}

    </Box>
  );
}

export default DeviceNotificationHistory;