import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import BasicSwitches from 'components/component/BasicSwitch';
import TextField from 'components/component/BasicTextField';

import {Grid, IconButton, Typography} from '@mui/material';

import { Box } from '@mui/system';
import {BASE_URL} from "../../../constants";
import {getHeaders, getHeadersFormData} from "../../../utils/Header";
import {CloseIcon24Black} from "../../../components/component/BasicIcon";
import Loading from "../../../components/component/Loading";

function PopAddFAQ({ name, closeAddFaq, afterSave }) {

  const handleClose = () => {
    closeAddFaq();
  };

  const [isDisplayFlagSwitchOn, setIsDisplayFlagSwitchOn] = useState(true);
  const [editQuestion, setEditQuestion] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [paramCategories, setParamCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [paramChannels, setParamChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    fetch(`${BASE_URL}/support/faq/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setParamCategories(body.data.categories?.map(category => ({id: category, value: category, option: category, type: 'text'})));
        setParamChannels(body.data.channels?.map(channel => ({id: channel, value: channel, option: channel, type: 'text'})));
      }
    })
  }, []);

  useEffect(() => {
    setSelectedCategory(paramCategories[0]?.value);
  }, [paramCategories]);

  useEffect(() => {
    setSelectedChannel(paramChannels[0]?.value);
  }, [paramChannels]);

  const addFaq = () => {
    setIsLoading(true);

    const faq = {
      category: selectedCategory,
      channel: selectedChannel,
      question: editQuestion,
      answer: editAnswer,
      displayFlag: isDisplayFlagSwitchOn ? 'Y' : 'N',
    }
    const formData = new FormData();
    formData.append('faq',
        new Blob([JSON.stringify(faq)], {type: "application/json"}));
    attachmentFiles.forEach(file => formData.append('files', file));

    fetch(`${BASE_URL}/support/faq`, {
      method: 'POST',
      headers: getHeadersFormData(),
      body: formData,
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info')
        setAlertMessage('FAQ added successfully.');
        setAlertOpen(true);
        handleClose();
      } else {
        setAlertTitle('Warning')
        setAlertMessage('Failed to add FAQ.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      setIsLoading(false);
      afterSave();
    })
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: addFaq,
    },
  ];

  const addAttachmentFile = (file) => {
    if (attachmentFiles.map(f => f.name).includes(file.name)) {
      setAlertTitle('Warning')
      setAlertMessage('No duplicate file name is allowed.');
      setAlertOpen(true);
      return;
    }
    setAttachmentFiles([...attachmentFiles, file]);
  }

  const excludeAttachmentFile = (file) => {
    setAttachmentFiles(attachmentFiles.filter(f => f.name !== file.name));
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add FAQ' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-add-faq'
        contentTextProps={
          isLoading ? <Loading /> :
          <>
            {/* Category/Channel */}
            <Grid container>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={6} mt={1}>
                  <SelectBox
                    id='tab1-channel'
                    size='large'
                    shrink={true}
                    label='Channel'
                    selectOptions={paramChannels}
                    disabled={false}
                    placeholder=''
                    required={true}
                    selected={selectedChannel}
                    onChange={(newValue) => setSelectedChannel(newValue)}

                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <SelectBox
                    id='tab1-Category'
                    size='large'
                    shrink={true}
                    label='Category'
                    selectOptions={paramCategories}
                    disabled={false}
                    placeholder=''
                    required={true}
                    selected={selectedCategory}
                    onChange={(newValue) => setSelectedCategory(newValue)}

                  />
                </Grid>
              </Grid>

              {/* CP Code */}
              <Grid item xs={12} mt={3} component='div' className='switch-with-label-box field-box'>
                <Typography className='field-label bold required' mr={3}>
                  Display
                </Typography>

                {/* Switch */}
                <BasicSwitches
                  disabled={false}
                  onOffMode={isDisplayFlagSwitchOn}
                  setSwitchOnOff={setIsDisplayFlagSwitchOn}
                  isLabelText={true}
                  onText='On'
                  offText='Off'
                />
              </Grid>

              {/*  Upload File */}
              <Grid item xs={12} mt={4}>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='Title'
                  label='Content'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={editQuestion}
                  onChange={(e) => setEditQuestion(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={3}>
                <TextField
                  type='outlined'
                  size='large'
                  label=''
                  placeholder=''
                  multiline
                  readOnly={false}
                  rows={6} // 텍스트 영역의 기본 행 수
                  variant='outlined' // 테두리 스타일
                  fullWidth // 전체 너비 사용
                  className='textarea'
                  value={editAnswer}
                  onChange={(e) => setEditAnswer(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Box className='field-box'>
                  <FileUpload
                    id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                    buttonLabel='Upload'
                    size='large'
                    label=''
                    placeholder='Search files'
                    onFileChange={addAttachmentFile}
                    accept='.jpg,.png,.pdf,.pptx,.xlsx'
                    subText=''
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    File Upload(Only .jpeg, png, pdf, pptx, xlsx files can be uploaded.)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} mt={2} className='upload-box'>
                <Box component='div' className='upload-file-list'>
                  <div className='upload-total-count'>Upload File</div>
                  {attachmentFiles.map((file, index) => (
                      <Box key={index} className='upload-file' display='flex' justifyContent='space-between'>
                        <Box className='box-content align-row' display='flex'>
                          <Box className='right-area'>
                            <Box component='div' className='upload-info-wrap'>
                              <Typography component='p' className='file-name'>
                                {file.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <IconButton onClick={() => excludeAttachmentFile(file)}>
                          <CloseIcon24Black />
                        </IconButton>
                      </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

export default PopAddFAQ;
