import {
  Box,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import SearchField from "../../../components/component/BasicSearchField";
import CustomButtonGroup from "../../../components/component/BasicButtonGroup";
import CheckBox from "../../../components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextLink from "../../../components/component/BasicTextLink";
import Pagination from "../../../components/component/BasicPagination";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopCodeManagementAddTab from "./PopCodeManagementAddTab";
import Dialog from "../../../components/component/BasicDialog";
import PopCodeManagementEditTab from "./PopCodeManagementEditTab";


// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

function CodeManagementTab() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayTabCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayTabCodes([...sortedData])
  };

  const [tabCodes, setTabCodes] = useState([]);
  const [displayTabCodes, setDisplayTabCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [addTabOpen, setAddTabOpen] = useState(false);
  const [editTabOpen, setEditTabOpen] = useState(false);
  const [tabToEdit, setTabToEdit] = useState({});
  const [checkedTabs, setCheckedTabs] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  const searchTab = () => {
    fetch(`${BASE_URL}/code-management/tab`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setTabCodes(body.data);
        setDisplayTabCodes(body.data);
      }
    })
  }

  useEffect(() => {
    searchTab();
  }, []);

  useEffect(() => {
    setDisplayTabCodes(tabCodes.filter(
        (item) => item.tabName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.tabCode.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handleEditTabOpen = (tabCode) => {
    setEditTabOpen(true);
    setTabToEdit(tabCodes.find(tab => tab.tabCode === tabCode));
  }

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedTabs];
    if (e.target.checked) {
      checkedList.push(e.target.value);
    } else {
      checkedList = checkedList.filter((item) => item !== e.target.value);
    }
    if (displayTabCodes.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedTabs(checkedList);
  }

  useEffect(() => {
    if (displayTabCodes.length === 0) {
      setAllChecked(false);
    } else if (displayTabCodes.length === checkedTabs.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [displayTabCodes, checkedTabs]);

  useEffect(() => {
    setCheckedTabs([...checkedTabs.filter(tabCode => displayTabCodes.map(item => item.tabCode).includes(tabCode))]);
    // eslint-disable-next-line
  }, [displayTabCodes]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedTabs(displayTabCodes.map((item) => item.tabCode));
      setAllChecked(true);
    } else {
      setCheckedTabs([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (tabCode) => {
    return checkedTabs.includes(tabCode);
  }

  const handleConfirmDelete = () => {
    if (checkedTabs.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the Tab you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected Tabs?');
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const deleteTabs = () => {
    setConfirmOpen(false);

    fetch(`${BASE_URL}/code-management/tab`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedTabs)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Selected Tabs have been deleted successfully.');
        setAlertOpen(true);
        searchTab();
      } else {
        setAlertTitle('Error');
        setAlertMessage('Failed to delete Tabs.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      setCheckedTabs([]);
    })
  }

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayTabCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayTabCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayTabCodes, page, itemsPerPage]);

  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: handleConfirmDelete
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      autosize: true,
      onClick: () => setAddTabOpen(true),
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteTabs
    },
  ];

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {displayTabCodes.length}
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)} />
              </Box>
            </Box>

            <Box className='right-area'>
              <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'tabCode'} className='th' align={'left'} sx={{ width: '40%' }}>
                      <CheckBox
                          label=''
                          value='select-all'
                          checked={allChecked}
                          inputProps={{
                            'aria-label': 'select all',
                          }}
                          onChange={onClickCheckAll}
                      />
                      <TableSortLabel
                          active={orderBy === 'tabCode'}
                          direction={orderBy === 'tabCode' ? order : 'desc'}
                          onClick={() => handleRequestSort('tabCode')}
                      >
                        Tab Code
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'tabName'} className='th' align={'left'} sx={{ width: 'auto' }}>
                      <TableSortLabel
                          active={orderBy === 'tabName'}
                          direction={orderBy === 'tabName' ? order : 'desc'}
                          onClick={() => handleRequestSort('tabName')}
                      >
                        Tab Name
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'tabCode'} className='td' align='left'>
                              <CheckBox
                                  label={<TextLink href='' text={td[`tabCode`]}
                                                   onClick={() => handleEditTabOpen(td.tabCode)} />}
                                  value={td.tabCode}
                                  onChange={onClickCheckBox}
                                  checked={getIfChecked(td.tabCode)}
                              />
                        </TableCell>
                        <TableCell key={'tabName'} className='td' align='left'>
                          {td[`tabName`]}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayTabCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
        {addTabOpen && (
            <PopCodeManagementAddTab onClose={() => setAddTabOpen(false)} afterSave={searchTab} tabs={tabCodes} />
        )}
        {editTabOpen && (
            <PopCodeManagementEditTab onClose={() => setEditTabOpen(false)} afterSave={searchTab} tabToEdit={tabToEdit} tabs={tabCodes} />
        )}

        <Dialog
            open={isAlertOpen} // boolean 값으로 설정
            handleClose={handleAlertClose}
            closeX={false}
            size='small' // large, medium, small
            title={alertTitle} // Popup Title
            contentTitle={false} // Content Title
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
        />
      </Box>
  )
}

export default CodeManagementTab;