import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import BasicSwitches from 'components/component/BasicSwitch';
import TextField from 'components/component/BasicTextField';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomRadio from 'components/component/BasicRadio';
import { Box, Typography, Link, Grid, RadioGroup } from '@mui/material';

import {
  BtnTeritaryOutlineRight,
  DelMinus
} from 'components/component/BasicIcon';
import { AddPlus } from 'components/component/BasicIcon';

import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import { BASE_URL } from "constants/index";
import {getHeaders, getHeadersFormData} from "utils/Header";
import { connect } from "react-redux";
import BasicSelectCheckBox from "components/component/BasicSelectCheckBox";
import Dialog from "components/component/BasicDialog";
import Loading from "components/component/Loading";

//메인 컨텐츠
function DeviceNotificationDetail({ name, message, country }) {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Device Notification',
    normalMenu: ['Device Notification Detail'],
  };

  // Radio Data
  const radioOptions = [
    { id: 'Text', checked: false, value: 'Text', label: 'Text', name: 'radio' },
    { id: 'Text and Image', checked: true, value: 'Text and Image', label: 'Text and Image', name: 'radio' },
    { id: 'Image', checked: true, value: 'Image', label: 'Image', name: 'radio' },
  ];

  // radio
  const [selectedValue, setSelectedValue] = useState('Text');
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Switch
  const [isEnableOn, setIsEnableOn] = useState(false);

  const navigate = useNavigate();

  const navigateBack = (currentFilter) => {
    navigate('/DeviceNotification', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'DeviceNotification',
        message: {
          currentFilter: currentFilter
        }
      }
    })
  }

  const imagePrefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;
  const [notification, setNotification] = useState({});
  const [titleInput, setTitleInput] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState({src: '', width: 0, height: 0});
  const [deeplinkUrlInput, setDeeplinkUrlInput] = useState('');
  const [multiLangInputFields, setMultiLangInputFields] = useState([{ id: 1, language: ' ', title: '', content: '' }]);
  const [paramLangCodes, setParamLangCodes] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [countryCounter, setCountryCounter] = useState(1);
  const [multiLangCounter, setMultiLangCounter] = useState(1);
  const [countryChannelMapInputFields, setCountryChannelMapInputFields] = useState([{ id: 1, country: ' ', paramChannelMap: [], selectedChannelMaps: [] }]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    fetch(`${BASE_URL}/code-management/all-lang-code`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setParamLangCodes(body.data.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text',
        })));
      }
    })
  }, []);

  useEffect(() => {
    if (message.notification && country) {
      const noti = message.notification
      setNotification(noti)
      setIsEnableOn(noti.enable === 'Y')
      switch (noti.type) {
        case 'text':
          setSelectedValue('Text')
          break;
        case 'textImage':
          setSelectedValue('Text and Image')
          break;
        case 'image':
          setSelectedValue('Image')
          break;
        default:
          setSelectedValue('Text')
          break;
      }
      setTitleInput(noti.title);

      const img = new Image();
      img.src = imagePrefix + noti.imageUrl;
      img.onload = () => {
        setImagePreview({src: img.src, width: img.width, height: img.height});
      }
      setDeeplinkUrlInput(noti.deeplinkUrl);

      if (noti.multiLangPayloads && noti.multiLangPayloads.length > 0) {
        setMultiLangInputFields(noti.multiLangPayloads.map((payload, index) => ({
          id: index + 1,
          language: payload.langCode,
          title: payload.title ? payload.title : '',
          content: payload.content ? payload.content : '',
        })))
        setMultiLangCounter(noti.multiLangPayloads.length + 1);
      }
      setStartDate(dayjs(noti.startDate));
      setEndDate(dayjs(noti.endDate));

      let countryChannelMap = noti.countryList.map((item, index) => {
        let selectedChannelMaps =
            country.serviceCountry
              .find(serviceCountry => serviceCountry.countryCode === item)?.channelMapList
              .filter(cm => noti.channelMaps.includes(cm.channelMapId))
              .map(cm => cm.channelMapId) || [];
        return {id: index + 1, country: item, paramChannelMap: getCountryChannelMaps(item,selectedChannelMaps), selectedChannelMaps: selectedChannelMaps};
      })
      setCountryCounter(countryChannelMap.length + 1);
      setCountryChannelMapInputFields(countryChannelMap)
    }

    setFetching(false);
    // eslint-disable-next-line
  }, [message, country]);

  const handleOpenImageURL = (e) => {
    e.preventDefault();
    window.open(imagePreview.src, '_blank');
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setImagePreview({src: img.src, width: img.width, height: img.height});
      }
    }
  }

  useEffect(() => {
    if (country.serviceCountry) {
      const existingCountries = countryChannelMapInputFields.map(field => field.country);
      const countries = country.serviceCountry.map(item => ({
        id: item.countryCode,
        value: item.countryCode,
        option: item.countryCode,
        type: 'text',
        disabled: existingCountries.includes(item.countryCode),
      }))
      countries.sort((a, b) => a.value.localeCompare(b.value));
      setParamCountry(countries);
    }
    // eslint-disable-next-line
  }, [country, countryChannelMapInputFields]);

  const handleAddCountry = () => {
    setCountryChannelMapInputFields([...countryChannelMapInputFields, {id: countryCounter + 1, country: ' ',
      paramChannelMap: [], selectedChannelMaps: [] }]);
    setCountryCounter(countryCounter + 1);
  }

  const handleRemoveCountry = (id) => {
    setCountryChannelMapInputFields(countryChannelMapInputFields.filter(field => field.id !== id));
  }

  const handleCountryChange = (id, value) => {
    const newInputFields = countryChannelMapInputFields.map(inputField => {
      if (inputField.id === id) {
        return {...inputField, country: value, paramChannelMap: getCountryChannelMaps(value, [])};
      }
      return inputField;
    });
    setCountryChannelMapInputFields(newInputFields);
  }

  const handleChannelMapCheck = (id, selectedChannelMaps) => {
    const newInputFields = countryChannelMapInputFields.map(inputField => {
      if (inputField.id === id) {
        return { ...inputField, selectedChannelMaps };
      }
      return inputField;
    });
    setCountryChannelMapInputFields(newInputFields);
  };

  const getCountryChannelMaps = (countryCode, selectedChannelMaps) => {
    if (country.serviceCountry) {
      const target = country.serviceCountry.find(item => item.countryCode === countryCode);
      if (target) {
        return [{id: 'all', value: 'all', option: 'All', type: 'checkbox'},
          ...target.channelMapList.map(item => item.channelMapId)
          .map(item => ({id: item, value: item, option: item, type: 'checkbox', defaultChecked: selectedChannelMaps.includes(item)}))];
      }
    }
  }

  const handleAddMultiLang = () => {
    setMultiLangInputFields([...multiLangInputFields, {id: multiLangCounter + 1, language: ' ', title: '', content: ''}]);
    setMultiLangCounter(multiLangCounter + 1);
  }

  const handleRemoveMultiLang = (id) => {
    setMultiLangInputFields(multiLangInputFields.filter(field => field.id !== id));
  }

  const handleMultiLangChange = (id, field, value) => {
    const newInputFields = multiLangInputFields.map(inputField => {
      if (inputField.id === id) {
        return {...inputField, [field]: value};
      }
      return inputField;
    });
    setMultiLangInputFields(newInputFields);
  }

  const updateNotification = () => {
    // 국가 선택해야 함 / 국가별 최소 1개 이상의 채널맵 선택해야 함
    for (let field of countryChannelMapInputFields) {
      if (field.country === ' ' || field.selectedChannelMaps.length === 0) {
        setAlertTitle('Failed');
        setAlertMessage('Please select country and channel map.');
        setAlertOpen(true);
        return;
      }
    }
    if (titleInput === '') {
      setAlertTitle('Failed');
      setAlertMessage('Please input title.');
      setAlertOpen(true);
      return;
    }
    if (startDate > endDate) {
      setAlertTitle('Failed');
      setAlertMessage('Start date must be earlier than end date.');
      setAlertOpen(true);
      return;
    }
    if ((selectedValue === 'Image' || selectedValue === 'Text and Image') && !imageFile) {
      setAlertTitle('Failed');
      setAlertMessage('Please upload image.');
      setAlertOpen(true);
      return;
    }
    // multi-language 영역은 Text, Text and Image 일 때만 필수
    if (selectedValue === 'Text' || selectedValue === 'Text and Image') {
      for (let field of multiLangInputFields) {
        if (field.language === ' ' || field.title === '') {
          setAlertTitle('Failed');
          setAlertMessage('Please input language and title.');
          setAlertOpen(true);
          return;
        }
      }
    }

    const data = {
      idx: notification.idx,
      notificationId: notification.notificationId,
      enable: isEnableOn ? 'Y' : 'N',
      type: selectedValue === 'Text' ? 'text' : selectedValue === 'Text and Image' ? 'textImage' : 'image',
      deviceType: 'tv',
      publishStatus: notification.publishStatus,
      title: titleInput,
      version: dayjs().format('YYYYMMDD-HHmmss'),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      imageUrl: selectedValue === 'Text' ? null : notification.imageUrl,
      deeplinkUrl: selectedValue === 'Text' ? null : deeplinkUrlInput,
      multiLangPayloads: multiLangInputFields.map(item => ({
        langCode: item.language,
        title: item.title,
        content: selectedValue === 'Image' ? null : item.content,
      })),
      countryList: countryChannelMapInputFields.map(field => field.country),
      channelMaps: countryChannelMapInputFields.map(item => item.selectedChannelMaps).flat(),
    }

    const formData = new FormData();
    formData.append('notification', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    if (selectedValue !== 'Text' && imageFile) {
      formData.append('image', imageFile);
    }

    fetch(`${BASE_URL}/device-notification/notifications/${notification.notificationId}`, {
      method: 'PATCH',
      headers: getHeadersFormData(),
      body: formData,
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Notification updated successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Failed');
        setAlertMessage('Failed to update notification.');
        setAlertOpen(true);
      }
    })
  }

  const Buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Cancel',
      autosize: true,
      onClick: () => navigateBack(message.currentFilter),
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      onClick: updateNotification,
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
    if (alertTitle === 'Success') {
      navigateBack(message.currentFilter);
    }
  }

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
      <Box display='flex' className='wrapper'>
        <Box className='main-container' component='main'>
          {/* BreadCrumb Area */}
          <Box className='top-title-container'>
            <Box className='top-title-box'>
              <Box className='title-area'>
                <Link className='title link' style={{cursor: "pointer"}} onClick={() => navigateBack(message.currentFilter)}>
                  Device Notification Detail
                </Link>
              </Box>
              <Box className='bread-area'>
                <BreadCrumb optionMenu={optionMenu}/>
              </Box>
            </Box>
          </Box>

          <Box className='content-wrap program-detail' component='div'>
            <Box className='white-box white-box-wrap'>
              <Box className='box-content'>
                <Box className='version-number' display={'flex'}>
                  <Box display={'flex'}>
                    <Typography component={'span'} className='label'>
                      Version
                    </Typography>
                    <Typography component={'span'} className='value'>
                      {notification.version}
                    </Typography>
                  </Box>
                  <Box className='button-group'>
                    <CustomButtonGroup buttons={Buttons}/>
                  </Box>
                </Box>
                {fetching ? <Loading /> : (
                <Box className='detail-list-container type-category'>
                  <Grid container columnSpacing={2.5}>
                    <Grid item xs={12} className='list-wrap'>
                      <Box className='info-value-wrap no-border bold'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Enable</span>
                        </Typography>
                        <Box className='value'>
                          <BasicSwitches
                              disabled={false}
                              onOffMode={isEnableOn}
                              setSwitchOnOff={setIsEnableOn}
                              isLabelText={true}
                              onText='On'
                              offText='off'
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className='list-wrap'>
                      <Box className='info-value-wrap no-border align-top'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Country / Channel Map</span>
                        </Typography>
                        <Box className='value'>
                          {countryChannelMapInputFields.map((inputField, index) => (
                          <Box component='div' className='field-box flex-start'>
                            <SelectBox
                                id='detail-Country-1'
                                size='large'
                                maxWidth='170px'
                                required={true}
                                selectOptions={paramCountry}
                                disabled={false}
                                label=''
                                shrink
                                selected={inputField.country}
                                onChange={(newValue) => handleCountryChange(inputField.id, newValue)}
                            />
                            <BasicSelectCheckBox
                                id='select-map-1'
                                size='large'
                                maxWidth='310px'
                                required={true}
                                selectOptions={inputField.paramChannelMap}
                                disabled={false}
                                label=''
                                shrink
                                multiple={true}
                                onChange={(newValue) => handleChannelMapCheck(inputField.id, newValue)}
                            />
                            {inputField.id === 1 ? (
                                <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={AddPlus}
                                    iconPos='center'
                                    autosize
                                    onClick={handleAddCountry}
                                />
                            ) : (
                                <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={DelMinus}
                                    iconPos='center'
                                    autosize
                                    onClick={() => handleRemoveCountry(inputField.id)}
                                />
                            )}
                          </Box>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className='list-wrap'>
                      <Box className='info-value-wrap no-border'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Type</span>
                        </Typography>
                        <Box className='value'>
                          <Box>
                            <RadioGroup value={selectedValue}
                                        onChange={handleRadioChange}
                                        className='align-h'>
                              {radioOptions.map((option) => (
                                  <Box key={option.value} sx={{mr: 6}}>
                                    <CustomRadio
                                        value={option.value}
                                        label={option.label}
                                        onChange={handleRadioChange}
                                        name={name}
                                    />
                                  </Box>
                              ))}
                            </RadioGroup>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className='list-wrap'>
                      <Box className='info-value-wrap no-border'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Title</span>
                        </Typography>
                        <Box className='value'>
                          <Box component='div' className='field-box flex-start'>
                            <TextField
                                type='outlined'
                                size='large'
                                placeholder=''
                                label=''
                                autoComplete='off'
                                required={false}
                                InputLabelProps={{shrink: false}}
                                value={titleInput}
                                onChange={(e) => setTitleInput(e.target.value)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6} className='list-wrap'>
                      <Box className='info-value-wrap no-border'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Start Date</span>
                        </Typography>
                        <Box className='value'>
                          <DatePicker size='large' singleDate={true} maxDate={'2099-12-31'}
                                      value={startDate}
                                      onChange={setStartDate}/>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6} className='list-wrap'>
                      <Box className='info-value-wrap no-border'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>End Date</span>
                        </Typography>
                        <Box className='value'>
                          <DatePicker size='large' singleDate={true} maxDate={'2099-12-31'}
                                      value={endDate} onChange={setEndDate}/>
                        </Box>
                      </Box>
                    </Grid>

                    {selectedValue !== 'Text' && (
                      <>
                        <Grid item xs={12} className='list-wrap'>
                          <Box className='info-value-wrap no-border align-top'
                               sx={{padding: '7px 0'}}>
                            <Typography component='span'
                                        className='label required'>
                              <span className='text'>Image</span>
                            </Typography>
                            <Box className='value'>
                              <>
                                <CustomImage fit={true} src={imagePreview.src} sx={{width: '100%', height: 202}}
                                             imgHeight={202} alt='' rounded/>
                                <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                  <Typography
                                      className='img-size'>{imagePreview.width}px X {imagePreview.height}px</Typography>
                                  <Box>
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Image URL'
                                      isIcon={true}
                                      Icon={BtnTeritaryOutlineRight}
                                      iconPos='right'
                                      onClick={handleOpenImageURL}
                                      sx={{mr: 1}}
                                    />
                                    <input
                                      type='file'
                                      id='file-upload'
                                      style={{display: 'none'}}
                                      onChange={handleImageUpload}
                                    />
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Upload File'
                                      isIcon={true}
                                      Icon={BtnTeritaryOutlineRight}
                                      iconPos='right'
                                      onClick={() => document.getElementById('file-upload').click()}
                                    />
                                  </Box>
                                </Box>
                              </>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12} className='list-wrap'>
                          <Box className='info-value-wrap no-border' sx={{padding: '7px 0'}}>
                            <Typography component='span' className='label'>
                              <span className='text'>Deep Link URL</span>
                            </Typography>
                            <Box className='value'>
                              <TextField
                                type='outlined'
                                size='large'
                                placeholder=''
                                label=''
                                autoComplete='off'
                                InputLabelProps={{shrink: false}}
                                value={deeplinkUrlInput}
                                onChange={(e) => setDeeplinkUrlInput(e.target.value)}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} className='list-wrap'>
                      <Box className='info-value-wrap no-border align-top'
                           sx={{padding: '7px 0'}}>
                        <Typography component='span' className='label required'>
                          <span className='text'>Language</span>
                        </Typography>
                        <Box className='value'>
                          {multiLangInputFields.map((inputField, index) => (
                            <React.Fragment key={inputField.id}>
                              <Box component='div' className='field-box flex-start'>
                                <SelectBox
                                  id='select-tab-name-region-1'
                                  size='large'
                                  maxWidth='170px'
                                  required={true}
                                  selectOptions={paramLangCodes}
                                  disabled={false}
                                  label=''
                                  shrink
                                  selected={inputField.language}
                                  onChange={(newValue) => handleMultiLangChange(inputField.id, 'language', newValue)}
                                />
                                <TextField
                                  type='outlined'
                                  size='large'
                                  placeholder='placeholder'
                                  label=''
                                  autoComplete='off'
                                  required={false}
                                  InputLabelProps={{shrink: false}}
                                  value={inputField.title}
                                  onChange={(e) => handleMultiLangChange(inputField.id, 'title', e.target.value)}
                                />
                                {inputField.id === 1 ? (
                                  <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={AddPlus}
                                    iconPos='center'
                                    autosize
                                    onClick={handleAddMultiLang}
                                  />
                                ) : (
                                  <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={DelMinus}
                                    iconPos='center'
                                    autosize
                                    onClick={() => handleRemoveMultiLang(inputField.id)}
                                  />
                                )}
                              </Box>
                              {selectedValue !== 'Image' && (
                                <Box item mt={1}>
                                  <TextField
                                    type='outlined'
                                    size='large'
                                    label=''
                                    placeholder='Content'
                                    multiline
                                    readOnly={false}
                                    rows={6} // 텍스트 영역의 기본 행 수
                                    variant='outlined' // 테두리 스타일
                                    fullWidth // 전체 너비 사용
                                    className='textarea'
                                    value={inputField.content}
                                    onChange={(e) => handleMultiLangChange(inputField.id, 'content', e.target.value)}
                                  />
                                </Box>
                              )}
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </Box>
  );
}

const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNotificationDetail);
