import {
  Box,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import SearchField from "../../../components/component/BasicSearchField";
import CustomButtonGroup from "../../../components/component/BasicButtonGroup";
import CheckBox from "../../../components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextLink from "../../../components/component/BasicTextLink";
import Button from "../../../components/component/BasicButton";
import {FileIcon} from "../../../components/component/BasicIcon";
import Pagination from "../../../components/component/BasicPagination";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopEditNotification from "./PopEditNotification";
import PopAddNotification from "./PopAddNotification";
import Dialog from "../../../components/component/BasicDialog";

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

function SupportNotification() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayNotifications].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayNotifications([...sortedData])
  };

  const [paramCategories, setParamCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [paramChannels, setParamChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('All');
  const [notifications, setNotifications] = useState([]);
  const [isOpenEditNotification, setIsOpenEditNotification] = useState(false);
  const [notificationToEdit, setNotificationToEdit] = useState({});
  const [isOpenAddNotification, setIsOpenAddNotification] = useState(false);
  const [checkedNotifications, setCheckedNotifications] = useState([]);
  const [displayNotifications, setDisplayNotifications] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/support/notification/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        let map = body.data.categories.map(category => ({id: category, value: category, option: category, type: 'text'}));
        setParamCategories([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...map]);
        map = body.data.channels.map(channel => ({id: channel, value: channel, option: channel, type: 'text'}));
        setParamChannels([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...map]);
      }
    })
  }, []);

  const searchNotifications = (category, channel) => {
    fetch(`${BASE_URL}/support/notification?category=${category}&channel=${channel}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setNotifications(body.data);
      }
    })
  }

  useEffect(() => {
    searchNotifications(selectedCategory, selectedChannel);
  }, [selectedCategory, selectedChannel]);

  useEffect(() => {
    setDisplayNotifications(notifications);
  }, [notifications]);

  const openEditNotification = (notificationIdx) => {
    fetch(`${BASE_URL}/support/notification/${notificationIdx}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then((res) => res.json())
    .then((body) => {
      if (body.result === 'SUCCESS') {
        setNotificationToEdit(body.data);
      }
    })
    setIsOpenEditNotification(true);
  }

  useEffect(() => {
    setDisplayNotifications(notifications.filter(
        (item) => item.title.toLowerCase().includes(
                searchTextInput.toLowerCase())
            || item.displayFlag.toLowerCase().includes(
                searchTextInput.toLowerCase())));

    setPage(1);
    //eslint-disable-next-line
  }, [searchTextInput]);

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedNotifications];
    if (e.target.checked) {
      checkedList.push(parseInt(e.target.value));
    } else {
      checkedList = checkedList.filter((item) => item !== parseInt(e.target.value));
    }
    if (displayNotifications.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedNotifications(checkedList);
  }

  useEffect(() => {
    if (displayNotifications.length === 0) {
      setAllChecked(false);
    } else if (displayNotifications.length === checkedNotifications.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [displayNotifications, checkedNotifications]);

  useEffect(() => {
    setCheckedNotifications([...checkedNotifications.filter(idx => displayNotifications.map(item => item.idx).includes(idx))]);
    // eslint-disable-next-line
  }, [displayNotifications]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedNotifications(displayNotifications.map((item) => item.idx));
      setAllChecked(true);
    } else {
      setCheckedNotifications([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (idx) => {
    return checkedNotifications.includes(idx);
  }

  const handleConfirmDelete = () => {
    if (checkedNotifications.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the Notification you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected Notifications?');
  }

  const deleteNotifications = () => {
    setConfirmOpen(false);

    fetch(`${BASE_URL}/support/notification`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedNotifications),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Notifications deleted successfully.');
        setAlertOpen(true);
        searchNotifications(selectedCategory, selectedChannel);
        setCheckedNotifications([]);
      }
    })
  }

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayNotifications.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayNotifications.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayNotifications, page, itemsPerPage]);


  const buttons = [
    {
      minWidth: '78px',
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      onClick: handleConfirmDelete,
    },
    {
      minWidth: '66px',
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      onClick: () => setIsOpenAddNotification(true),
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteNotifications
    },
  ];

  return (
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='tab1-Category'
                        size='large'
                        shrink={true}
                        label='Category'
                        selectOptions={paramCategories}
                        disabled={false}
                        placeholder=''
                        selected={selectedCategory}
                        onChange={(newValue) => setSelectedCategory(newValue)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='tab1-channel'
                        size='large'
                        shrink={true}
                        label='Channel'
                        selectOptions={paramChannels}
                        disabled={false}
                        placeholder=''
                        selected={selectedChannel}
                        onChange={(newValue) => setSelectedChannel(newValue)}
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {displayNotifications.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SearchField
                        id='search-2'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        value={searchTextInput}
                        onChange={(e) => setSearchTextInput(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={buttons}/>
                </Box>
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table'
                         stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        <TableCell key={'category'} className='th' align={'left'} sx={{width: '10%'}}>
                          <CheckBox
                              label=''
                              value='select-all'
                              checked={allChecked}
                              inputProps={{
                                'aria-label': 'select all',
                              }}
                              onChange={onClickCheckAll}
                          />
                          <TableSortLabel
                              active={orderBy === 'category'}
                              direction={orderBy === 'category' ? order : 'desc'}
                              onClick={() => handleRequestSort('category')}
                          >
                            Category
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'channel'} className='th' align={'left'} sx={{width: '10%'}}>
                          <TableSortLabel
                              active={orderBy === 'channel'}
                              direction={orderBy === 'channel' ? order : 'desc'}
                              onClick={() => handleRequestSort('channel')}
                          >
                            Channel
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'title'} className='th' align={'left'} sx={{width: 'auto'}}>
                          <TableSortLabel
                              active={orderBy === 'title'}
                              direction={orderBy === 'title' ? order : 'desc'}
                              onClick={() => handleRequestSort('title')}
                          >
                            Title
                          </TableSortLabel>
                        </TableCell>
                        <TableCell key={'displayFlag'} className='th' align={'left'} sx={{width: '10%'}}>
                          Display
                        </TableCell>
                        <TableCell key={'fixFlag'} className='th' align={'left'} sx={{width: '10%'}}>
                          Fix
                        </TableCell>
                        <TableCell key={'fileList'} className='th' align={'left'} sx={{width: '10%'}}>
                          File
                        </TableCell>
                        <TableCell key={'crtDate'} className='th' align={'left'} sx={{width: '10%'}}>
                          Registration Date
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            <TableCell key={'category'} className='td' align={'left'}>
                              <CheckBox label={td['category']} value={td.idx}
                                        onChange={onClickCheckBox}
                                        checked={getIfChecked(td.idx)} />
                            </TableCell>
                            <TableCell key={'channel'} className='td' align={'left'}>
                              {td['channel']}
                            </TableCell>
                            <TableCell key={'title'} className='td' align={'left'}>
                              <TextLink href='' text={td['title']}
                                        onClick={() => openEditNotification(td.idx)} />
                            </TableCell>
                            <TableCell key={'displayFlag'} className='td' align={'left'}>
                              {td['displayFlag']}
                            </TableCell>
                            <TableCell key={'fixFlag'} className='td' align={'left'}>
                              {td['fixFlag']}
                            </TableCell>
                            <TableCell key={'fileList'} className='td' align={'left'}>
                              {td['fileList'].length > 0 ? (
                                <Button
                                    color=''
                                    type='only-icon bg-no'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={FileIcon}
                                    sx={{cursor: 'initial'}}
                                />
                              ) : ('')}
                            </TableCell>
                            <TableCell key={'crtDate'} className='td' align={'left'}>
                              {td['crtDate']}
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              <Pagination count={Math.ceil(displayNotifications.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </div>
          </Box>
        </div>
        <PopEditNotification openEditNotification={isOpenEditNotification}
                             closeEditNotification={() => setIsOpenEditNotification(false)}
                             notificationToEdit={notificationToEdit}
                             afterSave={() => searchNotifications(selectedCategory, selectedChannel)}/>
        <PopAddNotification openAddNotification={isOpenAddNotification}
                            closeAddNotification={() => setIsOpenAddNotification(false)}
                            afterSave={() => searchNotifications(selectedCategory, selectedChannel)}/>
        <Dialog
            open={isAlertOpen} // boolean 값으로 설정
            handleClose={handleAlertClose}
            closeX={false}
            size='small' // large, medium, small
            title={alertTitle} // Popup Title
            contentTitle={false} // Content Title
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
        />
      </div>
  );

}

export default SupportNotification;