import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import CustomTabs from 'components/component/BasicTabs';
import Snackbar from 'components/component/BasicSnackbar';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import SelectBox from 'components/component/BasicSelectBox';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';
// Data
import {
  InformationGenreTh,
  InformationGenreCpTh,
} from 'pages/Organization/TabCategory/data/PopInformationData';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '440px',
  overflowY: 'auto',
}));

// Select
const selOptionAllData = [
  { id: 1, value: 'Genre Count', option: 'Genre Count', type: 'text' },
];

// 메인 컨텐츠
function PopInformationGenreCount(props) {
  const { countryByGenreList, onClose } = props;

  useEffect(() => {
    if (countryByGenreList) {
      const distinctGenre = countryByGenreList.reduce((acc, cur) => {
        if (!acc.some(obj => obj.contentType === cur.contentType && obj.genreCode === cur.genreCode && obj.genreName === cur.genreName)) {
          acc.push(cur);
        }
        return acc;
      }, []);

      const genreCount = countryByGenreList.reduce((acc, cur) => {
        if (acc[cur.contentType + cur.genreCode]) {
          acc[cur.contentType + cur.genreCode] += cur.count;
        } else {
          acc[cur.contentType + cur.genreCode] = cur.count;
        }
        return acc;
      }, {});

      const searchCountryByGenre = distinctGenre.map(n => {
        const newItem = {
          contentType: n.contentType,
          countryCode: n.countryCode,
          genreCode: n.genreCode,
          genreName: n.genreName,
          count: genreCount[n.contentType + n.genreCode],
        }
        return newItem;
      })

      setAllByGenreList(searchCountryByGenre);
      setDisplayProviderByGenreList(countryByGenreList);
    }
    // eslint-disable-next-line
  }, []);

  const [allByGenreList, setAllByGenreList] = useState([]);
  const [displayCountryByGenreList, setDisplayCountryByGenreList] = useState([]);
  const [displayProviderByGenreList, setDisplayProviderByGenreList] = useState([]);

  useEffect(() => {
    if (allByGenreList) {
      setDisplayCountryByGenreList(allByGenreList);
    }
    // eslint-disable-next-line
  }, [allByGenreList]);

  const handleClose = () => {
    onClose();
  };

  const [searchAllFilter, setSearchAllFilter] = useState('');
  // All 연관 검색 시
  useEffect(() => {
    if (searchAllFilter) {
      const filteredData = allByGenreList.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchAllFilter.toLowerCase()));
      });
      setDisplayCountryByGenreList([...filteredData]);
    } else {
      setDisplayCountryByGenreList(allByGenreList);
    }
    // eslint-disable-next-line
  }, [searchAllFilter]);

  // All 연관 검색창에 검색어 입력 시
  const handleSearchAll = (value) => {
    setSearchAllFilter(value);
  };

  const [searchCpFilter, setSearchCpFilter] = useState('');
  // CP 연관 검색 시
  useEffect(() => {
    if (searchCpFilter) {
      const filteredData = countryByGenreList.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchCpFilter.toLowerCase()));
      });
      setDisplayProviderByGenreList([...filteredData]);
    } else {
      setDisplayProviderByGenreList(countryByGenreList);
    }
    // eslint-disable-next-line
  }, [searchCpFilter]);

  // CP 연관 검색창에 검색어 입력 시
  const handleSearchCp = (value) => {
    setSearchCpFilter(value);
  };

  const [openSelect, setOpenSelect] = useState(false);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displayCountryByGenreList && displayCountryByGenreList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayCountryByGenreList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displayCountryByGenreList, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayCountryByGenreList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayCountryByGenreList([...sortedData]);
  };

  // Paging 기능에서 사용하는 데이터
  const [cpPaginatedData, setCpPaginatedData] = useState([]);
  const [cpPage, setCpPage] = useState(1);
  const [cpItemsPerPage, setCpItemsPerPage] = useState(20);
  const [cpOrder, setCpOrder] = useState('asc');
  const [cpOrderBy, setCpOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displayProviderByGenreList && displayProviderByGenreList.length > 0) {
      const startIndex = (cpPage - 1) * cpItemsPerPage;
      const endIndex = startIndex + cpItemsPerPage;
      const newPaginatedData = displayProviderByGenreList.slice(startIndex, endIndex);
      setCpPaginatedData([...newPaginatedData]);
    }
  }, [displayProviderByGenreList, cpPage, cpItemsPerPage]);

  const handleCpPagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setCpPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setCpItemsPerPage(pagingValue.value);
        setCpPage(1);
      }
    }
  };

  const handleCpRequestSort = (property) => {
    const isAsc = cpOrderBy === property && cpOrder === 'asc';
    setCpOrder(isAsc ? 'desc' : 'asc');
    setCpOrderBy(property);

    const sortedData = [...displayProviderByGenreList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayProviderByGenreList([...sortedData]);
  };


  // Tab
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabs = [{ label: 'All' }, { label: 'CP' }];

  return (
    <div className='component-view'>
      <Dialog open={true} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Information</DialogTitle>
          <SelectBox
            id='select-01'
            fullWidth
            size='medium'
            maxWidth='180px'
            selectOptions={selOptionAllData}
            disabled={false}
            isFirstSelected={true}
            label=''
            open={openSelect}
            setOpen={setOpenSelect}
            shrink={false}
          />
        </HeaderArea>
        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='information-container'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={tabs} />

              {/* All */}
              {value === 0 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='detail-list-container'>
                      <Box className='white-box-content-top-area' justifyContent='flex-end' gap={1} sx={{ padding: 0 }}>
                        <SearchField
                          size='medium'
                          placeholder='Please enter a search term'
                          maxWidth='230px'
                          onChange={(event) => handleSearchAll(event.target.value)}
                        />
                      </Box>

                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {InformationGenreTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    <>
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {paginatedData.map((row, index) => (
                                <TableRow className='tr' key={row.id || index}>
                                  {InformationGenreTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        row.contentType
                                      ) : colIndex === 1 ? (
                                        row.countryCode
                                      ) : colIndex === 2 ? (
                                        row.genreCode
                                      ) : colIndex === 3 ? (
                                        row.genreName
                                      ) : colIndex === 4 ? (
                                        row.count
                                      ) : (
                                        row.count
                                      )
                                      }
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>

                      {/* Pagination */}
                      <Pagination count={Math.ceil(displayCountryByGenreList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                    </Box>
                  </div>
                </div>
              )}

              {/* CP */}
              {value === 1 && (
                <div className='tab-panel'>
                  <div className='panel-wrap'>
                    <Box className='detail-list-container'>
                      <Box className='white-box-content-top-area' justifyContent='flex-end' gap={1} sx={{ padding: 0 }}>
                        <SearchField
                          size='medium'
                          placeholder='Please enter a search term'
                          maxWidth='230px'
                          onChange={(event) => handleSearchCp(event.target.value)}
                        />
                      </Box>

                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {InformationGenreCpTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    <>
                                      <TableSortLabel
                                        active={cpOrderBy === column.id}
                                        direction={cpOrderBy === column.id ? cpOrder : 'desc'}
                                        onClick={() => handleCpRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {cpPaginatedData.map((row, index) => (
                                <TableRow className='tr' key={row.id || index}>
                                  {InformationGenreCpTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        row.contentType
                                      ) : colIndex === 1 ? (
                                        row.countryCode
                                      ) : colIndex === 2 ? (
                                        row.providerId
                                      ) : colIndex === 3 ? (
                                        row.genreCode
                                      ) : colIndex === 4 ? (
                                        row.genreName
                                      ) : colIndex === 5 ? (
                                        row.count
                                      ) : (
                                        row.count
                                      )
                                      }
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>

                      {/* Pagination */}
                      <Pagination count={Math.ceil(displayProviderByGenreList.length / cpItemsPerPage)} id='pagination-02' selected={cpItemsPerPage} onChangeHandle={handleCpPagination} page={cpPage} />
                    </Box>
                  </div>
                </div>
              )}
            </Box>
          </BoxContainer>
        </DialogContent>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopInformationGenreCount;
