import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Pagination from 'components/component/BasicPagination';
import SortLabel from 'components/component/BasicTableSortLabel';
import { Box, Typography, Link, ListItem, List, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChannelMapHistoryDetailHeader } from 'pages/Organization/ChannelMapping/data/DefaultData';
import { getRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import { getExcelFile } from 'utils/ExcelDownload';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));
const CellType = styled(TableCell)(({ theme }) => ({
  '& .deleted': {
    color: '#B91C1C',
  },
  '& .created': {
    color: '#0077B5',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

// Main
function ChannelMapHistoryDetail({ message }) {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Channel Map History', 'Channel Map History Details'],
  };
  const [history, setHistory] = useState([]);

  // page
  const [historyList, setHistoryList] = useState([]);
  const [displayHistoryList, setDisplayHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);

  useEffect(() => {
    if (message) {
      setHistory(message.historyRow);
      getHistoryDetailList(message.historyRow.historyIdx);
    }
    // eslint-disable-next-line
  }, [message]);

  const getHistoryDetailList = async (historyIdx) => {
    const body = await getRequest(`/channel-map/history/${historyIdx}`, null);
    if (body && body.result === 'SUCCESS') {
      setHistoryList(body.data);
      setPageNavigate(body.data, currentPage, pageSize);
      setOrder('asc');
      setOrderBy('');
    }
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setPageNavigate(historyList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageNavigate(historyList, 1, paging.value);
      }
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      setDisplayHistoryList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayHistoryList([]);
    }
    setCurrentPage(current);
    setPageSize(size);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDisplayHistoryList(sortRows([...historyList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const channelMapHistoryExcelDownload = async () => {
    if (!isEmpty(history) && !isEmpty(historyList) && historyList.length > 0) {
      const body = await getRequest(`/channel-map/history/${history.historyIdx}/excel`, null);
      if (body && body.result === 'SUCCESS') {
        getExcelFile(body.data);
      }
    }
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container web-live' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Channel Map History Details
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              {!isEmpty(history) && (
                <List className='info-list-container'>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Country</Typography>
                    <Typography className='value'>{history.countryCode}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                  <ListItem className='list-item'>
                    <Typography className='label'>Channel Map</Typography>
                    <Typography className='value'>{history.channelMapId}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                  <ListItem className='list-item'>
                    <Typography className='label'>#Type</Typography>
                    <Typography className='value'>{history.type}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                  <ListItem className='list-item'>
                    <Typography className='label'>#Channel Map Change Date</Typography>
                    <Typography className='value'>{history.historyDate}</Typography>
                  </ListItem>
                </List>
              )}
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Box className='white-box white-box-wrap '>
            <Box className='box-content'>
              <Box className='white-box-content-top-area' alignItems='center'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {isEmpty(historyList) ? 0 : historyList.length}
                      </Box>
                    </Box>
                    <Box className='sum-count-container'>
                      {!isEmpty(history) && (
                        <List className='count-list'>
                          <ListItem className='count-item'>
                            Deleted <span className='count-value red'>{history.removedCount}</span>
                          </ListItem>
                          <ListItem className='count-item' sx={{ margin: '0 8px' }}>
                            |
                          </ListItem>
                          <ListItem className='count-item'>
                            Created <span className='count-value blue'>{history.addedCount}</span>
                          </ListItem>
                          <ListItem className='count-item' sx={{ margin: '0 8px' }}>
                            |
                          </ListItem>
                          <ListItem className='count-item'>
                            Updated <span className='count-value green'>{history.editedCount}</span>
                          </ListItem>
                        </List>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='outline' size='small' label='Download' isIcon={false} autosize onClick={channelMapHistoryExcelDownload} />
                </Box>
                {/* right-area */}
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {ChannelMapHistoryDetailHeader.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <SortLabel
                              labelText={column.label}
                              labelId={column.labelId}
                              sortable={column.sortable}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              order={order}
                              setOrder={setOrder}
                              sorting={dataSorting}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {displayHistoryList.map((td, index) => (
                        // 240926 추가수정 - TableRow 클래스 추가
                        <TableRow
                          className={`tr ${td.editAction === 'removed' ? 'Deleted' : td.editAction === 'added' ? 'Created' : td.editAction === 'update' ? 'Update' : '-'}`}
                          key={td.id || index}>
                          {ChannelMapHistoryDetailHeader.map((column, colIndex) => (
                            <CellType key={`HistoryDetail_${column.id}`} className='td' align={column.align}>
                              {column.labelId === 'editAction' ? (
                                <span className={td.editAction === 'removed' ? 'deleted' : td.editAction === 'added' ? 'created' : td.editAction === 'update' ? 'update' : ''}>
                                  {td[column.labelId]}
                                </span>
                              ) : (
                                td[column.labelId]
                              )}
                            </CellType>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
              {/* Pagination */}
              {isPageNavigate && <Pagination count={Math.ceil(historyList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ChannelMapHistoryDetail;
