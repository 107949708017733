import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomBasicMenu from 'components/component/BasicMenu';
import BasicList from 'components/component/BasicList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getRequest } from 'utils/Request';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/Valid';
import { patchRequest } from 'utils/Request';

import { styled } from '@mui/material/styles';

import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  UpcomingVodTh,
  UpcomingResultTh,
} from 'pages/Organization/TabCategory/data/TabCategoryData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { RefreshIcon } from 'components/component/BasicIcon';

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

// 메인 컨텐츠
function OrganizeUpcoming(props) {
  const { message } = props;
  const { tabIdx, categoryIdx, tab, category, countryOptions, providerOptions } = message;

  // 검색 Country 필터
  const [countryFilter, setCountryFilter] = useState(countryOptions[0].value);
  // 검색 provider 필터
  const [providerFilter, setProviderFilter] = useState('All');
  // 검색 Content Type 필터
  const [contentTypeFilter, setContentTypeFilter] = useState(category.contentType && category.contentType.split(',')[0]);
  // 검색 Content ID 필터
  const [contentIdFilter, setContentIdFilter] = useState('');
  // 검색 Content Name 필터
  const [contentNameFilter, setContentNameFilter] = useState('');

  // 편성 가능한 Content 데이터 목록
  const [searchContent, setSearchContent] = useState([]);
  const [displaySearchContent, setDisplaySearchContent] = useState([]);
  const [checkedSearchContent, setCheckedSearchContent] = useState([]);

  // 편성된 Content 데이터 목록
  const [resultContent, setResultContent] = useState([]);
  const [displayResultContent, setDisplayResultContent] = useState([]);
  const [checkedResultContent, setCheckedResultContent] = useState([]);

  // Search, Result 그리드에 연관검색 키워드 input
  const [searchContentFilter, setSearchContentFilter] = useState('');
  const [resultContentFilter, setResultContentFilter] = useState('');

  // Content Type이 live 일 경우와 vod 일 경우 화면 UI 및 비율 변경
  const [isLive, setIsLive] = useState(false);
  const [ratio, setRatio] = useState(6);

  const navigate = useNavigate();

  const [searchChannel, setSearchChannel] = useState([{id:'', value:'', primary:'', secondary:'', disabled: false}]);
  /* Temp Data */
  const [isOpenArray, setIsOpenArray] = useState([true, true, true]);
  const togglePanel = (index) => {
    setIsOpenArray((prev) => prev.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };
  /* Temp Data */

  // 카테고리에 설정된 contentType으로 Select Box Option 값 생성
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  useEffect(() => {
    if (category.contentType) {
      const contentTypeSplit = category.contentType.split(',').map(n => {
        const newItem = {
          id: n,
          value: n,
          option: n,
          type: 'text',
        }
        return newItem;
      });
      setContentTypeOptions(contentTypeSplit);

      if (category.contentType.includes('live')) {
        setIsLive(true);
        setRatio(4);
      } else {
        setIsLive(false);
        setRatio(6);
      }
    }
    // eslint-disable-next-line
  }, [category]);

  // 검색 Country 필터 값 변경 시 해당 국가의 데이터를 호출
  useEffect(() => {
    resultData();
    // eslint-disable-next-line
  }, [countryFilter]);

  // Search Content가 변경될 경우 화면 렌더링 객체인 Display에 데이터 셋팅 및 Result에 존재하는 Content는 비활성화
  useEffect(() => {
    if (searchContent) {
      setDisplaySearchContent(searchContent);
      setExistResult(searchContent, resultContent);
    }
    // eslint-disable-next-line
  }, [searchContent]);

  // Result Content 변경 시 화면 렌더링 객체인 Display데 데이터 셋팅
  useEffect(() => {
    if (resultContent) {
      setDisplayResultContent(resultContent);
      setExistResult(searchContent, resultContent);
    }
    // eslint-disable-next-line
  }, [resultContent]);

  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchContentFilter) {
      const filteredData = searchContent.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchContentFilter.toLowerCase()));
      });
      setDisplaySearchContent([...filteredData]);
    } else {
      setDisplaySearchContent(searchContent);
    }
    // eslint-disable-next-line
  }, [searchContentFilter]);

  // Result Content 연관 검색 시
  useEffect(() => {
    if (resultContentFilter) {
      const filteredData = resultContent.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(resultContentFilter.toLowerCase()));
      });
      setDisplayResultContent([...filteredData]);
    } else {
      setDisplayResultContent(resultContent);
    }
    // eslint-disable-next-line
  }, [resultContentFilter]);

  /* 정보 수정 시 객체에 값을 변경 한다 Start */
  // Country Filter 값
  const onChangeCountry = (value) => {
    setCountryFilter(value);
  };
  // Content Type Filter 값
  const onChangeContentType = (value) => {
    setContentTypeFilter(value);

    if (value === 'live') {
      setIsLive(true);
      setRatio(4);
    } else {
      setIsLive(false);
      setRatio(6);
    }
  };
  // Provider Filter 값
  const onChangeProvider = (value) => {
    setProviderFilter(value);
  };
  // Content ID 값
  const onChangeContentId = (event) => {
    const value = event.target.value;
    setContentIdFilter(value);
  };
  // Content Name 값
  const onChangeContentName = (event) => {
    const value = event.target.value;
    setContentNameFilter(value);
  };
  /* 정보 수정 시 객체에 값을 변경 한다 End */

  // resultContent에 searchContent와 같은 컨텐츠가 있을 경우 비활성화
  const setExistResult = (searchContent, resultContent) => {
    if (!isEmpty(searchContent)) {
      searchContent.forEach((n) => {
        n.existResultFlag = isExistResult(n, resultContent);
      });
      setSearchContent(searchContent);
    }
  };

  // searchContent와 resultContent 사이에 동일한 컨텐츠가 있으면 true, 없으면 false
  const isExistResult = (searchContent, resultContent) => {
    return !isEmpty(resultContent.find(n2 => n2.contentId === searchContent.contentId && n2.providerId === searchContent.providerId && n2.startDateTime === searchContent.startDateTime && n2.endDateTime === searchContent.endDateTime));
  };

  // Reset 버튼 클릭
  const resetFilter = () => {
    setCountryFilter(countryOptions[0].value);
    setProviderFilter('All');
    setContentTypeFilter(category.contentType.split(',')[0]);
    setContentIdFilter('');
    setContentNameFilter('');
  };

  // Live Type일 경우 채널 선택 시
  const changeValueHandler = async (value) => {
    if (contentTypeFilter === 'live') {
      const body = await getRequest(`/home-app/upcoming/program?country=${countryFilter}&channelId=${value}`, null);
      if (body && body.result === 'SUCCESS') {
        let content = body.data.map((item, index) => {
          const {
            contentId,
            contentName,
            contentType,
            countryCode,
            parentContentId,
            providerIdx,
            providerId,
            imageUrl,
            startDateTime,
            endDateTime,
            scheduleOrder,
          } = item;
          return {
            contentId: contentId,
            contentName: contentName,
            contentType: contentType,
            countryCode: countryCode,
            parentContentId: parentContentId,
            providerIdx: providerIdx,
            providerId: providerId,
            imageUrl: imageUrl,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            scheduleOrder: scheduleOrder,
            existResultFlag: false,
          };
        });

        if (!isEmpty(content)) {
          setSearchContent(content);
        } else {
          setSearchContent([]);
        }
      } else {
        setSearchContent([]);
      }
    }
  }

  // Search 버튼 클릭
  const searchData = async () => {
    if (contentTypeFilter === 'live') {
      /* 문제있음 reading value 에러 undefined */
      const body = await getRequest(`/home-app/upcoming/${categoryIdx}/country/${countryFilter}/channel?contentType=${contentTypeFilter}&provider=${providerFilter}`, null);
      if (body && body.result === 'SUCCESS') {
        let channel = body.data.map((item, index) => {
          const {
            channelId,
            channelName,
            logoUrl,
            channelLastChgDate,
            majorChannelNumber,
            providerId,
          } = item;
          return {
            id: channelId,
            value: channelId,
            primary: channelName,
            secondary: channelLastChgDate,
            checked: false,
            channelId: channelId,
            channelName: channelName,
            logoUrl: logoUrl,
            majorChannelNumber: majorChannelNumber,
            providerId: providerId,
          };
        });
        if (!isEmpty(channel)) {
          setSearchChannel(channel);
        }
      } else {
        setSearchChannel([]);
      }
    } else {
      const body = await getRequest(`/home-app/upcoming/${categoryIdx}/country/${countryFilter}/vod?contentType=${contentTypeFilter}&provider=${providerFilter}`, null);
      if (body && body.result === 'SUCCESS') {
        let content = body.data.map((item, index) => {
          const {
            contentId,
            contentName,
            contentType,
            countryCode,
            parentContentId,
            providerIdx,
            providerId,
            imageUrl,
            startDateTime,
            endDateTime,
            scheduleOrder,
          } = item;
          return {
            contentId: contentId,
            contentName: contentName,
            contentType: contentType,
            countryCode: countryCode,
            parentContentId: parentContentId,
            providerIdx: providerIdx,
            providerId: providerId,
            imageUrl: imageUrl,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            scheduleOrder: scheduleOrder,
            existResultFlag: false,
          };
        });
        setSearchContent(content);
      }
    }
  };

  const resultData = async () => {
    const body = await getRequest(`/home-app/upcoming/${categoryIdx}/country/${countryFilter}/result`, null);
    if (body && body.result === 'SUCCESS') {
      setResultContent(body.data);
    }
  };

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'CategoryList',
        message:
        {
          tabIdx: tabIdx,
        }
      }
    });
  };

  // SearchContent 연관 검색창에 검색어 입력 시
  const handleSearchContent = (value) => {
    setSearchContentFilter(value);
  };

  // ResultContent 연관 검색창에 검색어 입력 시
  const handleResultContent = (value) => {
    setResultContentFilter(value);
  };

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List','Category Organize'],
  };

  const FilterButtons = [
    {
      color: 'tertiary',
      type: 'outline icon',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      autosize: false,
      minWidth: '107px',
      onClick: resetFilter,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: false,
      minWidth: '120px',
      onClick: searchData,
    },
  ];

  // Search Content 목록에서 check box 선택
  const handleSearchContentCheckBox = (event, row) => {
    let selectContent = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      selectContent = [...searchContent];
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      selectContent = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      selectContent = [...checkedSearchContent, row];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      selectContent = checkedSearchContent.filter(
        (n) => n.contentId !== row.contentId
      );
    }
    setCheckedSearchContent(selectContent);
  };

  // Result Content 목록에서 check box 선택
  const handleResultContentCheckBox = (event, row) => {
    let selectContent = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      selectContent = [...resultContent];
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      selectContent = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      selectContent = [...checkedResultContent, row];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      selectContent = checkedResultContent.filter(
        (n) => n.contentId !== row.contentId
      );
    }
    setCheckedResultContent(selectContent);
  };

  // Search Content 체크박스 체크 후 Result Content로 편성 시 이벤트 동작
  const handleAddContentClick = () => {
    if (checkedSearchContent.find(n => resultContent.find(n2 => n2.contentId === n.contentId && n2.providerId === n.providerId && n2.startDateTime === n.startDateTime && n2.endDateTime === n.endDateTime))) {
      alert('content is already exist !')
      return;
    }

    const addContent = checkedSearchContent.map((n, i) => {
      // Result Content의 Order 값을 생성
      let insertOrder = Math.max(...resultContent.map(n2 => n2.resultOrder + 1));
          insertOrder = insertOrder === -Infinity ? 1 : insertOrder;

      // Result Content에 매핑
      const newItem = {
        contentId: n.contentId,
        countryCode: n.countryCode,
        providerIdx: n.providerIdx,
        providerId: n.providerId,
        genre: n.genre,
        imageUrl: n.imageUrl,
        contentType: n.contentType,
        contentName: n.contentName,
        parentContentId: n.parentContentId,
        startDateTime: n.startDateTime,
        endDateTime: n.endDateTime,
        scheduleOrder: n.scheduleOrder,
        resultOrder: insertOrder + i,
      }
      return newItem;
    });
    setResultContent(resultContent.concat(addContent));
    setCheckedSearchContent([]);
  }

  // Save 버튼 클릭 시 Result Content를 저장
  const saveResultContent = async () => {
    const newResult = resultContent
      .map(n => {
        const { countryCode, providerIdx, providerId, contentType, contentId, resultOrder, contentName, startDateTime, endDateTime, scheduleOrder, parentContentId } = n;
        let categoryResult = { categoryIdx: categoryIdx, countryCode: countryCode };
        categoryResult.providerIdx = providerIdx;
        categoryResult.providerId = providerId;
        categoryResult.contentType = contentType;
        categoryResult.contentId = contentId;
        categoryResult.contentName = contentName;
        categoryResult.parentContentId = parentContentId;
        categoryResult.resultOrder = resultOrder;
        categoryResult.startDateTime = startDateTime;
        categoryResult.endDateTime = endDateTime;
        categoryResult.scheduleOrder = scheduleOrder;
        return categoryResult;
      });

    const body = await patchRequest(`/home-app/upcoming/${categoryIdx}/country/${countryFilter}/result`, newResult, null);
    if (body && body.result === 'SUCCESS') {
      setResultContent([]);
      resultData();
    }
  };

  // Delete 버튼 클릭 시 Result Content에서 제거
  const removeResultContent = () => {
    if (!isEmpty(checkedResultContent)) {
      const removeContent = resultContent.filter(result => {
        let flag = true;
        checkedResultContent.forEach(check => {
          if (check.contentId === result.contentId && check.startDateTime === result.startDateTime && check.endDateTime === result.endDateTime) {
            flag = false;
          }
        })
        return flag;
      })
      setResultContent(removeContent);
      setCheckedResultContent([]);
    }
  };

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displaySearchContent && displaySearchContent.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displaySearchContent.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [displaySearchContent, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displaySearchContent].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplaySearchContent([...sortedData]);
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(displayResultContent);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((item, index) => {
      item.resultOrder = index + 1;
      item.checked = false;
    });

    setDisplayResultContent([...items]);
  };

  const Buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      autosize: true,
      onClick: removeResultContent,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      onClick: saveResultContent,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link'
                href=''
                onClick={handleBack}
              >
                Category Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {/* Category Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category type</Typography>
                    <Typography className='value'>{category.categoryType && category.categoryType}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Content Type */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Content Type</Typography>
                    <Typography className='value'>{category.contentType}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Category Name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category</Typography>
                    <Typography className='value'>{category.categoryName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                {/* Tab name */}
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Tab</Typography>
                    <Typography className='value'>{tab.tabName}</Typography>
                  </ListItem>
                </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={countryOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={countryFilter}
                      onChange={onChangeCountry}
                    />
                  </Box>
                  {/* Provider */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-cp-name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providerOptions]}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={providerFilter}
                      onChange={onChangeProvider}
                    />
                  </Box>
                  {/* Content Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='select-Content'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={contentTypeOptions}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={contentTypeFilter}
                      onChange={onChangeContentType}
                    />
                  </Box>
                  {/* ID */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='textfield-id-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='ID'
                      value={contentIdFilter}
                      onChange={onChangeContentId}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                  {/* Name */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='textfield-name-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      value={contentNameFilter}
                      onChange={onChangeContentName}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {isLive &&
            <Grid item xs={2}>
              <Box className='white-box-container align-hor'>
                {/* select channel start */}
                <Box
                  className={`white-box white-box-wrap feed-info ${isOpenArray[0] ? 'expanded' : 'collapsed'}`}
                  sx={{ overflow: 'initial' }}
                >
                  <Button className='btn-expand' onClick={() => togglePanel(0)} />
                  <Box className='fold-content'>
                    {/* Box Title Area */}
                    <Box className='title-wrap'>
                      <Box className='left-area'>
                        <Box className='box-title-area' display='flex' alignItems='center'>
                          <Typography variant='h2' className='box-title-text'>
                            Select Channel
                          </Typography>
                        </Box>
                      </Box>
                      <Box className='right-area'></Box>
                    </Box>
                    <Box className='box-content'>
                      <div className='inner-wrap'>
                        <SelectBox
                          id='select-02'
                          fullWidth
                          size='large'
                          selectOptions={countryOptions}
                          disabled={false}
                          isFirstSelected={true}
                          label='Country'
                          shrink
                        />

                        <div className='feed-list-container '>
                          <div className='list-area'>
                            <div className='list-box'>
                              <BasicList
                              type='radio'
                              items={searchChannel}
                              name='feed-info-group-2'
                              changeValueHandler={changeValueHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            }
            {/* Content List */}
            <Grid item xs={ratio}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>5</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displaySearchContent.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label='Ch.no or ch.Name'
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleSearchContent(event.target.value)}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                        onClick={() => handleAddContentClick()}
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader sx={{ width: 'auto' }}>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {UpcomingVodTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        checked={searchContent.length !== 0 && displaySearchContent.length === checkedSearchContent.length}
                                        onChange={handleSearchContentCheckBox}
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        {/* custom-table-row tr disabled - result에 편성된 search content는 비활성화 처리 */}
                        <TableBody className='tbody'>
                          {paginatedData.map((row, index) => (
                            <TableRow key={row.id || index} className={`custom-table-row${row.existResultFlag ? ' tr disabled' : ''}`}>
                              {UpcomingVodTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={row.providerId}
                                              value={row.contentId}
                                              disabled={row.existResultFlag}
                                              onChange={(event) => handleSearchContentCheckBox(event, row)}
                                              checked={checkedSearchContent.some((value) => String(value.contentId).includes(row.contentId) && String(value.startDateTime).includes(row.startDateTime) && String(value.endDateTime).includes(row.endDateTime))}
                                    />
                                  ) : colIndex === 1 ? (
                                    row.contentId
                                  ) : colIndex === 2 ? (
                                    row.contentName
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={row.imageUrl}
                                      fit={false}
                                      alt=''
                                      wrapWidth='82px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 4 ? (
                                    row.genre
                                  ) : colIndex === 5 ? (
                                    row.startDateTime + ' ~ ' + row.endDateTime
                                  ) : (
                                    row.idx
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination count={Math.ceil(displaySearchContent.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {displayResultContent && displayResultContent.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label='Ch.no or ch.Name'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                        onChange={(event) => handleResultContent(event.target.value)}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={Buttons} justifyContent='center' />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  {/* 241011 no-page-number 클래스 추가하였습니다. */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {UpcomingResultTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box display='flex' alignContent='center'>
                                          <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            checked={resultContent.length !== 0 && displayResultContent.length === checkedResultContent.length}
                                            onChange={handleResultContentCheckBox}
                                            defaultChecked={false}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                            sx={{ marginRight: '4px' }}
                                          />
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {displayResultContent.map((row, index) => (
                                  <Draggable key={row.contentId || index} draggableId={row.contentId || index.toString()} index={index}>
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {UpcomingResultTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <IconDraggable className='icon-draggable' />
                                                <CheckBox
                                                  label={row.resultOrder}
                                                  value={row.contentId}
                                                  defaultChecked={false}
                                                  onChange={(event) => handleResultContentCheckBox(event, row)}
                                                  checked={checkedResultContent.some((value) => String(value.contentId).includes(row.contentId) && String(value.startDateTime).includes(row.startDateTime) && String(value.endDateTime).includes(row.endDateTime))}
                                                />
                                              </Box>
                                            ) : colIndex === 1 ? (
                                              row.contentType
                                            ) : colIndex === 2 ? (
                                              row.providerId
                                            ) : colIndex === 3 ? (
                                              row.contentId
                                            ) : colIndex === 4 ? (
                                              row.contentName
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={row.imageUrl}
                                                fit={false}
                                                alt=''
                                                wrapWidth='81px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : colIndex === 6 ? (
                                              row.genre
                                            ) : colIndex === 7 ? (
                                              row.startDateTime + ' ~ ' + row.endDateTime
                                            ) : (
                                              row.content_id
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default OrganizeUpcoming;
