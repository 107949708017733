export const ChannelMapHistoryHeader = [
  {
    id: 'channel-map-history-date',
    align: 'left',
    labelId: 'historyDate',
    label: 'Channel Map Change Date',
    width: '16%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'channel-map-history-country',
    align: 'left',
    labelId: 'countryCode',
    label: 'Country',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channel-map-history-map',
    align: 'left',
    labelId: 'channelMapId',
    label: 'Channel Map',
    width: '12%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'channel-map-history-save-type',
    align: 'left',
    labelId: 'saveType',
    label: 'Type',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channel-map-history-result',
    align: 'left',
    labelId: 'historyResult',
    label: 'Result',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channel-map-history-modifier',
    align: 'left',
    labelId: 'historyUsrId',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const ChannelMapHistoryDetailHeader = [
  {
    id: 'HistoryDetailChannelNumber',
    align: 'left',
    labelId: 'channelNumber',
    label: 'Channel Number',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailChannelId',
    align: 'left',
    labelId: 'channelId',
    label: 'Channel ID',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailChannelName',
    align: 'left',
    labelId: 'channelName',
    label: 'Channel Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailDmaGroupName',
    align: 'left',
    labelId: 'dmaGroupName',
    label: 'Dma Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailGenreName',
    align: 'left',
    labelId: 'genreName',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailProviderName',
    align: 'Event',
    labelId: 'providerName',
    label: 'CP Name',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailEditAction',
    align: 'left',
    labelId: 'editAction',
    label: 'Event',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailChgUserId',
    align: 'left',
    labelId: 'chgUserId',
    label: 'Modifier',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'HistoryDetailChgDate',
    align: 'left',
    labelId: 'chgDate',
    label: 'Change Date',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const defaultOption = { id: 'All', value: 'All', option: 'All', type: 'text' };

export const defaultParameter = {
  carouselId: '',
  carouselIdx: -1,
  countryCode: '',
  carouselType: '',
  contentType: '',
  providerId: '',
  searchId: '',
  searchText: '',
};

export const defaultParameterDisabled = {
  isContent: false,
  isProvider: false,
};

/*
  default
 */
export const defaultImageInfo = {
  name: '',
  preview: '',
  height: '',
  width: '',
  file: null,
};

export const defaultPopupType = {
  add: false,
  remove: false,
  create: false,
  edit: false,
  save: false,
  cancel: false,
  alarm: false,
  preview: false,
  dma: false,
  publish: false,
  success: false, // 통신 성공후
  view: false, // image
  text: false,
  title: '',
  content: '',
};

export const defaultButton = {
  color: 'tertiary',
  type: 'outline',
  size: 'medium',
  isIcon: false,
  Icon: null,
  fontWeight: 'bold',
  disabled: false,
  label: 'Button',
};

export const CarouselResultHeader = [
  {
    id: 'carouselResultOrder',
    align: 'left',
    labelId: 'resultOrder',
    label: 'Order',
    width: '24%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'carouselResultCarouselType',
    align: 'left',
    labelId: 'carouselType',
    label: 'Carousel',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultContentsType',
    align: 'left',
    labelId: 'contentType',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultContentId',
    align: 'left',
    labelId: 'contentId',
    label: 'Content ID',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultContentName',
    align: 'left',
    labelId: 'contentName',
    label: 'Content Name',
    width: '17%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultFix',
    align: 'center',
    labelId: 'manualFlag',
    label: 'Fix',
    width: '9%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultHorizontalImage',
    align: 'left',
    labelId: 'image',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselResultVerticalImage',
    align: 'left',
    labelId: 'verticalImage',
    label: 'Vertical',
    width: '13%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselChannelHeader = [
  {
    id: 'carouselProviderName',
    align: 'left',
    labelId: 'providerId',
    label: 'CP Name',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselChannelId',
    align: 'left',
    labelId: 'contentId',
    label: 'Content ID',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselChannelName',
    align: 'left',
    labelId: 'contentName',
    label: 'Content Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselChannelImage',
    align: 'left',
    labelId: 'image',
    label: 'Image',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselChannelMaps',
    align: 'left',
    labelId: 'channelMap',
    label: 'Map',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselVodHeader = [
  {
    id: 'carouselVodProviderName',
    align: 'left',
    labelId: 'providerId',
    label: 'CP Name',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselVodId',
    align: 'left',
    labelId: 'contentId',
    label: 'Content ID',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselVodName',
    align: 'left',
    labelId: 'channelMap',
    label: 'Content Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselVodImage',
    align: 'left',
    labelId: 'image',
    label: 'Image',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselMoreHeader = [
  {
    id: 'carouselMoreId',
    align: 'left',
    labelId: 'contentId',
    label: 'Category ID',
    width: '12%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselMoreName',
    align: 'left',
    labelId: 'contentName',
    label: 'Category Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselMoreTab',
    align: 'left',
    labelId: 'tabName',
    label: 'Tab',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselMoreCategoryType',
    align: 'left',
    labelId: 'categoryType',
    label: 'Category Type',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const CarouselHubTabHeader = [
  {
    id: 'carouselType',
    align: 'left',
    labelId: 'carouselType',
    label: 'Carousel Type',
    width: '15%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselHubTabType',
    align: 'left',
    labelId: 'contentType',
    label: 'Type',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselHubTabCode',
    align: 'left',
    labelId: 'contentId',
    label: 'Tab Code',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselHubTabName',
    align: 'left',
    labelId: 'contentName',
    label: 'Tab Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const CarouselHubCategoryHeader = [
  {
    id: 'carouselHubCategoryCode',
    align: 'left',
    labelId: 'contentType',
    label: 'Category Code',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselHubCategoryName',
    align: 'left',
    labelId: 'contentType',
    label: 'Category Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselHubCategoryContentType',
    align: 'left',
    labelId: 'contentType',
    label: 'Content Type',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselHubCategoryMappingType',
    align: 'left',
    labelId: 'contentType',
    label: 'Mapping Type',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselHubCategoryType',
    align: 'left',
    labelId: 'contentType',
    label: 'Category type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const CarouselNoticeHeader = [
  {
    id: 'carouselNoticeId',
    align: 'left',
    labelId: 'contentId',
    label: 'Content ID',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselNoticeTitle',
    align: 'left',
    labelId: 'contentName',
    label: 'Content Name',
    width: '22%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselNoticeStartDate',
    align: 'left',
    labelId: 'startDate',
    label: 'Start Date',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselNoticeEndDate',
    align: 'left',
    labelId: 'endDate',
    label: 'End Date',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselNoticeChannelMap',
    align: 'left',
    labelId: 'channelMap',
    label: 'Map',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const CarouselEventHeader = [
  {
    id: 'carouselEventId',
    align: 'left',
    labelId: 'contentId',
    label: 'Event ID',
    width: '20%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'carouselEventTitle',
    align: 'left',
    labelId: 'contentName',
    label: 'Event Title',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselEventPublish',
    align: 'left',
    labelId: 'eventPublishedFlag',
    label: 'Publish',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'carouselEventHorizontal',
    align: 'left',
    labelId: 'image',
    label: 'Horizontal',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselEventVertical',
    align: 'left',
    labelId: 'verticalImage',
    label: 'Vertical',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselEventFocus',
    align: 'left',
    labelId: 'focusImage',
    label: 'Focus',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'carouselEventDeepLink',
    align: 'left',
    labelId: 'deepLink',
    label: 'Landing',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];

// Deep Link
export const DeepLinkChannelHeader = [
  {
    id: 'deepLinkType',
    align: 'left',
    labelId: 'deepLinkType',
    label: 'Link Type',
    width: '20%',
    radio: true,
    sortable: false,
  },
  {
    id: 'deepLinkProviderName',
    align: 'left',
    labelId: 'providerId',
    label: 'CP Name',
    width: '15%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkContentId',
    align: 'left',
    labelId: 'contentId',
    label: 'Content ID',
    width: '20%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkContentName',
    align: 'left',
    labelId: 'contentName',
    label: 'Content Name',
    width: '20%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkContentImage',
    align: 'left',
    labelId: 'contentImage',
    label: 'Image',
    width: 'auto',
    radio: false,
    sortable: false,
  },
];
export const DeepLinkHubHeader = [
  {
    id: 'deepLinkType',
    align: 'left',
    labelId: 'deepLinkType',
    label: 'Link Type',
    width: '20%',
    radio: true,
    sortable: false,
  },

  {
    id: 'deepLinkContentId',
    align: 'left',
    labelId: 'contentId',
    label: 'Tab Code',
    width: '30%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkContentName',
    align: 'left',
    labelId: 'contentName',
    label: 'Tab Name',
    width: 'auto',
    radio: false,
    sortable: true,
  },
];

export const DeepLinkNotificationHeader = [
  {
    id: 'deepLinkType',
    align: 'left',
    labelId: 'deepLinkType',
    label: 'Link Type',
    width: '20%',
    radio: true,
    sortable: false,
  },
  {
    id: 'deepLinkContentId',
    align: 'left',
    labelId: 'contentId',
    label: 'Notification ID',
    width: '20%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkContentName',
    align: 'left',
    labelId: 'contentName',
    label: 'Notification Title',
    width: 'auto',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkNotificationStart',
    align: 'left',
    labelId: 'startDate',
    label: 'Start Date',
    width: '15%',
    radio: false,
    sortable: true,
  },
  {
    id: 'deepLinkNotificationEnd',
    align: 'left',
    labelId: 'endDate',
    label: 'End Date',
    width: '15%',
    radio: false,
    sortable: true,
  },
];

export const BulkUploadHeader = [
  {
    id: 'bulkChannelMapId',
    align: 'left',
    labelId: 'channelMapId',
    label: 'Channel Map',
    width: '8%',
    sortable: true,
  },
  {
    id: 'bulkChannelNo',
    align: 'right',
    labelId: 'majorChannelNumber',
    label: 'Channel No',
    width: '8%',
    sortable: true,
  },
  {
    id: 'bulkChannelID',
    align: 'left',
    labelId: 'channelId',
    label: 'Channel ID',
    width: '15%',
    sortable: true,
  },
  {
    id: 'bulkChannelName',
    align: 'left',
    labelId: 'channelName',
    label: 'Channel Name',
    width: '15%',
    sortable: true,
  },
  {
    id: 'bulkDMAGroupName',
    align: 'left',
    labelId: 'dmaGroupName',
    label: 'DMA Group Name',
    width: '12%',
    sortable: true,
  },
  {
    id: 'bulkProviderName',
    align: 'left',
    labelId: 'providerName',
    label: 'Cp Name',
    width: '12%',
    sortable: true,
  },
  {
    id: 'bulkGenre',
    align: 'left',
    labelId: 'genreName',
    label: 'Genre',
    width: '10%',
    sortable: true,
  },
  {
    id: 'bulkStatus',
    align: 'left',
    labelId: 'bulkStatus',
    label: 'Status',
    width: '10%',
    sortable: true,
  },
  {
    id: 'bulkResult',
    align: 'left',
    labelId: 'bulkResult',
    label: 'Event',
    width: '12%',
    sortable: true,
  },
];

export const FeedChannelHeader = [
  {
    id: 'FeedChannelLogo',
    align: 'left',
    labelId: 'logoUrl',
    label: 'Logo',
    width: '30%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'FeedChannelNumber',
    align: 'right',
    labelId: 'channelNumber',
    label: 'Ch.No',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'FeedChannelName',
    align: 'left',
    labelId: 'channelName',
    label: 'Ch.Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'FeedChannelID',
    align: 'left',
    labelId: 'channelId',
    label: 'Ch.ID',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
];

export const SavedChannelHeader = [
  {
    id: 'savedChannelLogo',
    align: 'left',
    labelId: 'logoUrl',
    label: 'Logo',
    width: '16%',
    sortable: false,
    display: true,
  },
  {
    id: 'savedChannelNumber',
    align: 'left',
    labelId: 'majorChannelNumber',
    label: 'Ch.No',
    width: '12%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedChannelName',
    align: 'left',
    labelId: 'channelName',
    label: 'Ch.Name',
    width: '16%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedChannelId',
    align: 'left',
    labelId: 'channelId',
    label: 'Ch.ID',
    width: '15%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedProviderName',
    align: 'left',
    labelId: 'providerName',
    label: 'CP Name',
    width: '10%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedGenreName',
    align: 'left',
    labelId: 'genreName',
    label: 'Genre',
    width: '10%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedLastChangeId',
    align: 'left',
    labelId: 'changeId',
    label: 'Modifier',
    width: '11%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedDmaGroup',
    align: 'left',
    labelId: 'dmaFlag',
    label: 'DMA Group',
    width: '12%',
    sortable: true,
    display: true,
  },
  {
    id: 'savedChannelStatus',
    align: 'left',
    labelId: 'channelStatus',
    label: 'Ch. Status',
    width: '12%',
    sortable: true,
    display: false,
  },
  {
    id: 'savedChangeDate',
    align: 'left',
    labelId: 'changeDate',
    label: 'Last Chg Date',
    width: '12%',
    sortable: true,
    display: false,
  },
];
