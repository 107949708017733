import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Pagination from 'components/component/BasicPagination';
import {Box, Dialog} from '@mui/material';
import ChannelTable from './component/ChannelTable';
import ExtraFilter from './component/ExtraFilter';
import {BASE_URL} from 'constants/index';
import {getHeaders} from 'utils/Header';
import {RefreshIcon} from 'components/component/BasicIcon';
import {useNavigate} from "react-router-dom";
import SelectBox from "components/component/BasicSelectBox";
import TextField from "components/component/BasicTextField";
import CustomButtonGroup from "components/component/BasicButtonGroup";
import {MetaChannelTh} from "./data/MetaData";
import Loading from 'components/component/Loading';

function MetadataChannel(props) {
  const navigate = useNavigate();
  const {serviceCountry, providerByCountry, type, message, preferredCountry, tabValue} = props;

  // filter values
  const [filterRegionList, setFilterRegionList] = useState([]);
  const [filterCountryList, setFilterCountryList] = useState([]);
  const [filterChannelMapIdList, setFilterChannelMapIdList] = useState([]);
  const [filterProviderList, setFilterProviderList] = useState([]);
  const [filterDeviceTypeList, setFilterDeviceTypeList] = useState([]);

  // selected filter values
  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState(preferredCountry)
  const [selectedChannelMapId, setSelectedChannelMapId] = useState('All');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedDeviceType, setSelectedDeviceType] = useState('TV');
  const [selectedChannelNumber, setSelectedChannelNumber] = useState('');
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [selectedChannelName, setSelectedChannelName] = useState('');

  // origin data
  const [originalDataList, setOriginalDataList] = useState([]);

  // display data
  const [dataList, setDataList] = useState([]);

  // 화면에 보여 줄 데이터가 없는 경우
  const [isEmptyData, setEmptyData] = useState(false);

  // 검색에서 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setDisplayData] = useState(false);

  // popup
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // paging
  const [paginatedData, setPaginatedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // 타이밍 이슈로 인해서 예외 처리 추가
  const [isAfterFirstRequest, setAfterFirstRequest] = useState(false);

  useEffect(() => {
    if (type) {
      handleReset('init');
      searchMetadata(preferredCountry, 'TV', 'All', 'All', '', '', '');
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (message) {
      if (message.filters) {
        setSelectedRegion(message.filters.selectedRegion);
        setSelectedCountry(message.filters.selectedCountry);
        setSelectedProvider(message.filters.selectedProvider);
        setSelectedChannelMapId(message.filters.selectedChannelMapId);
        setSelectedDeviceType(message.filters.selectedDeviceType);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (providerByCountry && serviceCountry) {
      const updatedRegionList = ['All', ...Array.from(
          new Set(serviceCountry.map(item => item.ricCode))).sort()];
      const updatedSelectRegionList = updatedRegionList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      setFilterRegionList(updatedSelectRegionList);

      const updatedCountryList = providerByCountry.map(
          item => item.countryCode).sort();
      const updatedSelectCountryList = updatedCountryList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));

      setFilterCountryList(updatedSelectCountryList);

      let tempProviderList = providerByCountry.find(
          item => item.countryCode === selectedCountry)?.providerList.map(
          item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);
      setSelectedProvider(updatedProviderList[0].value);

      setFilterDeviceTypeList(['TV', 'Auto', 'Mobile', 'PC'].map(
        item => ({id: item, value: item, option: item, type: 'text'})));
    }
    // eslint-disable-next-line
  }, [providerByCountry, serviceCountry]);

  useEffect(() => {
    if (filterRegionList.length > 0 && filterCountryList.length > 0 && filterProviderList.length > 0 && filterDeviceTypeList.length > 0 && filterChannelMapIdList.length >0 && !isAfterFirstRequest) {
      searchMetadata(selectedCountry, selectedDeviceType, selectedProvider, selectedChannelMapId, selectedChannelNumber, selectedChannelId, selectedChannelName);
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [filterRegionList, filterCountryList, filterProviderList, filterDeviceTypeList, filterChannelMapIdList]);

  useEffect(() => {
    if (selectedRegion && serviceCountry) {
      let tempCountryList = [];

      if (selectedRegion === 'All') {
        tempCountryList = serviceCountry.map(item => item.countryCode).sort();
      } else {
        const tempRegion = message?.filters?.selectedRegion ? message?.filters?.selectedRegion : selectedRegion;

        tempCountryList = serviceCountry.filter(
          item => item.ricCode === tempRegion).map(item => item.countryCode).sort();
      }

      setFilterCountryList(tempCountryList.map(
          item => ({id: item, value: item, option: item, type: 'text'})));

      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedRegion === 'All' ? selectedCountry : tempCountryList[0];
      setSelectedCountry(tempCountry);
    }
    // eslint-disable-next-line
  }, [selectedRegion, serviceCountry]);

  useEffect(() => {
    if (selectedCountry && selectedDeviceType && providerByCountry && serviceCountry) {
      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedCountry;

      const tempProviderList = providerByCountry.find(
        item => item.countryCode === tempCountry)?.providerList.map(
        item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);

      const tempProvider = message?.filters?.selectedProvider ? message?.filters?.selectedProvider : updatedProviderList[0].value;
      setSelectedProvider(tempProvider);

      const tempDevice = message?.filters?.selectedDeviceType ? message?.filters?.selectedDeviceType : selectedDeviceType;
      updateChannelMapIdList(tempDevice);
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedDeviceType, providerByCountry, serviceCountry]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['logoUrl'];

      const filteredData = originalDataList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDataList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDataList([...originalDataList]);
      if (originalDataList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalDataList]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = dataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayData(true);
    }
  }, [dataList, page, itemsPerPage]);

  useEffect(() => {
    if (isEmptyData) {
      setPaginatedData([]);
    }
  }, [isEmptyData]);

  const updateChannelMapIdList = (deviceType) => {
    let tempChannelMapIdList = serviceCountry.find(
        item => item.countryCode === selectedCountry).channelMapList.filter(
        item => item.deviceType === deviceType).map(
        item => item.channelMapId).sort();

    const updatedChannelMapIdList = [
      {id: 'All', value: 'All', option: 'All', type: 'text'},
      ...tempChannelMapIdList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }))
    ];
    setFilterChannelMapIdList(updatedChannelMapIdList);

    if (message?.filters?.selectedChannelMapId) {
      setSelectedChannelMapId(message?.filters?.selectedChannelMapId);
    } else {
      setSelectedChannelMapId(updatedChannelMapIdList[0].value);
    }
  };

  const searchMetadata = (country, deviceType, provider, channelMapId, channelNumber, channelId, channelName) => {
    setDisplayData(false);
    setPage(1);
    setDataList([]);
    setOriginalDataList([]);
    let param = `countryCode=${country}&deviceType=${deviceType}&providerName=${provider}&channelMapId=${channelMapId}&channelNumber=${channelNumber}&channelId=${channelId}&channelName=${channelName}`;
    let url = `${BASE_URL}/metadata/channels?${param.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data);

        if (body.data && body.data.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`[Metadata] channel request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleReset = (type) => {
    if (type === 'reset' && message?.filters) {
      message.filters = null;
    }
    setSelectedCountry(preferredCountry)
    setSelectedRegion('All');
    setSelectedDeviceType('TV');
    setSelectedChannelMapId('All');
    setSelectedProvider('All');
    setSelectedChannelNumber('');
    setSelectedChannelId('');
    setSelectedChannelName('');
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: () => handleReset('reset'),
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: () => searchMetadata(selectedCountry, selectedDeviceType, selectedProvider, selectedChannelMapId, selectedChannelNumber, selectedChannelId, selectedChannelName),
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectedRegion = (region) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedRegion(region);
  };

  const handleSelectedCountry = (country) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedCountry(country);
  };

  const handleSelectedProvider = (provider) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedProvider(provider);
  };

  const handleSelectedChannelMapId = (value) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedChannelMapId(value);
  };

  const handleSelectedDeviceType = (value) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedDeviceType(value);
  };

  const handleSelectedChannelNumber = (value) => {
    setSelectedChannelNumber(value);
  };

  const handleSelectedChannelId = (value) => {
    setSelectedChannelId(value);
  };

  const handleSelectedChannelName = (value) => {
    setSelectedChannelName(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleAction = (contentId) => {
    const navigateTo = type === 'common' ? '/Metadata' : type === 'edit' ? '/MetadataEdit' : '/Metadata';
    const targetComponent = type === 'common' ? 'MetadataChannelDetail' : type === 'edit' ? 'MetadataChannelEdit' : 'MetadataChannelDetail';
    navigate(navigateTo,
    {
      replace: true,
      state: {
        move: true,
        targetComponent: targetComponent,
        message: {
          contentId: contentId,
          channelMapId: selectedChannelMapId,
          countryCode: selectedCountry,
          tabValue: tabValue,
          type: type,
          filters: {
            selectedRegion: selectedRegion,
            selectedCountry: selectedCountry,
            selectedChannelMapId: selectedChannelMapId,
            selectedProvider: selectedProvider,
            selectedDeviceType: selectedDeviceType,
          }
        }
      }
    });
  };

  return (
    <>
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-filter-region'
                      size='large'
                      shrink={true}
                      label='Region'
                      required={false}
                      selectOptions={filterRegionList}
                      disabled={false}
                      onChange={value => handleSelectedRegion(value)}
                      selected={selectedRegion}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-filter-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={filterCountryList}
                      disabled={false}
                      onChange={value => handleSelectedCountry(value)}
                      selected={selectedCountry ? selectedCountry : preferredCountry}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-filter-device'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={filterDeviceTypeList}
                      disabled={false}
                      required={false}
                      label='Device Type'
                      shrink
                      onChange={value => handleSelectedDeviceType(value)}
                      selected={selectedDeviceType}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-filter-channel-map'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={filterChannelMapIdList}
                      required={false}
                      label='Channel Map ID'
                      shrink
                      onChange={value => handleSelectedChannelMapId(value)}
                      selected={selectedChannelMapId}
                    />
                  </Box>
                </Box>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <TextField
                      id='channel-filter-channel-number'
                      type='outlined'
                      size='large'
                      placeholder='Channel No'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{shrink: false}}
                      value={selectedChannelNumber}
                      onChange={value => handleSelectedChannelNumber(value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='channel-filter-channel-id'
                      type='outlined'
                      size='large'
                      placeholder='Channel ID'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{shrink: false}}
                      value={selectedChannelId}
                      onChange={value => handleSelectedChannelId(value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='channel-filter-channel-name'
                      type='outlined'
                      size='large'
                      placeholder='Channel Name'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{shrink: false}}
                      value={selectedChannelName}
                      onChange={value => handleSelectedChannelName(value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-filter-cp-name'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={filterProviderList}
                      disabled={false}
                      required={false}
                      label='CP Name'
                      shrink
                      onChange={value => handleSelectedProvider(value)}
                      selected={selectedProvider}
                    />
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons}/>
              </div>
            </Box>
          </Box>
          {isDisplayData ? (
            <Box className='white-box white-box-wrap'>
              <div className='box-content'>
                <ExtraFilter
                  total={dataList.length}
                  data={originalDataList}
                  type='channel'
                  searchPlaceholder='Please enter a search term'
                  onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                />
                <Box className='content-inner-wrap'>
                  <ChannelTable
                    columns={MetaChannelTh}
                    rows={paginatedData}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    type={type}
                    handleAction={handleAction}
                  />
                </Box>
                <Pagination
                  id='pagination-01'
                  count={Math.ceil(dataList.length / itemsPerPage)}
                  selected={itemsPerPage}
                  onChangeHandle={handlePagination}
                  page={page}
                />
              </div>
            </Box>
          ) : (
            <Loading/>
          )}
        </div>
      </div>
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </>
  )
}

const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
  providerByCountry: state.common.provider.country,
});

export default connect(mapStateToProps)(MetadataChannel);