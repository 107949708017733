export const CheckDialogTh = [
  {
    id: 'tabCode',
    align: 'left',
    label: 'Tab Code',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'tabName',
    align: 'left',
    label: 'Tab Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryCode',
    align: 'left',
    label: 'Category Code',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'categoryName',
    align: 'left',
    label: 'Category Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'Event',
    label: 'Content Type',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'mappingType',
    align: 'left',
    label: 'Mapping Type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerId',
    align: 'left',
    label: 'CP Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'contentId',
    align: 'left',
    label: 'ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'tc-cd-10',
    align: 'left',
    label: 'Carousel',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'resultOrder',
    align: 'left',
    label: 'Order',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'manualFlag',
    align: 'left',
    label: 'Fix',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'event',
    align: 'left',
    label: 'Event',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'username',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const CheckDialogTd = [
  {
    id: 'CheckDialogTd-1',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-2',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-3',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-4',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-5',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-6',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-7',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-8',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
  {
    id: 'CheckDialogTd-9',
    data1: 'T-My',
    data2: 'My',
    data3: 'C-favorite',
    data4: 'My favorite',
    data5: 'live',
    data6: 'manual',
    data7: 'AMG',
    data8: 'us-lg_ncaachannel_1',
    data9: 'Monthly Picks : NCAA',
    data10: 'live',
    data11: 'channel',
    data12: '1 > 3',
    data13: 'on > off',
    data14: 'updated',
    data15: 'taeil.lim',
  },
];
