import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import TableSortLabel from '@mui/material/TableSortLabel';
import { getRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import * as XLSX from 'xlsx';
import {
  Box,
  Typography,
  Link,
  ListItem,
  List,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { HistoryDetailTh } from 'pages/Organization/TabCategory/data/TabCategoryHistoryData';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));
const CellType = styled(TableCell)(({ theme }) => ({
  '& .deleted': {
    color: '#B91C1C',
  },
  '& .create': {
    color: '#0077B5',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

// 메인 컨텐츠
function HistoryDetailContents(props) {
  const { message } = props;
  const { result, regionList, regionPlatformList, beforeResultHistory } = message;

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Tab/Category History','History Detail'],
  };

  useEffect(() => {
    if (!isEmpty(result.currentChangeId)) {
      searchHistory();
    }
    // eslint-disable-next-line
  }, [result]);

  // Result 히스토리 데이터
  const [resultHistory, setResultHistory] = useState([]);
  const [displayResultHistory, setDisplayResultHistory] = useState([]);

  useEffect(() => {
    if (!isEmpty(resultHistory)) {
      setDisplayResultHistory(resultHistory);
    } else {
      setDisplayResultHistory([]);
    }
    // eslint-disable-next-line
  }, [resultHistory]);

  // Result 그리드에 연관검색 키워드 input
  const [searchResultHistoryFilter, setSearchResultHistoryFilter] = useState('');
  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchResultHistoryFilter) {
      const filteredData = resultHistory.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchResultHistoryFilter.toLowerCase()));
      });
      setDisplayResultHistory([...filteredData]);
    } else {
      setDisplayResultHistory(resultHistory);
    }
    // eslint-disable-next-line
  }, [searchResultHistoryFilter]);

  // Result Paging 기능에서 사용하는 데이터
  const [resultPaginatedData, setResultPaginatedData] = useState([]);
  const [resultPage, setResultPage] = useState(1);
  const [resultItemsPerPage, setResultItemsPerPage] = useState(20);
  const [resultOrder, setResultOrder] = useState('asc');
  const [resultOrderBy, setResultOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displayResultHistory && displayResultHistory.length > 0) {
      const startIndex = (resultPage - 1) * resultItemsPerPage;
      const endIndex = startIndex + resultItemsPerPage;
      const newPaginatedData = displayResultHistory.slice(startIndex, endIndex);
      setResultPaginatedData([...newPaginatedData]);
    }
  }, [displayResultHistory, resultPage, resultItemsPerPage]);

  const handleResultPagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setResultPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setResultItemsPerPage(pagingValue.value);
        setResultPage(1);
      }
    }
  };

  const handleResultRequestSort = (property) => {
    const isAsc = resultOrderBy === property && resultOrderBy === 'asc';
    setResultOrder(isAsc ? 'desc' : 'asc');
    setResultOrderBy(property);
    const sortedData = [...displayResultHistory].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setDisplayResultHistory([...sortedData]);
  };

  // Result 연관 검색창에 검색어 입력 시
  const handleSearchResultHistory = (value) => {
    setSearchResultHistoryFilter(value);
  };

  const searchHistory = async () => {
    // Result History
    const body = await getRequest(`/home-app/result-history/detail?currentChangeId=${result.currentChangeId}&tabCode=${result.tabCode}&categoryCode=${result.categoryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      setResultHistory(!isEmpty(body.data) ? body.data : []);
    } else {
      setResultHistory([]);
    }
  };

  const excelDownload = (event) => {
    event.preventDefault();

    const excelData = resultHistory.map((item) => {
      let info = {};
      info['CP Name'] = item.providerId;
      info['ID'] = item.contentId;
      info['Name'] = item.contentName;
      info['Type'] = item.contentType;
      info['Order'] = !isEmpty(item.changeOrderValue) ? item.changeOrderValue : item.resultOrder;
      info['Fix'] = !isEmpty(item.changeManualValue) ? item.changeManualValue : item.manualFlag;
      info['Event'] = item.event;
      info['Last Modifier'] = item.username;
      return info;
    })
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Content Detail');
    XLSX.writeFile(workbook, `Content_Detail_${result.categoryName}.xlsx`);
  };

  const navigate = useNavigate();

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'HistoryContents',
        message: {
          regionList: regionList,
          regionPlatformList: regionPlatformList,
          tabNumber: 2,
          beforeResultHistory: beforeResultHistory,
        }
      }
    });
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container web-live' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='' onClick={handleBack}>
                History Detail
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Change Date</Typography>
                    <Typography className='value'>{result.crtDate}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category Code</Typography>
                    <Typography className='value'>{result.categoryCode}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Category Name</Typography>
                    <Typography className='value'>{result.categoryName}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Content Type</Typography>
                    <Typography className='value'>{result.contentType}</Typography>
                  </ListItem>
                  <Divider className='divider' />
                </React.Fragment>
                <React.Fragment>
                  <ListItem className='list-item'>
                    <Typography className='label'>#Mapping Type</Typography>
                    <Typography className='value'>{result.mappingType}</Typography>
                  </ListItem>
                </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Box className='white-box white-box-wrap '>
            <Box className='box-content'>
              <Box className='white-box-content-top-area' alignItems='center'>
                {/* left-area */}
                <Box className='left-area'>
                  <SearchField
                    placeholder='Please enter a search term'
                    maxWidth='230px'
                    onChange={(event) => handleSearchResultHistory(event.target.value)}
                    />
                </Box>
                <Box className='right-area'>
                  <Button
                    color='tertiary'
                    type='outline'
                    size='small'
                    label='Excel Download'
                    isIcon={false}
                    onClick={excelDownload}
                    autosize
                  />
                </Box>
                {/* right-area */}
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {HistoryDetailTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <>
                              <TableSortLabel
                                active={resultOrderBy === column.id}
                                direction={resultOrderBy === column.id ? resultOrder : 'desc'}
                                onClick={() => handleResultRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {resultPaginatedData.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {HistoryDetailTh.map((column, colIndex) => (
                            <CellType key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                row.providerId
                              ) : colIndex === 1 ? (
                                row.contentId
                              ) : colIndex === 2 ? (
                                row.contentName
                              ) : colIndex === 3 ? (
                                row.contentType
                              ) : colIndex === 4 ? (
                                ''
                              ) : colIndex === 5 ? (
                                !isEmpty(row.changeOrderValue) ? row.changeOrderValue : row.resultOrder
                              ) : colIndex === 6 ? (
                                !isEmpty(row.changeManualValue) ? row.changeManualValue : row.manualFlag
                              ) : colIndex === 7 ? (
                                <span
                                  className={
                                    row.event === 'Deleted'
                                      ? 'deleted'
                                      : row.event === 'Created'
                                      ? 'create'
                                      : row.event === 'Updated'
                                      ? 'update'
                                      : ''
                                  }
                                >
                                  {row.event === 'Hold' ? '-' : row.event}
                                </span>
                              ) : colIndex === 8 ? (
                                row.username
                              ) : (
                                row.idx
                              )}
                            </CellType>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={Math.ceil(displayResultHistory.length / resultItemsPerPage)} id='pagination-01' selected={resultItemsPerPage} onChangeHandle={handleResultPagination} page={resultPage} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default HistoryDetailContents;
