import React, {useEffect, useState} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextLink from 'components/component/BasicTextLink';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';

// data
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import Dialog from "../../../components/component/BasicDialog";
import PopCodeManagementAddArea from "./PopCodeManagementAddArea";
import PopCodeManagementEditArea from "./PopCodeManagementEditArea";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CodeManagementArea() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayAreaCodes].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayAreaCodes([...sortedData])
  };

  const [areaCodes, setAreaCodes] = useState([]);
  const [displayAreaCodes, setDisplayAreaCodes] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [addAreaOpen, setAddAreaOpen] = useState(false);
  const [editAreaOpen, setEditAreaOpen] = useState(false);
  const [areaToEdit, setAreaToEdit] = useState({});
  const [checkedAreas, setCheckedAreas] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);

  const searchAreas = () => {
    fetch(`${BASE_URL}/code-management/area`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAreaCodes(body.data);
        setDisplayAreaCodes(body.data);
      }
    })
  }

  useEffect(() => {
    searchAreas();
  }, []);

  useEffect(() => {
    setDisplayAreaCodes(areaCodes.filter(
        (item) => item.areaName.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.areaCode.toLowerCase().includes(searchTextInput.toLowerCase())));
    setPage(1);
    // eslint-disable-next-line
  }, [searchTextInput]);

  const handleEditAreaOpen = (areaCode) => {
    setEditAreaOpen(true);
    setAreaToEdit(areaCodes.find(tab => tab.areaCode === areaCode));
  }

  const onClickCheckBox = (e) => {
    let checkedList = [...checkedAreas];
    if (e.target.checked) {
      checkedList.push(e.target.value);
    } else {
      checkedList = checkedList.filter((item) => item !== e.target.value);
    }
    if (displayAreaCodes.length === checkedList.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setCheckedAreas(checkedList);
  }

  useEffect(() => {
    if (displayAreaCodes.length === 0) {
      setAllChecked(false);
    } else if (displayAreaCodes.length === checkedAreas.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [displayAreaCodes, checkedAreas]);

  useEffect(() => {
    setCheckedAreas([...checkedAreas.filter(areaCode => displayAreaCodes.map(item => item.areaCode).includes(areaCode))]);
    // eslint-disable-next-line
  }, [displayAreaCodes]);

  const onClickCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedAreas(displayAreaCodes.map((item) => item.areaCode));
      setAllChecked(true);
    } else {
      setCheckedAreas([]);
      setAllChecked(false);
    }
  }

  const getIfChecked = (areaCode) => {
    return checkedAreas.includes(areaCode);
  }

  const handleConfirmDelete = () => {
    if (checkedAreas.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the Area you want to delete.');
      setAlertOpen(true);
      return;
    }
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected Areas?');
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const deleteAreas = () => {
    setConfirmOpen(false);

    fetch(`${BASE_URL}/code-management/area`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(checkedAreas)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Selected Areas have been deleted successfully.');
        setAlertOpen(true);
        searchAreas();
      } else {
        setAlertTitle('Error');
        setAlertMessage('Failed to delete Areas.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      setCheckedAreas([]);
    })
  }

  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: handleConfirmDelete
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Add',
      isIcon: false,
      autosize: true,
      onClick: () => setAddAreaOpen(true),
    },
  ];

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayAreaCodes.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayAreaCodes.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayAreaCodes, page, itemsPerPage]);

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: deleteAreas
    },
  ];

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {displayAreaCodes.length}
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)} />
              </Box>
            </Box>

            <Box className='right-area'>
              <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    <TableCell key={'areaCode'} className='th' align={'left'} sx={{ width: '40%' }}>
                      <CheckBox
                        label=''
                        value='select-all'
                        checked={allChecked}
                        inputProps={{
                          'aria-label': 'select all',
                        }}
                        onChange={onClickCheckAll}
                      />
                      <TableSortLabel
                        active={orderBy === 'areaCode'}
                        direction={orderBy === 'areaCode' ? order : 'desc'}
                        onClick={() => handleRequestSort('areaCode')}
                      >
                        Area Code
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'areaName'} className='th' align={'left'} sx={{ width: 'auto' }}>
                      <TableSortLabel
                        active={orderBy === 'areaName'}
                        direction={orderBy === 'areaName' ? order : 'desc'}
                        onClick={() => handleRequestSort('areaName')}
                      >
                        Area Name
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {paginatedData.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'areaCode'} className='td' align='left'>
                          <CheckBox
                              label={<TextLink href='' text={td[`areaCode`]}
                                               onClick={() => handleEditAreaOpen(td.areaCode)} />}
                              value={td.areaCode}
                              onChange={onClickCheckBox}
                              checked={getIfChecked(td.areaCode)}
                          />
                        </TableCell>
                        <TableCell key={'areaName'} className='td' align='left'>
                          {td[`areaName`]}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={Math.ceil(displayAreaCodes.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
        </div>
        {addAreaOpen && (
            <PopCodeManagementAddArea onClose={() => setAddAreaOpen(false)} afterSave={searchAreas} areas={areaCodes} />
        )}
        {editAreaOpen && (
            <PopCodeManagementEditArea onClose={() => setEditAreaOpen(false)} afterSave={searchAreas} areaToEdit={areaToEdit} areas={areaCodes} />
        )}
        <Dialog
            open={isAlertOpen} // boolean 값으로 설정
            handleClose={handleAlertClose}
            closeX={false}
            size='small' // large, medium, small
            title={alertTitle} // Popup Title
            contentTitle={false} // Content Title
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
        <Dialog
            open={isConfirmOpen} // boolean 값으로 설정
            handleClose={handleConfirmClose}
            closeX={false}
            size='small'
            title='Confirm'
            contentTitle={false}
            bottomDivider={false}
            contentText={confirmMessage}
            buttons={confirmDialogButtons}
        />
      </Box>
  );
}

export default CodeManagementArea;
