import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import {
  Box,
  Typography,
} from '@mui/material';
import DeviceNotificationTV from 'pages/Organization/DeviceNotification/DeviceNotificationTV';
import DeviceNotificationMobile from 'pages/Organization/DeviceNotification/DeviceNotificationMobile';

// Tab Name
const tabs = [
  {label: 'TV'},
  // {label: 'Mobile'}
];

// 메인 컨텐츠
function DeviceNotification({ message }) {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Device Notification',
  };

  // Tab state
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Device Notification
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {tabValue === 0 && (
              <DeviceNotificationTV message={message} />
            )}
            {tabValue === 1 && (
              <DeviceNotificationMobile />
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default DeviceNotification;
