import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import CustomTabs from 'components/component/BasicTabs';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';

// Images
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { getHeaders } from 'utils/Header';
import {BASE_URL} from "constants";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

function PopSeriesDetail(props) {
  const { vodId, countryCode, open, close } = props;
  const [vodData, setVodData] = useState({});
  // Tab
  const [value, setValue] = useState(0);
  const tabs = [
    { label: 'Basic Information', index: 0 },
    { label: 'Media & Image', index: 1 },
    ...(vodData.seasonId ? [{ label: 'Season Information', index: 2 }] : []),
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    fetch(`${BASE_URL}/metadata/vod/${vodId}?countryCode=${countryCode}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setVodData(body.data);
      }
    })
  }, [vodId, countryCode]);

  const openImgNewWindow = (url) => {
    window.open(url);
  };

  const handleUrlButtonClick = (url) => {
    window.open(url, '_blank');
  };

  // Button Data
const buttons = [
  {
    minWidth: '150px',
    color: 'primary',
    type: 'box',
    size: 'large',
    label: 'OK',
    isIcon: 'false',
    IconType: '',
    iconPos: undefined,
    className: 'btn',
    onClick: handleClose
  },
];

  return (
    <div className='component-view'>
      <Dialog open={open} size='large' className='pop-series-detail'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>VOD Details</DialogTitle>
        </HeaderArea>

        <DialogContent className='pop-content'>
          <BoxContainer>
            <Box className='detail-content'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={tabs} />

              {/* Basic Information */}
              {value === 0 && (
                <div className='tab-panel'>
                  <Box className='panel-wrap' sx={{ overflow: 'auto', maxHeight: 634 }}>
                    <Box className='detail-list-container'>
                      <Grid container columnSpacing={2.5} sx={{ flexWrap: 'nowrap' }}>
                        <Grid item xs={6}>
                          {/*
                              DetailItem - 굵은 폰트 클래스 : bold / 컬러 폰트 클래스 : red
                            */}
                          <DetailItem label='VOD ID' className='' value={vodId} />
                          <DetailItem label='Type' className='' value={vodData.type} />
                          <DetailItem label='Title' className='' value={vodData.title} />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomImage
                            src={vodData.imageUrl}
                            fit={false}
                            wrapWidth='384px'
                            wrapHeight='216px'
                            alt=''
                            rounded
                          />
                          <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                            <Typography className='img-size'>{vodData.imageWidth}px X {vodData.imageHeight}px</Typography>
                            <Button
                              color='tertiary'
                              type='outline'
                              size='small'
                              label='Image URL'
                              isIcon={true}
                              Icon={BtnTeritaryOutlineRight}
                              iconPos='right'
                              href='#'
                              onClick={() => openImgNewWindow(vodData.imageUrl)}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={2.5}>
                        <DetailItem label='Description' className='' value={vodData.description} xs={12} />
                        <DetailItem label='CP Name' className='' value={vodData.cpName} xs={12} />
                        <DetailItem label='License Start Date' className='' value={vodData.licenseStartDate} xs={6} />
                        <DetailItem label='License End Date' className='' value={vodData.licenseEndDate} xs={6} />
                        <DetailItem label='Start Date Time' className='' value={vodData.startDateTime} xs={6} />
                        <DetailItem label='End Date Time' className='' value={vodData.endDateTime} xs={6} />

                        <DetailItem label='Duration(sec)' className='' value={vodData.duration} xs={12} />
                        <DetailItem label='Release Date' className='' value={vodData.releaseDate} xs={12} />
                        <DetailItem label='Genre Code' value={vodData.vodGenreCode} xs={12} />
                        <DetailItem label='Episode Number' value={vodData.episodeNumber} xs={12} />
                        <DetailItem label='Studio' value={vodData.studio} xs={12} />
                        <DetailItem label='Caption Flag' value={vodData.captionFlag} xs={12} />
                      </Grid>
                    </Box>
                  </Box>
                </div>
              )}

              {/* Media & Image */}
              {value === 1 && (
                <div className='tab-panel'>
                  <Box className='panel-wrap' sx={{ overflow: 'auto', maxHeight: 634 }}>
                    <Box className='media-detail-container'>
                      <Box className='media-inner-wrap'>
                        {/* Media */}
                        <Box className='media-wrap'>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Media
                            </Typography>
                          </div>

                          <Box className='media-item-wrap'>
                            <div className='item-image-area'>
                              <CustomImage
                                src={vodData.imageUrl}
                                fit={false}
                                sx={{ width: vodData.imageWidth, height: vodData.imageHeight }}
                                alt=''
                                rounded
                              />
                            </div>
                            <Box className='item-info-area'>
                              <Box>
                                <Box className='item-row'>
                                  <Typography className='text label'>Stream Container Type :</Typography>
                                  <Typography className='text value'>{vodData.streamContainerType}</Typography>
                                </Box>
                                <Box className='item-row'>
                                  <Typography className='text label'>Codec :</Typography>
                                  <Typography className='text value'>{vodData.codec}</Typography>
                                </Box>
                                <Box className='item-row'>
                                  <Typography className='text label'>Drmflag :</Typography>
                                  <Typography className='text value'>{vodData.drmFlag}</Typography>
                                </Box>
                              </Box>
                              <Box className='link-url'>
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Media URL'
                                  isIcon={true}
                                  Icon={BtnTeritaryOutlineRight}
                                  iconPos='right'
                                  href='#'
                                  target='_blank'
                                  onClick={() => handleUrlButtonClick(vodData.mediaUrl)}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        {/* Image List */}
                        <Box className='media-wrap'>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Image List
                            </Typography>
                          </div>
                          {vodData.thumbnailImageList.map((item, index) => (
                            <Box className='media-item-wrap' key={index}>
                              <div className='item-image-area'>
                                <CustomImage
                                  src={item.originUrl}
                                  fit={false}
                                  sx={{ width: 384, height: 216 }}
                                  alt=''
                                  rounded
                                />
                              </div>
                              <Box className='item-info-area'>
                                <Box>
                                  <Box className='item-row'>
                                    <Typography className='text label'>Type : </Typography>
                                    <Typography className='text value'>{item.type}</Typography>
                                  </Box>
                                  <Box className='item-row'>
                                    <Typography className='text label'>Ratio/Size : </Typography>
                                    <Typography className='text value'>{item.ratio}/{item.size}</Typography>
                                  </Box>
                                  <Box className='item-row'>
                                    <Typography className='text label'>Group : </Typography>
                                    <Typography className='text value'>{item.imageGroup}</Typography>
                                  </Box>
                                </Box>
                                <Box className='link-url'>
                                  <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='Image URL'
                                    isIcon={true}
                                    Icon={BtnTeritaryOutlineRight}
                                    iconPos='right'
                                    href={item.originUrl}
                                    target='_blank'
                                  />
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              )}

              {/* Season Information */}
              {value === 2 && (
                <div className='tab-panel'>
                  <Box className='panel-wrap' sx={{ overflow: 'auto', maxHeight: 634 }}>
                    <Box container className='detail-list-container' sx={{ mt: 0 }}>
                      <Grid container component='section' className='section-container' columnSpacing={2.5}>
                        {/* Series */}
                        <Grid item xs={12}>
                          <Typography className='sub-title'>Series</Typography>
                        </Grid>
                        <DetailItem label='Series ID' className='' value={vodData.seriesId} xs={12} />
                        <DetailItem label='Series Title' className='' value={vodData.seriesTitle} xs={12} />

                        {/* Season */}
                        <Grid item xs={12} sx={{ mt: 5 }}>
                          <Typography className='sub-title'>Season</Typography>
                        </Grid>
                        <DetailItem label='Season ID' className='' value={vodData.seasonId} xs={12} />
                        <DetailItem label='Season Number' className='' value={vodData.seasonNumber} xs={6} />
                        <DetailItem
                          label='Total Episodes'
                          className=''
                          value={vodData.totalEpisodes}
                          xs={6}
                        />
                        <DetailItem label='Genre Code' className='' value={vodData.seasonGenreCode} xs={6} />
                        <DetailItem label='Caption Flag' className='' value={vodData.seasonCaptionFlag} xs={6} />
                      </Grid>
                    </Box>

                    <Box className='media-detail-container '>
                      <Box className='media-inner-wrap'>
                        {/* Season Image List */}
                        <Box className='media-wrap' sx={{ mt: 5 }}>
                          <div className='title-area'>
                            <Typography variant='h3' className='category-title'>
                              Season Image List
                            </Typography>
                          </div>
                          <Grid container spacing={2.5}>
                            {vodData.seasonImageList.map((item, index) => (
                              <Grid item xl={12} key={index}>
                                <Box className='media-item-wrap'>
                                  <div className='item-image-area'>
                                    <CustomImage
                                      src={item.originUrl}
                                      fit={false}
                                      sx={{ width: 384, height: 216 }}
                                      alt=''
                                      rounded
                                    />
                                  </div>
                                  <Box className='item-info-area'>
                                    <Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Type :</Typography>
                                        <Typography className='text value'>{item.type}</Typography>
                                      </Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Ratio/Size :</Typography>
                                        <Typography className='text value'>{item.ratio}/{item.size}</Typography>
                                      </Box>
                                      <Box className='item-row'>
                                        <Typography className='text label'>Group :</Typography>
                                        <Typography className='text value'>{item.imageGroup}</Typography>
                                      </Box>
                                    </Box>
                                    <Box className='link-url'>
                                      <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='Image URL'
                                        isIcon={true}
                                        Icon={BtnTeritaryOutlineRight}
                                        iconPos='right'
                                        href={item.originUrl}
                                        target='_blank'
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              )}
            </Box>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '0 0 24px' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopSeriesDetail;
