import React, { useState, useEffect } from 'react';
import { postRequest, getRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  Table,
  TableBody,
  Divider,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogActions,
  TableSortLabel,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import CustomTabs from 'components/component/BasicTabs';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';
// Data
import { CheckDialogTh } from 'pages/Organization/TabCategory/data/PopTabCategoryCheckDialogData';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '340px',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .added': {
    color: '#0077B5',
  },
  '& .removed': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

const StatusCheckBox = styled('div')`
  padding: 8px 12px;
  margin-top: 8px;
  background: #f0ece4;
  border-radius: 4px;
  border: 1px solid #d9d4c9;

  .dark & {
    background: rgba(234, 218, 187, 0.2);
    border-color: rgba(217, 212, 201, 0.2);
  }

  .MuiList-root {
    display: flex;
  }
  .MuiListItem-root {
    width: auto;

    &:not(:last-child):after {
      content: '/';
      display: inline-block;
      margin: 0 8px;
      font-size: 14px;
      color: #a50034;
    }
  }
  .status-data {
    font-size: 14px;
    color: #a50034;

    .dark & {
      color: #fd312e;
    }

    &.label {
      &:after {
        content: ':';
      }
    }
  }
`;
const TobeArea = styled('div')`
  .area-title {
    font-size: 16px;
    font-weight: 700;
    color: #525252;
  }
  .update-time {
    font-size: 12px;
    color: #909090;

    .dark & {
      color: #909090;
    }
  }
  .time-data {
    padding-left: 8px;
    font-size: 12px;
    color: #525252;

    .dark & {
      color: #fff;
    }
  }
`;

// Tab Name
const tabs = [{ label: 'Contents' }, { label: 'Category' }, { label: 'Tab' }];

// 메인 컨텐츠
function PopTabCategoryCheckDialog(props) {
  const { deviceType, region, platformVersion, status, onClose, handelSetShowSnakBar } = props;

  // Result 히스토리 데이터
  const [resultCreatedCount, setResultCreatedCount] = useState(0);
  const [resultUpdatedCount, setResultUpdatedCount] = useState(0);
  const [lastResultHistoryDate, setLastResultHistoryDate] = useState('');
  const [resultHistory, setResultHistory] = useState([]);
  const [displayResultHistory, setDisplayResultHistory] = useState([]);

  useEffect(() => {
    if (!isEmpty(resultHistory)) {
      setDisplayResultHistory(resultHistory);
      setLastResultHistoryDate(resultHistory[0].lastUpdateDate);
      let createdCnt = 0;
      let updatedCnt = 0;
      resultHistory.forEach(n => {
        if (n.event === 'Created') {
          createdCnt += 1;
        } else if (n.event === 'Updated') {
          updatedCnt += 1;
        }
      });
      setResultCreatedCount(createdCnt);
      setResultUpdatedCount(updatedCnt);
    } else {
      setDisplayResultHistory([]);
      setLastResultHistoryDate('');
      setResultCreatedCount(0);
      setResultUpdatedCount(0);
    }
    // eslint-disable-next-line
  }, [resultHistory]);

  useEffect(() => {
    searchResultHistory();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    onClose();
  };

  const onCancelBtn = () => {
    handleClose();
  };

  const searchResultHistory = async () => {
    // Result History
    const body = await getRequest(`/home-app/publish/result-history/detail?deviceType=${deviceType}&region=${region}&platformVersion=${platformVersion}&status=${status}`, null);
    if (body && body.result === 'SUCCESS') {
      setResultHistory(!isEmpty(body.data) ? body.data : []);
    } else {
      setResultHistory([]);
    }
  };

  // Save-Testing 상태에 배포 버튼 클릭 시 전체 탭/카테고리 데이터를 Testing 으로 반영
  // Save-Published 상태에 배포 버튼 클릭 시 전체 탭/카테고리 데이터를 Published 로 반영
  const onPublishOKBtn = async () => {
    const body = await postRequest(`/home-app/device/${deviceType}/region/${region}/platform/${platformVersion}/status/${status}/publish`, null, null);
    if (body && body.result === 'SUCCESS') {
      onClose();
    }
    handelSetShowSnakBar({
      open: true,
      message: body.message,
      severity: body.severity,
    });
  };


  // Result Content 정렬 (paging X)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayResultHistory].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayResultHistory([...sortedData]);
  };

  // Tab state
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Button Data
  const buttons = [
    {
      minWidth: '150px',
      color: 'tertiary',
      type: 'outline',
      size: 'large',
      label: 'Cancel',
      onClick: onCancelBtn,
    },
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: status === 'S-T' ? 'Testing' : 'Publish',
      onClick: onPublishOKBtn,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={true} fullWidth className='pop-check-dialog'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Check Dialog</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content tc-check-dialog'>
          <BoxContainer>
            <Box className='content-inner-wrap overflow-auto'>
              <TobeArea className='tobe-area'>
                <Typography className='area-title'>To-Be</Typography>
              </TobeArea>

              <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

              {tabValue === 0 && (
                <div className='tab-panel pr-page'>
                  <div className='panel-wrap'>
                    <TobeArea className='tobe-area'>
                      <Typography component='p' className='update-time'>
                        Last Update date
                        <Typography component='span' className='time-data'>
                          {lastResultHistoryDate}
                        </Typography>
                      </Typography>
                    </TobeArea>
                    <StatusCheckBox className='status-check-box' sx={{ marginBottom: '12px' }}>
                      <List disablePadding>
                        <ListItem disablePadding>
                          <Typography component='span' className='status-data label'>
                            Created
                          </Typography>
                          <Typography component='span' className='status-data'>
                            {resultCreatedCount}
                          </Typography>
                        </ListItem>
                        <ListItem disablePadding>
                          <Typography component='span' className='status-data label'>
                            Updated
                          </Typography>
                          <Typography component='span' className='status-data'>
                            {resultUpdatedCount}
                          </Typography>
                        </ListItem>
                      </List>
                    </StatusCheckBox>

                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table auto-nowrap' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {CheckDialogTh.map((column) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                <>
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                </>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {displayResultHistory.map((row, index) => (
                            <TableRow className='tr' key={row.id || index}>
                              {CheckDialogTh.map((column, colIndex) => (
                                <CellType key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    row.tabCode
                                  ) : colIndex === 1 ? (
                                    row.tabName
                                  ) : colIndex === 2 ? (
                                    row.categoryCode
                                  ) : colIndex === 3 ? (
                                    row.categoryName
                                  ) : colIndex === 4 ? (
                                    row.contentType
                                  ) : colIndex === 5 ? (
                                    row.mappingType
                                  ) : colIndex === 6 ? (
                                    row.providerId
                                  ) : colIndex === 7 ? (
                                    row.contentId
                                  ) : colIndex === 8 ? (
                                    row.contentName
                                  ) : colIndex === 9 ? (
                                    row.contentType
                                  ) : colIndex === 10 ? (
                                    row.carouselType
                                  ) : colIndex === 11 ? (
                                    row.resultOrder
                                  ) : colIndex === 12 ? (
                                    row.manualFlag
                                  ) : colIndex === 13 ? (
                                    row.event
                                  ) : colIndex === 14 ? (
                                    row.username
                                  ) : (
                                    row.idx
                                  )}
                                </CellType>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </div>
                </div>
              )}
            </Box>
          </BoxContainer>
        </DialogContent>
        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center' />
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={false}
        Duration='1000'
        bottom={'8%'}
      />
    </div>
  );
}

export default PopTabCategoryCheckDialog;
