import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import CustomImage from "../../../components/component/BasicImage";
import SearchField from "../../../components/component/BasicSearchField";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";

import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopProgramDetail from "../../Common/PopProgramDetail";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function ProgramScheduleList({ country, provider }) {

  const [countryByRegion, setCountryByRegion] = useState({});
  const [paramRegion, setParamRegion] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [paramChannelMapId, setParamChannelMapId] = useState([]);
  const [paramProvider, setParamProvider] = useState([]);
  const [paramChannelName, setParamChannelName] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs());

  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedChannelMapId, setSelectedChannelMapId] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedChannelName, setSelectedChannelName] = useState('All');
  const [selectedChannelInfo, setSelectedChannelInfo] = useState({});
  const [allScheduleInfo, setAllScheduleInfo] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [channelInfo, setChannelInfo] = useState([]);
  const [scheduleTableData, setScheduleTableData] = useState([]);
  const [searchTextInput, setSearchTextInput] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [programDetailOpen, setProgramDetailOpen] = useState(false);
  const [popupProgramId, setPopupProgramId] = useState('');

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedSchedulesTdRows = sortRows([...scheduleTableData], orderBy, order);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (country.serviceCountry) {
      let region = [...new Set(country.serviceCountry.map(area => area.bizArea))].sort();
      const regions = region.map(reg => ({id: reg, value: reg, option: reg, type: 'text'}));
      setParamRegion([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...regions])

      let reduce = country.serviceCountry.reduce((acc, cur) => {
        const { bizArea, countryCode } = cur;
        if (!acc[bizArea]) {
          acc[bizArea] = []
        }
        acc[bizArea].push(countryCode)
        acc['All'].push(countryCode)
        return acc
      }, {'All': []});
      Object.keys(reduce).forEach(region => {
        reduce[region].sort()
      })
      setCountryByRegion(reduce)

      let countries = reduce['All'].map(country => (
        { id: country, value: country, option: country, type: 'text' }
      ));
      setParamCountry(countries)
    }
  }, [country]);

  useEffect(() => {
    if (selectedRegion && countryByRegion[selectedRegion]) {
      const b = countryByRegion[selectedRegion].map(country => (
        { id: country, value: country, option: country, type: 'text'}
      ))
      setSelectedCountry(countryByRegion[selectedRegion][0])
      setParamCountry(b)
    }
    // eslint-disable-next-line
  }, [selectedRegion]);

  useEffect(() => {
    if (provider && provider.country) {
      let find = provider.country.find(item => item.countryCode === selectedCountry);
      if (find.providerList) {
        let providers = find.providerList.map(item => ({
          id: item.providerName,
          value: item.providerName,
          option: item.providerName,
          type: 'text'
        }));
        setParamProvider([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providers])
      }
    }
    if (country && country.serviceCountry) {
      const find = country.serviceCountry.find(item => item.countryCode === selectedCountry);
      setParamChannelMapId(find.channelMapList.map(item => (
        { id: item.channelMapId, value: item.channelMapId, option: item.channelMapId, type: 'text' }
      )))
      setSelectedChannelMapId(find.channelMapList[0].channelMapId)
    }
    // eslint-disable-next-line
  }, [selectedCountry]);


  useEffect(() => {
    const channelNames = allScheduleInfo.map(schedule => (
      { id: schedule.channelName, value: schedule.channelName, option: schedule.channelName, type: 'text' }
    ))
    setParamChannelName(channelNames)
    if (allScheduleInfo.length > 0) {
      const channelName = allScheduleInfo[0].channelName
      setSelectedChannelName(channelName);

      setChannelAndScheduleInfoByChannelName(channelName)
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    // eslint-disable-next-line
  }, [allScheduleInfo]);

  useEffect(() => {
    setChannelAndScheduleInfoByChannelName(selectedChannelName)
    // eslint-disable-next-line
  }, [selectedChannelName]);


  const setChannelAndScheduleInfoByChannelName = (channelName) => {
    const channel = allScheduleInfo.find(schedule => schedule.channelName === channelName);
    setSelectedChannelInfo(channel);

    if (channel && channel.schedules) {
      const tableInfo = channel.schedules.map((schedule, idx) => (
        {
          id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16), programId: schedule.programId,
          title: schedule.programTitle
        }
      ));
      setChannelInfo(tableInfo);
      setScheduleTableData(tableInfo);
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }

  useEffect(() => {
    if (paramDate && selectedChannelMapId && selectedProvider) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [paramDate, selectedChannelMapId, selectedProvider]);

  const getSchedules = () => {
    const param = `?countryCode=${selectedCountry}&channelMapId=${selectedChannelMapId}&providerName=${selectedProvider}&date=${paramDate}`;
    fetch(`${BASE_URL}/channel-status/channel/schedules${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
      .then(res => res.json())
      .then(body => {
        const schedules = body.data.map(schedule => {
          return {
            ...schedule,
            schedules: schedule.schedules.sort((a, b) => new Date(a.endDateTime) - new Date(b.endDateTime))
          };
        });
        setAllScheduleInfo(schedules);
      })
  }

  useEffect(() => {
    setScheduleTableData(channelInfo.filter(
        (item) => item.programId?.toLowerCase().includes(searchTextInput.toLowerCase())
            || item.title?.toLowerCase().includes(searchTextInput.toLowerCase())));
    // eslint-disable-next-line
  }, [searchTextInput]);

  const programDialogOpen = (programId) => {
    setPopupProgramId(programId);
    setProgramDetailOpen(true);
  }

  return (
    <div className='tab-panel schedule'>
      <Box className='panel-wrap'>
        {/* 필터영역 */}
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box className='component-wrap col-5'>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-business'
                    size='large'
                    shrink={true}
                    label='Region'
                    required={false}
                    selectOptions={paramRegion}
                    disabled={false}
                    placeholder='Region'
                    selected={selectedRegion}
                    onChange={setSelectedRegion}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={true}
                    selectOptions={paramCountry}
                    disabled={false}
                    isFirstSelected={true}
                    placeholder='Country'
                    selected={selectedCountry}
                    onChange={setSelectedCountry}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='channel-map'
                    size='large'
                    shrink={true}
                    label='Channel Map ID'
                    required={false}
                    selectOptions={paramChannelMapId}
                    disabled={false}
                    placeholder='Channel Map ID'
                    selected={selectedChannelMapId}
                    onChange={setSelectedChannelMapId}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-cp'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    selectOptions={paramProvider}
                    disabled={false}
                    placeholder='CP Name'
                    selected={selectedProvider}
                    onChange={setSelectedProvider}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-channel-name'
                    size='large'
                    shrink={true}
                    label='Channel Name'
                    selectOptions={paramChannelName}
                    disabled={false}
                    placeholder='Channel Name'
                    selected={selectedChannelName}
                    onChange={setSelectedChannelName}
                  />
                </Box>
                <Box component='div' className='col auto'>
                  <DatePicker
                    className='input-date-picker'
                    format='MM/DD/YYYY'
                    size='large'
                    shrink
                    label='Date'
                    singleDate={true}
                    sx={{width: '250px'}}
                    value={paramDate}
                    onChange={(newValue) => setParamDate(newValue)}
                    selectAllDate={true}
                  />
                  <Button
                    color='tertiary'
                    type='outline'
                    size='medium'
                    label='Today'
                    isIcon={false}
                    autosize
                    onClick={() => setParamDate(dayjs())}
                  />
                </Box>
              </Box>
            </Box>
            <div className='form-area right'></div>
          </Box>
        </Box>

        <Box className='white-box white-box-wrap'>
          {isEmpty ? (
            <></>
          ) : (
            <Box component='div' className='title-wrap space-between flex-end'>
              <Box component='div' className='left-area'>
                <Box component='div' className='monthly-picks-wrap'>
                  <div className='img'>
                    <CustomImage
                        src={selectedChannelInfo ? selectedChannelInfo.channelLogoUrl : ''}
                        fit={true}
                        wrapWidth='120px'
                        wrapHeight='64px'
                        imgWidth='120px'
                        imgHeight='64px'
                        alt=''
                        rounded
                    />
                  </div>
                  <Box component='div' className='box-top-label'>
                    <Box component='span' className='label'>
                      {selectedChannelInfo ? selectedChannelInfo.channelName : ''}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box component='div' className='right-area'>
                <Box display='flex' justifyContent='flex-end' gap={1} sx={{width: '100%'}}>
                  {/* 검색영역 */}
                  <SearchField
                      maxWidth='230px'
                      placeholder='Please enter a search term'
                      size='medium'
                      value={searchTextInput}
                      onChange={(e) => setSearchTextInput(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box className='box-content'>
            <Box className='content-inner-wrap'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      <TableCell key={'time'} className='th' align={'left'} sx={{width: '%'}}>
                        <TableSortLabel
                            active={orderBy === 'time'}
                            direction={orderBy === 'time' ? order : 'desc'}
                            onClick={() => handleRequestSort('time')}
                        >
                          Time
                        </TableSortLabel>
                      </TableCell>
                      <TableCell key={'programId'} className='th' align={'left'} sx={{width: '40%'}}>
                        <TableSortLabel
                            active={orderBy === 'programId'}
                            direction={orderBy === 'programId' ? order : 'desc'}
                            onClick={() => handleRequestSort('programId')}
                        >
                          Program ID
                        </TableSortLabel>
                      </TableCell>
                      <TableCell key={'title'} className='th' align={'left'} sx={{width: '40%'}}>
                        <TableSortLabel
                            active={orderBy === 'title'}
                            direction={orderBy === 'title' ? order : 'desc'}
                            onClick={() => handleRequestSort('title')}
                        >
                          Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell key={'action'} className='th' align={'left'} sx={{width: '10%'}}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {isEmpty ? (
                    <NoRecords>
                      No records to display
                    </NoRecords>
                  ) : (
                    <TableBody className='tbody'>
                      {sortedSchedulesTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          <TableCell key={'time'} className='td' align={'left'}>
                            {td.time}
                          </TableCell>
                          <TableCell key={'programId'} className='td' align={'left'}>
                            {td.programId}
                          </TableCell>
                          <TableCell key={'title'} className='td' align={'left'}>
                            {td.title}
                          </TableCell>
                          <TableCell key={'action'} className='td' align={'left'}>
                            <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Detail'
                                isIcon={false}
                                Icon=''
                                sx={{minWidth: 'auto'}}
                                onClick={() => programDialogOpen(td.programId)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </CustomTableContainer>
              {/* // Table */}
            </Box>

            {/*<Pagination id='pagination-01' count={5} selOptionData={selOptionData}/>*/}
          </Box>
        </Box>
      </Box>
      {programDetailOpen && <PopProgramDetail open={programDetailOpen} close={() => setProgramDetailOpen(false)} programId={popupProgramId} />}
    </div>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramScheduleList);