import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';

// Images
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import {BASE_URL} from "constants";
import {getHeaders} from "utils/Header";
import noimage378 from 'assets/images/No-Image_378.png';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px', //팝업사이즈
    height: '800px',
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, xs, className, resolution }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <Box className='info-value-wrap' pb={1}>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
        {
          resolution && (
            <Typography component='div' className='text'>
              {resolution}
            </Typography>
          )
        }
      </div>
    </Box>
  </Grid>
);

const openImgNewWindow = (url) => {
  window.open(url);
}

const DetailImageItem = ({ label, xs, img }) => {

  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    const logoImg = new Image();
    logoImg.src = img;
    logoImg.onload = () => {
      setImgWidth(logoImg.width);
      setImgHeight(logoImg.height);
    };
  }, [img]);

  return (
    <Grid xs={xs} item alignItems='center' className='list-wrap'>
      <Box className='image-value-area'>
        <Typography component='span' className='label'>
          {label}
        </Typography>

        <Box className='value'>
          <Box className='image-wrap'>
            <Box className='image-item'>
              <CustomImage src={img} fit={false} wrapWidth='200px' wrapHeight='100px' alt='' rounded />
              <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                <Typography className='img-size'>{imgWidth}px X {imgHeight}px</Typography>
                <Box display='flex' gap={0.8}>
                  <Button
                    color='tertiary'
                    type='outline'
                    size='small'
                    label='Image URL'
                    isIcon={true}
                    Icon={BtnTeritaryOutlineRight}
                    iconPos='right'
                    href={imgWidth > 0 ? '#' : ''}
                    disabled={imgWidth === 0}
                    onClick={() => openImgNewWindow(img)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )};

function PopChannelDetail(props) {
  const { channelId, close } = props;

  const [channelInfo, setChannelInfo] = useState({});
  const [representative1920, setRepresentative1920] = useState({width: 0, height: 0});
  const [representative768, setRepresentative768] = useState({width: 0, height: 0});

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    fetch(`${BASE_URL}/channel-status/channel/detail/${channelId}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setChannelInfo(body.data)
        if (body.data.representative1920) {
          const img = new Image();
          img.src = body.data.representative1920;
          img.onload = () => {
            setRepresentative1920({width: img.width, height: img.height});
          };
        }

        if (body.data.representative768) {
          const img = new Image();
          img.src = body.data.representative768;
          img.onload = () => {
            setRepresentative768({width: img.width, height: img.height});
          };
        }
      }
    });
  }, [channelId]);

  const buttons = [
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'OK',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick: handleClose
    },
  ];

  return (
      <div className='component-view'>
        <Dialog open={true} onClose={handleClose} size='large'
                className='pop-channel-detail' sx={{position:'absolute', overflowY: 'auto'}}>
          <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 24,
                top: 18,
              }}
          >
            <CloseIcon/>
          </IconButton>
          <HeaderArea className='header-wrap'>
            <DialogTitle>Channel Detail</DialogTitle>
            <Typography component='span' className='text' sx={{fontSize: '14px'}}>
              Basic Information
            </Typography>
          </HeaderArea>

          <DialogContent className='pop-content'>
            <BoxContainer>
              <Box className='detail-content'>
                {/* Basic Information */}
                <Box className='detail-list-container'>
                  <Grid className='section' columnSpacing={2.5}>
                    <Grid item xs={12}>
                      <DetailItem label='Channel ID' className='' value={channelInfo.channelId}/>
                      <DetailItem label='Channel Type' className='' value={channelInfo.channelType}/>
                      <DetailItem label='Channel Name' className='' value={channelInfo.channelName}/>
                      <DetailItem label='Channel Number' className='' value={channelInfo.channelNumber}/>
                      <DetailItem label='CP Name' className='' value={channelInfo.providerName}/>
                    </Grid>
                    <Grid container columnSpacing={2.5}>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Active time Period Start'
                          className=''
                          value={channelInfo.activeTimePeriodStart}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Active Time Period End'
                          className=''
                          value={channelInfo.activeTimePeriodEnd}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} item alignItems='center' className='list-wrap'>
                      <Box className='image-value-area'>
                        <Typography component='span' className='label'>
                          Image
                        </Typography>
                        <Box className='value no-border'>
                          <Box className='image-wrap'>
                            <Box className='image-item'>
                              <CustomImage
                                src={representative1920.width > 0 ? channelInfo.representative1920: noimage378}
                                fit={false}
                                wrapWidth='384px'
                                wrapHeight='216px'
                                alt=''
                                rounded
                              />
                              <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                <Typography className='img-size'>{representative1920.width}px X {representative1920.height}px</Typography>
                                <Box display='flex' gap={0.8}>
                                  <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='Image URL'
                                    isIcon={true}
                                    Icon={BtnTeritaryOutlineRight}
                                    iconPos='right'
                                    href={representative1920.width > 0 ? '#' : ''}
                                    disabled={representative1920.width === 0}
                                    onClick={() => openImgNewWindow(channelInfo.representative1920)}
                                  />
                                </Box>
                              </Box>
                            </Box>

                            <Box className='image-item'>
                              <CustomImage
                                src={representative768.width > 0 ? channelInfo.representative768 : noimage378}
                                fit={false}
                                wrapWidth='384px'
                                wrapHeight='216px'
                                alt=''
                                rounded
                              />
                              <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                <Typography className='img-size'>{representative768.width}px X {representative768.height}px</Typography>
                                <Box display='flex' gap={0.8}>
                                  <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='Image URL'
                                    isIcon={true}
                                    Icon={BtnTeritaryOutlineRight}
                                    iconPos='right'
                                    href={representative768.width > 0 ? '#' : ''}
                                    disabled={representative768.width === 0}
                                    onClick={() => openImgNewWindow(channelInfo.representative768)}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Media */}
                    <DetailItem xs={12} label='Media' className='' value={channelInfo.mediaStaticUrl} resolution={`Resolution : ${channelInfo.resolution}`} />

                    {/* Logo */}
                    <DetailImageItem xs={8} label='Logo' img={channelInfo.logoUrl}/>

                    <Grid container columnSpacing={2.5}>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Channel Genre Code'
                          className=''
                          value={channelInfo.channelGenreCode}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Adult Flag'
                          className=''
                          value={channelInfo.adultFlag === '0' ? 'False' : 'True'}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2.5}>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Paid Flag'
                          className=''
                          value={channelInfo.adultFlag === '0' ? 'False' : 'True'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Ad Flag'
                          className=''
                          value={channelInfo.adFlag === '0' ? 'False' : 'True'}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2.5}>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Visible'
                          className=''
                          value={channelInfo.visible === '0' ? 'False' : 'True'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DetailItem
                          label='Last Update'
                          className=''
                          value={channelInfo.lastChgDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </BoxContainer>
          </DialogContent>

          <Divider sx={{margin: '24px 0'}}/>
          <DialogActions className='align-center' sx={{padding: 0}}>
            <CustomButtonGroup buttons={buttons} justifyContent='center'/>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default PopChannelDetail;
