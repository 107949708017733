export const setChannelMapSelectOptionList = (mapList) => {
  return mapList.map((n) => {
    const { channelMapId } = n;
    return {
      id: channelMapId,
      value: channelMapId,
      option: channelMapId,
      type: 'text',
    };
  });
};
