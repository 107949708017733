import { BASE_URL } from "constants/index";
import dayjs from "dayjs";
import { getHeaders } from "utils/Header";

const statusOrder = {
  ready: 1,
  testing: 2,
  done: 3,
};

export const getAnTestServiceSearchData = async (params, type) => {
  const apiUrl = `${BASE_URL}/${type}/details`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(params),
  });
  const body = await response.json();

  if (body.result === "SUCCESS") {
    const respData = [...body.data];
    respData.forEach((data) => {
      const currentTime = dayjs(new Date());
      if (dayjs(new Date(data.forcedEndTime)) <= currentTime) {
        data.status = "done";
      } else if (currentTime < dayjs(new Date(data.startTime))) {
        data.status = "ready";
      } else {
        data.status = "testing";
      }
      return data;
    });

    respData.sort((item1, item2) => {
      // order by startTime desc
      const dateComp = new Date(item2.startTime) - new Date(item1.startTime);
      if (dateComp !== 0) return dateComp;

      // order by deviceType asc
      const deviceTypeComp = item1.deviceType?.localeCompare(item2.deviceType);
      if (deviceTypeComp !== 0) return deviceTypeComp;

      // order by status (ready > testing > done)
      return statusOrder[item1.status] - statusOrder[item2.status];
    });

    return {
      data: respData,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getTabCategoryAction = async (
  deviceType,
  selectedCountry,
  platformVersion,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/tab-category?device=${deviceType}&country=${selectedCountry}&platform=${platformVersion}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveCreateAnTestService = async (data, type) => {
  let apiUrl = `${BASE_URL}/${type}`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Created AnTest!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const updateAnTestService = async (id, updatedData, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(updatedData),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Updated AnTest!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const deleteAnTestService = async (params, type) => {
  let apiUrl = `${BASE_URL}/${type}`;
  const response = await fetch(apiUrl, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify(params),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Deleted!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const endAnTestService = async (id, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/end`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Ended!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getAnTestServiceSegment = async (id, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/segment`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getSegmentDetails = async (country, type) => {
  let apiUrl = `${BASE_URL}/${type}/segment-detail/${country}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveCreateAnTestServiceSegment = async (
  createAnTestSegment,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/${createAnTestSegment.idx}/segment`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(createAnTestSegment),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      data: body.data,
      message: body.message || "Successfully AnTest Segment created!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getAnTestServiceSegmentByIdxAndSegmentIdx = async (
  id,
  segmentId,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/segment/${segmentId}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return body.data;
  } else {
    return null;
  }
};

export const getContentType = async (
  data,
  segmentTarget,
  segmentGroup,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/content-type?segmentTarget=${segmentTarget}&country=${data.countryCode}&segmentGroup=${segmentGroup}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
    };
  } else {
    return {
      data: [],
    };
  }
};

export const getCPDetailsByCountry = async (country, type) => {
  let apiUrl = `${BASE_URL}/${type}/feeds?country=${country}&cpname=${""}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
    };
  } else {
    return {
      data: [],
    };
  }
};

export const getGenre = async (data, contentType, cp, type) => {
  let apiUrl = `${BASE_URL}/${type}/genre?country=${data.countryCode}&region=${
    data.region ? data.region : ""
  }&type=${contentType}&cp=${cp}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
    };
  } else {
    return {
      data: [],
    };
  }
};

export const getOrganizeContent = async (params, type) => {
  let apiUrl = `${BASE_URL}/${type}/content?country=${params.country}&type=${params.type}&cp=${params.cp}&genre=${params.genre}&id=${params.id}&name=${params.name}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    body.data.sort((item1, item2) => {
      if (item1.cpName < item2.cpName) return -1;
      if (item1.cpName > item2.cpName) return 1;
      return item1.contentName?.localeCompare(item2.contentName);
    });
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getAnTestServiceUserTotal = async (countryCode, type) => {
  let apiUrl = `${BASE_URL}/${type}/total-user/${countryCode}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return body.data;
  } else {
    return null;
  }
};

export const updateAnTestServiceSegment = async (
  testIdx,
  segmentIdx,
  anTestSegment,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/${testIdx}/segment/${segmentIdx}`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(anTestSegment),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully AnTest Segment updated!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getOrganizeResult = async (selectedRow, segmentIdx, type) => {
  let apiUrl = `${BASE_URL}/${type}/${selectedRow.idx}/segment/${segmentIdx}/category-result`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveOrganizeResult = async (
  selectedRow,
  result,
  segmentIdx,
  type
) => {
  let apiUrl = `${BASE_URL}/${type}/${selectedRow.idx}/segment/${segmentIdx}/category-result`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(result),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Saved Result!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const deleteAnTestServiceSegment = async (abtestId, SegmentId, type) => {
  let apiUrl = `${BASE_URL}/${type}/${abtestId}/segment/${SegmentId}`;
  const response = await fetch(apiUrl, {
    method: "DELETE",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Deleted!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getSegmentDailyData = async (id, sid, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/segment/${sid}/daily-data`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const updateValidityReason = async (id, sid, validityReason, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/segment/${sid}/validity-reason`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(validityReason),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Updated Validity and Reason!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getDailyUdsDataAnTestService = async (id, type) => {
  let apiUrl = `${BASE_URL}/${type}/${id}/daily-uds`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
    };
  } else {
    return {
      data: [],
    };
  }
};
