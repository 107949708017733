import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import { useNavigate } from 'react-router-dom';
import { getRequest, patchRequest } from 'utils/Request';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import BasicSnackbar from 'components/component/BasicSnackbar';
import BasicDialog from 'components/component/BasicDialog';
import { isEmpty } from 'utils/Valid';
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { PrimaryInfoIcon } from 'components/component/BasicIcon';
import { styled } from '@mui/material/styles';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';

// Dummy Data //
import { CategoryListDragTh } from 'pages/Organization/WebService/data/CategoryListData';

// Styled
const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Tooltip 내부에 출력될 자식 컴포넌트 //
const MappingTypeTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
        <div className='info'>
          <Typography className='label'>{`Order Type : `}</Typography>
          <span className='info-field'>{props.list.orderType}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`Rule : `}</Typography>
          <span className='info-field'>{props.list.categoryMappingRule.rule}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`CP List : `}</Typography>
          <span className='info-field'>{props.list.cpList}</span>
        </div>
    </div>
  );
};

// 메인 컨텐츠
function CategoryListReorder(props) {
  const { message } = props;
  const { tabIdx } = message;

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List'],
  };

  // Category List 화면 진입 시 Tab 정보를 저장
  const [tab, setTab] = useState({});
  // Category List 화면 진입 시 Categorys 정보를 저장
  const [categorys, setCategorys] = useState([]);
  // 체크박스 체크된 Category의 idx 데이터 저장
  const [checkedCategory, setCheckedCategory] = useState([]);
  const navigate = useNavigate()

  // PopUp창 노출
  const [actionDialog, setActionDialog] = useState("");

  // 팝업창 관리(label 매개값을 전달 받으면 해당 값으로 정의된 팝업창을 open)
  const handleSaveButton = () => {
    setActionDialog("Save");
  };

  // open된 팝업창에서 Cancel 버튼을 클릭하면 해당 팝업을 닫음
  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  // PopUp창 이벤트 액션 이후에 서버에서 받은 메세지를 출력하는 알림창 기본값
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  // 서버 메세지를 띄운 알림창을 close 버튼 클릭 했을 때
  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };
  // 팝업창 안에서 save 이벤트를 수행 후 서버에서 받은 메세지 값들을 알림창으로 띄워서 전달
  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
  };

  // Result Content 목록에서 check box 선택
  const handleCategoryCheckBox = (event, row) => {
    let select = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      select = [...categorys];
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      select = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      select = [row, ...checkedCategory];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      select = checkedCategory.filter(
        (n) => n.idx !== row.idx
      );
    }
    setCheckedCategory(select);
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(categorys);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let topIndex = 1;
    let defaultIndex = 10;
    items.forEach((item, index) => {
      if (item.categoryType === 'top') {
        item.categoryOrder = topIndex;
        topIndex++;
      } else {
        item.categoryOrder = defaultIndex;
        defaultIndex++;
      }
      item.checked = false;
    });
    const sortOrder = items.sort((a, b) => a.categoryOrder - b.categoryOrder);

    setCategorys([...sortOrder]);
  };

  // Draggable Table
  const handleOrderTop = () => {
    if (checkedCategory.length === 0) return;
    const items = Array.from(categorys);
    checkedCategory.forEach(check => {
      const findIndex = items.findIndex(n => n.idx === check.idx);
      const [reorderedItem] = items.splice(findIndex, 1);
      items.splice(0, 0, reorderedItem);

      let topIndex = 1;
      let defaultIndex = 10;
      items.forEach((item, index) => {
        if (item.categoryType === 'top') {
          item.categoryOrder = topIndex;
          topIndex++;
        } else {
          item.categoryOrder = defaultIndex;
          defaultIndex++;
        }
        item.checked = false;
      });
      const sortOrder = items.sort((a, b) => a.categoryOrder - b.categoryOrder);

      setCategorys([...sortOrder]);
    });
  };

  const handleOrderBottom = () => {
    if (checkedCategory.length === 0) return;
    const items = Array.from(categorys);
    checkedCategory.forEach(check => {
      const findIndex = items.findIndex(n => n.idx === check.idx);
      const [reorderedItem] = items.splice(findIndex, 1);
      items.splice(items.length, 0, reorderedItem);

      let topIndex = 1;
      let defaultIndex = 10;
      items.forEach((item, index) => {
        if (item.categoryType === 'top') {
          item.categoryOrder = topIndex;
          topIndex++;
        } else {
          item.categoryOrder = defaultIndex;
          defaultIndex++;
        }
        item.checked = false;
      });
      const sortOrder = items.sort((a, b) => a.categoryOrder - b.categoryOrder);

      setCategorys([...sortOrder]);
    });
  };

  useEffect(() => {
    // 페이지 진입 및 새로고침 시 또는 카테고리 데이터 변경 시 정보 재호출
    refreshTabCategory();
    // eslint-disable-next-line
  }, []);

  const refreshTabCategory = async () => {
    const body = await getRequest(`/home-app/tab/${tabIdx}`, null);
    if (body && body.result === 'SUCCESS') {
      if (!isEmpty(body.data)) {
        setTab(body.data);
        const categoryBody = await getRequest(`/home-app/tab/${tabIdx}/category-list`, null);
        if (categoryBody && categoryBody.result === 'SUCCESS') {
          if (!isEmpty(categoryBody.data)) {
            setCategorys(!isEmpty(categoryBody.data) ? categoryBody.data : []);
          } else {
            setCategorys([]);
          }
          // setCategorys(!isEmpty(categoryBody.data.categorys) ? body.data.categorys : []);
        }
      } else {
        setTab({});
      }
    }
    setCheckedCategory([]);
  }

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'CategoryList',
        message:
        {
          tabIdx: tabIdx,
        }
      }
    });
  };

  // Save 버튼 클릭 시 Category Order를 저장
  const saveOrder = async () => {
    const newResult = categorys
      .map(n => {
        let categoryResult = { categoryIdx: n.idx, categoryOrder: n.categoryOrder };
        return categoryResult;
      });

    const body = await patchRequest(`/home-app/tab-category/order`, newResult, null);
    if (body && body.result === 'SUCCESS') {
      handelSetShowSnakBar({
        open: true,
        message: body.message,
        severity: body.severity,
      });
      setCheckedCategory([]);
    }
    setActionDialog("");
    refreshTabCategory();
  };

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Paging 처리
  useEffect(() => {
    if (categorys && categorys.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = categorys.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [categorys, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Top',
      isIcon: false,
      autosize: true,
      onClick: handleOrderTop,
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Bottom',
      isIcon: false,
      autosize: true,
      onClick: handleOrderBottom,
    },
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Cancel',
      isIcon: false,
      autosize: true,
      onClick: handleBack,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      isIcon: false,
      autosize: true,
      onClick: handleSaveButton,
    },
  ];

  // Save 버튼 클릭 시 해당 팝업의 버튼을 정의
  const saveActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: saveOrder,
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='' onClick={handleBack}>
                Category List
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                  <React.Fragment key='1'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Tab</Typography>
                      <Typography className='value'>{tab.tabName}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
                  <React.Fragment key='2'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Random</Typography>
                      <Typography className='value'>{tab.randomOrderFlag === 'Y' ? 'On' : 'Off'}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={buttons} />
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='content-inner-wrap'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId='droppable-table'>
                    {(provided) => (
                      <CustomTableContainer
                        className='table-container'
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {CategoryListDragTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <Box display='flex' alignContent='center'>
                                      <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        onChange={handleCategoryCheckBox}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        sx={{ marginRight: '4px' }}
                                      />
                                      <TableSortLabel>
                                        {column.label}
                                      </TableSortLabel>
                                    </Box>
                                  ) : (
                                      (
                                        column.label
                                      )
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {paginatedData.map((row, index) => (
                              <Draggable key={row.id || index} draggableId={row.id || index.toString()} index={index}>
                                {(provided) => (
                                  <TableRow
                                    className='tr'
                                    //key={row.idx || index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {CategoryListDragTh.map((column, colIndex) => (
                                      <TableCell key={column.id} className='td' align={column.align}>
                                        {colIndex === 0 ? (
                                          <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                            <IconDraggable className='icon-draggable' />

                                            <CheckBox
                                              label={row.categoryOrder}
                                              value='value'
                                              defaultChecked={false}
                                              onChange={(event) => handleCategoryCheckBox(event, row)}
                                              checked={checkedCategory.some((value) => String(value.idx).includes(row.idx))}
                                            />
                                          </Box>
                                        ) : colIndex === 1 ? (
                                          row.categoryCode
                                        ) : colIndex === 2 ? (
                                          row.categoryName
                                        ) : colIndex === 3 ? (
                                          row.contentType
                                        ) : colIndex === 4 ? (
                                          <>
                                            <Typography
                                              component='span'
                                              sx={{ verticalAlign: 'bottom' }}
                                            >
                                              {row.mappingType}
                                            </Typography>
                                            {/* Auto이면서 Auto Rule 셋팅값이 있을 경우에만 Tooltip 출력 */}
                                            {(row.mappingType === 'auto' && row.categoryMappingRule) && (
                                              <>
                                                {/* Icon Tooltip */}
                                                <Tooltip title={<MappingTypeTooltip list={row} />} placement='top'>
                                                  <IconButton
                                                    size='small'
                                                    sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                                  >
                                                    <PrimaryInfoIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              </>
                                            )}
                                          </>
                                        ) : colIndex === 5 ? (
                                          row.categoryType
                                        ) : (
                                          row.idx
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
              <div>
                {(actionDialog === 'Save') &&
                <BasicDialog
                  title={actionDialog === 'Save' ? 'Save Category Order' : ''}
                  contentTitle={actionDialog === 'Save' ? ' Are you sure to save?' : ''}
                  size={'small'}
                  closeX={true}
                  open={true}
                  handleClose={() => setActionDialog('')}
                  buttons={actionDialog === 'Save' ? saveActionButtons : []}
                />}
              </div>
              <div>
                <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={3000} />
              </div>

              {/* Pagination */}
              <Pagination count={Math.ceil(categorys.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default CategoryListReorder;
