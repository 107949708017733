import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextLink from 'components/component/BasicTextLink';
import BasicSwitches from 'components/component/BasicSwitch';
import SelectBox from 'components/component/BasicSelectBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextField from 'components/component/BasicTextField';
import DatePicker from 'components/component/BasicDatePicker';
import CustomList from 'components/component/BasicList';
import CustomImage from 'components/component/BasicImage';
import Tag from 'components/component/BasicTag';
import SearchField from 'components/component/BasicSearchField';
import BasicDialog from 'components/component/BasicDialog';
import { Box, Link, Typography, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { getRequest, postRequest } from 'utils/Request';
import SortLabel from 'components/component/BasicTableSortLabel';
import { BtnArrowRight } from 'components/component/BasicIcon';
import dayjs from 'dayjs';
import { isEmpty } from 'utils/Valid';
import { useNavigate } from 'react-router-dom';
import PopDMAGroup from 'pages/Organization/ChannelMapping/PopDMAGroupNameOutput';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));
// 퍼블 임시 데이타 - 향후 변경해 주세요
export const columnSelectData = [
  {
    id: 'count-01',
    label: 'Logo',
    type: 'checkbox',
  },
  {
    id: 'count-02',
    label: 'Ch. No',
    type: 'checkbox',
  },
  {
    id: 'count-03',
    label: 'Ch. Name',
    type: 'checkbox',
  },
  {
    id: 'count-04',
    label: 'Ch. ID',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'CP Name',
    type: 'checkbox',
  },
  {
    id: 'count-06',
    label: 'Genre',
    type: 'checkbox',
  },
  {
    id: 'count-07',
    label: 'Modifier',
    type: 'checkbox',
  },
  {
    id: 'count-08',
    label: 'DMA Group Name',
    type: 'checkbox',
  },
  {
    id: 'count-09',
    label: 'Ch.Status',
    type: 'checkbox',
  },
  {
    id: 'count-10',
    label: 'Last Chg Date',
    type: 'checkbox',
  },
];
// 메인 컨텐츠
function ReservationEdit({ message }) {
  const navigate = useNavigate();
  // ---------------------------- Init ----------------------------
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        setListHeight(containerHeight - 60); // 헤더 높이 빼기 (필요에 따라 조절)
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  // List 설정
  const [focusIndex, setFocusIndex] = useState(null);
  const itemData = {
    focusIndex,
    setFocusIndex,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // List 설정
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Channel Map Reservations', 'New Reservations'],
  };

  const reservationStatusList = [
    { id: 'statusT', value: 'T', option: 'Reservation For Testing', type: 'text' },
    { id: 'statusP', value: 'P', option: 'Reservation For Published', type: 'text' },
  ];

  const timeIdx = [...Array.from({ length: 24 }, (v, i) => (i++).toString())];

  const defaultPopupType = {
    remove: false,
    save: false,
    alarm: false,
    dma: false,
    link: false,
    title: '',
    content: '',
  };
  const [popupType, setPopupType] = useState(defaultPopupType);

  const reservationDateHandler = (value) => {
    setReservationChannelMap({ ...reservationChannelMap, ...{ reservationDate: value } });
  };

  const onChangeHandler = (name, value) => {
    setReservationChannelMap({ ...reservationChannelMap, ...{ [name]: value } });
  };

  // 개발
  // ---------------------------- sorting ----------------------------
  const [isOpenArray, setIsOpenArray] = useState([true, true, true]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const togglePanel = (index) => {
    setIsOpenArray((prev) => prev.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };
  const feedChannelSort = (col, desc) => {
    setSortedFeedChannelRows(sortRows([...feedChannelList], col, desc));
  };
  const savedChannelSort = (col, desc) => {
    setSortedReservationContentRows(sortRows([...sortedReservationContentRows], col, desc));
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  const containerRef = useRef(null);
  const [listHeight, setListHeight] = useState(700); // 초기값 설정

  // ---------------------------- Feed Info ----------------------------
  const [feedCountry, setFeedCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [feedProviderList, setFeedProviderList] = useState([]);
  const [selectedFeedProvider, setSelectedFeedProvider] = useState({ providerId: '', providerName: '' });
  const [feedChannelList, setFeedChannelList] = useState([]);
  const [feedChannelCount, setFeedChannelCount] = useState(0);
  const [sortedFeedChannelRows, setSortedFeedChannelRows] = useState([]);
  const [feedChannelCheckedList, setFeedChannelCheckedList] = useState([]);
  const [isEnableSwitchOn, setIsEnableSwitchOn] = useState(false);
  const [feedSearchField, setFeedSearchField] = useState('');
  const [isFeedChannelAll, setIsFeedChannelAll] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      getFeedProviderList(selectedCountry);
      let mapList = feedCountry.find((f) => f.id === selectedCountry).channelMapList;
      setReservationChannelMapList(setChannelMapOption(mapList));
      if (!isEmpty(mapList)) {
        setReservationChannelMap({ ...reservationChannelMap, ...{ channelMapId: isEmpty(editChannelMapId) ? mapList[0].channelMapId : editChannelMapId } });
      }
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  const getFeedProviderList = async (country) => {
    const body = await getRequest(`/channel-map/feed-provider?countryCode=${country}`, null);
    if (body && body.result === 'SUCCESS') {
      let feedList = body.data.map((item, index) => {
        const { providerIdx, serviceProviderId, providerId, providerName, rcvDate } = item;
        if (index === 0) {
          setSelectedFeedProvider({ providerId: providerId, providerName: providerName });
        }
        return {
          id: providerIdx,
          value: providerId,
          primary: !isEmpty(serviceProviderId) ? `[${serviceProviderId}-${providerId}] ${providerName}` : `[${providerId}] ${providerName}`,
          secondary: rcvDate,
          checked: index === 0 ? true : false,
          providerId: providerId,
          providerName: providerName,
        };
      });
      setFeedProviderList(feedList);
    }
  };

  const setSelectedFeedProviderHandler = (providerId) => {
    if (!isEmpty(providerId) && !isEmpty(feedProviderList)) {
      let feedProvider = feedProviderList.find((f) => f.providerId === providerId);
      setSelectedFeedProvider({ providerId: feedProvider.providerId, providerName: feedProvider.providerName });
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedFeedProvider)) {
      getFeedProviderChannelList(selectedFeedProvider.providerId, selectedCountry);
    }
    // eslint-disable-next-line
  }, [selectedFeedProvider.providerId]);

  const getFeedProviderChannelList = async (providerId, country) => {
    const body = await getRequest(`/channel-map/feed-channel?countryCode=${country}&providerId=${providerId}`, null);
    if (body && body.result === 'SUCCESS') {
      let channelList = body.data.map((item, index) => {
        const {
          channelIdx,
          displayLogoUrl,
          channelId,
          channelName,
          genreName,
          channelNumber,
          countryCode,
          providerId,
          providerName,
          dmaRank,
          dmaGroupSet,
          dmaGroupCode,
          dmaGroupName,
          dmaFlag,
          dmaList,
        } = item;
        return {
          id: channelIdx,
          feedId: providerId.concat(channelIdx),
          channelIdx: channelIdx,
          channelId: channelId,
          channelNumber: channelNumber,
          channelName: channelName,
          logoUrl: displayLogoUrl,
          providerId: providerId,
          providerName: providerName,
          genreName: genreName,
          dmaRank: dmaRank,
          dmaGroupSet: dmaGroupSet,
          dmaGroupCode: dmaGroupCode,
          dmaGroupName: dmaGroupName,
          dmaFlag: dmaFlag,
          dmaList: dmaList,
          countryCode: countryCode,
          isOrganized: false,
        };
      });
      setOrganizedSortedFeedChannelRows([...channelList], sortedReservationContentRows);
      setIsFeedChannelAll(false);
      setFeedChannelCheckedList([]);
      setIsEnableSwitchOn(false);
      setFeedSearchField('');
      setFeedChannelList([...channelList]);
    }
  };

  const setChannelMapOption = (mapList) => {
    return mapList.map((n) => {
      const { channelMapId } = n;
      return {
        id: channelMapId,
        value: channelMapId,
        option: channelMapId,
        type: 'text',
      };
    });
  };

  useEffect(() => {
    setExcludeApplied(isEnableSwitchOn);
    // eslint-disable-next-line
  }, [isEnableSwitchOn]);

  const setExcludeApplied = (isSwitchOn) => {
    let rows = [];
    if (!isEmpty(feedChannelList) && !isEmpty(sortedReservationContentRows)) {
      if (isSwitchOn) {
        rows = [...feedChannelList].filter((feed) => !isOrganized(feed, sortedReservationContentRows));
      } else {
        rows = [...feedChannelList];
      }
    }
    if (!isEmpty(feedSearchField)) {
      rows = rows.filter((search) => isIncludeSearchText(search, feedSearchField));
    }
    setSortedFeedChannelRows(rows);
  };

  const isOrganized = (feedRow, savedRows) => {
    return !isEmpty(savedRows.find((saved) => feedRow.channelId === saved.channelId));
  };

  const feedSearchFieldHandler = (searchText) => {
    setFeedSearchField(searchText);
    let rows = [];
    if (!isEmpty(feedChannelList)) {
      rows = [...feedChannelList];
      if (isEnableSwitchOn) {
        rows = rows.filter((feed) => !isOrganized(feed, sortedReservationContentRows));
      }
      if (!isEmpty(searchText)) {
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    setSortedFeedChannelRows(rows);
    setFeedChannelCheckedList([]);
    setIsFeedChannelAll(false);
  };

  const isIncludeSearchText = (row, searchText) => {
    let cName = '';
    let cNumber = '';
    if (!isEmpty(searchText)) {
      searchText = searchText.toString().toLowerCase();
    }
    if (!isEmpty(row.channelName)) {
      cName = row.channelName.toString().toLowerCase();
    }
    if (!isEmpty(row.channelNumber)) {
      cNumber = row.channelNumber.toString().toLowerCase();
    }
    return cName.indexOf(searchText) > -1 || cNumber.indexOf(searchText) > -1;
  };

  useEffect(() => {
    getFeedChannelRowCount(sortedFeedChannelRows);
    // eslint-disable-next-line
  }, [sortedFeedChannelRows]);

  const getFeedChannelChecked = (feedId) => {
    if (isEmpty(feedChannelCheckedList)) {
      return false;
    } else {
      return feedChannelCheckedList.includes(feedId);
    }
  };

  const setFeedChannelAll = (e) => {
    if (!isEmpty(sortedFeedChannelRows) && !isEmpty(sortedFeedChannelRows.filter((f) => !f.isOrganized))) {
      if (e.target.checked) {
        setFeedChannelCheckedList(sortedFeedChannelRows.filter((f) => !f.isOrganized).map((f) => f.feedId));
      } else {
        setFeedChannelCheckedList([]);
      }
      setIsFeedChannelAll(e.target.checked);
    }
  };

  const setFeedChannelCheck = (e) => {
    e.preventDefault();
    let feedCheckList = [];
    if (!isEmpty(feedChannelCheckedList)) {
      feedCheckList = [...feedChannelCheckedList];
    }
    if (e.target.checked) {
      feedCheckList.push(e.target.value);
    } else {
      feedCheckList = feedCheckList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;
    let sortedFeed = [];

    if (!isEmpty(feedCheckList) && !isEmpty(sortedFeedChannelRows)) {
      sortedFeed = sortedFeedChannelRows.filter((f) => !f.isOrganized);
      if (feedCheckList.length === sortedFeed.length) {
        allCheck = true;
      }
    }
    setIsFeedChannelAll(allCheck);
    setFeedChannelCheckedList(feedCheckList);
  };

  const getFeedChannelRowCount = (feedChannelList) => {
    setFeedChannelCount(isEmpty(feedChannelList) ? 0 : [...feedChannelList].length);
  };

  const setFeedChannelRow = ({ index, style, data }) => {
    const row = sortedFeedChannelRows[index];
    return (
      <TableRow key={`feed-row-${row.feedId}`} style={{ ...style, display: 'flex' }} className={`custom-table-row${row.isOrganized ? ' tr disabled' : ''}`}>
        <TableCell key={`feed-logo-${row.id}`} align={'left'} className='custom-table-cell td' sx={{ width: '25%' }}>
          <Box display='flex' gap='3px'>
            <CheckBox
              label=''
              value={row.feedId}
              checked={isEmpty(feedChannelCheckedList) ? false : getFeedChannelChecked(row.feedId)}
              disabled={row.isOrganized}
              onChange={(event) => setFeedChannelCheck(event)}
            />
            <CustomImage src={row.logoUrl ? row.logoUrl : ''} sx={{ width: 50, height: 64 }} rounded />
          </Box>
        </TableCell>
        <TableCell key={`feed-number-${row.feedId}`} align={'right'} className='custom-table-cell td' sx={{ width: '15%' }}>
          {row.channelNumber}
        </TableCell>
        <TableCell key={`feed-name-${row.feedId}`} align={'left'} className='custom-table-cell td' sx={{ width: '35%' }}>
          <React.Fragment key={`feed-name-fragment-${row.id}`}>
            {row.dmaFlag === 'Y' && <Tag type='testing' label='DMA' badgeType='' />} {row.channelName && <Typography component='span'>{row.channelName}</Typography>}{' '}
          </React.Fragment>
        </TableCell>
        <TableCell key={`feed-id-${row.feedId}`} align={'left'} className='custom-table-cell td' sx={{ width: '25%' }}>
          {row.channelId}
        </TableCell>
      </TableRow>
    );
  };

  // ---------------------------- Reservation Channel Map ----------------------------
  const defaultReservation = {
    reservationIdx: -1,
    channelMapId: '',
    reservationStatus: '',
    reservationSaveType: 'T',
    reservationDate: dayjs().add(1, 'day'),
    reservationTime: '00:00',
    reservationUtcDate: '',
    channelMapAction: 'added',
    channelList: [],
  };
  const [reservationChannelMap, setReservationChannelMap] = useState(defaultReservation);
  const [savedChannelMap, setSavedChannelMap] = useState({});
  const [editChannelMapId, setEditChannelMapId] = useState('');
  const [reservationChannelMapList, setReservationChannelMapList] = useState([]);
  const [reservationContentRemoveList, setReservationContentRemoveList] = useState([]);
  const [reservationContentCount, setReservationContentCount] = useState(0);
  const [addContentCount, setAddContentCount] = useState(0);
  const [sortedReservationContentRows, setSortedReservationContentRows] = useState([]);
  const [savedReservationContentRows, setSavedReservationContentRows] = useState([]);
  const [reservationChannelCheckedList, setReservationChannelCheckedList] = useState([]);
  const [isReservationChannelAll, setIsReservationChannelAll] = useState(false);
  // dma
  const [dmaChannel, setDmaChannel] = useState({});
  const [reservationParameter, setReservationParameter] = useState({});
  const [channelMapParameter, setChannelMapParameter] = useState({});

  useEffect(() => {
    let isEdit = isReservationEdit(savedChannelMap, reservationChannelMap, savedReservationContentRows, sortedReservationContentRows);
    setSaveButton(isEdit);
    // eslint-disable-next-line
  }, [reservationChannelMap]);

  useEffect(() => {
    // channel map Parameter
    if (message && !isEmpty(message.channelMapParameter)) {
      setChannelMapParameter(message.channelMapParameter);
    }
    // reservation Parameter
    if (message && !isEmpty(message.reservationParameter)) {
      setReservationParameter(message.reservationParameter);
    }

    let initCountry = 'US';
    if (message && !isEmpty(message.mode)) {
      if (message.mode === 'add') {
        setReservationChannelMap(defaultReservation);
      } else if (message.mode === 'edit') {
        getReservationContentList(message.reservation.reservationIdx);
        let channelMap = {
          ...defaultReservation,
          reservationIdx: message.reservation.reservationIdx,
          channelMapId: message.reservation.channelMapId,
          reservationStatus: message.reservation.reservationStatus,
          reservationSaveType: message.reservation.reservationSaveType,
          reservationDate: dayjs(message.reservation.reservationDate),
          reservationTime: dayjs(message.reservation.reservationDate).format('HH:mm'),
          channelMapAction: 'edit',
        };
        setReservationChannelMap(channelMap);
        setSavedChannelMap(channelMap);
        initCountry = message.reservationParameter.countryCode;
        setEditChannelMapId(message.reservation.channelMapId);
      }
      setSelectedCountry(initCountry);

      if (message && !isEmpty(message.countryFilter)) {
        setFeedCountry(message.countryFilter);
        let country = message.countryFilter.find((f) => f.value === initCountry);
        if (!isEmpty(country)) {
          setReservationChannelMapList([...setChannelMapOption(country.channelMapList)]);
        }
      }
    }
    // eslint-disable-next-line
  }, [message]);

  const getReservationContentList = async (reservationIdx) => {
    const body = await getRequest(`/channel-map/reservation/${reservationIdx}`, null);
    if (body && body.result === 'SUCCESS') {
      let contentList = body.data.map((item, index) => {
        const {
          channelMapIdx,
          channelIdx,
          channelId,
          channelName,
          channelNumber,
          providerId,
          providerName,
          majorChannelNumber,
          logoUrl,
          saveType,
          genreName,
          dmaRank,
          dmaGroupSet,
          dmaGroupName,
          dmaGroupCode,
          dmaFlag,
          dmaList,
          countryCode,
          reservationAction,
          lastChgDate,
          lastChgUsrId,
        } = item;
        return {
          id: channelMapIdx,
          savedId: 'saved_'.concat(channelMapIdx),
          channelMapIdx: channelMapIdx,
          channelIdx: channelIdx,
          channelId: channelId,
          channelNumber: channelNumber,
          channelName: channelName,
          majorChannelNumber: majorChannelNumber,
          providerId: providerId,
          providerName: providerName,
          logoUrl: logoUrl,
          genreName: genreName,
          dmaRank: dmaRank,
          dmaGroupSet: dmaGroupSet,
          dmaGroupCode: dmaGroupCode,
          dmaGroupName: dmaGroupName,
          dmaFlag: dmaFlag,
          dmaList: dmaList,
          countryCode: countryCode,
          reservationAction: reservationAction,
          saveType: saveType,
          lastChgDate: lastChgDate,
          lastChgUsrId: lastChgUsrId,
          editAction: 'unchanged',
        };
      });
      getReservationChannelRowCount(contentList);
      setSortedReservationContentRows(contentList);
      setSavedReservationContentRows(contentList);
      setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, contentList);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [reservationChannelMap]);

  // TODO 리팩토리 대상
  useEffect(() => {
    setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, sortedReservationContentRows);
    // eslint-disable-next-line
  }, [feedChannelList]);

  const setOrganizedSortedFeedChannelRows = (feed, saved) => {
    if (!isEmpty(feed)) {
      feed.forEach((f) => {
        f.isOrganized = isOrganized(f, saved);
      });
      setSortedFeedChannelRows(feed);
    }
  };
  // Reservations
  const setReservationChannelAll = (e) => {
    if (!isEmpty(sortedReservationContentRows)) {
      if (e.target.checked) {
        setReservationChannelCheckedList([...sortedReservationContentRows].map((f) => f.savedId));
      } else {
        setReservationChannelCheckedList([]);
      }
      setIsReservationChannelAll(e.target.checked);
    }
  };

  const getReservationChannelChecked = (id) => {
    if (isEmpty(reservationChannelCheckedList)) {
      return false;
    } else {
      return reservationChannelCheckedList.includes(id);
    }
  };

  const setReservationChannelCheck = (e) => {
    let savedCheckList = [];
    if (!isEmpty(reservationChannelCheckedList)) {
      savedCheckList = [...reservationChannelCheckedList];
    }
    if (e.target.checked) {
      savedCheckList.push(e.target.value);
    } else {
      savedCheckList = savedCheckList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;

    if (!isEmpty(savedCheckList) && !isEmpty(sortedReservationContentRows)) {
      let sortedChannel = [...sortedReservationContentRows];
      if (savedCheckList.length === sortedChannel.length) {
        allCheck = true;
      }
    }
    setIsReservationChannelAll(allCheck);
    setReservationChannelCheckedList(savedCheckList);
  };

  const reservationChannelNumberHandler = (e, savedId) => {
    e.preventDefault();
    if (!isEmpty(e.target.value)) {
      if (!isFinite(e.target.value)) {
        return;
      }
    }
    sortedReservationContentRows.forEach((f) => {
      if (f.savedId === savedId) {
        f.majorChannelNumber = e.target.value;
        if (f.editAction !== 'added' && f.editAction !== 'removed') {
          if (e.target.value !== f.channelNumber) {
            f.editAction = 'update';
          } else {
            f.editAction = 'unchanged';
          }
        }
        return;
      }
    });
    let isEdit = isReservationEdit(savedChannelMap, reservationChannelMap, savedReservationContentRows, [...sortedReservationContentRows]);
    setSaveButton(isEdit);
    setSortedReservationContentRows([...sortedReservationContentRows]);
  };

  const getReservationChannelRowCount = (savedChannelList) => {
    setReservationContentCount(isEmpty(savedChannelList) ? 0 : [...savedChannelList].filter((f) => f.editAction !== 'removed').length);
  };
  const openDmaPopUp = (row) => {
    setPopupType({ ...defaultPopupType, dma: true });
    let list = [];
    list.push({
      channelIdx: row.channelIdx,
      channelNumber: row.channelNumber,
      channelName: row.channelName,
      channelId: row.channelId,
      dmaGroupName: row.dmaGroupName,
    });
    if (!isEmpty(row.dmaList)) {
      row.dmaList.forEach((f) => {
        list.push({
          channelIdx: f.channelIdx,
          channelNumber: row.channelNumber,
          channelName: f.channelName,
          channelId: f.channelId,
          dmaGroupName: f.dmaGroupName,
        });
      });
    }
    setDmaChannel(list);
  };

  const SavedChannelRow = ({ index, style, data }) => {
    const { setFocusIndex, focusIndex } = data;
    const ref = useRef(null);
    useEffect(() => {
      if (index === focusIndex) {
        ref.current.focus();
      }
    }, [focusIndex, index]);
    const row = sortedReservationContentRows[index];
    return (
      <TableRow key={`saved-channel-row-${row.savedId}`} style={{ ...style, display: 'flex' }} className={`custom-table-row${row.editAction !== 'unchanged' ? ' tr unsaved' : ''}`}>
        <TableCell key={`saved-channel-logo-${row.savedId}`} className='custom-table-cell td' align={'center'} sx={{ width: '16%' }}>
          <Box display='flex' gap='3px'>
            <CheckBox
              label=''
              value={row.savedId}
              checked={isEmpty(reservationChannelCheckedList) ? false : getReservationChannelChecked(row.savedId)}
              onChange={(event) => setReservationChannelCheck(event)}
              disabled={false}
            />
            <CustomImage src={row.logoUrl ? row.logoUrl : ''} sx={{ width: 50, height: 64 }} alt='' rounded />
          </Box>
        </TableCell>
        <TableCell key={`saved-channel-number-${row.savedId}`} className='custom-table-cell td' align={'right'} sx={{ width: '13%' }}>
          <TextField
            inputRef={ref}
            type='outlined'
            size='medium'
            placeholder=''
            value={row.majorChannelNumber}
            maxLength={4}
            readOnly={false}
            autoComplete='off'
            InputLabelProps={{ shrink: false }}
            onChange={(event) => reservationChannelNumberHandler(event, row.savedId)}
            onFocus={() => setFocusIndex(index)}
          />
        </TableCell>
        <TableCell key={`saved-channel-name-${row.savedId}`} className='custom-table-cell td' align={'left'} sx={{ width: '20%' }}>
          {row.channelName}
        </TableCell>
        <TableCell key={`saved-channel-id-${row.savedId}`} className='custom-table-cell td' align={'left'} sx={{ width: '18%' }}>
          <TextLink href='' text={row.channelId} />
        </TableCell>
        <TableCell key={`saved-provider-name-${row.savedId}`} className='custom-table-cell td' align={'left'} sx={{ width: '11%' }}>
          {row.providerName}
        </TableCell>
        <TableCell key={`saved-genre-name-${row.savedId}`} className='custom-table-cell td' align={'left'} sx={{ width: '10%' }}>
          {row.genreName}
        </TableCell>
        <TableCell key={`saved-last-change-id-${row.savedId}`} className='custom-table-cell td' align={'right'} sx={{ width: '11%' }}>
          {row.changeId}
        </TableCell>
        <TableCell key={`saved-dma-group-${row.savedId}`} className='custom-table-cell td' align={'left'} sx={{ width: '12%' }}>
          {!isEmpty(row.dmaFlag) && row.dmaFlag === 'Y' && (
            <Button color={'primary'} type='box' size='small' label='DMA' isIcon={false} Icon='' iconPos={undefined} autosize onClick={() => openDmaPopUp(row)} />
          )}
        </TableCell>
      </TableRow>
    );
  };

  // ---------------------------- organization ----------------------------
  const feedToReservationChannel = () => {
    if (!isEmpty(feedChannelCheckedList) && !isEmpty(feedChannelList)) {
      let checkedList = feedChannelList.filter((f) => feedChannelCheckedList.includes(f.feedId));
      let addCount = addContentCount;
      let addList = [];
      checkedList.forEach((feed) => {
        const add = {
          id: -1,
          savedId: feed.feedId.concat(++addCount),
          channelMapIdx: -1,
          channelIdx: feed.channelIdx,
          channelId: feed.channelId,
          channelNumber: feed.channelNumber,
          channelName: feed.channelName,
          majorChannelNumber: feed.channelNumber,
          displayLogoUrl: feed.logoUrl,
          providerId: feed.providerId,
          providerName: feed.providerName,
          genreName: feed.genreName,
          dmaRank: feed.dmaRank,
          dmaGroupSet: feed.dmaGroupSet,
          dmaGroupCode: feed.dmaGroupCode,
          dmaGroupName: feed.dmaGroupName,
          dmaFlag: feed.dmaFlag,
          dmaList: feed.dmaList,
          saveType: reservationChannelMap.reservationSaveType,
          countryCode: feed.countryCode,
          lastChgDate: '',
          lastChgUsrId: 'new',
          editAction: 'added',
          reservationAction: 'add',
        };
        addList.push(add);
        setAddContentCount(addCount);
      });
      let toSavedList = [...addList, ...sortedReservationContentRows];
      setFeedChannelCheckedList([]);
      setIsFeedChannelAll(false);
      setSortedReservationContentRows([...toSavedList]);
      getReservationChannelRowCount(toSavedList);
      setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, toSavedList);
      let isEdit = isReservationEdit(savedChannelMap, reservationChannelMap, savedReservationContentRows, toSavedList);
      setSaveButton(isEdit);
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
    if (popupType.link === true) {
      reservationLink();
    }
  };

  // ---------------------------- Button Data ----------------------------
  // Button Data
  const openSaveDialog = () => {
    setPopupType({ ...defaultPopupType, save: true, title: 'Reservation Channel', content: 'Would you like to save your channel?' });
  };

  // reservation channel save
  const reservationChannelSave = async () => {
    let savedList = [...sortedReservationContentRows];
    if (!isEmpty(reservationContentRemoveList)) {
      savedList = [...reservationContentRemoveList, ...savedList];
    }
    let requestBody = { ...reservationChannelMap };
    requestBody.reservationDate = requestBody.reservationDate.format('YYYY-MM-DD');
    requestBody.channelList = savedList;
    const body = await postRequest(`/channel-map/reservation`, requestBody, null);
    let title = '';
    let content = '';
    let link = false;
    if (body && body.result === 'SUCCESS') {
      setReservationContentRemoveList([]);
      title = body.result;
      content = 'Saved successfully.';
      link = true;
    } else {
      if (body && body.result === 'FAILED') {
        title = body.result;
        content = body.message;
      } else {
        title = body.error;
        content = 'Contact your administrator!';
      }
    }
    setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content, link: link });
  };

  const reservationLink = () => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'Reservation',
        message: {
          countryFilter: feedCountry,
          channelMapParameter: channelMapParameter,
          reservationParameter: reservationParameter,
          pageMode: 'init',
          endDate: reservationChannelMap.reservationDate.format('YYYY-MM-DD'),
          countryCode: selectedCountry,
        },
      },
    });
  };

  const openRemoveDialog = () => {
    if (!isEmpty(reservationChannelCheckedList)) {
      setPopupType({ ...defaultPopupType, remove: true, title: 'Delete Reservation Channel', content: 'Do you want to delete reservation channels? (You must save it for it to take effect.)' });
    }
  };

  const reservationChannelRemove = () => {
    if (!isEmpty(reservationChannelCheckedList)) {
      closeDialog();
    }
    let contentList = [...sortedReservationContentRows];
    let excludedList = contentList.filter((f) => !reservationChannelCheckedList.includes(f.savedId));
    setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, excludedList);
    setSortedReservationContentRows(excludedList);
    let removeList = contentList.filter((f) => reservationChannelCheckedList.includes(f.savedId) && f.channelMapIdx > 0);
    if (!isEmpty(removeList)) {
      removeList.forEach((f) => {
        f.editAction = 'removed';
      });
    }
    setReservationContentRemoveList(isEmpty(removeList) ? [] : removeList);
    setReservationChannelCheckedList([]);
    setIsReservationChannelAll(false);

    let isEdit = isReservationEdit(savedChannelMap, reservationChannelMap, contentList, excludedList);
    setSaveButton(isEdit);

    closeDialog();
  };

  const defaultButton = {
    buttonId: 'buttonId',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };

  const reservationChannelRemoveButton = [
    {
      buttonId: 'reservationChannelSaveButton',
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',

      onClick: openRemoveDialog,
    },
  ];

  const reservationSaveButton = {
    ...defaultButton,
    buttonId: 'reservationChannelRemoveButton',
    color: 'tertiary',
    type: 'BOX',
    label: 'Reservation',
    autosize: true,
    disabled: true,
    onClick: openSaveDialog,
  };

  const [reservationChannelSaveButton, setReservationChannelSaveButton] = useState([reservationSaveButton]);

  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButton',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const removeButton = {
    ...defaultButton,
    buttonId: 'channelRemove',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    onClick: reservationChannelRemove,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButton',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButton',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: reservationChannelSave,
  };

  const removeActionButton = [cancelButton, removeButton];
  const saveActionButton = [cancelButton, saveButton];
  const alarmActionButtons = [closeButton];

  const setSaveButton = (isSaved) => {
    let button = { ...reservationSaveButton, onClick: openSaveDialog, color: isSaved ? 'primary' : 'tertiary', disabled: !isSaved };
    setReservationChannelSaveButton([button]);
  };

  const isReservationEdit = (savedMap, editMap, savedMapRows, editMapRows) => {
    if (!isEmpty(savedMap) && editMap.channelMapAction === 'edit') {
      if (
        editMap.channelMapId !== savedMap.channelMapId ||
        editMap.channelMapId !== savedMap.channelMapId ||
        editMap.reservationSaveType !== savedMap.reservationSaveType ||
        editMap.reservationDate.format('YYYY-MM-DD') !== savedMap.reservationDate.format('YYYY-MM-DD') ||
        editMap.reservationTime !== savedMap.reservationTime ||
        (editMapRows.length !== savedMapRows.length && editMapRows.length > 0) ||
        (editMapRows.filter((f) => f.editAction === 'unchanged').length !== savedMapRows.length && editMapRows.length > 0)
      ) {
        return true;
      }
    } else {
      if (editMapRows.length > 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href=''>
                New Reservations
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap channel-mapping' component='div'>
          <Box className='white-box-container align-hor'>
            {/* Feed Info */}
            <Box className={`white-box white-box-wrap feed-info ${isOpenArray[0] ? 'expanded' : 'collapsed'}`} sx={{ overflow: 'initial' }}>
              <Button className='btn-expand' onClick={() => togglePanel(0)} />
              <Box className='fold-content'>
                {/* Box Title Area */}
                <Box className='title-wrap'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2' className='box-title-text'>
                        Feed Info
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'></Box>
                </Box>
                <Box className='box-content'>
                  <div className='inner-wrap'>
                    {!isEmpty(feedCountry) && (
                      <SelectBox
                        id='select-02'
                        fullWidth
                        size='large'
                        selectOptions={feedCountry}
                        disabled={!isEmpty(sortedReservationContentRows)}
                        isFirstSelected={true}
                        label='Country'
                        selected={selectedCountry}
                        onChange={setSelectedCountry}
                        shrink
                      />
                    )}
                    {/* feed-list-container 위치수정 */}
                    <div className='feed-list-container '>
                      <div className='list-area'>
                        <div className='list-box'>
                          {!isEmpty(feedProviderList) && <CustomList type='radio' items={feedProviderList} changeValueHandler={setSelectedFeedProviderHandler} name='feed-info-group-2' />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>

            {/* Select Feed: */}
            <Box className={`white-box white-box-wrap  select-feed ${isOpenArray[1] ? 'expanded' : 'collapsed'}`} sx={{ overflow: 'initial' }}>
              <Button className='btn-expand' onClick={() => togglePanel(1)} />
              <Box className='fold-content'>
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography className='box-title-text title-label' variant='h2'>
                        Select Feed:
                        <Typography className='title-value' component='span'>
                          {selectedFeedProvider.providerName}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box display='flex' className='count-all'>
                      <Typography variant='span' className='text label'>
                        Showing All
                      </Typography>
                      <Typography variant='span' className='text value'>
                        {feedChannelCount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className='box-content'>
                  <div className='inner-wrap'>
                    <Box className='view-filter'>
                      <Box className='left-area'>
                        <BasicSwitches
                          disabled={false}
                          onOffMode={isEnableSwitchOn}
                          setSwitchOnOff={setIsEnableSwitchOn}
                          isLabelText={true}
                          className='switch-wrap'
                          onText='Exclude Applied Channels'
                          offText='Exclude Applied Channels'
                        />
                      </Box>
                      <Box className='right-area' width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                        <SearchField
                          id='search-1'
                          placeholder='Please enter a search term'
                          label='Ch.no or ch.Name'
                          maxWidth='230px'
                          size='medium'
                          required={false}
                          shrink={false}
                          value={feedSearchField}
                          setSearchText={feedSearchFieldHandler}
                        />
                      </Box>
                    </Box>
                    {/* Table */}
                    <CustomTableContainer className='table-container infinite-scroll-table' sx={{ position: 'relative' }}>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            <TableCell key={'feed-channel-logo'} className='th' align={'left'} sx={{ width: '25%' }}>
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={isFeedChannelAll}
                                  onChange={setFeedChannelAll}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <SortLabel labelText={'Logo'} sortable={false} labelId={'logoUrl'} />
                              </Box>
                            </TableCell>
                            <TableCell key={'feed-channel-number'} className='th' align={'right'} sx={{ width: '15%' }}>
                              <SortLabel
                                labelText={'Ch.No'}
                                labelId={'channelNumber'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={feedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'feed-channel-name'} className='th' align={'left'} sx={{ width: '35%' }}>
                              <SortLabel
                                labelText={'Ch.name'}
                                labelId={'channelName'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={feedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'feed-channel-id'} className='th' align={'left'} sx={{ width: '25%' }}>
                              <SortLabel
                                labelText={'Ch.Id'}
                                labelId={'channelId'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={feedChannelSort}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* table Body */}
                        {isEmpty(sortedFeedChannelRows) ? (
                          <TableBody className='tbody'>
                            <TableRow className='tr'>
                              <TableCell colSpan={4} className={'td noData'} align='center'>
                                <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                  <Typography>Please select a CP</Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody className='tbody'>
                            <List
                              height={listHeight} // 전체 테이블 높이
                              itemCount={sortedFeedChannelRows.length} // 데이터 길이
                              itemSize={88} // 각 행의 높이 (이 값은 행의 높이에 맞게 설정)
                              width='100%' // 전체 테이블 너비
                              position='absolute'
                              className='infinite-scroll-tbody bottom-y'>
                              {setFeedChannelRow}
                            </List>
                          </TableBody>
                        )}
                      </Table>
                    </CustomTableContainer>
                  </div>
                  {!isEmpty(sortedFeedChannelRows) ? (
                    <div className='bottom-shadow-wrap'>
                      <Box display='flex' justifyContent='flex-end'>
                        <Button
                          color='primary'
                          type='box'
                          size='medium'
                          label='Select Ch Input'
                          isIcon={true}
                          iconPos={'right'}
                          Icon={BtnArrowRight}
                          disabled={false}
                          onClick={feedToReservationChannel}
                        />
                      </Box>
                    </div>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>

            {/* Selected Channel Map */}
            <Box className='white-box white-box-wrap select-channel' sx={{ overflow: 'initial' }}>
              {/* fold-content 클래스 추가하였습니다.  */}
              <Box className='fold-content'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between' style={{ padding: '0.8rem 2.4rem 1.2rem' }}>
                  <Box className='left-area'>
                    <Box>
                      <Typography className='box-title-text title-label' variant='h2'>
                        Selected Channel Map
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box display='flex' className='count-all'>
                      <Typography variant='span' className='text label'>
                        Showing All
                      </Typography>
                      <Typography variant='span' className='text value'>
                        {reservationContentCount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <div className='box-content'>
                  {/* inner-wrap 클래스 추가하였습니다.  */}
                  <Box className='inner-wrap'>
                    <Box className='white-box-content-top-area'>
                      <Box className='component-align-wrap' justifyContent='flex-end'>
                        <Box className='field-item' maxWidth={160}>
                          <SelectBox
                            id='select-channel-map'
                            size='medium'
                            maxWidth='200px'
                            shrink={true}
                            label='Channel Map List'
                            required={false}
                            disabled={false}
                            isFirstSelected={true}
                            placeholder=''
                            selectOptions={reservationChannelMapList}
                            selected={reservationChannelMap.channelMapId}
                            onChange={(value) => onChangeHandler('channelMapId', value)}
                          />
                        </Box>
                        <Box className='field-item' maxWidth={200}>
                          <SelectBox
                            id='select-channel-map-status'
                            size='medium'
                            maxWidth='200px'
                            shrink={true}
                            label='Channel Map Status'
                            required={false}
                            selectOptions={reservationStatusList}
                            disabled={false}
                            isFirstSelected={true}
                            placeholder=''
                            selected={reservationChannelMap.reservationSaveType}
                            onChange={(value) => onChangeHandler('reservationSaveType', value)}
                          />
                        </Box>
                        <Box className='field-item' maxWidth={170}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            className='input-date-picker'
                            format='YYYY-MM-DD'
                            size='medium'
                            shrink
                            label='Recommend Month'
                            value={reservationChannelMap.reservationDate}
                            onChange={reservationDateHandler}
                            singleDate={true}
                            isAfterDate={true}
                            slots={{
                              Textfield: (params) => <TextField {...params} size='large' />,
                            }}
                          />
                        </Box>
                        <Box className='field-item' maxWidth={140}>
                          <SelectBox
                            id='select-country'
                            size='medium'
                            maxWidth='200px'
                            shrink={true}
                            label='Select Time'
                            required={false}
                            selectOptions={timeIdx.map((m) => {
                              return { id: `time${m}`, value: `${m.padStart(2, '0')}:00`, option: `${m.padStart(2, '0')}:00`, type: 'text' };
                            })}
                            disabled={false}
                            isFirstSelected={true}
                            placeholder=''
                            selected={reservationChannelMap.reservationTime}
                            onChange={(value) => onChangeHandler('reservationTime', value)}
                          />
                        </Box>
                        <Box className='field-item auto'>
                          <div className='type-button-dropdown'>
                            <Button color='' type='only-icon' size='small' label='' isIcon={false} className='btn-layer-popup' onClick={handleClick} />
                            <StyledMenu
                              id='demo-customized-menu'
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              className='btn-drop-paper'
                              PaperProps={{
                                sx: {
                                  maxHeight: '230px',
                                },
                              }}>
                              <li>
                                {/* Channel Count  */}
                                <div className='text-label'>
                                  <span>Add or remove columns</span>
                                </div>
                              </li>
                              {columnSelectData.map((list) => (
                                <MenuItem key={list.id}>{list.type === 'checkbox' && <CheckBox label={list.label} value={list.label} defaultChecked={false} />}</MenuItem>
                              ))}
                            </StyledMenu>
                          </div>
                        </Box>
                      </Box>
                    </Box>

                    {/* content-inner-wrap 삭제하였습니다. */}
                    {/* Table */}
                    <CustomTableContainer className='table-container infinite-scroll-table' sx={{ position: 'relative' }}>
                      <Table className='custom-table auto' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            <TableCell key={'saved-channel-logo'} className='th' align={'center'} sx={{ width: '16%' }}>
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={isReservationChannelAll}
                                  onChange={setReservationChannelAll}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <SortLabel labelText={'Logo'} sortable={false} labelId={'displayLogoUrl'} />
                              </Box>
                            </TableCell>
                            <TableCell key={'saved-channel-number'} className='th' align={'left'} sx={{ width: '13%' }}>
                              <SortLabel
                                labelText={'Ch.No'}
                                labelId={'displayChannelNumber'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-channel-name'} className='th' align={'left'} sx={{ width: '20%' }}>
                              <SortLabel
                                labelText={'Ch.Name'}
                                labelId={'channelName'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-channel-id'} className='th' align={'left'} sx={{ width: '18%' }}>
                              <SortLabel
                                labelText={'Ch.Id'}
                                labelId={'channelId'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-provider-name'} className='th' align={'left'} sx={{ width: '11%' }}>
                              <SortLabel
                                labelText={'CP Name'}
                                labelId={'providerName'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-genre-name'} className='th' align={'left'} sx={{ width: '10%' }}>
                              <SortLabel
                                labelText={'Genre'}
                                labelId={'genreName'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-last-change-id'} className='th' align={'left'} sx={{ width: '11%' }}>
                              <SortLabel
                                labelText={'Modifier'}
                                labelId={'lastChgUsrId'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                            <TableCell key={'saved-dma-group'} className='th' align={'center'} sx={{ width: '12%' }}>
                              <SortLabel
                                labelText={'DMA Group Name'}
                                labelId={'dmaGroupName'}
                                sortable={true}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={savedChannelSort}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* table Body */}
                        {isEmpty(sortedReservationContentRows) ? (
                          <TableBody className='tbody'>
                            <TableRow className='tr'>
                              <TableCell colSpan={8} className={'td noData'} align='center'>
                                <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                  <Typography>Please set up the Channel Map</Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody className='tbody'>
                            <List
                              height={listHeight} // 전체 테이블 높이
                              itemCount={sortedReservationContentRows.length} // 데이터 길이
                              itemSize={88} // 각 행의 높이 (이 값은 행의 높이에 맞게 설정)
                              width='100%' // 전체 테이블 너비
                              position='absolute'
                              className='infinite-scroll-tbody bottom-y'
                              itemData={itemData}>
                              {SavedChannelRow}
                            </List>
                          </TableBody>
                        )}
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  <div className='bottom-shadow-wrap'>
                    <Box display='flex' justifyContent={'space-between'}>
                      <CustomButtonGroup buttons={reservationChannelRemoveButton} justifyContent='flex-start' gap='4px' />
                      <CustomButtonGroup buttons={reservationChannelSaveButton} justifyContent='flex-end' gap='4px' />
                    </Box>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {popupType.remove && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={removeActionButton} />}
      {popupType.save && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={saveActionButton} />}

      {popupType.alarm && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={popupType.close} buttons={alarmActionButtons} />}
      {popupType.dma && <PopDMAGroup isDialogOpen={popupType.dma} closeHandler={closeDialog} dmaList={dmaChannel}></PopDMAGroup>}
    </Box>
  );
}
ReservationEdit.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationEdit);
