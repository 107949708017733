import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import DatePicker from 'components/component/BasicDatePicker';
import {Box, Grid, Link, Typography} from '@mui/material';
import DetailItem from './component/DetailItem';
import {BtnTeritaryOutlineRight} from 'components/component/BasicIcon';
import SelectBox from 'components/component/BasicSelectBox';
import {deleteRequest, getRequest, multiRequest} from "utils/Request";
import DetailImageItem from "./component/DetailImageItem";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

const vodDetailTabs = [
  { label: 'Basic Information', badgeType: '' },
  { label: 'Media & Image', badgeType: '' },
];

// ************************** 메인 컨텐츠 ***********************************
const MetadataVodEdit = React.memo((props) => {
  const { darkMode, message} = props;
  const { contentId, countryCode, tabValue, filters, type } = message;
  const [vodData, setVodData] = useState(null);
  const [genres, setGenres] = useState([]);
  const [modifiedVod, setModifiedVod] = useState({});
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  // Modified file list
  const [modifiedFiles, setModifiedFiles] = useState([]);
  const [paramLicenseStartDate, setParamLicenseStartDate] = useState(dayjs());
  const [paramLicenseEndDate, setParamLicenseEndDate] = useState(dayjs());

  const navigate = useNavigate();
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata Edit',
    normalMenu: ['VOD Detail Edit'],
  };

  useEffect(() => {
    getGenres();
    // eslint-disable-next-line
  }, []);

  // Tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (message) {
      searchVodDetail();
    }
    // eslint-disable-next-line
  }, [message]);

  const searchVodDetail = async () => {
    setIsSearchSuccessful(false);
    setModifiedFiles([]);

    const body = await getRequest(`/metadata/vod/${contentId}?countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      setVodData(body.data);

      let originalVodData = {};
      originalVodData.vodId = body.data.vodId;
      originalVodData.providerId = body.data.providerId;
      originalVodData.genreCode = body.data.vodGenreCode;
      originalVodData.licenseStartDate = body.data.licenseStartDate;
      originalVodData.licenseEndDate = body.data.licenseEndDate;
      originalVodData.poster360 = body.data.posterImageList.find(image => image.width === '360')?.replacedUrl;
      originalVodData.thumbnail768 = body.data.thumbnailImageList.find(image => image.width === '768')?.replacedUrl;

      setParamLicenseStartDate(dayjs(body.data.licenseStartDate));
      setParamLicenseEndDate(dayjs(body.data.licenseEndDate));

      setModifiedVod(originalVodData);
    }
  };

  const getGenres = async () => {
    const body = await getRequest(`/metadata/genres?genreType=program&countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      const genreObjects = body.data.map(genre => ({
        id: genre.genreCode,
        value: `(${genre.genreCode}) ${genre.genreName}`,
        option: `(${genre.genreCode}) ${genre.genreName}`,
        type: 'text'
      }));
      setGenres(genreObjects);
    }
  };

  useEffect(() => {
    if (vodData && genres.length > 0) {
      setIsSearchSuccessful(true);
    }
  }, [genres, vodData]);

  const saveMetadata = async () => {
    const requestBody = new FormData();
    requestBody.append('modifiedVod', new Blob([JSON.stringify(modifiedVod)], { type: 'application/json' }));

    if (modifiedFiles.length > 0) {
      modifiedFiles.forEach(({ fileType, file }) => {
        requestBody.append(fileType, file);
      });
    }

    const body = await multiRequest('POST', `/metadata-edit/vod/${modifiedVod.vodId}`, requestBody);

    if (body && body.result === 'SUCCESS') {
      setIsSearchSuccessful(false);
      await searchVodDetail();
    }
  };

  const restoreMetadata = async () => {
    const body = await deleteRequest(`/metadata-edit/vod/${modifiedVod.vodId}`,
        null);
    if (body && body.result === 'SUCCESS') {
      setIsSearchSuccessful(false);
      await searchVodDetail();
    }
  };

  const handleUrlButtonClick = (url) => {
    window.open(url, '_blank');
  };

  const handleVodLicenseDateChange = (newValue, type) => {
    if (type === 'start') {
      setModifiedVod({
        ...modifiedVod,
        licenseStartDate: newValue,
      });
      setParamLicenseStartDate(newValue);
    } else {
      setModifiedVod({
        ...modifiedVod,
        licenseEndDate: newValue,
      });
      setParamLicenseEndDate(newValue);
    }
  };

  const handleVodGenreCodeChange = (value) => {
    setModifiedVod({
      ...modifiedVod,
      genreCode: value,
    });
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/MetadataEdit', {
      replace: true, state: {
        move: true,
        targetComponent: 'Metadata',
        message: {
          filters: filters,
          tabValue: tabValue,
          type: type,
        }
      }
    });
  };

  const handleUploadFile = (type, size, file) => {
    let fileType = '';

    if (type === 'thumbnail') {
      if (size === '768') {
        fileType = 'thumbnail768File';
      } else {
        return; // Not supported
      }
    } else { // poster
      if (size === '360') {
        fileType = 'poster360File';
      } else {
        return; // Not supported
      }
    }

    const tempModifiedFile = {
      fileType : fileType,
      file : file,
    }

    setModifiedFiles([...modifiedFiles, tempModifiedFile]);
  };

  const getModifiedFile = (type, size) => {
    let fileType = '';

    if (type === 'thumbnail' && size === '768') {
      fileType = 'thumbnail768File';
    } else if (type === 'poster' && size === '360') {
      fileType = 'poster360File';
    } else {
      return null;
    }

    const modifiedFile = modifiedFiles.find(file => file.fileType === fileType);
    return modifiedFile ? URL.createObjectURL(modifiedFile.file) : null;
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href='#' onClick={handleBack}>
                VOD Detail Edit
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        <Box className='content-wrap vod-detail' component='div'>
          {isSearchSuccessful && (
          <Box className='white-box white-box-wrap single-box'>
            <div className='tab-content-area'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={vodDetailTabs} />
              <Button
                color='tertiary'
                type='outline'
                size='medium'
                label='Restore'
                isIcon={false}
                autosize
                sx={{
                  position: 'absolute',
                  right: 106,
                  top: 29,
                }}
                onClick={() => restoreMetadata()}
              />
              <Button
                color='primary'
                type='box'
                size='medium'
                label='Save'
                isIcon={false}
                autosize
                sx={{
                  position: 'absolute',
                  right: 23,
                  top: 29,
                }}
                onClick={() => saveMetadata()}
              />
              <div className='box-content'>
                <Box className='detail-desc-container'>*Items modified in CMP are highlighted in red.</Box>
                {value === 0 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='detail-list-container'>
                          <Grid className='section' container columnSpacing={2.5}>
                            <Grid item xs={6}>
                              <DetailItem label='VOD ID ' value={vodData.vodId} />
                              <DetailItem label='Type' value={vodData.type} />
                              <DetailItem label='Title' value={vodData.title} />
                              <DetailItem label='CP Name' value={vodData.cpName} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailImageItem xs={8} label='' uiType='BASIC'
                                               src={vodData.imageUrl}
                                               uiWidth='768px'
                                               uiHeight='432px'
                                               actualWidth={vodData.imageWidth}
                                               actualHeight={vodData.imageHeight}/>
                            </Grid>
                          </Grid>
                          <Grid container columnSpacing={2.5}>
                            <Grid item xs={12}>
                              <DetailItem label='Description' value={vodData.description} />
                            </Grid>
                            <DetailItem
                              label={<> License Start <br /> Date </> }
                              className='none-border'
                              value={ <DatePicker
                                className='input-date-picker'
                                format='MM/DD/YYYY'
                                size='large'
                                sx={{maxWidth: '220px'}}
                                shrink
                                label='Date'
                                singleDate={true}
                                selectAllDate={true}
                                value={paramLicenseStartDate}
                                onChange={(newValue) => {
                                  handleVodLicenseDateChange(newValue, 'start');
                                }}
                              />}
                              xs={6}
                            />
                            <DetailItem
                              label={<> License End <br /> Date </> }
                              className='none-border'
                              value={ <DatePicker
                                className='input-date-picker'
                                format='MM/DD/YYYY'
                                size='large'
                                sx={{maxWidth: '220px'}}
                                shrink
                                label='Date'
                                singleDate={true}
                                selectAllDate={true}
                                value={paramLicenseEndDate}
                                onChange={(newValue) => {
                                  handleVodLicenseDateChange(newValue, 'end');
                                }}
                              />}
                              xs={6}
                            />
                            <DetailItem label='Start date time' value={vodData.startDateTime} xs={6} />
                            <DetailItem label='End date time' value={vodData.endDateTime} xs={6} />
                            <DetailItem label='Duration' value={vodData.duration} xs={6} />
                            <DetailItem label='Release Date' value={vodData.releaseDate} xs={6} />
                            <DetailItem
                              label='Genre Code'
                              className='none-border'
                              content={
                                <SelectBox
                                  id='channel-genres'
                                  size='large'
                                  shrink={false}
                                  label=''
                                  required={false}
                                  selectOptions={genres}
                                  disabled={false}
                                  selected={vodData.vodGenreName}
                                  onChange={(value) => {
                                    const match = value.match(/\((\d+)\)/);
                                    if (match) {
                                      handleVodGenreCodeChange(match[1]);
                                    }
                                  }}
                                />
                              }
                              xs={6}
                            />
                            <DetailItem label='Episode Number' value={vodData.episodeNumber} xs={6} />
                            <DetailItem label='Keywords' value={vodData.keywords} xs={6} />
                            <DetailItem label='Studio' value={vodData.studio} xs={6} />
                            <DetailItem label='Caption Flag' value={vodData.captionFlag} xs={12} />
                            <DetailItem label='Rating' value={vodData.rating} xs={12} />
                          </Grid>
                          <Grid
                            container
                            component='section'
                            className='section-container'
                            columnSpacing={2.5}
                            sx={{ mt: 5 }}
                          >
                            <Grid item xs={12}>
                              <Typography className='sub-title'>Credit List</Typography>
                            </Grid>
                              {vodData.creditList.map((credit, index) => (
                                <DetailItem label='Role/Name' className='' value={credit} xs={6} />
                              ))}
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
                {value === 1 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='media-detail-container'>
                          <Box className='media-inner-wrap'>
                            <Box className='media-wrap'>
                              <Box className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Media
                                </Typography>
                              </Box>
                              <Box className='media-item-wrap'>
                                <Box className='item-info-area'>
                                  <Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Stream Container Type :</Typography>
                                      <Typography className='text value'>{vodData.streamContainerType}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Codec :</Typography>
                                      <Typography className='text value'>{vodData.codec}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Drmflag :</Typography>
                                      <Typography className='text value'>{vodData.drmFlag}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>MediaUrl :</Typography>
                                      <Typography className='text value'>{vodData.mediaUrl}</Typography>
                                    </Box>
                                  </Box>
                                  <Box className='link-url'>
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Media URL'
                                      isIcon={true}
                                      Icon={BtnTeritaryOutlineRight}
                                      iconPos='right'
                                      target='_blank'
                                      onClick={() => handleUrlButtonClick(vodData.mediaUrl)}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Image List
                                </Typography>
                              </div>
                              <Grid container direction="column" spacing={5}>
                                {vodData.stillCutImageList.map((item, index) => (
                                  <Grid item xs={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <div className='item-image-area'>
                                        <DetailImageItem xs={8} label='StillCut' uiType='SIZE_BASIC'
                                                         src={item.replacedUrl || item.originUrl || ''}
                                                         uiWidth='768px'
                                                         uiHeight='432px'
                                                         actualWidth={item.width || 0}
                                                         actualHeight={item.height || 0}
                                                         type={item.type}
                                                         ratio={item.ratio}
                                                         group={item.imageGroup}
                                        />
                                      </div>
                                    </Box>
                                  </Grid>
                                ))}
                                <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                {vodData.thumbnailImageList.map((item, index) => (
                                  <Grid item xs={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <div className='item-image-area'>
                                        <DetailImageItem xs={8} label='Thumbnail' uiType='SIZE_EDIT'
                                                         src={getModifiedFile('thumbnail', item.width) || item.replacedUrl || item.originUrl || ''}
                                                         uiWidth='768px'
                                                         uiHeight='432px'
                                                         actualWidth={item.width || 0}
                                                         actualHeight={item.height || 0}
                                                         type={item.type}
                                                         ratio={item.ratio}
                                                         group={item.imageGroup}
                                                         index={index}
                                                         uploadImageId={'thumbnail' + index}
                                                         uploadFile={(file) => handleUploadFile('thumbnail', item.width, file)}
                                        />
                                      </div>
                                    </Box>
                                  </Grid>
                                ))}
                                </Grid>
                                {vodData.posterImageList.map((item, index) => (
                                  <Grid item xs={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <div className='item-image-area'>
                                        <DetailImageItem xs={8} label='Poster' uiType='SIZE_EDIT'
                                                         src={getModifiedFile('poster', item.width) || item.replacedUrl || item.originUrl || ''}
                                                         uiWidth='360px'
                                                         uiHeight='540px'
                                                         actualWidth={item.width || 0}
                                                         actualHeight={item.height || 0}
                                                         type={item.type}
                                                         ratio={item.ratio}
                                                         group={item.imageGroup}
                                                         index={index}
                                                         uploadImageId={'poster' + index}
                                                         uploadFile={(file) => handleUploadFile('poster', item.width, file)}
                                        />
                                      </div>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
          )}
        </Box>
      </Box>
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
    </Box>
  );
});

MetadataVodEdit.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    countryCode: PropTypes.string,
    filters: PropTypes.object,
    tabValue: PropTypes.number,
  }).isRequired,
};

export default MetadataVodEdit;
