import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextLink from 'components/component/BasicTextLink';
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import SortLabel from 'components/component/BasicTableSortLabel';

import { ChannelMapHistoryHeader } from 'pages/Organization/ChannelMapping/data/DefaultData';
import { useNavigate } from 'react-router-dom';
import { getRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import { setChannelMapSelectOptionList as setChannelMapOption } from 'utils/CommonChannelMap';
import dayjs from 'dayjs';

import PopSnapshotDownload from 'pages/Common/PopSnapshotDownload'; // Snapshot Download Popup

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

export const ChannelMapModeOption = [
  { id: 'ChannelMapMode', value: 'channelMap', option: 'Channel Map', type: 'text' },
  //{ id: 'ChannelMode', value: 'channel', option: 'Channel', type: 'text' },
];
const historySaveTypeOption = [
  { id: 'saveType1', value: 'All', option: 'All', type: 'text' },
  { id: 'saveType2', value: 'ST', option: 'Saved For Testing', type: 'text' },
  { id: 'saveType3', value: 'SP', option: 'Saved For Published', type: 'text' },
  { id: 'saveType4', value: 'T', option: 'Testing', type: 'text' },
  { id: 'saveType5', value: 'P', option: 'Published', type: 'text' },
  // TODO
  // { id: 'saveType6', value: 'SRT', option: 'Saved Reservation For Testing', type: 'text' },
  // { id: 'saveType7', value: 'SRP', option: 'Saved Reservation For Published', type: 'text' },
  // { id: 'saveType8', value: 'RT', option: 'Reservation For Testing', type: 'text' },
  // { id: 'saveType9', value: 'RP', option: 'Reservation For Published', type: 'text' },
];

// 메인 컨텐츠
function History({ message }) {
  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['History'],
  };

  const defaultOption = { id: 'All', value: 'All', option: 'All', type: 'text' };
  const defaultParameter = { historyMode: 'channelMap', countryCode: 'US', channelMapId: 'All', saveType: 'All', isUseDate: true, startDate: dayjs().subtract(7, 'day'), endDate: dayjs() };
  const [historyParameter, setHistoryParameter] = useState(defaultParameter);
  const [countryFilter, setCountryFilter] = useState('');
  const [channelMapList, setChannelMapList] = useState([]);
  const [channelMapParameter, setChannelMapParameter] = useState({});

  // page
  const [historyList, setHistoryList] = useState([]);
  const [displayHistoryList, setDisplayHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);

  // Snapshot Download
  const [isSnapshotPopupOpen, setIsSnapshotPopupOpen] = useState(false);
  const [snapshotChannelMapList, setSnapshotChannelMapList] = useState([]); // All 제외 필터

  useEffect(() => {
    let countryCode = 'US';
    if (message && !isEmpty(message.channelMapParameter)) {
      setChannelMapParameter(message.channelMapParameter);
    }

    if (message && !isEmpty(message.pageMode)) {
      if (message.pageMode === 'init') {
        countryCode = message.countryCode;
      } else if (message.pageMode === 'historyBack') {
        countryCode = message.historyParameter.countryCode;
        setHistoryParameter(message.historyParameter);
      }
    }

    if (message && !isEmpty(message.countryFilter)) {
      setCountryFilter(message.countryFilter);
      let country = message.countryFilter.find((f) => f.value === countryCode);
      if (!isEmpty(country)) {
        setChannelMapList([defaultOption, ...setChannelMapOption(country.channelMapList)]);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (
      !isEmpty(historyParameter) &&
      !isEmpty(historyParameter.historyMode) &&
      !isEmpty(historyParameter.countryCode) &&
      !isEmpty(historyParameter.channelMapId) &&
      !isEmpty(historyParameter.saveType)
    ) {
      getHistoryList(
        historyParameter.historyMode,
        historyParameter.countryCode,
        historyParameter.channelMapId,
        historyParameter.saveType,
        historyParameter.isUseDate,
        historyParameter.startDate.format('YYYY-MM-DD'),
        historyParameter.endDate.format('YYYY-MM-DD')
      );
    }
    // eslint-disable-next-line
  }, [historyParameter]);

  const getHistoryList = async (historyMode, countryCode, channelMapId, saveType, isUseDate, startDate, endDate) => {
    let param = `?countryCode=${countryCode}&channelMapId=${channelMapId}&saveType=${saveType}`;
    param += `&isUseDate=${isUseDate ? 'Y' : 'N'}&startDate=${startDate}&endDate=${endDate}`;

    if (historyMode === 'channelMap') {
      getChannelMapHistoryList(param);
    } else if (historyMode === 'channel') {
      getChannelHistoryList(param);
    }
  };

  const getChannelMapHistoryList = async (param) => {
    const body = await getRequest(`/channel-map/history${param}`, null);
    if (body && body.result === 'SUCCESS') {
      setHistoryList(body.data);
      setPageNavigate(body.data, currentPage, pageSize);
      setOrder('asc');
      setOrderBy('');
    }
  };

  const getChannelHistoryList = async (param) => {
    // TODO
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    setDisplayHistoryList(sortRows([...displayHistoryList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const onChangeHandler = (name, value) => {
    if (name === 'historyMode') {
      setHistoryParameter({ ...defaultParameter, [name]: value });
    } else if (name === 'countryCode') {
      setHistoryParameter({ ...defaultParameter, historyMode: historyParameter.historyMode, [name]: value });
      let country = countryFilter.find((f) => f.value === value);
      if (!isEmpty(country)) {
        setChannelMapList([defaultOption, ...setChannelMapOption(country.channelMapList)]);
      }
    } else if (name === 'startDate') {
      if (
        (!isEmpty(value) && !isEmpty(historyParameter.startDate) && historyParameter.startDate.format('YYYY-MM-DD'), value.format('YYYY-MM-DD') !== historyParameter.startDate.format('YYYY-MM-DD'))
      ) {
        setHistoryParameter({ ...historyParameter, [name]: value });
      }
    } else if (name === 'endDate') {
      if ((!isEmpty(value) && !isEmpty(historyParameter.endDate) && historyParameter.endDate.format('YYYY-MM-DD'), value.format('YYYY-MM-DD') !== historyParameter.endDate.format('YYYY-MM-DD'))) {
        setHistoryParameter({ ...historyParameter, [name]: value });
      }
    } else {
      setHistoryParameter({ ...historyParameter, [name]: value });
    }
  };

  const setUseDateCheck = (e) => {
    setHistoryParameter({ ...historyParameter, isUseDate: !e.target.checked });
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setPageNavigate(historyList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageNavigate(historyList, 1, paging.value);
      }
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      setDisplayHistoryList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayHistoryList([]);
    }
    setCurrentPage(current);
    setPageSize(size);
  };

  const getSaveTypeText = (row) => {
    let text = '';
    let content = historySaveTypeOption.find((f) => f.value === row.saveType);

    if (!isEmpty(content)) {
      text = content.option;
      if (row.syncFlag === 'Y') {
        text = text + ' (sync)';
      }
    }
    return text;
  };

  const getHistoryResultText = (row) => {
    let text = `Deleted ${row.removedCount} / Created ${row.addedCount} / Updated ${row.editedCount} / Unchanged ${row.unchangedCount}`;
    return text;
  };

  const historyDetailLinkHandler = (row) => {
    if (!isEmpty(row)) {
      navigate('/ChannelMapping', {
        replace: true,
        state: {
          move: true,
          targetComponent: 'ChannelMapHistoryDetail',
          message: {
            historyRow: { ...row, type: historySaveTypeOption.find((f) => f.value === row.saveType).option },
            historyParameter: historyParameter,
            countryFilter: countryFilter,
            channelMapParameter: channelMapParameter,
          },
        },
      });
    }
  };

  const handleSnapshotDownload = () => {
    setSnapshotChannelMapList(channelMapList.filter((f) => f.value !== 'All'));
    setIsSnapshotPopupOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                History
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'></Box>
            <Box className='right-area'>
              <Button color='tertiary' type='outline' size='medium' label='Snapshot Download' isIcon={false} onClick={handleSnapshotDownload} />
            </Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    {/* Country */}
                    <SelectBox
                      id='history-mode'
                      size='large'
                      label='Mode'
                      shrink={true}
                      required={false}
                      selectOptions={ChannelMapModeOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      selected={historyParameter.historyMode}
                      onChange={(value) => onChangeHandler('historyMode', value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    {/* CP Name */}
                    {!isEmpty(countryFilter) && (
                      <SelectBox
                        id='history-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        required={false}
                        selectOptions={countryFilter}
                        disabled={false}
                        isFirstSelected={true}
                        placeholder=''
                        selected={historyParameter.countryCode}
                        onChange={(value) => onChangeHandler('countryCode', value)}
                      />
                    )}
                  </Box>
                  <Box component='div' className='col'>
                    {/* Channel Id */}
                    <SelectBox
                      id='history-channel-map'
                      size='large'
                      shrink={true}
                      label='Channel Map'
                      selectOptions={channelMapList}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      value=''
                      selected={historyParameter.channelMapId}
                      onChange={(value) => onChangeHandler('channelMapId', value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    {/* Channel Name */}
                    <SelectBox
                      id='history-save-status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={historySaveTypeOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      value=''
                      selected={historyParameter.saveType}
                      onChange={(value) => onChangeHandler('saveType', value)}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap'>
                  <Box component='div' className='col auto'>
                    <DatePicker
                      format='YYYY-MM-DD'
                      size='large'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      sx={{ maxWidth: '210px' }}
                      disabled={!historyParameter.isUseDate}
                      startDate={historyParameter.startDate}
                      endDate={historyParameter.endDate}
                      onChangeStartDate={(value) => onChangeHandler('startDate', value)}
                      onChangeEndDate={(value) => onChangeHandler('endDate', value)}
                    />
                    <CheckBox label='Point of Time' checked={!historyParameter.isUseDate} onChange={(event) => setUseDateCheck(event)} disabled={false} />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='total-count' mb={1}>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {isEmpty(historyList) ? 0 : historyList.length}
                    </Box>
                  </Box>
                </Box>
                <Box className='right-area'></Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {ChannelMapHistoryHeader.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <SortLabel
                              labelText={column.label}
                              labelId={column.labelId}
                              sortable={column.sortable}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              order={order}
                              setOrder={setOrder}
                              sorting={dataSorting}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {displayHistoryList.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {ChannelMapHistoryHeader.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {column.labelId === 'historyDate' ? (
                                <TextLink onClick={() => historyDetailLinkHandler(td)} text={td[column.labelId]} />
                              ) : column.labelId === 'saveType' ? (
                                getSaveTypeText(td)
                              ) : column.labelId === 'historyResult' ? (
                                getHistoryResultText(td)
                              ) : (
                                td[column.labelId]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
              {/* Pagination */}
              {isPageNavigate && <Pagination count={Math.ceil(historyList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />}
            </div>
          </Box>
        </Box>
      </Box>
      {isSnapshotPopupOpen && <PopSnapshotDownload type={'channelMap'} country={historyParameter.countryCode} channelMapFilter={snapshotChannelMapList} close={setIsSnapshotPopupOpen} />}
    </Box>
  );
}
export default History;
