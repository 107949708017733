import React, {useState, useEffect, useRef, createContext, useContext} from 'react';
import { FixedSizeList } from 'react-window';
import {
  Box
  , List as MuiList
  , ListItem
  , ListItemText
  , Typography
} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import { SchedulesTime } from "./data/ChannelStatusData";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import styles from "../../../assets/scss/page/channelStatus.module.scss";
import Tooltip from "../../../components/component/BasicTooltip";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import { connect } from "react-redux";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { styled } from '@mui/material/styles';
import PopChannelDetail from "../../Common/PopChannelDetail";
import Loading from "../../../components/component/Loading";

dayjs.extend(utc);

const TimelineWidthContext = createContext();

const ProgramItem = ({ data, style, rowRef }) => (
  <Box component='div' className={styles['program-item-wrap']} style={style}>
    <Box component='div' className={styles.row} ref={rowRef}>
      {data.map((program) => (
        <>
          {program.leftGap > 0 && (
              <Box component='div' sx={{ width: `${program.leftGap}px`}} />
          )}
          <Box
            component='div'
            className={`${styles['program-item']} ${
              program.current === 'current' ? styles.current : program.current === 'past' ? styles.past : ''
            } ${parseInt(program.width) < 25 ? styles.tiny : ''}`}
            key={program.id}
            sx={{ width: program.width }}
          >
            <Tooltip title={
              <>
                <div>{program.title}</div>
                <div><b>{program.time}</b></div>
              </>
            } placement='top'>
              <Typography className={styles['program-title']}>{program.title}</Typography>
              <Typography className={styles['program-time']}>
                <span className={styles.text}>{program.time}</span>
              </Typography>
            </Tooltip>
          </Box>
        </>
      ))}
    </Box>
  </Box>
);

const VirtualizedProgramList = ({ programs }) => {
  const listHeight = window.innerHeight * 0.7;
  // 각 아이템의 높이는 80px로 고정
  const itemSize = 80;
  const [, setContentWidth] = useState('auto');
  const rowRef = useRef(null);

  const { timelineWidth } = useContext(TimelineWidthContext);

  useEffect(() => {
    if (rowRef.current) {
      const rowWidth = rowRef.current.scrollWidth;
      setContentWidth(rowWidth);
    }
  }, [programs]); // programs가 변경될 때마다 넓이 재계산

  const Row = ({ index, style }) => {
    return (
      <ProgramItem
        data={programs[index]}
        style={style}
        rowRef={index === 0 ? rowRef : null} // 첫 번째 행에만 ref 적용
      />
    );
  };

  const onScroll = ({ scrollOffset }) => {
    const channelArea = document.querySelector(`.${styles['channel-area']}`);
    if (channelArea) {
      channelArea.scrollTop = scrollOffset;
    }
  };

  return (
    <FixedSizeList
      className={`${styles['scroll-contents']} ${styles['program-schedule-container']}`}
      itemCount={programs.length}
      itemSize={itemSize}
      width={timelineWidth}
      height={listHeight}
      onScroll={({ scrollOffset }) => onScroll({ scrollOffset })}
    >
      {Row}
    </FixedSizeList>
  );
};

const CustomListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontSize: '12px',
    lineHeight: '1.6rem',
  },
});

function ProgramScheduleGrid({ country, provider }) {

  const [countryByRegion, setCountryByRegion] = useState({});
  const [paramRegion, setParamRegion] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [paramChannelMapId, setParamChannelMapId] = useState([]);
  const [paramProvider, setParamProvider] = useState([]);
  const [paramChannelName, setParamChannelName] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs().utc());

  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedChannelMapId, setSelectedChannelMapId] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedChannelName, setSelectedChannelName] = useState('All');

  const [currentTimeBar, setCurrentTimeBar] = useState('')
  const [timeBarPosition, setTimeBarPosition] = useState(0);
  const [channelInfo, setChannelInfo] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState([]);

  const [programList, setProgramList] = useState([]);
  const programListRef = useRef(programList);

  const [channelDetailOpen, setChannelDetailOpen] = useState(false);
  const [popupChannelId, setPopupChannelId] = useState('');
  const [isDisplaySchedule, setIsDisplaySchedule] = useState(false);

  const [timelineWidth, setTimelineWidth] = useState(0);

  const scheduleContainerRef = useRef(null); // schedule-container 참조
  const currentTimeRef = useRef(null);
  const eachHourRefs = useRef([]);
  const timerRef = useRef(null);
  const timelineWidthRef = useRef(null);

  const getToday = () => {
    const days = ['일', '월', '화', '수', '목', '금', '토'];

    let date =  paramDate.format('YYYY.MM.DD');
    return date + ' (' + days[paramDate.day()] + ')';
  }

  const getProgramWidth = (startDateTime, endDateTime) => {
    const startOfDay = paramDate.toDate();
    startOfDay.setUTCHours(0, 0, 0, 0);
    if (new Date(startDateTime) < startOfDay) {
      return (new Date(endDateTime) - startOfDay) / (1000 * 60) * (timelineWidth / 24 / 60);
    }
    else return (new Date(endDateTime) - new Date(startDateTime)) / (1000 * 60) * (timelineWidth / 24 / 60);
  }

  // 시간선
  useEffect(() => {
    const updateHeight = () => {
      if (scheduleContainerRef.current && currentTimeRef.current) {
        const scheduleContainerHeight = scheduleContainerRef.current.offsetHeight;
        currentTimeRef.current.style.height = `${scheduleContainerHeight}px`;
      }

      // eachHourRefs의 유효성 검사 추가
      if (eachHourRefs.current && eachHourRefs.current.length > 0 && eachHourRefs.current[0]) {
        // 첫 번째 탭에서만 실행
        setTimelineWidth(eachHourRefs.current[0].offsetWidth * eachHourRefs.current.length);
      }
    };

    // DOM이 완전히 로드된 후에 실행
    const timer = setTimeout(() => {
      updateHeight();
    }, 100);

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
      clearTimeout(timer);
    };
  }, [channelInfo]);

  const getIfCurrent = (startDateTime, endDateTime) => {
    const now = new Date();
    if (new Date(startDateTime) <= now && now < new Date(endDateTime)) {
      return 'current'
    }
    if (new Date(endDateTime) < new Date()) {
      return 'past'
    }
    return 'tobe'
  }

  useEffect(() => {
    programListRef.current = programList;
  }, [programList]);

  const updateTime = () => {
    const date = new Date();
    const utcDate = new Date(date.getTime() - 9 * 60 * 60 * 1000);
    const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'UTC', hour12: false };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    setCurrentTimeBar(formattedDate.replace(',', '').replace(/\//g, '/').replace(' ', ' ') + '(UTC)');
    setTimeBarPosition(-56.5 + (timelineWidthRef.current / 24) * utcDate.getHours() + (timelineWidthRef.current / 24 / 60) * utcDate.getMinutes());
    SchedulesTime.forEach((time) => {time.timeCurrent = false})
    SchedulesTime.find((Schedule) => Number(Schedule.id) === new Date().getUTCHours()).timeCurrent = true

    if (programListRef.current && programListRef.current.length > 0) {
      // program list
      const schedules = programListRef.current.map(schedule =>
        schedule.map(program => {
          return {
            ...program,
            current: getIfCurrent(program.startDateTime, program.endDateTime),
          };
        })
      );
      setProgramList(schedules)
    }
  }

  useEffect(() => {
    timerRef.current = setInterval(updateTime, 30000)

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (country.serviceCountry) {
      let region = [...new Set(country.serviceCountry.map(area => area.bizArea))].sort();
      const regions = region.map(reg => ({id: reg, value: reg, option: reg, type: 'text'}));
      setParamRegion([{ id: 'all', value: 'All', option: 'All', type: 'text' }, ...regions])

      let reduce = country.serviceCountry.reduce((acc, cur) => {
        const { bizArea, countryCode } = cur;
        if (!acc[bizArea]) {
          acc[bizArea] = []
        }
        acc[bizArea].push(countryCode)
        acc['All'].push(countryCode)
        return acc
      }, {'All': []});
      Object.keys(reduce).forEach(region => {
        reduce[region].sort()
      })
      setCountryByRegion(reduce)

      let countries = reduce['All'].map(country => (
        { id: country, value: country, option: country, type: 'text' }
      ));

      setParamCountry(countries)
    }
  }, [country]);

  useEffect(() => {
    if (selectedRegion && countryByRegion[selectedRegion]) {
      const b = countryByRegion[selectedRegion].map(country => (
        { id: country, value: country, option: country, type: 'text'}
      ))
      setSelectedCountry(countryByRegion[selectedRegion][0])
      setParamCountry(b)
    }
    // eslint-disable-next-line
  }, [selectedRegion]);

  useEffect(() => {
    if (provider && provider.country) {
      let find = provider.country.find(item => item.countryCode === selectedCountry);
      if (find.providerList) {
        let providers = find.providerList.map(item => ({
          id: item.providerName,
          value: item.providerName,
          option: item.providerName,
          type: 'text'
        }));
        setParamProvider([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providers])
      }
    }
    if (country && country.serviceCountry) {
      const item = country.serviceCountry.find(item => item.countryCode === selectedCountry);
      setParamChannelMapId(item.channelMapList.map(item => (
        { id: item.channelMapId, value: item.channelMapId, option: item.channelMapId, type: 'text' }
      )))
      setSelectedChannelMapId(item.channelMapList[0].channelMapId)
    }
    // eslint-disable-next-line
  }, [selectedCountry, provider, country]);

  useEffect(() => {
    if (paramDate && selectedChannelMapId) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [paramDate, selectedChannelMapId, selectedProvider, selectedChannelName]);

  const getSchedules = () => {
    const param = `?countryCode=${selectedCountry}&channelMapId=${selectedChannelMapId}&providerName=${selectedProvider}&channelName=${selectedChannelName}&date=${paramDate}`;
    fetch(`${BASE_URL}/channel-status/channel/schedules${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const schedules = body.data
          // eslint-disable-next-line
          schedules.map(schedule => {
            schedule.schedules.sort((a, b) => new Date(a.endDateTime) - new Date(b.endDateTime))
          })
          setScheduleInfo(schedules)
          setIsDisplaySchedule(true)
        }
      })
  }

  useEffect(() => {
    // 현재 시간 표시줄 업데이트
    timelineWidthRef.current = timelineWidth
    updateTime();
    // 프로그램 리스트 업데이트
    const channelInfos = scheduleInfo.map(schedule => (
      { id: schedule.channelId, number: schedule.channelNumber, title: schedule.channelName }
    ))
    setChannelInfo(channelInfos)
    const channelNames = scheduleInfo.map(schedule => (
      { id: schedule.channelName, value: schedule.channelName, option: schedule.channelName, type: 'text' }
    ))
    setParamChannelName([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...channelNames])

    // program list
    const programs = scheduleInfo.map(schedule =>
      schedule.schedules.map((program, idx) =>  {
        let leftGap = 0
        if (idx === 0) {
          const startOfDay = paramDate.toDate();
          startOfDay.setHours(9, 0, 0, 0);
          if (new Date(program.startDateTime) > startOfDay) {
            leftGap = (new Date(program.startDateTime) - startOfDay) / (1000 * 60) * (timelineWidth / 24 / 60);
          }
        } else {
          const formerProgram = schedule.schedules[idx - 1];
          if (formerProgram.endDateTime < program.startDateTime) {
            leftGap = (new Date(program.startDateTime) - new Date(formerProgram.endDateTime)) / (1000 * 60) * (timelineWidth / 24 / 60);
          }
        }
        return {
          id: `${program.programTitle} ${program.startDateTime} ${idx}`,
          current: getIfCurrent(program.startDateTime, program.endDateTime),
          width: `${getProgramWidth(program.startDateTime, program.endDateTime)}px`,
          title: program.programTitle,
          time: `${program.startDateTime.substring(11, 16)}~${program.endDateTime.substring(11, 16)}`,
          leftGap: leftGap,
          startDateTime: program.startDateTime,
          endDateTime: program.endDateTime,
        };
      })
    );

    setProgramList(programs);
    // eslint-disable-next-line
  }, [scheduleInfo, timelineWidth]);

  const channelDialogOpen = (channelId) => {
    setPopupChannelId(channelId)
    setChannelDetailOpen(true)
  }

  return (
    <TimelineWidthContext.Provider value={{ timelineWidth, setTimelineWidth }}>
      <div className='tab-panel schedule'>
        <div className='panel-wrap'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box component='div' className='form-wrap-box'>
              <Box component='div' className='form-area left'>
                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='select-business'
                      size='large'
                      shrink={true}
                      label='Region'
                      required={true}
                      selectOptions={paramRegion}
                      disabled={false}
                      selected={selectedRegion}
                      placeholder=''
                      onChange={setSelectedRegion}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='tab1-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={true}
                      selectOptions={paramCountry}
                      disabled={false}
                      placeholder=''
                      selected={selectedCountry}
                      onChange={setSelectedCountry}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='channel-map'
                      size='large'
                      shrink={true}
                      label='Channel Map ID'
                      required={false}
                      selectOptions={paramChannelMapId}
                      disabled={false}
                      placeholder=''
                      selected={selectedChannelMapId}
                      onChange={setSelectedChannelMapId}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='select-cp'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      selectOptions={paramProvider}
                      disabled={false}
                      placeholder=''
                      selected={selectedProvider}
                      onChange={setSelectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='tab2-channel-name'
                      size='large'
                      shrink={true}
                      label='Channel Name'
                      selectOptions={paramChannelName}
                      disabled={false}
                      placeholder=''
                      selected={selectedChannelName}
                      onChange={setSelectedChannelName}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box component='div' className='component-wrap '>
                  <Box component='div' className='col auto'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      sx={{maxWidth: '220px'}}
                      shrink
                      label='Date'
                      singleDate={true}
                      value={paramDate}
                      selectAllDate={true}
                      onChange={(newValue) => setParamDate(newValue)}
                    />
                    <Button
                      color='tertiary'
                      type='outline'
                      size='medium'
                      label='Today'
                      isIcon={false}
                      autosize
                      onClick={() => setParamDate(dayjs())}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {isDisplaySchedule ? (
            <Box className={`program-schedule-container ${styles['program-schedule-container']}`}>
              {/* Header Row */}
              <Box component='div' className='align-set-wrap'>
                <Box className='total-count' marginRight='45px'>
                  <Box component='span' className='label'>
                    Total
                  </Box>
                  <Box component='span' className='value'>
                    {channelInfo.length}
                  </Box>
                </Box>
                <Box component='div' className={styles['date-mark']}>
                  <Typography className={styles['date']} component='span'>
                    {getToday()}
                  </Typography>
                </Box>
              </Box>

              <div ref={scheduleContainerRef} className={styles['schedule-container']}>
                <Box className={styles['schedule-wrap']}>
                  <Box className={styles['channel-area']}>
                    <MuiList className={styles['channel-list-container']} disablePadding>
                      <ListItem className={styles['top-area']}>
                        <Typography component='p' className={styles.text}>
                          Channel
                        </Typography>
                      </ListItem>
                      {channelInfo.map((channel) => (
                        <ListItem
                          className={styles['channel-item']}
                          key={channel.id}
                          onClick={() => channelDialogOpen(channel.id)}
                        >
                          <Box className={styles['info-box']}>
                            <Typography component='span' className={styles['channel-number']}>
                              {channel.number}
                            </Typography>
                            <CustomListItemText className={styles['channel-name']} primary={channel.title}/>
                          </Box>
                        </ListItem>
                      ))}
                    </MuiList>
                  </Box>
                  <Box component='div' className={styles['schedule-area']}>
                    <Box className={styles['program-header-wrap']} wrap='nowrap'>
                      {/* 현재시간 - 시간표시 */}
                      <Box
                        className={styles['current-time']}
                        ref={currentTimeRef}
                        sx={{
                          left: `${timeBarPosition}px`, // 현재시간 위치
                          pointerEvents: 'none',
                        }}
                      >
                        <div className={styles['time-area']}>
                          <span className={styles['time-text']}>{currentTimeBar}</span>
                          <span className={styles['time-bar']}></span>
                        </div>
                      </Box>
                      <Box className={styles['header-inner']}>
                        {SchedulesTime.map((Schedule, index) => (
                          // 현재시간 표시 클래스: current
                          <div
                            className={`${styles['header-item']} ${Schedule.timeCurrent ? styles.current : ''}`}
                            key={Schedule.id}
                            ref={(el) => (eachHourRefs.current[index] = el)}
                          >
                            <Typography component='span' className={styles.text}>
                              {Schedule.time}
                            </Typography>
                          </div>
                        ))}
                      </Box>
                    </Box>
                    <Box className={styles['scroll-content-wrap']}>
                      <VirtualizedProgramList programs={programList} itemSize={80} />
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          ) : <Loading />}
        </div>
        {channelDetailOpen && (
            <PopChannelDetail channelId={popupChannelId} close={() => setChannelDetailOpen(false)} />
        )}

      </div>
    </TimelineWidthContext.Provider>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramScheduleGrid);