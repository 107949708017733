import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Snackbar from 'components/component/BasicSnackbar';
import { Box, Typography, Link, Grid } from '@mui/material';
import { getRequest } from 'utils/Request';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import DetailItem from "./component/DetailItem";
import DetailImageItem from "./component/DetailImageItem";

// 메인 컨텐츠
function MetaDataProgramDetail(props) {
  const { darkMode, message } = props;
  const { contentId, countryCode, tabValue, filters, type} = message;
  const navigate = useNavigate();
  const [isDisplay, setIsDisplay] = useState(false);
  const [programData, setProgramData] = useState({});
  const [value, setValue] = useState(0);
  const [activeButton, setActiveButton] = useState(0);
  const programDetailTabs = [
    { label: 'Basic Information', badgeType: '' },
    { label: 'Media & Image', badgeType: '' },
  ];
  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Metadata',
    normalMenu: ['Program Detail'],
  };

  useEffect(() => {
    if (message) {
      searchProgramDetail();
    }
    // eslint-disable-next-line
  }, [message]);

  const searchProgramDetail = async () => {
    setIsDisplay(false);
    const body = await getRequest(`/metadata/program/${contentId}?countryCode=${countryCode}`, null);
    if (body && body.result === 'SUCCESS') {
      setProgramData(body.data);
      setIsDisplay(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUrlButtonClick = (url) => {
    window.open(url, '_blank');
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/Metadata', { replace: true, state:
      { move: true,
        targetComponent: 'Metadata',
        message: {
          filters: filters,
          tabValue: tabValue,
          type: type,
        },
      }
    });
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Link className='title link' href='#' onClick={handleBack}>
                Program Detail
              </Link>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='white-box white-box-wrap single-box'>
            <div className='tab-content-area'>
              <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={programDetailTabs} />
              <div className='box-content'>
                {isDisplay && value === 0 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='detail-list-container'>
                          <Grid className='section' container columnSpacing={2.5}>
                            <Grid item xs={6}>
                              <DetailItem label='Program ID' className='' value={programData.programId} />
                              <DetailItem label='Title' className='' value={programData.programTitle} />
                              <DetailItem label='Episode Title' className='' value={programData.episodeTitle} />
                              <DetailItem label='Season Title' className='' value={programData.seasonTitle} />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomImage
                                src={programData.imageUrl}
                                fit={false}
                                wrapWidth='100%'
                                wrapHeight='202px'
                                alt=''
                                rounded
                              />
                              <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
                                <Typography className='img-size'>{programData.imageWidth}px X {programData.imageHeight}px</Typography>
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Image URL'
                                  isIcon={true}
                                  Icon={BtnTeritaryOutlineRight}
                                  iconPos='right'
                                  onClick={() => handleUrlButtonClick(programData.imageUrl)}
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid container columnSpacing={2.5}>
                            <Grid item xs={6}>
                              <DetailItem label='CP Name' className='' value={programData.cpName} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Duration(sec)' className='' value={programData.duration} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Description' className='' value={programData.description} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Season No.' className='' value={programData.seasonNumber} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Episode No.' className='' value={programData.episodeNumber} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='First Genre Code' className='' value={programData.firstGenreCode} />
                            </Grid>
                            <Grid item xs={6}>
                              <DetailItem label='Second Genre Code' className='' value={programData.secondGenreCode} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Caption Flag' className='' value={programData.captionFlag} />
                            </Grid>
                            <Grid item xs={12}>
                              <DetailItem label='Rating' className='' value={programData.rating} />
                            </Grid>
                          </Grid>

                          <Box sx={{ mt: 5 }}>
                            <Grid container component='section' className='section-container'>
                              <Grid item xs={12}>
                                <Typography className='sub-title'>Multi Language List</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className='toggle-btn-wrap' display='flex' gap={1}>
                                  {programData.multiLanguageList.map((item, index) => (
                                    <Button
                                      key={index}
                                      color={activeButton === index ? 'primary' : 'tertiary'}
                                      type={activeButton === index ? 'box' : 'outline'}
                                      size='small'
                                      label={item.language}
                                      isIcon={false}
                                      autosize
                                      onClick={() => handleButtonClick(index)}
                                    />
                                  ))}
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <DetailItem label='Title' className='' value={programData.multiLanguageList?.length > 0 ? programData.multiLanguageList[activeButton].title : ''} />
                              </Grid>
                              <Grid item xs={12}>
                                <DetailItem label='Season Title' className='' value={programData.multiLanguageList?.length > 0 ? programData.multiLanguageList[activeButton].seasonTitle : ''} />
                              </Grid>
                              <Grid item xs={12}>
                                <DetailItem label='Episode Title' className='' value={programData.multiLanguageList?.length > 0 ? programData.multiLanguageList[activeButton].episodeTitle : ''} />
                              </Grid>
                              <Grid item xs={12}>
                                <DetailItem label='Episode Title' className='' value={programData.multiLanguageList?.length > 0 ? programData.multiLanguageList[activeButton].description : ''} />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
                {isDisplay && value === 1 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <Box className='content-inner-wrap'>
                        <Box className='media-detail-container'>
                          <Box className='media-inner-wrap'>
                            {/* Media */}
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Media
                                </Typography>
                              </div>
                              <Box className='media-item-wrap'>
                                <Box className='item-info-area'>
                                  <Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Stream Container Type :</Typography>
                                      <Typography className='text value'>{programData.streamContainerType}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>Resolution :</Typography>
                                      <Typography className='text value'>{programData.resolution}</Typography>
                                    </Box>
                                    <Box className='item-row'>
                                      <Typography className='text label'>MediaUrl :</Typography>
                                      <Typography className='text value'>{programData.mediaUrl}</Typography>
                                    </Box>
                                  </Box>
                                  <Box className='link-url'>
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Media URL'
                                      isIcon={true}
                                      Icon={BtnTeritaryOutlineRight}
                                      iconPos='right'
                                      onClick={() => handleUrlButtonClick(programData.mediaUrl)}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {/* Preview clip  List */}
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Preview clip List
                                </Typography>
                              </div>
                              <Grid container spacing={5}>
                                {programData.previewClipList.map((clip, index) => (
                                  <Grid item xs={6} key={index}>
                                    <Box className='media-item-wrap'>
                                      <Box className='item-info-area'>
                                        <Box className='item-row'>
                                          <Typography className='text label'>Stream Container Type: </Typography>
                                          <Typography className='text value'>{clip.streamContainerType}</Typography>
                                        </Box>
                                        <Box className='item-row'>
                                          <Typography className='text label'>ClipUrl :</Typography>
                                          <Typography className='text value'>{clip.mediaUrl}</Typography>
                                        </Box>
                                        <Box className='link-url'>
                                          <Button
                                            color='tertiary'
                                            type='outline'
                                            size='small'
                                            label='Media URL'
                                            isIcon={true}
                                            Icon={BtnTeritaryOutlineRight}
                                            iconPos='right'
                                            onClick={() => handleUrlButtonClick(clip.url)}
                                            target='_blank'
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>

                            {/* Image List*/}
                            <Box className='media-wrap'>
                              <div className='title-area'>
                                <Typography variant='h3' className='category-title'>
                                  Image List
                                </Typography>
                              </div>
                              <Grid container direction="row" spacing={5} sx={{ margin: '0px' }}>
                                {programData.imageList.map((item, index) => (
                                    <Grid item xs={6} key={index}>
                                      <Box className='media-item-wrap'>
                                        <div className='item-image-area'>
                                          <DetailImageItem xs={8} label='' uiType='TYPE_BASIC'
                                                           src={programData.imageList && programData.imageList.length > 0 ? programData.imageList[index].originUrl : ''}
                                                           uiWidth='640px'
                                                           uiHeight='360px'
                                                           actualWidth={programData.imageList && programData.imageList.length > 0 ? programData.imageList[index].width : 0}
                                                           actualHeight={programData.imageList && programData.imageList.length > 0 ? programData.imageList[index].height : 0}
                                                           type={programData.imageList[index].type}
                                                           ratio={programData.imageList[index].ratio}
                                                           group={programData.imageList[index].imageGroup}
                                                           index={index}
                                          />
                                        </div>
                                      </Box>
                                    </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Box>

      {/*
        Toast Pop-Up
        - severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={'8%'} darkMode={darkMode} />
    </Box>
  );
}

export default MetaDataProgramDetail;
