import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import CustomImage from 'components/component/BasicImage';
import BasicSwitches from 'components/component/BasicSwitch';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import Dialog from 'components/component/BasicDialog';
import { useNavigate } from 'react-router-dom';
import Snackbar from 'components/component/BasicSnackbar';
import Loading from 'components/component/Loading';
import TextLink from 'components/component/BasicTextLink';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Menu } from '@mui/material';

import { styled } from '@mui/material/styles';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import PopChannelDetail from "pages/Common/PopChannelDetail";

// Data
import {
  ServiceOption,
  ManualAutoOption,
  ManualAutoOptionWithCpAndPluto,
  MappingRuleOption,
  PublishingFlagOption,
  InteractiveTitle,
} from './data/InteractiveDockData';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, disabled, manualError }) => ({
  ...(disabled && {
    backgroundColor: '#808080 !important',
    pointerEvents: 'none',
    opacity: 0.8,
  }),
  ...(manualError && {
    backgroundColor: '#FFBF7C !important',
  }),
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

function InteractiveDock(props) {
  const { currentUser, darkMode, message, providerByCountry } = props;

  const navigate = useNavigate();

  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
  };

  // 화면에 보이는 필터 정보
  const [countryOptionList, setCountryOptionList] = useState([]);
  const [providerOptionList, setProviderOptionList] = useState([]);
  const [ChannelGenreOptionList, setChannelGenreOptionList] = useState([]);

  // 화면에 보이는 필터에서 선택한 값
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedService, setSelectedService] = useState('All');
  const [selectedMappingRule, setSelectedMappingRule] = useState('All');
  const [selectedChannelGenre, setSelectedChannelGenre] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('Saved for Testing');

  // 검색에 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 서버에서 받은 원본 데이터
  const [originalChannelGenreList, setOriginalChannelGenreList] = useState([]);
  const [originalChannelList, setOriginalChannelList] = useState([]);

  // 화면에서 사용할 Channel List
  const [displayChannelList, setDisplayChannelList] = useState([]);

  // 선택한 국가에 해당하는 Provider List - 편성하는 곳에서 사용됨
  const [providerListByCountry, setProviderListByCountry] = useState([]);

  // 로딩바를 보여 줄 때 사용
  const [isDisplayChannel, setIsDisplayChannel] = useState(false);

  // Save and Publishing 버튼 활성화 유무 체크
  const [isDisplaySaveButton, setIsDisplaySaveButton] = useState(false);
  const [isDisplayPublishingButton, setIsDisplayPublishingButton] = useState(false);

  // 타이밍 이슈로 인해서 예외 처리 추가
  const [isAfterFirstRequest, setAfterFirstRequest] = useState(false);

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // Message filter 에 의한 provider 값 변경 유무 체크, 네트워크 요청을 2번 하는 부분을 제거
  const [isChangedProviderByMessageFilter, setChangeProviderByMessageFilter] = useState(false);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');

  // 토스트 팝업
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Related content list 에서 save 된 정보 체크
  const [isPublishedNeed, setIsPublishedNeed] = useState(false);

  // Detail Popup
  const [currentChannelId, setCurrentChannelId] = useState('');
  const [detailOpen, setDetailOpen] = useState(false);

  useEffect(() => {
    getLocalStorage();
    getChannelGenreList();
  }, []);

  useEffect(() => {
    if (message) {
      if (message.filter) {
        setSelectedCountry(message.filter.selectedCountry);
        setSelectedStatus(message.filter.selectedStatus);
        setSelectedProvider(message.filter.selectedProvider);
        setSelectedService(message.filter.selectedService);
        setSelectedMappingRule(message.filter.selectedMappingRule);
        setSelectedChannelGenre(message.filter.selectedChannelGenre);
      }
      if (message.isRelatedContentListSaved) {
        setIsPublishedNeed(true);
      }
    }
  }, [message]);

  useEffect(() => {
    if (originalChannelGenreList && originalChannelGenreList.channelGenreList) {
      const tempChannelGenre = [
        {
          id: 'all',
          value: 'All',
          option: 'All',
          type: 'text'
        },
        ...originalChannelGenreList.channelGenreList.filter(item => selectedCountry === 'KR' ? item.genreType === 'channel_kr' : item.genreType === 'channel').map(item => ({
          id: item.genreName,
          value: item.genreName,
          option: item.genreName,
          type: 'text',
        })),
      ];
      setChannelGenreOptionList([...tempChannelGenre]);

      // Get channel list
      getChannelList();
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [originalChannelGenreList]);

  useEffect(() => {
    if (selectedCountry && providerByCountry) {
      const countryCodeList = providerByCountry.map(item => item.countryCode).sort();

      const tempCountry = countryCodeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }));
      setCountryOptionList([...tempCountry]);

      const providerNameList = providerByCountry.find(item => item.countryCode === selectedCountry)?.providerList.map(item => item.providerName).sort();
      setProviderListByCountry([...providerNameList]);

      let messageFilterProvider = [];
      if (message && message.filter) {
        messageFilterProvider = message.filter.selectedProvider;
      }

      const tempProvider = [
        {
          id: 'all',
          value: 'all',
          option: 'All',
          type: 'checkbox',
          defaultChecked: messageFilterProvider && messageFilterProvider.length > 0 ? false : true,
        },
        ...providerNameList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'checkbox',
          defaultChecked: messageFilterProvider && messageFilterProvider.length && messageFilterProvider.includes(item) ? true : false,
        })),
      ];

      setProviderOptionList([...tempProvider]);
    }

    if (isAfterFirstRequest) {
      getChannelList();
    }
    // eslint-disable-next-line
  }, [selectedCountry, providerByCountry]);

  useEffect(() => {
    if (isAfterFirstRequest) {
      if (isChangedProviderByMessageFilter) {
        setChangeProviderByMessageFilter(false);
      } else {
        getChannelList();
      }
    }
    // eslint-disable-next-line
  }, [selectedProvider]);

  useEffect(() => {
    if (isAfterFirstRequest) {
      getChannelList();
    }
    // eslint-disable-next-line
  }, [selectedService, selectedMappingRule, selectedChannelGenre, selectedStatus]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['contentCount', 'genreCode', 'logoUrl', 'relatedFlag', 'relatedRule'];

      const filteredData = originalChannelList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDisplayChannelList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDisplayChannelList([...originalChannelList]);
      if (originalChannelList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalChannelList]);

  // Paging 처리
  useEffect(() => {
    if (displayChannelList && displayChannelList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayChannelList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setIsDisplayChannel(true);
    }
  }, [displayChannelList, page, itemsPerPage]);

  const getLocalStorage = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));

    setSelectedCountry(savedSettings && savedSettings.preferredCountry ? savedSettings.preferredCountry : 'US');
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayChannelList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayChannelList([...sortedData]);
  };

  // Category Title menu 을 선택하기 위한 용도
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Filter 처리
  const handleSelectedCountry = (country) => {
    if (message && message.filter) {
      message.filter = null;
      setChangeProviderByMessageFilter(true);
      const providerNameList = providerByCountry.find(item => item.countryCode === country)?.providerList.map(item => item.providerName).sort();
      setSelectedProvider([...providerNameList]);
    }
    setSelectedCountry(country);
  };

  const handleSelectedProvider = (provider) => {
    if (provider && provider.length > 0) {
      setSelectedProvider([...provider]);
    }
  };

  const handleSelectedService = (value) => {
    setSelectedService(value);
  };

  const handleSelectedMappingRule = (value) => {
    setSelectedMappingRule(value);
  };

  const handleSelectedChannelGenre = (value) => {
    setSelectedChannelGenre(value);
  };

  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };

  // Channel genre 가져오는 함수
  const getChannelGenreList = () => {
    fetch(`${BASE_URL}/interactivedock/channel-genre-list`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalChannelGenreList({
          channelGenreList : body.data.channelGenreList,
        });
      } else {
        throw new Error(`[Interactive] search-filter request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  // Channel List 가져오는 함수
  const getChannelList = () => {
    setIsDisplayChannel(false);
    setIsDisplaySaveButton(false);
    setIsDisplayPublishingButton(false);
    setEmptyData(false);
    setPage(1);
    setDisplayChannelList([]);
    setSearchTerm('');

    const status = selectedStatus === 'Saved for Testing' ? 'T' : 'P';
    const param = `country=${selectedCountry}&providerName=${selectedProvider}&relatedFlag=${selectedService}&mappingRule=${selectedMappingRule}&genreName=${encodeURIComponent(selectedChannelGenre)}&status=${status}`;

    fetch(`${BASE_URL}/interactivedock/channel-list?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const modifyChannelList = body.data.channelList.map(item => {
            return {
              ...item,
              relatedContentCategoryType: item.relatedRule === 'cp' ? 'cp' :
                item.relatedRule === 'block' ? 'none' :
                item.relatedRule === 'manual' ? 'manual' : 'auto',
          }});

          setOriginalChannelList([...modifyChannelList]);

          if (modifyChannelList.length === 0) {
            setEmptyData(true);
            setIsDisplayChannel(true);
          }
        } else {
          throw new Error(`[Interactive] channel-list request error`);
        }
      })
      .catch(error => {
        setAlertTitle(`Warning`);
        setAlertMessage(error.message.replace('Error: ', ''));
        setAlertOpen(true);
      });
  };

  // Alert 팝업 처리
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmSave = () => {
    postPublishRelatedContent();
    setConfirmOpen(false);
  };

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handleConfirmSave
    },
  ];

  const postPublishRelatedContent = () => {
    const status = selectedStatus === 'Saved for Testing' ? 'T' : 'P';
    const param = `country=${selectedCountry}&status=${status}&user=${currentUser.name}`;

    fetch(`${BASE_URL}/interactivedock/publish-related-content?${param}`, {
      method: 'POST',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setIsDisplaySaveButton(false);
        setIsDisplayPublishingButton(false);
        setIsPublishedNeed(false);
        setSnackbarMessage('Successfully Published !');
        setSnackbarOpen(true);
      } else {
        throw new Error(`[Interactive] Published error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  const handleTesting = () => {
    setConfirmMessage('Do you want to publish ?');
    setConfirmOpen(true);
  };

  // Service on/off 와 Manual/Auto 처리
  const handleChangedValue = (item, channelId, value) => {
    if (item === 'service') {
      setDisplayChannelList(prev =>
        prev.map(item => ({
          ...item,
          relatedFlag: item.channelId === channelId ? value ? 'on' : 'off' : item.relatedFlag
        }))
      );
    } else {  // rule
      setDisplayChannelList(prev =>
        prev.map(item => ({
          ...item,
          relatedRule: item.channelId === channelId ? value : item.relatedRule,
          relatedContentCategoryType: item.channelId === channelId ? value :
            item.relatedRule === 'cp' ? 'cp' :
            item.relatedRule === 'block' ? 'none' : item.relatedRule,
        }))
      );
    }

    setIsDisplaySaveButton(true);
    setIsDisplayPublishingButton(false);
  };

  // Service on flag 을 toggle 시킴 (단, relatedRule 이 CP 와 block 은 제외)
  // relatedRule - cp : CP 가 제공해 주는 related content 가 존재하고 항상 on임
  // relatedRule - block : CP가 pluto tv 인 경우는 항상 off 이고 편성에서 제외
  const handleAllServiceChange = () => {
    const anyServiceOnItem = displayChannelList.find(item => item.relatedRule !== 'cp' && item.relatedFlag === 'on');

    // Service on 이 1개라도 되어 있는 경우에는 전부다 off 로 변경함
    if (anyServiceOnItem) {
      setDisplayChannelList(prev =>
        prev.map(item => ({
          ...item,
          relatedFlag: item.relatedRule !== 'cp' && item.relatedRule !== 'block' ? 'off' : item.relatedFlag
        }))
      );
    } else {
      setDisplayChannelList(prev =>
        prev.map(item => ({
          ...item,
          relatedFlag: item.relatedRule !== 'cp' && item.relatedRule !== 'block' ? 'on' : item.relatedFlag
        }))
      );
    }

    setIsDisplaySaveButton(true);
    setIsDisplayPublishingButton(false);
  };

  // 상태 값을 전부 auto 로 변경함 (단, relatedRule 이 cp와 block 이 아닌 경우만)
  const handleAllAutoSave = () => {
    setDisplayChannelList(prev =>
      prev.map(item => ({
        ...item,
        relatedRule: item.relatedRule !== 'cp' && item.relatedRule !== 'block' ? 'auto' : item.relatedRule,
        relatedContentCategoryType: item.relatedRule === 'cp' ? 'cp' :
          item.relatedRule === 'block' ? 'none' : 'auto',
      }))
    );

    setIsDisplaySaveButton(true);
    setIsDisplayPublishingButton(false);
  };

  const handleSave = () => {
    const requestData = {
      user: currentUser.name,
      country: selectedCountry,
      status: selectedStatus === 'Saved for Testing' ? 'T' : 'P',
      saveChannelId: '',
      channelLists: displayChannelList
    };

    fetch(`${BASE_URL}/interactivedock/saved-related-content`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setIsDisplaySaveButton(false);
        setIsDisplayPublishingButton(true);
        setSnackbarMessage('Successfully saved !');
        setSnackbarOpen(true);
      } else {
        throw new Error(`[Interactive] Save error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  const Buttons = [
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'All Service Toggle',
      isIcon: false,
      autosize: true,
      disabled: displayChannelList.length === 0 ? true : false,
      onClick: handleAllServiceChange
    },
    {
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'All Auto Change',
      isIcon: false,
      autosize: true,
      disabled: displayChannelList.length === 0 ? true : false,
      onClick: handleAllAutoSave
    },
    {
      color: isDisplaySaveButton ? 'primary' : 'tertiary',
      type: 'box',
      size: 'small',
      label: 'Save',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      disabled: !isDisplaySaveButton ? true : false,
      onClick: handleSave
    },
    {
      color: isDisplayPublishingButton || isPublishedNeed ? 'primary' : 'tertiary',
      type: 'box',
      size: 'small',
      label: selectedStatus === 'Saved for Testing' ? 'Testing' : 'Published',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      disabled: isDisplayPublishingButton || isPublishedNeed ? false : true,
      onClick: handleTesting
    },
  ];

  const handleSearchContent = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Category Title 화면으로 이동
  const handleCategoryTitle = () => {
    navigate('/InteractiveDock',
      { replace: true, state:
        { move: true,
          targetComponent: 'CategoryTitle',
          message:
          {
            // filter 의 용도는 전달화면에서 사용하고, 그 뒤에 back 했을 때 main으로 돌려 주기 위한 용도임
            filter : {
              selectedCountry: selectedCountry,
              selectedStatus: selectedStatus,
              selectedProvider: selectedProvider,
              selectedService: selectedService,
              selectedMappingRule: selectedMappingRule,
              selectedChannelGenre: selectedChannelGenre,
            },
          }
        }
      });
  };

  // Organize 화면으로 이동
  const handleOrganize = (channelId) => {
    navigate('/InteractiveDock',
      { replace: true, state:
        { move: true,
          targetComponent: 'InteractiveDockOrganize',
          message:
          {
            providerList: providerListByCountry,
            selectedChannelId: channelId,
            channelList : displayChannelList,
            // filter 의 용도는 전달화면에서 사용하고, 그 뒤에 back 했을 때 main으로 돌려 주기 위한 용도임
            filter : {
              selectedCountry: selectedCountry,
              selectedStatus: selectedStatus,
              selectedProvider: selectedProvider,
              selectedService: selectedService,
              selectedMappingRule: selectedMappingRule,
              selectedChannelGenre: selectedChannelGenre,
            }
          }
        }
      });
  };

  const handleItemClick = (event, channelId) => {
    event.preventDefault();
    setCurrentChannelId(channelId);
    setDetailOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                Interactive Dock - Recommendation
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={countryOptionList}
                      disabled={false}
                      selected={selectedCountry}
                      onChange={handleSelectedCountry}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectCheckBox
                      id='CPName'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={providerOptionList}
                      disabled={false}
                      multiple={true}
                      onChange={handleSelectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Service'
                      size='large'
                      shrink={true}
                      label='Service'
                      required={false}
                      selectOptions={ServiceOption}
                      disabled={false}
                      selected={selectedService}
                      onChange={handleSelectedService}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='MappingRule'
                      size='large'
                      shrink={true}
                      label='Mapping Rule'
                      required={false}
                      selectOptions={MappingRuleOption}
                      disabled={false}
                      selected={selectedMappingRule}
                      onChange={handleSelectedMappingRule}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='ChannelGenre'
                      size='large'
                      shrink={true}
                      label='Channel Genre'
                      required={false}
                      selectOptions={ChannelGenreOptionList}
                      disabled={false}
                      selected={selectedChannelGenre}
                      onChange={handleSelectedChannelGenre}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={PublishingFlagOption}
                      disabled={false}
                      selected={selectedStatus}
                      onChange={handleSelectedStatus}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'></Box>
            </Box>
          </Box>

          {isDisplayChannel ? (
            <Box className='white-box white-box-wrap'>
              <div className='box-content'>
                <Box className='white-box-content-top-area' alignItems='flex-end'>
                  <Box className='left-area column'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {displayChannelList.length}
                      </Box>
                    </Box>
                    <Box display='flex' gap={1}>
                      <SearchField
                        placeholder='Please enter a search term'
                        maxWidth='230px'
                        onChange={(event) => handleSearchContent(event.target.value)}
                      />
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <CustomButtonGroup buttons={Buttons} />
                    <div className='type-button-dropdown'>
                      <Button
                        color=''
                        type='only-icon'
                        size='small'
                        label=''
                        isIcon={false}
                        className='btn-layer-popup'
                        onClick={handleClick}
                      />
                      <StyledMenu
                        id='demo-customized-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className='btn-drop-paper'
                        PaperProps={{
                          sx: {
                            maxHeight: '230px',
                          },
                        }}
                      >
                      <div className='text-value' onClick={handleCategoryTitle}>Category Title</div>
                      </StyledMenu>
                    </div>
                  </Box>
                </Box>
                <Box className='content-inner-wrap'>
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {InteractiveTitle.map((column) => (
                            <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className='tbody'>
                        {isEmptyData ? (
                          <NoRecords>
                            No records to display
                          </NoRecords>
                        ) : (
                          paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.channelId || index}>
                              {InteractiveTitle.map((column, colIndex) => (
                                <StyledTableCell
                                  key={column.id}
                                  className='td'
                                  align={column.align}
                                  manualError={td.relatedRule === 'manual' && td.contentCount === 0 ? true : false}
                                  disabled={td.relatedRule === 'cp' || td.relatedRule === 'block' ? true : false}
                                >
                                  {colIndex === 0 ? (
                                    <Box display='flex' gap='3px'>
                                      <BasicSwitches
                                        id={td.channelId}
                                        onOffMode={td.relatedFlag === 'on' ? true : false}
                                        setSwitchOnOff={(value) => handleChangedValue('service', td.channelId, value)}
                                        isLabelText={true}
                                        onText=''
                                        offText=''
                                      />
                                    </Box>
                                  ) : colIndex === 1 ? (
                                    <SelectBox
                                      id={`select-${td.channelId}`}
                                      fullWidth
                                      required={false}
                                      size='medium'
                                      maxWidth='155px'
                                      selectOptions={td.relatedRule === 'cp' || td.relatedRule === 'block' ? ManualAutoOptionWithCpAndPluto : ManualAutoOption}
                                      label=''
                                      selected={td.relatedRule}
                                      onChange={(value) => handleChangedValue('rule', td.channelId, value)}
                                    />
                                  ) : colIndex === 2 ? (
                                    <CustomImage
                                      src={td.logoUrl}
                                      fit={false}
                                      alt=''
                                      wrapWidth='140px'
                                      wrapHeight='70px'
                                      rounded
                                    />
                                  ) : colIndex === 3 ? (
                                    <>
                                      {td.channelNumber}
                                    </>
                                  ) : colIndex === 4 ? (
                                    <>
                                      {td.channelName}
                                    </>
                                  ) : colIndex === 5 ? (
                                    <TextLink
                                      href='#'
                                      text={td.channelId}
                                      onClick={(event) => handleItemClick(event, td.channelId)}
                                    />
                                  ) : colIndex === 6 ? (
                                    <>
                                      {td.providerName}
                                    </>
                                  ) : colIndex === 7 ? (
                                    <>
                                      {td.genreName}
                                    </>
                                  ) : colIndex === 8 ? (
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='ORGANIZE'
                                      isIcon={false}
                                      Icon=''
                                      iconPos={undefined}
                                      autosize
                                      disabled={td.relatedRule === 'auto'}
                                      onClick={() => handleOrganize(td.channelId)}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                </Box>
                <Pagination count={Math.ceil(displayChannelList.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              </div>
            </Box>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
      {detailOpen && <PopChannelDetail channelId={currentChannelId} open={detailOpen} close={() => setDetailOpen(false)} />}
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Dialog
        open={isConfirmOpen} // boolean 값으로 설정
        handleClose={handleConfirmClose}
        closeX={false}
        size='small' // large, medium, small
        title='Confirm' // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={confirmMessage}
        buttons={confirmDialogButtons}
      />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
InteractiveDock.propTypes = {
  providerByCountry: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  providerByCountry: state.common.provider.country
});

export default connect(mapStateToProps)(InteractiveDock);
