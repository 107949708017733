export const getExcelFile = (data) => {
  const byteArray = Uint8Array.from(atob(data.file), (c) => c.charCodeAt(0));
  const blob = new Blob([byteArray], { type: data.fileType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = data.fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};
