import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Snackbar from 'components/component/BasicSnackbar';
import { Box, Typography, Link, Grid } from '@mui/material';
import DetailItem from './component/DetailItem';
import DetailImageItem from './component/DetailImageItem';
import {getRequest} from "utils/Request";
import {useNavigate} from "react-router-dom";

const MetadataChannelDetail = React.memo((props) => {
  const { darkMode, message} = props;
  const { contentId, channelMapId, countryCode, tabValue, filters, type } = message;
  const [channelData, setChannelData] = useState(null);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata Edit',
    normalMenu: ['Channel Detail Edit'],
  };

  useEffect(() => {
    if (message) {
      searchChannelDetail().then(r => r);
    }
    // eslint-disable-next-line
  }, [message]);

  const searchChannelDetail = async () => {
    setIsSearchSuccessful(false);
    const body = await getRequest(`/metadata/channel/${contentId}?countryCode=${countryCode}&channelMapId=${channelMapId}`, null);
    if (body && body.result === 'SUCCESS') {
      setChannelData(body.data);

    }
  };

  useEffect(() => {
    if (channelData) {
      setIsSearchSuccessful(true);
    }
  }, [channelData]);

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/Metadata', {
      replace: true, state: {
        move: true,
        targetComponent: 'Metadata',
        message: {
          filters: filters,
          tabValue: tabValue,
          type: type,
        }
      }
    });
  };

  return (
      <Box display='flex' className='wrapper'>
        <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Link className='title link' href='#' onClick={handleBack}>
                  Channel Detail
                </Link>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu} />
              </div>
            </div>
          </div>

          <Box className='content-wrap channel-detail' component='div'>
            {isSearchSuccessful && (
                <Box className='white-box white-box-wrap single-box'>
                  <Box className='title-wrap space-between'>
                    <Box className='left-area'>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2'>Basic Information</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <div className='box-content'>
                    <Box className='detail-desc-container'>*Items modified in CMP are highlighted in red.</Box>

                    <Box className='content-inner-wrap' mt={2}>
                      <Box className='detail-list-container'>
                        <Grid className='section' container columnSpacing={2.5}>
                          <Grid item xs={6}>
                            <DetailItem label='Channel ID' value={channelData.channelId}/>
                            <DetailItem label='Channel Type' value={channelData.channelType}/>
                            <DetailItem label='Channel Name' className='' value={channelData.channelName} />
                            <DetailItem label='Channel Number' value={channelData.channelNumber}/>
                            <DetailItem label='CP Name' value={channelData.cpName}/>
                          </Grid>
                        </Grid>

                        <Grid container columnSpacing={2.5}>
                          <DetailItem
                              label={<>Active Time <br/> Period Start</>}
                              value={channelData.activeTimePeriodStart}
                              xs={6}
                          />
                          <DetailItem
                              label={<>Active Time <br/> Period End</>}
                              value={channelData.activeTimePeriodEnd}
                              xs={6}
                          />
                          <Box display='flex' gap='1px' marginLeft='20px'>
                            {channelData.representativeImageList.map((item, index) => (
                              <DetailImageItem xs={8} label='Representative Image' uiType='SIZE_BASIC'
                                                src={item.replacedUrl || item.originUrl || ''}
                                                uiWidth='640px'
                                                uiHeight='360px'
                                                actualWidth={item.width || 0}
                                                actualHeight={item.height || 0}
                                                type={item.type}
                                                ratio={item.ratio}
                                                group={item.imageGroup}
                                                index={index}
                              />
                            ))}
                          </Box>
                          <DetailItem xs={12} label='Media URL' value={channelData.mediaUrl}/>
                          <DetailImageItem xs={8} label='Logo' uiType='SIZE_BASIC'
                                           src={(channelData.logoImage.replacedUrl || channelData.logoImage.originUrl || '')}
                                           uiWidth='400px'
                                           uiHeight='200px'
                                           actualWidth={channelData.logoImage.width}
                                           actualHeight={channelData.logoImage.height}
                                           index={0}
                          />

                          <DetailItem xs={6} label='Channel Genre Code' className='' value={channelData.channelGenreCode}/>
                          <DetailItem xs={6} label='Keywords' value={channelData.keywords}/>
                          <DetailItem xs={6} label='Adult Flag' value={channelData.adultFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Paid Flag' value={channelData.paidFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='AD Flag' value={channelData.adFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Promotion Flag' value={channelData.promotionFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Visible' value={channelData.visible ? 'Yes': 'No'}/>
                          <DetailItem xs={6} label='Last Update' value={channelData.lastUpdateDate}/>
                        </Grid>

                        <Grid container component='section'
                              className='section-container' columnSpacing={2.5}
                              sx={{mt: 5}}>
                          <Grid item xs={12}>
                            <Typography className='sub-title'>Device List</Typography>
                          </Grid>

                          <DetailItem label='Device Name / Version' className='bold' value={channelData.deviceName} xs={6}/>

                        </Grid>
                      </Box>
                    </Box>
                  </div>
                </Box>
            )}
          </Box>
        </Box>

        <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode}/>
      </Box>
  );
});

MetadataChannelDetail.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    channelMapId: PropTypes.string,
    filters: PropTypes.object,
    tabValue: PropTypes.number,
  }).isRequired,
};

export default MetadataChannelDetail;