export const shelfDeviceOption = [
  { id: 'tv', value: 'tv', option: 'tv', type: 'text' },
  { id: 'phone', value: 'phone', option: 'phone', type: 'text' },
];

export const RegionData = [
  { id: 'US', value: 'US', option: 'US', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
  { id: 'country3', value: 'GR', option: 'GR', type: 'text' },
];
export const PlatformVersionOption = [
  { id: 'list1', value: '3.4.0', option: '3.4.0', type: 'text' },
  { id: 'list2', value: '3.4.1', option: '3.4.1', type: 'text' },
  { id: 'list3', value: '3.4.2', option: '3.4.2', type: 'text' },
];

export const EnableOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const StatusOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'Status1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const CPNameOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'Name2', value: 'Name2', option: 'Name2', type: 'text' },
];
export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const ShelfHistoryCategoryTh = [
  {
    id: 'lastChgDate',
    align: 'left',
    label: 'Change Date',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'ID',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'title',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentType',
    align: 'left',
    label: 'Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'resultOrder',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'fixFlag',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'eventType',
    align: 'left',
    label: 'Event',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastChgUsrNo',
    align: 'left',
    label: 'Last Modifier',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
];
export const ShelfHistoryCategoryTd = [
  {
    id: 'sh-row-1',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Select',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-2',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Unselect',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-3',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Select',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-4',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Select',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-5',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Unselect',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-6',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '4.9.0',
    data9: '3.4.0',
    data10: 'Select',
    data11: 'taeil.lim',
  },
];

export const ShelfHistoryContentTh = [
  {
    id: 'sh-1',
    align: 'left',
    label: 'Change Date',
    width: '12%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'sh-2',
    align: 'left',
    label: 'Tab Code',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-3',
    align: 'left',
    label: 'Tab Name',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'sh-4',
    align: 'left',
    label: 'Category code',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-5',
    align: 'left',
    label: 'Category Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-6',
    align: 'left',
    label: 'Mapping Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-7',
    align: 'left',
    label: 'Status',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-8',
    align: 'left',
    label: 'Result',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const ShelfHistoryContentTd = [
  {
    id: 'sh-row-1',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 10, created: 55, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-2',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 54, created: 4, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-3',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 65, created: 2, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-4',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 1, created: 99, updated: 32 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-5',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 10, created: 55, updated: 23 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-6',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 33, created: 24, updated: 11 },
    data09: 'taeil.lim',
  },
];

export const ShelfHistoryDetailTh = [
  {
    id: 'shd-1',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-2',
    align: 'left',
    label: 'ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-3',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-4',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-5',
    align: 'Event',
    label: 'Carousel',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-6',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-7',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-8',
    align: 'left',
    label: 'Event',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const ShelfHistoryDetailTd = [
  {
    id: 'HistoryDetailTd-1',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '4',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-2',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '5',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-3',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '2',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-4',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-5',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '1 > 3',
    data7: 'On > Off',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-6',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '3 > 1',
    data7: 'On',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-7',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
];
