import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { Box, Link, ListItem, List, Grid, Divider, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { PieChart } from '@mui/x-charts/PieChart';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import { useNavigate } from 'react-router-dom';
import { getAnTestServiceSegmentByIdxAndSegmentIdx, getAnTestServiceUserTotal, getSegmentDetails, updateAnTestServiceSegment } from '../ANTestService/api/restApi';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import { DayOfWeekOption } from './data/SegmentData';

// 메인 컨텐츠
function ANTestServiceSegmentOrganizeAddGroup(props) {
  const { message, type } = props;
  const {
    selectedTestService,  // selected antest
    segmentIdx, // selected segment idx
    mainFilteringData, // for AN Test main page filtering data
    selectedSegment // segment info
  } = message;
  const navigate = useNavigate();
  const [existedSegment, setExistedSegment] = useState(null);
  const [selectedSegmentTarget, setSelectedSegmentTarget] = useState('');
  const [segmentSampleRate, setSegmentSampleRate] = useState(100);
  const [segmentDescription, setSegmentDescription] = useState('');
  const [bRecommendationOption, setBRecommendationOption] = useState([]); // label은 recommendationContents, value는 segmentId
  const [selectedBRecommendationContent, setSelectedBRecommendationContent] = useState(''); // value: segment id
  const [selectedBMappingType, setSelectedBMappingType] = useState('');
  const [bGroupRate, setBGroupRate] = useState(100);
  const [bContentPolicy, setBContentPolicy] = useState('');
  const [isCGroupOpen, setIsCGroupOpen] = useState(false);
  const [isAddCGroupDisabled, setIsAddCGroupDisabled] = useState(true);
  const [cRecommendationOption, setCRecommendationOption] = useState([]); // label은 recommendationContents, value는 segmentId
  const [selectedCRecommendationContent, setSelectedCRecommendationContent] = useState('NONE');
  const [selectedCMappingType, setSelectedCMappingType] = useState('auto');
  const [cGroupRate, setCGroupRate] = useState(0);
  const [cContentPolicy, setCContentPolicy] = useState('');
  const [bSegmentInfoList, setBSegmentInfoList] = useState([]);
  const [cSegmentInfoList, setCSegmentInfoList] = useState([]);
  const [segmentTargetDescription, setSegmentTargetDescription] = useState(null); // segmentTarget(key) 에 대한 description(value) 값 Object
  const [userTotalInfo, setUserTotalInfo] = useState(null); // chart 위한 각 keyword의 user total count 정보
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    open: false,
    title: '',
    content: '',
    button: [],
  });
  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: '',
    severity: ''
  });
  const [dayOfWeekOption, setDayOfWeekOption] = useState([]);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState([]);
  const [bMappingTypeOption, setBMappingTypeOption] = useState([]);
  const [cMappingTypeOption, setCMappingTypeOption] = useState([]);

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
    normalMenu: ['Segment', 'Segment Organize'],
  };

  const getCurrentUTCTime = () => {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  };

  const infos = useMemo(() => {
    return [
      {
        index: 1,
        label: type === 'antest' ? '#Current Time(UTC)' : '#Current Time(Local)',
        value: getCurrentUTCTime(),
        divide: true,
      },
      {
        index: 2,
        label: '#Title',
        value: selectedTestService.title,
        divide: true,
      },
      {
        index: 3,
        label: '#Date',
        value: `${type === 'antest' ? selectedTestService.startTime : selectedTestService.startTimeLocal} ~ ${type === 'antest' ? selectedTestService.endTime : selectedTestService.endTimeLocal}`,
        divide: true,
      },
      {
        index: 4,
        label: '#Device Type',
        value: selectedTestService.deviceType,
        divide: true,
      },
      {
        index: 5,
        label: '#Country',
        value: selectedTestService.countryCode,
        divide: true,
      },
      {
        index: 6,
        label: '#Platform Version',
        value: selectedTestService.platformVersion,
        divide: true,
      },
      {
        index: 7,
        label: '#Status',
        value: selectedTestService.status,
        divide: true,
      },
      {
        index: 8,
        label: '#Tab',
        value: `${selectedTestService.tabCode} ${selectedTestService.tabName}`,
        divide: true,
      },
      {
        index: 9,
        label: '#Category',
        value: `${selectedTestService.categoryCode} ${selectedTestService.categoryName}`,
        divide: false,
      },
    ];
  }, [selectedTestService, type]);

  const chartData = useMemo(() => {
    if (selectedSegmentTarget && bSegmentInfoList && userTotalInfo && segmentSampleRate && bGroupRate) {
      // 사람 수
      let baseTotal, adtsTotal, bGroup, cGroup = 0, aGroup;
      const nowSegment = bSegmentInfoList.find(item =>
        item.segmentTarget === selectedSegmentTarget);
      if (selectedSegmentTarget === 'ALL') {
        baseTotal = userTotalInfo.ALL;
        adtsTotal = userTotalInfo.ALL;
      } else if (nowSegment) {
        if (nowSegment.segmentType === 'CMP') {
          baseTotal = userTotalInfo.ALL;
          adtsTotal = userTotalInfo[nowSegment.keyword];
        } else if (nowSegment.segmentType === 'PER') {
          baseTotal = userTotalInfo.ALL;
          adtsTotal = userTotalInfo.ALL;
        } else {
          return null;
        }
      } else {
        return null;
      }
      if (baseTotal == null || typeof baseTotal !== 'number' ||
        adtsTotal == null || typeof adtsTotal !== 'number') {
        return null;
      }
      bGroup = Math.floor(
        adtsTotal *
        (segmentSampleRate / 100) * ((100 - cGroupRate) / 100)
      );

      if (cGroupRate && cGroupRate !== 0) {
        cGroup = Math.floor(
          adtsTotal *
          (segmentSampleRate / 100) * (cGroupRate / 100)
        );
      }

      aGroup = baseTotal - bGroup - cGroup;
      // 비율
      let bRate = Math.floor(segmentSampleRate * ((100 - cGroup) / 100));
      let cRate = Math.floor(segmentSampleRate * (cGroup / 100));
      let aRate = 100 - bRate - cRate;

      // Validate values
      if (aGroup < 0 || bGroup < 0 || cGroup < 0) {
        console.warn('Negative values calculated:', { aGroup, bGroup, cGroup });
        return null;
      }

      return [
        { id: 0, value: aGroup, label: 'All - B Group - C Group', color: '#38df9f', rate: aRate },
        { id: 1, value: bGroup, label: 'B Group', color: '#0077b5', rate: bRate },
        { id: 2, value: cGroup, label: 'C Group', color: '#0d9488', rate: cRate },
      ];
    }
    return null;
    // eslint-disable-next-line
  }, [selectedSegmentTarget, bSegmentInfoList, userTotalInfo, segmentSampleRate, cGroupRate]);

  const CloseIcon = styled('span')({
    display: 'block',
    width: '32px',
    height: '32px',
    backgroundImage: `url(${CloseIcon32})`,
    backgroundRepeat: 'no-repeat',
  });

  // Chart 유무
  const isChart = useMemo(() => {
    return segmentSampleRate !== 0 && bGroupRate !== 0;
  }, [segmentSampleRate, bGroupRate]);

  const handleOrganizeConfirmation = () => {
    isOrganizeClicked = true;
    onSaveClick();
  }

  const getSelectedSegment = () => {
    return {
      comparativeSegmentType: existedSegment.comparative_segment_id,
      abtestId: existedSegment.abtest_id,
      recommendationContentsBType: existedSegment.recommendationContentsBType,
      comparativeSegmentSampleRate: existedSegment.cSegmentSampleRate,
      description: existedSegment.description,
      abtestSubid: existedSegment.abtest_subid,
      segmentTargetRate: existedSegment.sample_rate,
      contentPolicy: existedSegment.content_policy,
      recommendationContentsCType: existedSegment.recommendationContentsCType,
      defaultContent: existedSegment.default_content,
      segmentTarget: existedSegment.segmentTarget,
      segmentId: existedSegment.segment_id,
      mappingType: existedSegment.mapping_type,
      idx: segmentIdx,
      comparativeSegmentId: existedSegment.comparative_segment_id,
      segmentType: existedSegment.segment_type
    };
  }


  const onOrganizeClick = () => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceSegmentOrganizeOrganize',
        message: {
          selectedRow: selectedTestService,
          segmentIdx: segmentIdx,
          mainFilteringData: mainFilteringData,
          firstSearch: true,
          selectedSegment: {
            segmentIdx: segmentIdx,
            sampleBRate: bGroupRate,
            bSegmentInfo: bSegmentInfoList.filter(item => item.segmentTarget === selectedSegmentTarget && item.segmentMappingType === selectedBMappingType),
            selectedSegment: selectedSegment ? selectedSegment : getSelectedSegment()
          }
        }
      }
    });
  }

  const handleSegmentOrganize = () => {
    if (!hasUserModified || selectedTestService.status === 'done') {
      onOrganizeClick();
    } else {
      setOpenDialog(true);
      setDialogContent({
        open: true,
        title: 'Organize Segment',
        content: <div>To navigate to the Organize page,<br />you need to save the current state.<br />Do you want to save it?</div>,
        button: [
          {
            color: 'tertiary',
            type: 'outline',
            size: 'medium',
            label: 'Cancel',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 85,
            disabled: false,
            onClick: handleDialogClose,
          },
          {
            color: 'primary',
            type: 'box',
            size: 'medium',
            label: 'OK',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 103,
            disabled: false,
            onClick: handleOrganizeConfirmation,
          }
        ],
      });
    }
  }

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  const hasUserModified = useMemo(() => {
    if (existedSegment) {
      return (existedSegment.segment_id !== selectedBRecommendationContent
        || existedSegment.mapping_type !== selectedBMappingType
        || existedSegment.default_content !== (isCGroupOpen ? 'Y' : 'N')
        || existedSegment.sample_rate !== segmentSampleRate
        || existedSegment.cSegmentSampleRate !== cGroupRate
        || (existedSegment.default_content === 'Y' && existedSegment.comparative_segment_id !== selectedCRecommendationContent)
        || (selectedBMappingType === 'manual' && existedSegment.content_policy !== bContentPolicy)
        || (type === 'anservice' && existedSegment.weekdays && !areArraysEqual(existedSegment.weekdays, selectedDayOfWeek)));
    } else {
      return false;
    }
  }, [existedSegment, selectedBRecommendationContent, selectedBMappingType, isCGroupOpen, bContentPolicy,
    segmentSampleRate, cGroupRate, selectedCRecommendationContent, selectedDayOfWeek, type]);

  let isOrganizeClicked = false;
  const handleUpdateSegment = async () => {
    const res = await updateAnTestServiceSegment(selectedTestService.idx, segmentIdx, {
      segmentId: selectedBRecommendationContent,
      segmentType: bSegmentInfoList.filter(item => item.segmentId === selectedBRecommendationContent)[0].segmentType,
      mappingType: selectedBMappingType,
      defaultContent: isCGroupOpen ? 'Y' : 'N',
      contentPolicy: bContentPolicy,
      sampleRate: segmentSampleRate,
      startTime: type === 'antest' ? selectedTestService.startTime : selectedTestService.startTimeLocal,
      endTime: type === 'antest' ? selectedTestService.endTime : selectedTestService.endTimeLocal,
      comparativeSegmentType: isCGroupOpen ? cSegmentInfoList.filter(item => item.segmentId === selectedCRecommendationContent)[0].segmentType : 'NONE',
      comparativeSegmentId: isCGroupOpen ? selectedCRecommendationContent : 'NONE',
      countryCode: selectedTestService.countryCode,
      comparativeMappingType: selectedCMappingType,
      comparativeSegmentSampleRate: isCGroupOpen ? cGroupRate : 0,
      comparativeContentPolicy: cContentPolicy,
      ...(type === 'antest' ? {
        abtestSubid: existedSegment.abtest_subid,
        abtestId: existedSegment.abtest_id,
      } : {
        recmdSegmentSubId: existedSegment.recmd_segment_subid,
        dayOfWeek: selectedDayOfWeek,
      }),
    }, type);

    setShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity
    });

    if (res.severity !== 'error') {
      if (isOrganizeClicked) {
        handleDialogClose();
        navigate(type === 'antest' ? '/ANTest' : '/ANService', {
          replace: true,
          state: {
            move: true,
            targetComponent: 'ANTestServiceSegmentOrganizeOrganize',
            message: {
              selectedRow: selectedTestService,
              segmentIdx: segmentIdx,
              mainFilteringData: mainFilteringData,
              firstSearch: true,
              selectedSegment: {
                segmentIdx: segmentIdx,
                sampleBRate: bGroupRate,
                bSegmentInfo: bSegmentInfoList.filter(item => item.segmentTarget === selectedSegmentTarget && item.segmentMappingType === selectedBMappingType),
                selectedSegment: selectedSegment ? selectedSegment : getSelectedSegment()
              }
            }
          }
        });
      } else {
        const selectedSegmentDetail = await getAnTestServiceSegmentByIdxAndSegmentIdx(selectedTestService.idx, segmentIdx, type);
        setExistedSegment({
          ...selectedSegmentDetail,
          ...(type === 'anservice' ? {
            weekdays: selectedSegmentDetail.weekdays?.filter(item => item !== null),
          } : {}),
        });
      }
    } else if (isOrganizeClicked) {
      handleDialogClose();
    }
  }

  const onResultClick = () => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceResult',
        message: {
          mainFilteringData: mainFilteringData,
          selectedTestService: selectedTestService,
          selectedSegment: selectedSegment,
          tComponent: 'ANTestServiceSegmentOrganizeAddGroup',
        },
      }
    });
  }

  const onSaveClick = () => {
    // C그룹 없이, B group rate이 100이 아닌 경우
    if (!isCGroupOpen && bGroupRate !== 100) {
      setShowSnackBar({ open: true, message: 'Unless the C group is turned on, the B group rate should be 100%.', severity: 'error' });
      handleDialogClose();
      return;
    }

    // B group rate < 50 일 경우
    if (bGroupRate < 50) {
      setShowSnackBar({ open: true, message: 'B group rate must be 50 or higher.', severity: 'error' });
      handleDialogClose();
      return;
    }

    // Segment sample rate 0일 경우, 한 번 더 확인
    if (segmentSampleRate === '' || segmentSampleRate === 0) {
      setOpenDialog(true);
      setDialogContent({
        title: 'Update Segment',
        content: <div>The Segment sample rate is 0.<br />Are you sure you want to save it this way?</div>,
        button: [
          {
            color: 'tertiary',
            type: 'outline',
            size: 'medium',
            label: 'Cancel',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 85,
            disabled: false,
            onClick: handleDialogClose,
          },
          {
            color: 'primary',
            type: 'box',
            size: 'medium',
            label: 'OK',
            isIcon: false,
            Icon: null,
            iconPos: undefined,
            minWidth: 103,
            disabled: false,
            onClick: async () => {
              await handleUpdateSegment();
              handleDialogClose();
            },
          }
        ],
      });
    } else {
      handleUpdateSegment();
    }
  }

  const segmentTargetOption = useMemo(() => {
    if (segmentTargetDescription) {
      const segmentTargets = Object.keys(segmentTargetDescription);
      segmentTargets.sort((a, b) => a.localeCompare(b));
      return segmentTargets.map(item => ({ id: item, value: item, option: item, type: 'text' }));
    } else return [];
  }, [segmentTargetDescription]);

  const handleSegmentTargetChange = (value) => {
    setSelectedSegmentTarget(value);
    setSegmentDescription(segmentTargetDescription[value]);

    // Update B Options
    const filteredBSegment = bSegmentInfoList.filter(item => item.segmentTarget === value);
    const newBOptions = filteredBSegment
      .map(item => ({ id: item.segmentId, value: item.segmentId, option: item.recommendationContents, type: 'text' }));
    const uniqueNewBOption = Array.from(
      new Map(newBOptions.map(item => [item.value, item])).values()
    );  // 중복 제거
    setBRecommendationOption(uniqueNewBOption);

    const newSelectedBRecommendationContent = uniqueNewBOption && uniqueNewBOption.length > 0 ? uniqueNewBOption[0].value : '';
    setSelectedBRecommendationContent(newSelectedBRecommendationContent);

    const newBMappingTypeOption = filteredBSegment
      .filter(item => item.segmentId === newSelectedBRecommendationContent)  // Segment Target 과 Recommendation Content 로 필터링
      .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' }));
    setBMappingTypeOption(newBMappingTypeOption);

    const newSelectedBMappingType = newBMappingTypeOption.length > 0 ? newBMappingTypeOption[0].value : '';
    setSelectedBMappingType(newSelectedBMappingType);

    let contentPolicyB = '';
    if (newSelectedBMappingType === 'auto') {
      const filteredSegments = bSegmentInfoList.filter(item => item.segmentId === newSelectedBRecommendationContent);
      contentPolicyB = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setBContentPolicy(contentPolicyB);
    setBGroupRate(100);

    // Update C Options
    const filteredCSegment = cSegmentInfoList.filter(item => item.segmentTarget === value);
    const newCOptions = filteredCSegment
      .map(item => ({ id: item.segmentId, value: item.segmentId, option: item.recommendationContents, type: 'text' }));
    const uniqueNewCOption = Array.from(
      new Map(newCOptions.map(item => [item.value, item])).values()
    );  // 중복 제거
    if (uniqueNewCOption?.length > 0) {
      setCRecommendationOption(uniqueNewCOption);
      const newSelectedCRecommendationContent = uniqueNewCOption[0].value;
      setSelectedCRecommendationContent(newSelectedCRecommendationContent);

      const newCMappingTypeOption = filteredCSegment
        .filter(item => item.segmentId === newSelectedCRecommendationContent)  // Segment Target 과 Recommendation Content 로 필터링
        .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' }));
      setCMappingTypeOption(newCMappingTypeOption);

      const newSelectedCMappingType = newCMappingTypeOption.length > 0 ? newCMappingTypeOption[0].value : '';
      setSelectedCMappingType(newSelectedCMappingType);

      let contentPolicyC = '';
      if (newSelectedCMappingType === 'auto') {
        const filteredSegments = cSegmentInfoList.filter(item => item.segmentId === newSelectedCRecommendationContent);
        contentPolicyC = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
      }
      setCContentPolicy(contentPolicyC);
    }

    setIsAddCGroupDisabled(true); // B Group Rate 이 100 이므로
    setIsCGroupOpen(false); // close C Group
    setCGroupRate(0);
  }

  const handleSegmentSampleRateChange = (e) => {
    const value = e.target.value;

    // 숫자만 입력되도록 유효성 검사 및 0부터 100까지만 입력 가능하도록 범위 검사
    if (/^\d*$/.test(value) && (value === '' || (Number(value) >= 0 && Number(value) <= 100))) {
      setSegmentSampleRate(Number(value));
    } else {
      e.preventDefault();
    }
  }

  const handleDayOfWeekChange = (value) => {
    setSelectedDayOfWeek(value);
  }

  const handleBRecommendationContentChange = (value) => {
    setSelectedBRecommendationContent(value);

    const newBMappingTypeOption = bSegmentInfoList
      .filter(item => item.segmentTarget === selectedSegmentTarget && item.segmentId === value)
      .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' }));
    setBMappingTypeOption(newBMappingTypeOption);

    const newSelectedBMappingType = newBMappingTypeOption.length > 0 ? newBMappingTypeOption[0].value : '';
    setSelectedBMappingType(newSelectedBMappingType);

    let contentPolicyB = '';
    if (newSelectedBMappingType === 'auto') {
      const filteredSegments = bSegmentInfoList.filter(item => item.segmentId === value);
      contentPolicyB = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setBContentPolicy(contentPolicyB);
  }

  const handleBMappingTypeChange = (value) => {
    setSelectedBMappingType(value);

    let contentPolicyB = '';
    if (value === 'auto') {
      const filteredSegments = bSegmentInfoList.filter(item => item.segmentId === selectedBRecommendationContent);
      contentPolicyB = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setBContentPolicy(contentPolicyB);
  }

  const handleBGroupRateChange = (e) => {
    const value = e.target.value;

    // 숫자만 입력되도록 유효성 검사 및 0부터 100까지만 입력 가능하도록 범위 검사
    if (/^\d*$/.test(value) && (value === '' || (Number(value) >= 0 && Number(value) <= 100))) {
      const numericValue = Number(value);
      setBGroupRate(numericValue);
      setCGroupRate(100 - numericValue);
    } else {
      e.preventDefault();
    }
  }

  // update c group open condition by b group rate value
  useEffect(() => {
    if (bGroupRate && cSegmentInfoList && selectedSegmentTarget) {
      if (bGroupRate === 100) {
        setIsAddCGroupDisabled(true);
        setIsCGroupOpen(false);
      } else if (bGroupRate < 100) {
        const newCOptions = cSegmentInfoList.filter(item => item.segmentTarget === selectedSegmentTarget);
        if (newCOptions?.length > 0) {
          setIsAddCGroupDisabled(false);
        } else {
          setIsAddCGroupDisabled(true);
        }
      }
    }
  }, [bGroupRate, cSegmentInfoList, selectedSegmentTarget]);

  const handleBContentPolicyChange = (e) => {
    setBContentPolicy(e.target.value);
  }

  const onCGroupAddClick = () => {
    setIsCGroupOpen(true);
    handleCRecommendationContentChange(cRecommendationOption.length > 0 ? cRecommendationOption[0].value : '');
  }

  const onCGroupCloseClick = () => {
    setIsCGroupOpen(false);
  }

  const handleCRecommendationContentChange = (value) => {
    setSelectedCRecommendationContent(value);

    const newCMappingTypeOption = cSegmentInfoList
      .filter(item => item.segmentTarget === selectedSegmentTarget && item.segmentId === value)
      .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' }));
    setCMappingTypeOption(newCMappingTypeOption);

    const newSelectedCMappingType = newCMappingTypeOption.length > 0 ? newCMappingTypeOption[0].value : '';
    setSelectedCMappingType(newSelectedCMappingType);

    let contentPolicyC = '';
    if (newSelectedCMappingType === 'auto') {
      const filteredSegments = cSegmentInfoList.filter(item => item.segmentId === value);
      contentPolicyC = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setCContentPolicy(contentPolicyC);
  }

  const handleCMappingTypeChange = (value) => {
    setSelectedCMappingType(value);

    let contentPolicyC = '';
    if (value === 'auto') {
      const filteredSegments = cSegmentInfoList.filter(item => item.segmentId === selectedCRecommendationContent);
      contentPolicyC = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setCContentPolicy(contentPolicyC);
  }

  const handleCContentPolicyChange = (e) => {
    setCContentPolicy(e.target.value);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  }

  const bRecommendationContentTitle = useMemo(() => {
    if (!bRecommendationOption || !selectedBRecommendationContent) {
      return null;
    }

    const nowSelected = bRecommendationOption.find(item => item.value === selectedBRecommendationContent);
    return nowSelected ? nowSelected.option : null;
  }, [bRecommendationOption, selectedBRecommendationContent]);

  const cRecommendationContentTitle = useMemo(() => {
    if (!cRecommendationOption || !selectedCRecommendationContent) {
      return null;
    }

    const nowSelected = cRecommendationOption.find(item => item.value === selectedCRecommendationContent);
    return nowSelected ? nowSelected.option : null;
  }, [cRecommendationOption, selectedCRecommendationContent]);

  const initSegmentation = async () => {
    const segmentDetails = await getSegmentDetails(selectedTestService.countryCode, type);
    const selectedSegmentDetail = await getAnTestServiceSegmentByIdxAndSegmentIdx(selectedTestService.idx, segmentIdx, type);
    setExistedSegment({
      ...selectedSegmentDetail,
      ...(type === 'anservice' ? {
        weekdays: selectedSegmentDetail.weekdays?.filter(item => item !== null),
      } : {}),
    });

    // 1. Set Segmentation info
    const targetDescObj = segmentDetails.data.filter(item => item.segmentGroup === 'B')
      .reduce((acc, item) => {  // target - description object
        acc[item.segmentTarget] = item.description;
        return acc;
      }, {});
    setSegmentTargetDescription(targetDescObj);
    setSegmentDescription(targetDescObj[selectedSegmentDetail?.segmentTarget]);  // Segment Description
    const weekdays = selectedSegmentDetail.weekdays;
    let newDayOfWeekOption;
    let checkedCnt = weekdays?.filter(item => item !== null).length;
    const selectedWeekdays = [];
    if (checkedCnt === 7) {
      newDayOfWeekOption = DayOfWeekOption.map(item => {
        if (item.value !== 'all') {
          selectedWeekdays.push(item.value);
        }
        return {
          ...item,
          defaultChecked: true
        };
      });
    } else {
      newDayOfWeekOption = DayOfWeekOption.map(item => {
        if (weekdays?.includes(item.value)) {
          selectedWeekdays.push(item.value);
        }
        return {
          ...item,
          defaultChecked: weekdays?.includes(item.value)
        };
      });
    }
    setDayOfWeekOption(newDayOfWeekOption);
    setSelectedDayOfWeek(selectedWeekdays);

    setSelectedSegmentTarget(selectedSegmentDetail?.segmentTarget);
    setSegmentSampleRate(selectedSegmentDetail?.sample_rate);

    // B Group segments
    const bGroupSegments = segmentDetails.data.filter(item => item.segmentGroup === 'B');
    setBSegmentInfoList(bGroupSegments);

    // C Group segments
    const cGroupSegments = segmentDetails.data.filter(item => item.segmentGroup === 'C');
    setCSegmentInfoList(cGroupSegments);

    // 2. Set B Group & C Group info
    // 2-1. set B Group info
    setSelectedBRecommendationContent(selectedSegmentDetail?.segment_id);
    const newBRecommendationOptions = bGroupSegments
      .filter(item => item.segmentTarget === selectedSegmentDetail?.segmentTarget)
      .map(item => ({ id: item.segmentId, value: item.segmentId, option: item.recommendationContents, type: 'text' }));
    // label은 recommendationContents, value는 segmentId
    setBRecommendationOption(Array.from(
      new Map(newBRecommendationOptions.map(item => [item.value, item])).values() // 중복 제거
    ));
    setSelectedBMappingType(selectedSegmentDetail?.mapping_type);
    setBMappingTypeOption(bGroupSegments
      .filter(item => item.segmentTarget === selectedSegmentDetail?.segmentTarget && item.segmentId === selectedSegmentDetail?.segment_id)  // Segment Target 과 Recommendation Content 로 필터링
      .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' })));
    setBGroupRate(selectedSegmentDetail?.default_content === 'Y' ? 100 - selectedSegmentDetail?.cSegmentSampleRate : 100);
    let contentPolicyB;
    if (selectedSegmentDetail?.mapping_type !== 'auto') {
      contentPolicyB = selectedSegmentDetail?.content_policy;
    } else {  // auto
      const filteredSegments = bGroupSegments.filter(item => item.segmentId === selectedSegmentDetail?.segment_id);
      contentPolicyB = filteredSegments.length > 0 ? filteredSegments[0].contentPolicyAuto : '';
    }
    setBContentPolicy(contentPolicyB);

    // 2-2. set C Group info
    const newCRecommendationOptions = cGroupSegments
      .filter(item => item.segmentTarget === selectedSegmentDetail?.segmentTarget)
      .map(item => ({ id: item.segmentId, value: item.segmentId, option: item.recommendationContents, type: 'text' }));
    setCRecommendationOption(Array.from(
      new Map(newCRecommendationOptions.map(item => [item.value, item])).values() // 중복 제거
    )); // label은 recommendationContents, value는 segmentId

    if (selectedSegmentDetail?.default_content === 'Y') {
      setIsCGroupOpen(true);
      setSelectedCRecommendationContent(selectedSegmentDetail?.cSegmentId);
      setCMappingTypeOption(cGroupSegments
        .filter(item => item.segmentTarget === selectedSegmentDetail?.segmentTarget && item.segmentId === selectedSegmentDetail?.cSegmentId)  // Segment Target 과 Recommendation Content로 필터링
        .map(item => ({ id: item.segmentMappingType, value: item.segmentMappingType, option: item.segmentMappingType, type: 'text' })));
      setSelectedCMappingType(selectedSegmentDetail?.comparative_mapping_type);
      setCGroupRate(selectedSegmentDetail?.cSegmentSampleRate);

      const filteredCSegments = cGroupSegments.filter(item => item.segmentId === selectedSegmentDetail?.cSegmentId);
      setCContentPolicy(filteredCSegments.length > 0 ? filteredCSegments[0].contentPolicyAuto : '');
    }
  }

  const getUserTotalInfo = async () => {
    const userTotalData = await getAnTestServiceUserTotal(selectedTestService.countryCode, type);
    setUserTotalInfo({
      ALL: userTotalData?.total_unique_users_beacon,
      drama: userTotalData?.total_unique_users_drama_adts,
      hobbyLeisure: userTotalData?.total_unique_users_hobbyLeisure_adts,
      movies: userTotalData?.total_unique_users_movies_adts,
      music: userTotalData?.total_unique_users_music_adts,
      news: userTotalData?.total_unique_users_news_adts,
      sport: userTotalData?.total_unique_users_sport_adts,
    });
  }

  useEffect(() => {
    initSegmentation();
    getUserTotalInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container segment-container' component='main' sx={{ flexGrow: 1 }}>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  // status가 ready 또는 testing 이며, 변경 사항이 있는 경우, 다시 확인 후 이동
                  if (selectedTestService.status !== 'done' && hasUserModified) {
                    setOpenDialog(true);
                    setDialogContent({
                      title: 'Update Segment',
                      content: <div>Do you want to navigate away from this page?<br />Unsaved changes may be lost.</div>,
                      button: [
                        {
                          color: 'tertiary',
                          type: 'outline',
                          size: 'medium',
                          label: 'Cancel',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 85,
                          disabled: false,
                          onClick: handleDialogClose,
                        },
                        {
                          color: 'primary',
                          type: 'box',
                          size: 'medium',
                          label: 'OK',
                          isIcon: false,
                          Icon: null,
                          iconPos: undefined,
                          minWidth: 103,
                          disabled: false,
                          onClick: () => {
                            navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                              replace: true, state: {
                                move: true,
                                targetComponent: 'ANTestServiceSegment',
                                message: {
                                  selectedRow: selectedTestService,
                                  mainFilteringData: mainFilteringData
                                }
                              }
                            });
                          }
                        }
                      ],
                    });
                  } else {
                    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
                      replace: true, state: {
                        move: true,
                        targetComponent: 'ANTestServiceSegment',
                        message: {
                          selectedRow: selectedTestService,
                          mainFilteringData: mainFilteringData
                        }
                      }
                    });
                  }
                }}
              >
                Segment
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap height-auto' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              {selectedTestService.status === 'done' ?
                <Button color='primary' type='box' size='medium' label='Result' autosize isIcon={false}
                  onClick={onResultClick} />
                :
                <Button color='primary' type='box' size='medium' label='Save' autosize isIcon={false}
                  onClick={onSaveClick} disabled={!hasUserModified} />
              }
            </Box>
          </Box>

          <Box className='white-box white-box-wrap segment-box' sx={{ flexShrink: 0 }}>
            <Box className='title-wrap'>
              <Box className='box-title-area' display='flex' alignItems='center'>
                <Typography className='segment-box-title'>Segmentation</Typography>
              </Box>
            </Box>
            <Box className='box-content'>
              <Box className='segment-contgent-wrap'>
                <Grid container gap={1}>
                  <Grid item xs={1.7}>
                    <Box component='div'>
                      <SelectBox
                        id='slt-country'
                        size='large'
                        shrink={true}
                        label='Segment Target'
                        require={false}
                        selectOptions={segmentTargetOption}
                        disabled={selectedTestService.status === 'done'}
                        placeholder=''
                        selected={selectedSegmentTarget}
                        onChange={handleSegmentTargetChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={1.7}>
                    <Box component='div'>
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder='0 ~ 100'
                        label='Segment Sample Rate'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        disabled={selectedTestService.status === 'done'}
                        value={segmentSampleRate}
                        onChange={handleSegmentSampleRateChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={type === 'antest' ? 6 : 5}>
                    <Box component='div'>
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        label='Segment Description'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                        value={segmentDescription}
                      />
                    </Box>
                  </Grid>
                  {type === 'anservice' &&
                    <Grid item xs={1.7}>
                      <Box component='div'>
                        <SelectCheckBox
                          id='slt-day-of-week'
                          size='large'
                          label='Day of Week'
                          placeholder=''
                          require={false}
                          selectOptions={dayOfWeekOption}
                          disabled={selectedTestService.status === 'done'}
                          onChange={handleDayOfWeekChange}
                          shrink
                          multiple={true}
                        />
                      </Box>
                    </Grid>
                  }
                </Grid>
              </Box>
            </Box>
          </Box>

          {/* Group B */}
          <Box className='group-addbox-container group-b'>
            <Box className='white-box white-box-wrap segment-box'>
              <Box className='title-wrap'>
                <Box className='box-title-area' display='flex' alignItems='center'>
                  <Typography className='segment-box-title'>B Group</Typography>
                </Box>
              </Box>
              <Box className='box-content'>
                <Box className='segment-contgent-wrap'>
                  <Grid container gap={1}>
                    {/* Recommendation */}
                    <Grid item xs={2}>
                      <Box component='div' title={bRecommendationContentTitle}>
                        <SelectBox
                          id='slt-Recommendation'
                          size='large'
                          shrink={true}
                          label='Recommendation Content'
                          require={false}
                          selectOptions={bRecommendationOption}
                          disabled={selectedTestService.status === 'done'}
                          placeholder=''
                          selected={selectedBRecommendationContent}
                          onChange={handleBRecommendationContentChange}
                        />
                      </Box>
                    </Grid>

                    {/* Mapping Type */}
                    <Grid item xs={2}>
                      <Box component='div'>
                        <SelectBox
                          id='slt-Mapping'
                          size='large'
                          shrink={true}
                          label='Mapping Type'
                          require={false}
                          selectOptions={bMappingTypeOption}
                          disabled={selectedTestService.status === 'done'}
                          placeholder=''
                          selected={selectedBMappingType}
                          onChange={handleBMappingTypeChange}
                        />
                      </Box>
                    </Grid>

                    {/* B Group Rate(%) */}
                    <Grid item xs={2}>
                      <Box component='div'>
                        <TextField
                          type='outlined'
                          size='large'
                          placeholder='50 ~ 100'
                          defaultValue=''
                          label='B Group Rate(%)'
                          autoComplete='off'
                          helperText='At least 50%'
                          InputLabelProps={{ shrink: true }}
                          disabled={selectedTestService.status === 'done'}
                          value={bGroupRate}
                          onChange={handleBGroupRateChange}
                        />
                      </Box>
                    </Grid>

                    {/* Contents Policy*/}
                    <Grid item xs={4.5}>
                      <Box component='div'>
                        <TextField
                          type='outlined'
                          size='large'
                          placeholder='Insert your manual contents policy'
                          label='Contents Policy'
                          autoComplete='off'
                          required={false}
                          InputLabelProps={{ shrink: true }}
                          disabled={selectedBMappingType === 'auto' || selectedTestService.status === 'done'}
                          value={bContentPolicy}
                          onChange={handleBContentPolicyChange}
                          maxLength={255}
                        />
                      </Box>
                    </Grid>

                    {/* Button */}
                    {selectedBMappingType === 'manual' &&
                      <Grid item xs={1.2} textAlign={'right'}>
                        <Box component='div'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='medium'
                            label='Organize'
                            isIcon={false}
                            autosize
                            onClick={handleSegmentOrganize}
                          />
                        </Box>
                      </Grid>
                    }
                  </Grid>

                  {/* C Group Add Button */}
                  {!isCGroupOpen && selectedTestService.status !== 'done' &&
                    <Grid container mt={1.5}>
                      <Grid item xs={12} textAlign={'right'}>
                        <Box component='div'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='medium'
                            label='C Group Add'
                            isIcon={false}
                            autosize
                            disabled={isAddCGroupDisabled}
                            onClick={onCGroupAddClick} // This will trigger the state change to show C Group UI
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  }
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Group C */}
          {isCGroupOpen &&
            <Box className='group-addbox-container group-c'>
              <Box className='white-box white-box-wrap segment-box'>
                <Box className='title-wrap'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Typography className='segment-box-title'>C Group</Typography>
                  </Box>
                </Box>
                <Box className='box-content'>
                  <Box className='segment-contgent-wrap'>
                    {selectedTestService.status !== 'done' &&
                      <IconButton
                        aria-label='close'
                        onClick={onCGroupCloseClick}
                        sx={{
                          position: 'absolute',
                          right: 12,
                          top: 12,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    <Grid container gap={1}>
                      {/* Recommendation */}
                      <Grid item xs={2}>
                        <Box component='div' title={cRecommendationContentTitle}>
                          <SelectBox
                            id='slt-Recommendation'
                            size='large'
                            shrink={true}
                            label='Recommendation Content'
                            require={false}
                            selectOptions={cRecommendationOption}
                            disabled={selectedTestService.status === 'done'}
                            placeholder=''
                            selected={selectedCRecommendationContent}
                            onChange={handleCRecommendationContentChange}
                          />
                        </Box>
                      </Grid>

                      {/* Mapping Type */}
                      <Grid item xs={2}>
                        <Box component='div'>
                          <SelectBox
                            id='slt-Mapping'
                            size='large'
                            shrink={true}
                            label='Mapping Type'
                            require={false}
                            selectOptions={cMappingTypeOption}
                            disabled={selectedTestService.status === 'done'}
                            placeholder=''
                            selected={selectedCMappingType}
                            onChange={handleCMappingTypeChange}
                          />
                        </Box>
                      </Grid>

                      {/* C Group Rate(%) */}
                      <Grid item xs={2}>
                        <Box component='div'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder=''
                            defaultValue=''
                            label='C Group Rate(%)'
                            autoComplete='off'
                            disabled={true}
                            helperText='100% - B Group rate = C Group rate'
                            InputLabelProps={{ shrink: true }}
                            value={cGroupRate}
                          />
                        </Box>
                      </Grid>

                      {/* Contents Policy*/}
                      <Grid item xs={4.5}>
                        <Box component='div'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder='Insert your manual contents policy'
                            label='Contents Policy'
                            autoComplete='off'
                            required={false}
                            InputLabelProps={{ shrink: true }}
                            disabled={selectedCMappingType === 'auto' || selectedTestService.status === 'done'}
                            value={cContentPolicy}
                            onChange={handleCContentPolicyChange}
                            maxLength={255}
                          />
                        </Box>
                      </Grid>

                      {/* Button */}
                      {/* <Grid item xs={1.2} textAlign={'right'}>
                        <Box component='div'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='medium'
                            label='Organize'
                            isIcon={false}
                            autosize
                            onClick={onOrganizeClick}
                          />
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          }

          {/* Chart area */}
          {isChart && chartData &&
            <Box className='white-box white-box-wrap chart-box' mt={1.5} mb={2}>
              <Box className='title-wrap'>
                <Box className='box-title-area' display='flex' alignItems='center'>
                  <Typography className='segment-box-title'>User Population Segmentation Chart</Typography>
                </Box>
              </Box>
              <Box className='box-content'>
                <Box className='chart-container'>
                  <Box className='chart-wrap align-center'>
                    <PieChart
                      series={[
                        {
                          data: chartData,
                          valueFormatter: (v => `${v.value.toLocaleString()} (${v.rate}%)`)
                        },
                      ]}
                      width={400}
                      height={200}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        </Box>
        <BasicDialog
          title={dialogContent.title}
          contentTitle={dialogContent.content}
          size={'small'}
          closeX={true}
          open={openDialog}
          handleClose={handleDialogClose}
          buttons={dialogContent.button}
        />
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={1000} />
      </Box>
    </Box>
  );
}
export default ANTestServiceSegmentOrganizeAddGroup;
