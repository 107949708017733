import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import Loading from 'components/component/Loading';
import { getRequest } from 'utils/Request';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import calendarIconLight from 'assets/images/icon/ic_calendar_24_outline.svg';
import calendarIconDark from 'assets/images/icon/ic_calendar_24_outline_dark.svg';
import { StatusOption, HistoryContentTh } from 'pages/Promotion/FreeOnLGChannelsShelf/data/FreeOnData';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

const DateIcon = styled('span')({
  display: 'block',
  width: '24px',
  height: '24px',
  backgroundImage: `url(${calendarIconLight})`,
  backgroundRepeat: 'no-repeat',

  '.dark &': {
    backgroundImage: `url(${calendarIconDark})`,
  },
});

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function FreeOnLGChannelsShelfHistory(props) {
  const { message } = props;
  const { countryCode, saveType, optionCountryList, filter } = message;
  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Free On LG Channels Shelf',
    normalMenu: ['Free On LG Channels Shelf History'],
  };
  const [selectedCountry, setSelectedCountry] = useState(countryCode);
  const [selectedStatus, setSelectedStatus] = useState(saveType);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [isDisplayData, setDisplayData] = useState(false);
  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [historyList, setHistoryList] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const optionStatusList = StatusOption;
  const [searchTermInHistoryList, setSearchTermInHistoryList] = useState('');
  const [displayHistoryList, setDisplayHistoryList] = useState([]);

  useEffect(() => {
    if (selectedCountry && selectedStatus && selectedDate) {
      getContentList();
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedStatus, selectedDate]);

  useEffect(() => {
    if (historyList) {
      if (searchTermInHistoryList) {
        const excludeKeyList = ['resultOrder', 'manualFlag', 'prevResultOrder', 'prevManualFlag'];
        const filteredData = historyList.filter((item) => {
          return Object.entries(item)
            .filter(([key, value]) => !excludeKeyList.includes(key))
            .some(([, value]) => String(value).toLowerCase().includes(searchTermInHistoryList));
        });
        setDisplayHistoryList([...filteredData]);
      } else {
        setDisplayHistoryList([...historyList]);
      }
    }
    // eslint-disable-next-line
  }, [searchTermInHistoryList]);

  useEffect(() => {
    if (!searchTermInHistoryList) {
      setHistoryList([...displayHistoryList]);
    }
    // eslint-disable-next-line
  }, [displayHistoryList]);

  // paging
  useEffect(() => {
    if (displayHistoryList) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displayHistoryList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
    }
  }, [page, itemsPerPage, displayHistoryList]);

  const getContentList = async () => {
    setDisplayData(false);
    setPage(1);
    setDisplayHistoryList([]);
    setSearchTermInHistoryList('');

    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    const body = await getRequest(`/free-on-lgchannels/history?countryCode=${selectedCountry}&recommendMonth=${formattedDate}&saveType=${selectedStatus}`, null);
    if (body && body.result === 'SUCCESS') {
      setHistoryList(body.data);
      setDisplayHistoryList(body.data);
      setDisplayData(true);
    }
  };

  // 최상단에서 back
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/freeOnLGChannelsShelf', { replace: true, state:
      { move: true,
        targetComponent: 'FreeOnLGChannelsShelf',
        message:
        {
          filter
        }
      }
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedData = [...historyList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setHistoryList([...sortedData]);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleSelectedCountry = (country) => {
    setSelectedCountry(country);
  };

  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleSearchContent = (value) => {
    setSearchTermInHistoryList(value.toLowerCase());
  };

  const handleDownload = (event) => {
    event.preventDefault();
    const worksheet = XLSX.utils.json_to_sheet(historyList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `free_on_shelf_history_data`);
    XLSX.writeFile(workbook, `free_on_shelf_history_data.xlsx`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' to='#' onClick={handleBack}>
                Free On LG Channels shelf History
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={optionCountryList}
                      disabled={false}
                      selected={selectedCountry}
                      onChange={handleSelectedCountry}
                    />
                  </Box>

                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Content'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={optionStatusList}
                      disabled={false}
                      selected={selectedStatus}
                      onChange={handleSelectedStatus}
                    />
                  </Box>

                  <Box component='div' className='col'>
                    <DatePicker
                      views={['year', 'month']}
                      className='input-date-picker'
                      format='YYYY/MM'
                      size='large'
                      shrink
                      label='Recommend Month'
                      value={selectedDate}
                      onChange={handleDateChange}
                      singleDate={true}
                      slots={{
                        openPickerIcon: DateIcon,
                        TextField: (params) => <TextField {...params} size='large' />,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {isDisplayData ? (<Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <SearchField placeholder='Please enter a search term' maxWidth='230px' onChange={(event) => handleSearchContent(event.target.value)}/>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='outline' size='small' label='Download' isIcon={false} autosize onClick={handleDownload} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {HistoryContentTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {paginatedData.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {HistoryContentTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 5 && td.prevResultOrder ? (
                                td.prevResultOrder + ' > ' + td.resultOrder
                              ) : colIndex === 6 && td.prevFixFlag ? (
                                (td.prevFixFlag ? 'Y' : 'N') + ' > ' + (td.fixFlag ? 'Y' : 'N')
                              ) : colIndex === 6 && !td.prevFixFlag ? (
                                (td.prevFixFlag ? 'Y' : 'N')
                              ) :
                              (
                                td[column.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination
                id='pagination'
                count={Math.ceil(historyList.length / itemsPerPage)}
                selected={itemsPerPage}
                onChangeHandle={handlePagination}
                page={page}
              />
            </div>
            </Box>) : (
            <Loading />
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default FreeOnLGChannelsShelfHistory;
