import React, { useState, useEffect } from 'react';
import { Dialog as MuiDialog, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle, DialogActions, Box, IconButton, Divider, Typography, Grid } from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import FileUpload from 'components/component/BasicFileUpload';
import ConfirmDialog from 'components/component/BasicDialog';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// Images
import noImage378 from 'assets/images/No-Image_378.png';
import noImageVer from 'assets/images/NoImage_ver.png';
import { multiRequest } from 'utils/Request';
// Data
import { defaultPopupType, defaultButton } from 'pages/Organization/TabCategory/Carousel/DefaultData';

import { isEmpty } from 'utils/Valid';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
`;
const MediaDetailContainer = styled('div')`
  max-height: 420px;
  overflow-y: auto;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// Data
const DetailItem = ({ label, value, xs, className }) => (
  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <div className='info-value-wrap'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </div>
  </Grid>
);

// 메인 컨텐츠
function ResultImageUpload({ tabCategory, resultImage, setResultImage, closeHandler }) {
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [isOpen, setIsOpen] = useState(true);
  const [contentId, setContentId] = useState('');
  const defaultImageInfo = {
    name: '',
    preview: '',
    height: '',
    width: '',
    file: null,
  };
  const [horizontalImage, setHorizontalImage] = useState(null);
  const [verticalImage, setVerticalImage] = useState(null);

  useEffect(() => {
    if (!isEmpty(resultImage)) {
      setContentId(resultImage.contentId);
      if (!isEmpty(resultImage.image)) {
        const img = new Image();
        img.onload = () => {
          setHorizontalImage({
            ...defaultImageInfo,
            preview: resultImage.image,
            height: img.height,
            width: img.width,
          });
        };
        img.src = resultImage.image;
      }
      if (!isEmpty(resultImage.verticalImage)) {
        const img = new Image();
        img.onload = () => {
          setVerticalImage({
            ...defaultImageInfo,
            preview: resultImage.verticalImage,
            height: img.height,
            width: img.width,
          });
        };
        img.src = resultImage.verticalImage;
      }
    }
    // eslint-disable-next-line
  }, [resultImage]);

  const onClose = () => {
    setIsOpen(false);
    if (closeHandler) {
      closeHandler();
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const successDialog = () => {
    setPopupType(defaultPopupType);
    onClose();
  };

  const getImageInfo = (file, setImage) => {
    let imageInfo = { ...defaultImageInfo };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e2) => {
      imageInfo.name = file.name;
      imageInfo.preview = e2.target.result;
      const img = new Image();
      img.src = e2.target.result;
      img.onload = () => {
        imageInfo.height = img.height;
        imageInfo.width = img.width;
        imageInfo.file = file;
        setImage(imageInfo);
      };
    };
  };

  const onFileChangeHandler = (uploadTarget, file) => {
    if (file) {
      let ext = file.name.split('.').pop().toLowerCase();
      if (!['jpeg', 'jpg', 'png'].includes(ext)) {
        alert('jpeg, jpg, png 파일만 업로드 할수 있습니다.');
        return;
      }
      switch (uploadTarget) {
        case 'horizontal':
          getImageInfo(file, setHorizontalImage);
          return;
        case 'vertical':
          getImageInfo(file, setVerticalImage);
          return;
        default:
          return;
      }
    }
  };

  const imageUploadDialog = () => {
    if (isEmpty(horizontalImage) || isEmpty(horizontalImage.name)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Image', content: 'Please register a horizontal image' });
      return;
    }
    if (isEmpty(verticalImage) || isEmpty(verticalImage.name)) {
      setPopupType({ ...defaultPopupType, alarm: true, title: 'Image', content: 'Please register a vertical image' });
      return;
    }
    if (!isEmpty(horizontalImage) && !isEmpty(verticalImage)) {
      setPopupType({ ...defaultPopupType, edit: true, title: 'Image', content: 'Would you like to upload an image? You must upload and save an image to complete registration.?' });
    }
  };

  const carouselResultImageUpload = async () => {
    let title = '';
    let content = '';
    let requestBody = new FormData();
    requestBody.append('contentId', contentId);
    if (horizontalImage.file) requestBody.append('customHorizontalImage', horizontalImage.file);
    if (verticalImage.file) requestBody.append('customVerticalImage', verticalImage.file);
    const body = await multiRequest('POST', '/home-app/carousel/result/image', requestBody);
    if (body && body.result === 'SUCCESS') {
      title = body.result;
      content = 'Saved successfully.';
      setResultImage(body.data.contentId, body.data.horizontalImage, body.data.customHorizontalImagePath, body.data.verticalImage, body.data.customVerticalImagePath);
      setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
    } else {
      if (body && body.result === 'FAILED') {
        title = 'Image';
        content = body.message;
      } else {
        title = 'Image';
        content = 'Contact your administrator!';
      }
      setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
    }
  };

  // Button Data

  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const uploadButton = {
    ...defaultButton,
    buttonId: 'uploadButtonId',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: carouselResultImageUpload,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successDialog,
  };

  const uploadButtons = [
    {
      minWidth: '150px',
      color: 'tertiary',
      type: 'outline',
      size: 'large',
      label: 'Cancel',
      onClick: onClose,
    },
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'Save',
      onClick: imageUploadDialog,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={isOpen} size='large' className='pop-program-detail'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}>
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Content Image Upload</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content pop-upload-image'>
          <BoxContainer>
            <Box className='detail-content'>
              <Box className='detail-list-container'>
                <Grid container columnSpacing={2.5}>
                  <Grid item xs={6}>
                    <DetailItem label='Tab/Category' value={tabCategory} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Country' value={resultImage.countryCode} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content ID' value={resultImage.contentId} />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem label='Content Name' value={resultImage.contentName} />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem label='Carousel Type' value={resultImage.carouselType} />
                  </Grid>
                </Grid>
              </Box>
              <MediaDetailContainer className='media-detail-container' sx={{ mt: '40px' }}>
                <Box className='media-inner-wrap'>
                  {/* Media */}
                  <Box className='media-wrap'>
                    <div className='title-area'>
                      <Typography className='field-label bold required'>Horizontal Image</Typography>
                    </div>
                    <Box className='media-item-wrap'>
                      <div className='item-image-area'>
                        <CustomImage
                          src={isEmpty(horizontalImage) || isEmpty(horizontalImage.preview) ? noImage378 : horizontalImage.preview}
                          bgDel={!isEmpty(horizontalImage) && isEmpty(horizontalImage.preview)}
                          fit={false}
                          sx={{ width: 378, height: 202 }}
                          alt=''
                          rounded
                        />
                      </div>
                      <Box className='item-info-area'>
                        <Box>
                          <Box className='item-row'>
                            <Typography className='text label'>ReCommanded Size :</Typography>
                            <Typography className='text value'>1280px x 720px</Typography>
                          </Box>
                          {!isEmpty(horizontalImage) && (
                            <Box className='item-row'>
                              <Typography className='text label'>Actual Image Size :</Typography>
                              <Typography className='text value'>
                                {horizontalImage.height} x {horizontalImage.width}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box className='link-url'>
                          <Button color='tertiary' type='outline' size='small' label='Upload File' isIcon={false} onClick={() => document.getElementById('horizontalFileUpload').click()} />
                          <Box className='field-box' style={{ display: 'none' }}>
                            <FileUpload
                              id='horizontalFileUpload'
                              buttonLabel='Upload'
                              size='large'
                              label='Vertical Image'
                              placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                              accept=''
                              required={true}
                              InputLabelProps={{ shrink: true }}
                              disabled={true}
                              onFileChange={(file) => onFileChangeHandler('horizontal', file)}
                              value={isEmpty(horizontalImage) ? '' : horizontalImage.name}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Image List */}
                  <Box className='media-wrap'>
                    <div className='title-area'>
                      <Typography className='field-label bold required'>Vertical Image</Typography>
                    </div>
                    <Box className='media-item-wrap'>
                      <div className='item-image-area'>
                        <CustomImage
                          src={isEmpty(verticalImage) ? noImageVer : verticalImage.preview}
                          bgDel={!isEmpty(verticalImage)}
                          fit={true}
                          alt=''
                          sx={{ width: 202, height: 378 }}
                          imgWidth={'202px'}
                          imgHeight={'378px'}
                          wrapWidth={'202px'}
                          wrapHeight={'378px'}
                          rounded
                        />
                      </div>
                      <Box className='item-info-area'>
                        <Box className=''>
                          <Box className='item-row'>
                            <Typography className='text label'>ReCommanded Size :</Typography>
                            <Typography className='text value'>610px x 968px</Typography>
                          </Box>
                          {!isEmpty(verticalImage) && (
                            <Box className='item-row'>
                              <Typography className='text label'>Actual Image Size :</Typography>
                              <Typography className='text value'>
                                {verticalImage.height} x {verticalImage.width}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box className='link-url'>
                          <Button color='tertiary' type='outline' size='small' label='Upload File' isIcon={false} onClick={() => document.getElementById('verticalFileUpload').click()} />
                          <Box className='field-box' style={{ display: 'none' }}>
                            <FileUpload
                              id='verticalFileUpload'
                              buttonLabel='Upload'
                              size='large'
                              label='Vertical Image'
                              placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                              accept=''
                              required={true}
                              InputLabelProps={{ shrink: true }}
                              disabled={true}
                              onFileChange={(file) => onFileChangeHandler('vertical', file)}
                              value={isEmpty(verticalImage) ? '' : verticalImage.name}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </MediaDetailContainer>
            </Box>
          </BoxContainer>
        </DialogContent>
        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={uploadButtons} justifyContent='center' />
        </DialogActions>
      </Dialog>
      {/* severity: default, error, warning, info, success */}
      <Snackbar severity='warning' message='*You can have up to 10 favorite menus.' open={false} Duration='1000' bottom={'8%'} />
      {(popupType.alarm || popupType.edit || popupType.success) && (
        <ConfirmDialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.alarm ? [closeButton] : popupType.edit ? [cancelButton, uploadButton] : popupType.success ? [successButton] : []}
          // buttons={popupType.alarm ? [closeButton] : popupType.publish ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
        />
      )}
    </div>
  );
}

export default ResultImageUpload;
