import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Pagination from 'components/component/BasicPagination';
import {Box, Dialog} from '@mui/material';
import ExtraFilter from './component/ExtraFilter';
import {BASE_URL} from 'constants/index';
import {getHeaders} from 'utils/Header';
import {RefreshIcon} from 'components/component/BasicIcon';
import {useNavigate} from "react-router-dom";
import SelectBox from "components/component/BasicSelectBox";
import TextField from "components/component/BasicTextField";
import CustomButtonGroup from "components/component/BasicButtonGroup";
import {MetaSeriesTh} from "./data/MetaData";
import SeriesTable from "./component/SeriesTable";
import Loading from 'components/component/Loading';

function MetadataSeries(props) {
  const navigate = useNavigate();
  const {serviceCountry, providerByCountry, message, preferredCountry, tabValue, type} = props;

  // filter values
  const [filterRegionList, setFilterRegionList] = useState([]);
  const [filterCountryList, setFilterCountryList] = useState([]);
  const [filterProviderList, setFilterProviderList] = useState([]);

  // selected filter values
  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState(preferredCountry)
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedSeriesId, setSelectedSeriesId] = useState('');
  const [selectedSeriesTitle, setSelectedSeriesTitle] = useState('');
  const [selectedSeasonId, setSelectedSeasonId] = useState('');
  const [selectedSeasonTitle, setSelectedSeasonTitle] = useState('');

  // original data
  const [originalDataList, setOriginalDataList] = useState([]);

  // display data
  const [dataList, setDataList] = useState([]);

  // 화면에 보여 줄 데이터가 없는 경우
  const [isEmptyData, setEmptyData] = useState(false);

  // 검색에서 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setDisplayData] = useState(false);

  // popup
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // paging
  const [paginatedData, setPaginatedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // 타이밍 이슈로 인해서 예외 처리 추가
  const [isAfterFirstRequest, setAfterFirstRequest] = useState(false);

  useEffect(() => {
    if (message) {
      if (message.filters) {
        setSelectedRegion(message.filters.selectedRegion);
        setSelectedCountry(message.filters.selectedCountry);
        setSelectedProvider(message.filters.selectedProvider);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (providerByCountry && serviceCountry) {
      const updatedRegionList = ['All', ...Array.from(
        new Set(serviceCountry.map(item => item.ricCode))).sort()];
      const updatedSelectRegionList = updatedRegionList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      setFilterRegionList(updatedSelectRegionList);

      const updatedCountryList = providerByCountry.map(
          item => item.countryCode).sort();
      const updatedSelectCountryList = updatedCountryList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      setFilterCountryList(updatedSelectCountryList);

      let tempProviderList = providerByCountry.find(
          item => item.countryCode === preferredCountry)?.providerList.map(
          item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);
      setSelectedProvider(updatedProviderList[0].value);
    }
    // eslint-disable-next-line
  }, [providerByCountry, serviceCountry]);

  useEffect(() => {
    if (filterRegionList.length > 0 && filterCountryList.length > 0 && filterProviderList.length > 0 && !isAfterFirstRequest) {
      searchMetadata();
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [filterRegionList, filterCountryList, filterProviderList]);

  useEffect(() => {
    if (selectedRegion && serviceCountry) {
      let tempCountryList = [];

      if (selectedRegion === 'All') {
        tempCountryList = serviceCountry.map(item => item.countryCode).sort();
      } else {
        const tempRegion = message?.filters?.selectedRegion ? message?.filters?.selectedRegion : selectedRegion;

        tempCountryList = serviceCountry.filter(
          item => item.ricCode === tempRegion).map(item => item.countryCode).sort();
      }

      setFilterCountryList(tempCountryList.map(
          item => ({id: item, value: item, option: item, type: 'text'})));

      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedRegion === 'All' ? selectedCountry : tempCountryList[0];
      setSelectedCountry(tempCountry);
    }
    // eslint-disable-next-line
  }, [selectedRegion, serviceCountry]);

  useEffect(() => {
    if (selectedCountry && providerByCountry) {
      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedCountry;

      const tempProviderList = providerByCountry.find(
        item => item.countryCode === tempCountry)?.providerList.map(
        item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);

      const tempProvider = message?.filters?.selectedProvider ? message?.filters?.selectedProvider : updatedProviderList[0].value;
      setSelectedProvider(tempProvider);
    }
    // eslint-disable-next-line
  }, [selectedCountry, providerByCountry]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['logoUrl', 'representativeImageList'];

      const filteredData = originalDataList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDataList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDataList([...originalDataList]);
      if (originalDataList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalDataList]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = dataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayData(true);
    }
  }, [dataList, page, itemsPerPage]);

  useEffect(() => {
    if (isEmptyData) {
      setPaginatedData([]);
    }
  }, [isEmptyData]);

  const searchMetadata = () => {
    setDisplayData(false);
    setPage(1);
    setDataList([]);
    setOriginalDataList([]);
    let param = `countryCode=${selectedCountry}&providerName=${selectedProvider}&seriesId=${selectedSeriesId}&seriesTitle=${selectedSeriesTitle}&seasonId=${selectedSeasonId}&seasonTitle=${selectedSeasonTitle}`;
    let url = `${BASE_URL}/metadata/series?${param.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data);

        if (body.data && body.data.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`[Metadata] series request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleReset = () => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedRegion('All');
    setSelectedCountry(preferredCountry);
    setSelectedProvider('All');
    setSelectedSeriesId('');
    setSelectedSeasonTitle('');
    setSelectedSeasonId('');
    setSelectedSeriesTitle('');
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: handleReset,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: searchMetadata,
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectedRegion = (region) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedRegion(region);
  };

  const handleSelectedCountry = (country) => {
    if (message?.filters) {
      message.filters = null;
    }

    const tempProviderList = providerByCountry.find(
      item => item.countryCode === country)?.providerList.map(
      item => item.providerName).sort();

    const updatedProviderList = [
      ...tempProviderList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }))
    ];
    setFilterProviderList(updatedProviderList);

    setSelectedProvider(tempProviderList[0].providerName);

    setSelectedCountry(country);
  };

  const handleSelectedProvider = (provider) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedProvider(provider);
  };

  const handleSelectedSeriesId = (value) => {
    setSelectedSeriesId(value);
  };

  const handleSelectedSeriesTitle = (value) => {
    setSelectedSeriesTitle(value);
  };

  const handleSelectedSeasonId = (value) => {
    setSelectedSeasonId(value);
  };

  const handleSelectedSeasonTitle = (value) => {
    setSelectedSeasonTitle(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleAction = (contentId) => {
    navigate('/Metadata',
        {
          replace: true,
          state: {
            move: true,
            targetComponent: 'MetadataSeriesDetail',
            message: {
              contentId: contentId,
              countryCode: selectedCountry,
              tabValue: tabValue,
              type: type,
              filters: {
                selectedRegion: selectedRegion,
                selectedCountry: selectedCountry,
                selectedProvider: selectedProvider,
              }
            }
          }
        });
  };

  return (
      <>
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='series-filter-region'
                        size='large'
                        shrink={true}
                        label='Region'
                        required={false}
                        selectOptions={filterRegionList}
                        disabled={false}
                        onChange={value => handleSelectedRegion(value)}
                        selected={selectedRegion}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='series-filter-country'
                        size='large'
                        shrink={true}
                        label='Country'
                        selectOptions={filterCountryList}
                        disabled={false}
                        onChange={value => handleSelectedCountry(value)}
                        selected={selectedCountry}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                        id='series-filter-cp-name'
                        fullWidth
                        size='large'
                        width='100%'
                        selectOptions={filterProviderList}
                        disabled={false}
                        required={false}
                        label='CP Name'
                        shrink
                        onChange={value => handleSelectedProvider(value)}
                        selected={selectedProvider}
                    />
                  </Box>
                </Box>

                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-series-id'
                        type='outlined'
                        size='large'
                        placeholder='Series ID'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeriesId}
                        onChange={value => handleSelectedSeriesId(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-series-title'
                        type='outlined'
                        size='large'
                        placeholder='Series Title'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeriesTitle}
                        onChange={value => handleSelectedSeriesTitle(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-season-id'
                        type='outlined'
                        size='large'
                        placeholder='Season ID'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeasonId}
                        onChange={value => handleSelectedSeasonId(
                            value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                        id='series-filter-season-title'
                        type='outlined'
                        size='large'
                        placeholder='Season Title'
                        defaultValue=''
                        label=''
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        value={selectedSeasonTitle}
                        onChange={value => handleSelectedSeasonTitle(
                            value.target.value)}
                    />
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons}/>
              </div>
            </Box>
          </Box>
          {isDisplayData ? (
              <Box className='white-box white-box-wrap'>
                <div className='box-content'>
                  <ExtraFilter
                    total={dataList.length}
                    data={originalDataList}
                    type='series'
                    searchPlaceholder='Please enter a search term'
                    onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                  />
                  <Box className='content-inner-wrap'>
                    <SeriesTable
                        columns={MetaSeriesTh}
                        rows={paginatedData}
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        handleAction ={handleAction}
                    />
                  </Box>
                  <Pagination
                      id='pagination-01'
                      count={Math.ceil(
                          dataList.length / itemsPerPage)}
                      selected={itemsPerPage}
                      onChangeHandle={handlePagination}
                      page={page}
                  />
                </div>
              </Box>
            ) : (
              <Loading/>
          )}
        </div>
      </div>
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </>
  )
}

const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
  providerByCountry: state.common.provider.country,
});

export default connect(mapStateToProps)(MetadataSeries);