import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Tag from 'components/component/BasicTag';
import Tooltip from 'components/component/BasicTooltip';
import Dialog from 'components/component/BasicDialog';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

import { menuItemData } from 'components/layout/data/GnbItemData';

const CardContainer = styled('div')({
  display: 'block',
});
const BoxContainer = styled('div')({
  paddingRight: '3px',
});

const Activity = () => {
  const navigate = useNavigate();

  const [activityData, setActivityData] = useState([]);

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    getActivityData();
  }, []);

  const getActivityData = () => {
    fetch(`${BASE_URL}/activity/activity-list`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setActivityData(body.data.activityList);
      } else {
        throw new Error(`activity/activity-list request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const updateContent = (data) => {
    let content = '';
    if (data.module === 'Channel Mapping') {
      content = `Country: ${data.countryCode} / Channel Map: ${data.channelMapId} / Status: - ${data.status === 'T' ? 'Testing' : 'Published'}`;
    } else if (data.module === 'Tab/Category') {
      content = `Device Type: ${data.deviceType} / Region: ${data.region} / Platform version: ${data.platformVersion} / Status: ${data.status === 'T' ? 'Testing' : 'Published'}`;
    } else if (data.module === 'Free On LG Channels Shelf') {
      content = `Country: ${data.countryCode} / Recommend Month: ${data.recommendMonth} / Status: ${data.status === 'T' ? 'Testing' : 'Published'}`;
    } else if (data.module === 'Watch On LG Channels Shelf') {
      content = `Country: ${data.countryCode} / Recommend Month: ${data.recommendMonth} / Status: ${data.status === 'T' ? 'Testing' : 'Published'}`;
    } else if (data.module === 'LG Channels Shelf') {
      content = `Country: ${data.countryCode} / Recommend Month: ${data.recommendMonth}`;
    } else {
      console.error(`[ERROR] Unknown module: ${data.module}`);
    }

    return content;
  };

  const showPath = (data) => {
    const menuItem = menuItemData.find(item =>
      item.subItems.some(subItem => subItem.text === data.module)
    );

    let path = '';

    if (menuItem) {
      path = `(HOME > ${menuItem.primaryText} > ${data.module})`;
    } else {
      console.error(`[ERROR] Menu item not found for module: ${data.module}`);
    }

    return path;
  };

  const handleMoveToOtherPage = (event, data) => {
    event.preventDefault()

    const menuItem = menuItemData.find(item =>
      item.subItems.some(subItem => subItem.text === data.module)
    );

    if (menuItem) {
      const subItem = menuItem.subItems.find(subItem => subItem.text === data.module);
      if (subItem) {
        let targetComponent = '';

        // Todo: targetComponent 설정
        // if (data.module === 'Channel Mapping') {
        //   targetComponent = 'ChannelMapHistory';
        // } else if (data.module === 'Tab/Category') {
        //   targetComponent = 'HistoryContents';
        // } else if (data.module === 'Free On LG Channels Shelf') {
        //   targetComponent = 'History';
        // } else if (data.module === 'Watch On LG Channels Shelf') {
        //   targetComponent = 'WatchOnLGChannelsShelfHistory';
        // } else if (data.module === 'LG Channels Shelf') {
        //   targetComponent = '';
        // } else {
        //   console.error(`[ERROR] Unknown module: ${data.module}`);
        //   return;
        // }

        navigate(subItem.path, { replace: true, state: { move: true, targetComponent: targetComponent } });
      } else {
        console.error(`[ERROR] Sub-item not found for module: ${data.module}`);
      }
    } else {
      console.error(`[ERROR] Menu item not found for module: ${data.module}`);
    }
  };

  return (
    <Grid item xs={11.7}>
      <Box className='white-box white-box-wrap activity'>
        <div className='title-wrap'>
          <div className='box-title-area'>
            <Typography variant='h2' className='box-title'>
              Activity
            </Typography>
          </div>
        </div>

        <CardContainer className='card-container'>
          <BoxContainer className='box-container'>
            <Box className='box-wrap'>
              {activityData.map((data) => (
                <React.Fragment key={data.activityId}>
                  <Card className='card-box'>
                    <div className='card-header'>
                      <Typography variant='body2' className='user-name'>
                        {data.lastModifier}
                      </Typography>
                      <Link
                        href='#'
                        className='link-item'
                        onClick={(event) => handleMoveToOtherPage(event, data)}
                      >
                        <Tooltip placement='top' title={`Click to navigate to the ${data.module} history screen.`}>
                          <Typography variant='body2' className='channel'>
                            updated the
                            <span className='channel-name'>{data.module}</span>
                          </Typography>
                        </Tooltip>
                      </Link>
                      <Typography variant='body2' className='location'>
                        {showPath(data)}
                      </Typography>
                      <Tag
                        type={data.status === 'All' ? '' : data.status === 'T' ? 'testing' : 'publishing'}
                        label={data.status === 'All' ? '' : data.status === 'T' ? 'Testing' : 'Publishing'}
                        badgeType='' className='chip'
                      />
                    </div>

                    <CardContent className='card-content'>
                      <Typography variant='body2' className='text-label'>
                        Update Content
                      </Typography>
                      <Typography variant='string' className='update-content'>
                        {updateContent(data)}
                      </Typography>
                      <Typography variant='caption' className='update-date'>
                        {data.crtDate}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Divider sx={{ marginY: 1 }} className='divider' />
                </React.Fragment>
              ))}
            </Box>
          </BoxContainer>
        </CardContainer>
      </Box>
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </Grid>
  );
};

export default Activity;