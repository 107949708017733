import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ArrowRight24 } from 'components/component/BasicIcon';
import Snackbar from 'components/component/BasicSnackbar';

import NotificationList from 'pages/Main/Support/NotificationList';
import Activity from 'pages/Main/Activity/Activity';

import {
  Button,
  Box,
  Checkbox,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { styled } from '@mui/system';

// css
import 'assets/scss/page/main.scss';

// image
import FavoriteCheckOff from 'assets/images/icon/ic_favorite_off_32_outline.svg';
import FavoriteCheckOn from 'assets/images/icon/ic_favorite_on_32_outline.svg';
import FavoriteCheckOn_dark from 'assets/images/icon/ic_favorite_on_24_outline_darkmode.svg';

// Recently Used Box Contents
const FavoriteCheck = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;

    & .MuiButtonBase-root {
      &:hover {
        background: none;
      }
    }

    & input[type='checkbox'] {
      & + .MuiSvgIcon-root {
        width: 32px;
        height: 32px;
        background: url(${FavoriteCheckOff});

        & path {
          display: none;
        }
      }

      &:checked {
        & + .MuiSvgIcon-root {
          width: 32px;
          height: 32px;
          background: url(${FavoriteCheckOn});

          .dark & {
            background: url(${FavoriteCheckOn_dark});
          }
        }
      }
    }

    & .Mui-checked {
      & + .MuiTypography-root {
        & .MuiTypography-root {
          color: #000;

          .dark & {
            color: #fff;
          }
        }
      }
    }
  }
`;

const CardContainer = styled('div')({
  display: 'block',
});
const BoxContainer = styled('div')({
  paddingRight: '3px',
});

function Main(props) {
  const { recentlyUsedMenu
          , darkMode
          , callbackFavoriteMenuChange
          , changedFavoriteMenuItems
        } = props;
  const navigate = useNavigate();

  // Recently Used Menu
  const [adminUsedMenu, setAdminUsedMenu] = useState([]);

  // 화면 강제 업데이트 용도
  // eslint-disable-next-line
  const [isRecentlyUsedMenuUpdate, setRecentlyUsedMenuUpdate] = useState(null);

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (recentlyUsedMenu && recentlyUsedMenu.adminMenu) {
      const storedData = localStorage.getItem('favoriteMenu');
      const storedFavoriteMenuData = storedData ? JSON.parse(storedData) : [];

      const adminMenuWithFavorite = recentlyUsedMenu.adminMenu.map(menu => {
        const isFavorite = storedFavoriteMenuData.some(fav => fav.text === menu.title);
        return {
          ...menu,
          favorite: isFavorite,
        };
      });

      setAdminUsedMenu(adminMenuWithFavorite);
    }
  }, [recentlyUsedMenu]);

  // 화면 강제 업데이트 용도
  useEffect(() => {
    setRecentlyUsedMenuUpdate(prev => !prev);
  }, [adminUsedMenu]);

  // Favorite Menu popup 에서 favorite items 이 변경된 경우에 Recently Used Menu 에도 반영
  useEffect(() => {
    if (changedFavoriteMenuItems) {
      const adminMenuWithFavorite = recentlyUsedMenu.adminMenu.map(menu => {
        const isFavorite = changedFavoriteMenuItems.some(fav => fav.text === menu.title);
        return {
          ...menu,
          favorite: isFavorite ? true : false,
        };
      });

      setAdminUsedMenu(adminMenuWithFavorite);
    }
    // eslint-disable-next-line
  }, [changedFavoriteMenuItems]);

  const handleMoveToOtherPage = (event, moveToPage, targetComponent, selectedId) => {
    event.preventDefault();
    navigate(moveToPage, { replace: true, state: { move: true, targetComponent: targetComponent, selectedId: selectedId } });
  };

  const handleFavoriteItem = (event, recentData) => {
    event.preventDefault();

    const storedData = localStorage.getItem('favoriteMenu');
    const storedFavoriteMenuData = storedData ? JSON.parse(storedData) : [];

    if (storedFavoriteMenuData.length >= 10 && event.target.checked) {
      setSnackbarOpen(true);
      return;
    }

    const updatedAdminUsedMenu = adminUsedMenu.map(menu => {
      if (menu.title === recentData.title) {
        return {
          ...menu,
          favorite: !menu.favorite,
        };
      }
      return menu;
    });

    setAdminUsedMenu(updatedAdminUsedMenu);

    const favoriteItem = {
      title: recentData.title,
      favorite: !recentData.favorite,
    };

    callbackFavoriteMenuChange(favoriteItem);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main' sx={{ flexGrow: 1 }}>
        <Box className='main-wrap' component='div'>
          <Grid container spacing={2.5} columnSpacing={{ md: 2.5 }} className='activity-box left-container'>
            <Activity />
          </Grid>

          <Grid container spacing={2.5} direction='row' className='right-container'>
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap notification'>
                <div className='title-wrap'>
                  <div className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Support
                    </Typography>
                  </div>
                  <Link
                    href='#'
                    underline='none'
                    className='title-link'
                    onClick={(event) => handleMoveToOtherPage(event, '/', 'Notification', 10000)} // Lnb 선택할 필요가 없어서 10000 으로 설정
                  >
                    <ArrowRight24 />
                  </Link>
                </div>
                <NotificationList darkMode={darkMode} channel={'CMP'}/>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap recently'>
                <div className='title-wrap'>
                  <div className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      Recently Used Menu
                    </Typography>
                  </div>
                </div>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <List className='recently-used'>
                      {adminUsedMenu.map((recentData, index) => (
                        <ListItem key={index} className='list-item'>
                          <FavoriteCheck
                            control={<Checkbox onClick={(event) => handleFavoriteItem(event, recentData)}/>}
                            className='menu-list-left'
                            checked={recentData.favorite}
                            label={
                              <Box component='div'>
                                <Typography className='channelTitle' component='div' variant='body1'>
                                  {recentData.title}
                                </Typography>
                                <Typography className='channelBreadCrumb' component='div' variant='body1'>
                                  {recentData.breadCrumb}
                                </Typography>
                              </Box>
                            }
                          />
                          <Button
                            href='/'
                            variant=''
                            className='link-arrow'
                            onClick={(event) => handleMoveToOtherPage(event, recentData.path, '', recentData.menuId)}
                          >
                            <ArrowRight24 />
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={isSnackbarOpen}
        duration='1000'
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}

export default Main;
