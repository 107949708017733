import React, {useEffect, useState} from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import BasicSwitches from 'components/component/BasicSwitch';
import Pagination from 'components/component/BasicPagination';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';

import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { styled } from '@mui/material/styles';

// data
import {OperationModeOption,EditDefaultSettingOption} from 'pages/Configuration/CodeManagement/data/CodeData';
import {useNavigate} from "react-router-dom";
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import Dialog from "components/component/BasicDialog";

// Button

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function AccountPermissionsEdit({ message }) {
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Code Management',
    normalMenu: ['Account Permissions Add'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displayMenus].sort((a, b) => {
      const left = a[property] ? a[property].toString().toLowerCase() : '';
      const right = b[property] ? b[property].toString().toLowerCase() : '';
      if (left < right) {
        return isAsc ? -1 : 1;
      }
      if (left > right) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplayMenus([...sortedData])
  };

  const [roleToEdit, setRoleToEdit] = useState({});
  const [allRole, setAllRole] = useState([]);
  const [roleCodeInput, setRoleCodeInput] = useState('');
  const [roleNameInput, setRoleNameInput] = useState('');
  const [defaultSetting, setDefaultSetting] = useState('None');
  const [menus, setMenus] = useState([]);
  const [displayMenus, setDisplayMenus] = useState([]);
  const [paramMenuGroupOptions, setParamMenuGroupOptions] = useState([]);
  const [selectedMenuGroup, setSelectedMenuGroup] = useState('All');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [switchStates, setSwitchStates] = useState({});
  const [operationModes, setOperationModes] = useState({});

  const handleSwitchChange = (idx) => {
    setSwitchStates((prevSwitchStates) => ({
      ...prevSwitchStates,
      [idx]: !prevSwitchStates[idx],
    }));
  };

  const navigate = useNavigate();

  const navigateBack = (e) => {
    e.preventDefault();
    goBack();
  }

  const goBack = () => {
    navigate('/CodeManagement', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'CodeManagement',
        message: {
          managementType: 'Account Permissions',
        }
      }
    })
  }

  useEffect(() => {
    if (message.allRole) {
      setAllRole(message.allRole)
    }
    if (message.role) {
      const role = message.role
      setRoleToEdit(role);
      setRoleCodeInput(role.roleCode);
      setRoleNameInput(role.roleName);
    }
  }, [message]);

  const getMenus = () => {
    fetch(`${BASE_URL}/code-management/admin-menu`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const data = body.data;
        setMenus(data);
        const menuGroups = [...new Set(data.map(menu => menu.gnb))]
        const map = menuGroups.map(group => ({id: group, value: group, option: group, type: 'text'}))
        setParamMenuGroupOptions([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...map])
        setSwitchStates(data.reduce((acc, menu) => {
          acc[menu.idx] = false;
          return acc;
        }, {}));
        setOperationModes(data.reduce((acc, menu) => {
          acc[menu.idx] = 'Editor';
          return acc;
        }, {}));
      }
    })
  }

  useEffect(() => {
    if (menus.length > 0 && roleToEdit) {
      const roleMenuList = roleToEdit.roleMenuList;
      const newSwitchStates = menus.reduce((acc, menu) => {
        acc[menu.idx] = false;
        return acc;
      }, {});
      const newOperationModes = menus.reduce((acc, menu) => {
        acc[menu.idx] = 'Editor';
        return acc;
      }, {});
      roleMenuList.forEach(menu => {
        const idx = menus.find(m => m.gnb === menu.gnb && m.lnb === menu.lnb)?.idx;
        if (idx) {
          newSwitchStates[idx] = true;
          newOperationModes[idx] = menu.authName === 'AUTH_EDIT' ? 'Editor' : 'Viewer';
        }
      });
      setSwitchStates(newSwitchStates);
      setOperationModes(newOperationModes);
    }
  }, [menus, roleToEdit]);

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    setDisplayMenus(menus);
  }, [menus]);

  useEffect(() => {
    setDisplayMenus(menus.filter(menu => selectedMenuGroup === 'All' || menu.gnb === selectedMenuGroup));
  }, [selectedMenuGroup, menus]);

  useEffect(() => {
    if (menus.length > 0 && allRole.length > 0 && defaultSetting) {
      const role = allRole.find(role => role.roleCode === defaultSetting);
      if (role) {
        const roleMenuList = role.roleMenuList;
        const newSwitchStates = menus.reduce((acc, menu) => {
          acc[menu.idx] = false;
          return acc;
        }, {});
        const newOperationModes = menus.reduce((acc, menu) => {
          acc[menu.idx] = 'Editor';
          return acc;
        }, {});
        roleMenuList.forEach(menu => {
          const idx = menus.find(m => m.gnb === menu.gnb && m.lnb === menu.lnb)?.idx;
          if (idx) {
            newSwitchStates[idx] = true;
            newOperationModes[idx] = menu.authName === 'AUTH_EDIT' ? 'Editor' : 'Viewer';
          }
        });
        setSwitchStates(newSwitchStates);
        setOperationModes(newOperationModes);
      }
    }
  }, [menus, allRole, defaultSetting]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  useEffect(() => {
    if (displayMenus.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedData(displayMenus.slice(startIndex, endIndex));
    } else {
      setPaginatedData([]);
    }
  }, [displayMenus, page, itemsPerPage]);

  const editRole = () => {
    const roleCodes = allRole.map(role => role.roleCode).filter(roleCode => roleCode !== roleToEdit.roleCode);
    if (roleCodeInput === '') {
      setAlertTitle('Error');
      setAlertMessage('Please enter Role Code.');
      setAlertOpen(true);
      return;
    }
    if (roleCodes.includes(roleCodeInput)) {
      setAlertTitle('Error');
      setAlertMessage('Role Code already exists.');
      setAlertOpen(true);
      return;
    }

    const roleMenuList = [];
    Object.keys(switchStates).forEach(idx => {
      if (switchStates[idx]) {
        const menu = menus.find(menu => menu.idx === parseInt(idx));
        roleMenuList.push({
          gnb: menu.gnb, lnb: menu.lnb,
          authName: operationModes[idx] === 'Editor' ? 'AUTH_EDIT' : 'AUTH_VIEW'
        });
      }
    })
    if (roleMenuList.length === 0) {
      setAlertTitle('Error');
      setAlertMessage('Please select at least one menu.');
      setAlertOpen(true);
      return;
    }
    const role = {
      roleCode: roleCodeInput,
      roleName: roleNameInput,
      roleMenuList: roleMenuList
    }
    fetch(`${BASE_URL}/code-management/role/${roleToEdit.roleCode}`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(role)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Role has been updated successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Error');
        setAlertMessage('Failed to update Role.');
        setAlertOpen(true);
      }
    })
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
    if (alertTitle === 'Success') {
      goBack();
    }
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
      <Box sx={{ display: 'flex' }} className='wrapper'>
        <Box className='main-container pr-page-container' component='main'>
          {/* BreadCrumb Area */}
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Link className='title link' href='' onClick={navigateBack}>
                  Account Permissions Edit
                </Link>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu} />
              </div>
            </div>
          </div>
          {/*  //BreadCrumb Area */}

          <Box className='content-wrap ' component='div'>
            <Box className='white-box white-box-wrap'>
              <Box component='div' className='title-wrap space-between flex-end'>
                <Box component='div' className='left-area'></Box>
                <Box component='div' className='right-area'>
                  <Button color='primary' type='box' size='medium' label='Save' isIcon={false} autosize onClick={editRole} />
                </Box>
              </Box>

              <div className='box-content'>
                <Box padding={'0 24px'}>
                  <Box className='field-box' mb={1}>
                    <TextField
                        type='outlined'
                        size='large'
                        placeholder='ex)ROLE_SUPER_ADMIN'
                        label='Role Code'
                        autoComplete='off'
                        required={true}
                        InputLabelProps={{ shrink: true }}
                        value={roleCodeInput}
                        onChange={(e) => setRoleCodeInput(e.target.value)}
                    />
                  </Box>
                  <Box className='field-box' mb={5}>
                    <TextField
                        type='outlined'
                        size='large'
                        label='Role Name'
                        placeholder='ex)Super Admin'
                        autoComplete='off'
                        required={true}
                        InputLabelProps={{ shrink: true }}
                        value={roleNameInput}
                        onChange={(e) => setRoleNameInput(e.target.value)}
                    />
                  </Box>
                </Box>

                <Box className='white-box-content-top-area' alignItems='flex-end'>
                  <Box className='left-area '>
                    <SelectBox
                        id='select-Setting'
                        maxWidth='230px'
                        fullWidth
                        size='medium'
                        selectOptions={EditDefaultSettingOption}
                        disabled={false}
                        label='Default Setting'
                        shrink
                        selected={defaultSetting}
                        onChange={(newValue) => setDefaultSetting(newValue)}
                    />

                    <SelectBox
                        id='select-Menu'
                        maxWidth='230px'
                        fullWidth
                        size='medium'
                        selectOptions={paramMenuGroupOptions}
                        disabled={false}
                        label='Menu Group'
                        shrink
                        selected={selectedMenuGroup}
                        onChange={(newValue) => setSelectedMenuGroup(newValue)}
                    />
                  </Box>

                  <Box className='right-area'></Box>
                </Box>
                <Box className='content-inner-wrap'>
                  {/* Table */}
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          <TableCell key={'menuGroup'} className='th' align={'left'} sx={{ width: '24%' }}>
                            <TableSortLabel
                                active={orderBy === 'gnb'}
                                direction={orderBy === 'gnb' ? order : 'desc'}
                                onClick={() => handleRequestSort('gnb')}
                            >
                              Menu Group
                            </TableSortLabel>
                          </TableCell>
                          <TableCell key={'menuName'} className='th' align={'left'} sx={{ width: '26%' }}>
                            <TableSortLabel
                                active={orderBy === 'lnb'}
                                direction={orderBy === 'lnb' ? order : 'desc'}
                                onClick={() => handleRequestSort('lnb')}
                            >
                              Menu Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell key={'accessStatus'} className='th' align={'left'} sx={{ width: '24%' }}>
                            Access Status
                          </TableCell>
                          <TableCell key={'operationMode'} className='th' align={'left'} sx={{ width: '24%' }}>
                            Operation Mode
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {/* table Body */}
                      <TableBody className='tbody'>
                        {paginatedData.map((td, index) => (
                            <TableRow className='tr' key={index}>
                              <TableCell key={'menuGroup'} className='td' align={'left'}>
                                {td['gnb']}
                              </TableCell>
                              <TableCell key={'menuName'} className='td' align={'left'}>
                                {td['lnb']}
                              </TableCell>
                              <TableCell key={'accessStatus'} className='td' align={'left'}>
                                <BasicSwitches
                                    disabled={false}
                                    onOffMode={switchStates[td.idx]}
                                    setSwitchOnOff={() => handleSwitchChange(td.idx)}
                                    isLabelText={true}
                                    onText=''
                                    offText=''
                                />
                              </TableCell>
                              <TableCell key={'operationMode'} className='td' align={'left'}>
                                <SelectBox
                                    id='select-01'
                                    fullWidth
                                    required={false}
                                    label=''
                                    size='small'
                                    maxWidth='250px'
                                    selectOptions={OperationModeOption}
                                    disabled={false}
                                    shrink={false}
                                    selected={operationModes[td.idx]}
                                    onChange={(newValue) => {
                                      setOperationModes((prevOperationModes) => ({
                                        ...prevOperationModes,
                                        [td.idx]: newValue,
                                      }));
                                    }}
                                />
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                  {/* // Table */}
                </Box>

                {/* Pagination */}
                <Pagination count={Math.ceil(menus.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              </div>
            </Box>
          </Box>
        </Box>
        <Dialog
            open={isAlertOpen} // boolean 값으로 설정
            handleClose={handleAlertClose}
            closeX={false}
            size='small' // large, medium, small
            title={alertTitle} // Popup Title
            contentTitle={false} // Content Title
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </Box>
  );
}

export default AccountPermissionsEdit;
