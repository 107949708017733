import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from 'utils/Request';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomTabs from 'components/component/BasicTabs';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/Valid';
import * as XLSX from 'xlsx';
import dayjs from "dayjs";

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  HistoryCategoryTh,
  HistoryContentTh,
  HistoryTabTh,
} from 'pages/Organization/TabCategory/data/TabCategoryHistoryData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

import PopSnapshotDownload from 'pages/Common/PopSnapshotDownload'; // Snapshot Download Popup

const homeAppStatus =[
  {
    name: 'Saved',
    value: 'S',
  },
  {
    name: 'Saved-Testing',
    value: 'S-T',
  },
  {
    name: 'Saved-Published',
    value: 'S-P',
  },
  {
    name: 'Testing',
    value: 'T',
  },
  {
    name: 'Published',
    value: 'P',
  },
]
const statusOptions =[
  {
    id: 'S-T',
    value: 'S-T',
    option: 'Saved-Testing',
    type: 'text',
  },
  {
    id: 'S-P',
    value: 'S-P',
    option: 'Saved-Published',
    type: 'text',
  },
  {
    id: 'T',
    value: 'T',
    option: 'Testing',
    type: 'text',
  },
  {
    id: 'P',
    value: 'P',
    option: 'Published',
    type: 'text',
  },
]
const enableOptions =[
  {
    id: 'All',
    value: 'All',
    option: 'All',
    type: 'text',
  },
  {
    id: 'Y',
    value: 'Y',
    option: 'on',
    type: 'text',
  },
  {
    id: 'N',
    value: 'N',
    option: 'off',
    type: 'text',
  },
]
const deviceTypeOptions =[
  {
    id: 'tv',
    value: 'tv',
    option: 'tv',
    type: 'text',
  },
  {
    id: 'web',
    value: 'web',
    option: 'web',
    type: 'web',
  },
  {
    id: 'mobile',
    value: 'mobile',
    option: 'mobile',
    type: 'text',
  },
]
// Tab Name
const tabs = [{ label: 'Tab' }, { label: 'Category' }, { label: 'Contents' }];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

  // Admin UI에서 상태값을 DB 쿼리 수행 시 조건 값으로 변환
  // Save-Testing > S-T
  // Testing > T
  const mappingStatusValue = name => {
    const status = homeAppStatus.find(n => n.name === name).value;
    return status;
  }

  // S-T > Save-Testing
  const mappingStatusName = value => {
    const status = homeAppStatus.find(n => n.value === value).name;
    return status;
  }

// 메인 컨텐츠
function HistoryContents(props) {
  const { message, param, regionList, regionPlatformList } = props;
  const { tabNumber, beforeResultHistory } = message;

  useEffect(() => {
    if (!isEmpty(tabNumber)) {
      setTabValue(tabNumber);
    } else {
      setTabValue(2);
    }
    // eslint-disable-next-line
  }, [tabNumber]);

  useEffect(() => {
    if (!isEmpty(beforeResultHistory)) {
      setResultHistory(beforeResultHistory);
    } else {
      setResultHistory([]);
    }
    // eslint-disable-next-line
  }, [beforeResultHistory]);

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Tab/Category History'],
  };

  // Select Box Filter 선택 값
  const [deviceTypeFilter, setDeviceTypeFilter] = useState(param.deviceType);
  const [regionFilter, setRegionFilter] = useState(param.region);
  const [platformVersionFilter, setPlatformVersionFilter] = useState(param.platformVersion);
  const [statusFilter, setStatusFilter] = useState(mappingStatusValue(param.status));
  const [enableFilter, setEnableFilter] = useState('All');
  const [searchDateFilter, setSearchDateFilter] = useState(dayjs());
  const [categoryCodeFilter, setCategoryCodeFilter] = useState('');
  const [categoryNameFilter, setCategoryNameFilter] = useState('');


  // Select Box Filter 데이터 리스트
  //const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [platformVersionOptions, setPlatformVersionOptions] = useState([]);

  // 페이지 첫 진입 시 Main 에서 선택된 DeviceType, Region, PlatformVersion, Status 값 셋팅
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  // DeviceType Filter 값 변경 시 해당되는 Region의 Select Box 데이터 리스트 변경
  useEffect(() => {
    const region = (regionList.find(n => n.deviceType === deviceTypeFilter) || { list: [] }).list.map(n => {
      const newItem = {
        id: n.region,
        value: n.region,
        option: n.region,
        type: 'text',
      }
      return newItem;
    })
    setRegionOptions(region);
    // eslint-disable-next-line
  }, [deviceTypeFilter]);

  // Region Filter 값 변경 시 해당되는 Platform Version의 Select Box 데이터 리스트 변경
  useEffect(() => {
    const platformVersion = (regionPlatformList.filter(n => n.deviceType === deviceTypeFilter && n.region === regionFilter)).map(n => {
      const newItem = {
        id: n.platformVersion,
        value: n.platformVersion,
        option: n.platformVersion,
        type: 'text',
      }
      return newItem;
    })
    setPlatformVersionOptions(platformVersion);
    // eslint-disable-next-line
  }, [regionFilter]);

  // Tab 히스토리 데이터
  const [tabHistory, setTabHistory] = useState([]);
  const [displayTabHistory, setDisplayTabHistory] = useState([]);

  useEffect(() => {
    if (!isEmpty(tabHistory)) {
      setDisplayTabHistory(tabHistory);
    } else {
      setDisplayTabHistory([]);
    }
    // eslint-disable-next-line
  }, [tabHistory]);

  // Category 히스토리 데이터
  const [categoryHistory, setCategoryHistory] = useState([]);
  const [displayCategoryHistory, setDisplayCategoryHistory] = useState([]);

  useEffect(() => {
    if (!isEmpty(categoryHistory)) {
      setDisplayCategoryHistory(categoryHistory);
    } else {
      setDisplayCategoryHistory([]);
    }
    // eslint-disable-next-line
  }, [categoryHistory]);

  // Result 히스토리 데이터
  const [resultHistory, setResultHistory] = useState([]);
  const [displayResultHistory, setDisplayResultHistory] = useState([]);

  useEffect(() => {
    if (!isEmpty(resultHistory)) {
      setDisplayResultHistory(resultHistory);
    } else {
      setDisplayResultHistory([]);
    }
    // eslint-disable-next-line
  }, [resultHistory]);

  // Tab 그리드에 연관검색 키워드 input
  const [searchTabHistoryFilter, setSearchTabHistoryFilter] = useState('');
  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchTabHistoryFilter) {
      const filteredData = tabHistory.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTabHistoryFilter.toLowerCase()));
      });
      setDisplayTabHistory([...filteredData]);
    } else {
      setDisplayTabHistory(tabHistory);
    }
    // eslint-disable-next-line
  }, [searchTabHistoryFilter]);

  // Category 그리드에 연관검색 키워드 input
  const [searchCategoryHistoryFilter, setSearchCategoryHistoryFilter] = useState('');
  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchCategoryHistoryFilter) {
      const filteredData = categoryHistory.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchCategoryHistoryFilter.toLowerCase()));
      });
      setDisplayCategoryHistory([...filteredData]);
    } else {
      setDisplayCategoryHistory(categoryHistory);
    }
    // eslint-disable-next-line
  }, [searchCategoryHistoryFilter]);

  // Result 그리드에 연관검색 키워드 input
  const [searchResultHistoryFilter, setSearchResultHistoryFilter] = useState('');
  // Search Content 연관 검색 시
  useEffect(() => {
    if (searchResultHistoryFilter) {
      const filteredData = resultHistory.filter((item) => {
        return Object.entries(item)
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchResultHistoryFilter.toLowerCase()));
      });
      setDisplayResultHistory([...filteredData]);
    } else {
      setDisplayResultHistory(resultHistory);
    }
    // eslint-disable-next-line
  }, [searchResultHistoryFilter]);

  /* 정보 수정 시 객체에 값을 변경 한다 Start */
  // Device Type Filter 값
  const onChangeDeviceType = (value) => {
    setDeviceTypeFilter(value);
  };
  // Region Filter 값
  const onChangeRegion = (value) => {
    setRegionFilter(value);
  };
  // Platform Version Filter 값
  const onChangePlatformVersion = (value) => {
    setPlatformVersionFilter(value);
  };
  // Status 값
  const onChangeStatus = (value) => {
    setStatusFilter(value);
  };
  // Enable 값
  const onChangeEnable = (value) => {
    setEnableFilter(value);
  };
  // category code filter
  const onChangeCategoryCode = (event) => {
    const value = event.target.value;
    setCategoryCodeFilter(value);
  };
  // category name filter
  const onChangeCategoryName = (event) => {
    const value = event.target.value;
    setCategoryNameFilter(value);
  };
  /* 정보 수정 시 객체에 값을 변경 한다 End */

  const todayClick = () => {
    setSearchDateFilter(dayjs());
  };

  // Reset 버튼 클릭
  const resetFilter = () => {
    setDeviceTypeFilter(param.deviceType);
    setRegionFilter(param.region);
    setPlatformVersionFilter(param.platformVersion);
    setStatusFilter('All');
    setEnableFilter('All');
    setSearchDateFilter(dayjs());
    setCategoryCodeFilter('');
    setCategoryNameFilter('');
  };

  // Search 버튼 클릭
  const searchHistory = async () => {
    // Tab History
    if (tabValue === 0) {
      const body = await getRequest(`/home-app/tab-history?deviceType=${deviceTypeFilter}&region=${regionFilter}&platformVersion=${platformVersionFilter}&status=${statusFilter}&enable=${enableFilter}&date=${searchDateFilter}`, null);
      if (body && body.result === 'SUCCESS') {
        setTabHistory(!isEmpty(body.data) ? body.data : []);
      } else {
        setTabHistory([]);
      }
    // Category History
    } else if (tabValue === 1) {
      const body = await getRequest(`/home-app/category-history?deviceType=${deviceTypeFilter}&region=${regionFilter}&platformVersion=${platformVersionFilter}&status=${statusFilter}&enable=${enableFilter}&date=${searchDateFilter}&categoryCode=${categoryCodeFilter}&categoryName=${categoryNameFilter}`, null);
      if (body && body.result === 'SUCCESS') {
        setCategoryHistory(!isEmpty(body.data) ? body.data : []);
      } else {
        setCategoryHistory([]);
      }
    // Result History
    } else if (tabValue === 2) {
      const body = await getRequest(`/home-app/result-history?deviceType=${deviceTypeFilter}&region=${regionFilter}&platformVersion=${platformVersionFilter}&status=${statusFilter}&enable=${enableFilter}&date=${searchDateFilter}&categoryCode=${categoryCodeFilter}&categoryName=${categoryNameFilter}`, null);
      if (body && body.result === 'SUCCESS') {
        setResultHistory(!isEmpty(body.data) ? body.data : []);
      } else {
        setResultHistory([]);
      }
    }
  };

  const tabExcelDownload = (event) => {
    event.preventDefault();

    const excelData = tabHistory.map((item) => {
      let info = {};
      info['Change Date'] = item.crtDate;
      info['Tab Code'] = item.tabCode;
      info['Tab Name'] = item.tabName;
      info['Event'] = item.event;
      info['Enable'] = item.useFlag === 'Y' ? 'On' : 'Off'
      info['Last Modifier'] = item.username;
      return info;
    })
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tab');
    XLSX.writeFile(workbook, `Tab_History_${deviceTypeFilter}_${regionFilter}_${platformVersionFilter}.xlsx`);
  };

  const categoryExcelDownload = (event) => {
    event.preventDefault();

    const excelData = categoryHistory.map((item) => {
      let info = {};
      info['Change Date'] = item.crtDate;
      info['Tab Code'] = item.tabCode;
      info['Tab Name'] = item.tabName;
      info['Category Code'] = item.categoryCode;
      info['Category Name'] = item.categoryName;
      info['Content Type'] = item.contentType;
      info['Mapping Type'] = item.mappingType;
      info['Order'] = item.categoryOrder;
      info['Enable'] = item.useFlag === 'Y' ? 'On' : 'Off';
      info['Event'] = item.event;
      info['Last Modifier'] = item.username;
      return info;
    })
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Category');
    XLSX.writeFile(workbook, `Category_${deviceTypeFilter}_${regionFilter}_${platformVersionFilter}.xlsx`);
  };

  const contentExcelDownload = (event) => {
    event.preventDefault();

    const excelData = resultHistory.map((item) => {
      let info = {};
      info['Change Date'] = item.crtDate;
      info['Tab Code'] = item.tabCode;
      info['Tab Name'] = item.tabName;
      info['Category Code'] = item.categoryCode;
      info['Category Name'] = item.categoryName;
      info['Mapping Type'] = item.mappingType;
      info['Status'] = mappingStatusName(item.status);
      info['Result'] = 'Create/'+item.createCnt+' Update/'+item.updateCnt+' Delete'+item.deleteCnt;
      info['Last Modifier'] = item.username;
      return info;
    })
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contents');
    XLSX.writeFile(workbook, `Content_${deviceTypeFilter}_${regionFilter}_${platformVersionFilter}.xlsx`);
  };

  // Tab 연관 검색창에 검색어 입력 시
  const handleSearchTabHistory = (value) => {
    setSearchTabHistoryFilter(value);
  };

  // Category 연관 검색창에 검색어 입력 시
  const handleSearchCategoryHistory = (value) => {
    setSearchCategoryHistoryFilter(value);
  };

  // Result 연관 검색창에 검색어 입력 시
  const handleSearchResultHistory = (value) => {
    setSearchResultHistoryFilter(value);
  };

  // Tab Paging 기능에서 사용하는 데이터
  const [tabPaginatedData, setTabPaginatedData] = useState([]);
  const [tabPage, setTabPage] = useState(1);
  const [tabItemsPerPage, setTabItemsPerPage] = useState(20);
  const [tabOrder, setTabOrder] = useState('asc');
  const [tabOrderBy, setTabOrderBy] = useState('');

  // Paging 처리
  useEffect(() => {
    if (displayTabHistory && displayTabHistory.length > 0) {
      const startIndex = (tabPage - 1) * tabItemsPerPage;
      const endIndex = startIndex + tabItemsPerPage;
      const newPaginatedData = displayTabHistory.slice(startIndex, endIndex);
      setTabPaginatedData([...newPaginatedData]);
    } else {
      setTabPaginatedData([]);
    }
  }, [displayTabHistory, tabPage, tabItemsPerPage]);

  const handleTabPagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setTabPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setTabItemsPerPage(pagingValue.value);
        setTabPage(1);
      }
    }
  };

  const handleTabRequestSort = (property) => {
    const isAsc = tabOrderBy === property && tabOrderBy === 'asc';
    setTabOrder(isAsc ? 'desc' : 'asc');
    setTabOrderBy(property);
    const sortedData = [...displayTabHistory].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setDisplayTabHistory([...sortedData]);
  };

  // Category Paging 기능에서 사용하는 데이터
  const [categoryPaginatedData, setCategoryPaginatedData] = useState([]);
  const [categoryPage, setCategoryPage] = useState(1);
  const [categoryItemsPerPage, setCategoryItemsPerPage] = useState(20);
  const [categoryOrder, setCategoryOrder] = useState('asc');
  const [categoryOrderBy, setCategoryOrderBy] = useState('');

  // Category Paging 처리
  useEffect(() => {
    if (displayCategoryHistory && displayCategoryHistory.length > 0) {
      const startIndex = (categoryPage - 1) * categoryItemsPerPage;
      const endIndex = startIndex + categoryItemsPerPage;
      const newPaginatedData = displayCategoryHistory.slice(startIndex, endIndex);
      setCategoryPaginatedData([...newPaginatedData]);
    } else {
      setCategoryPaginatedData([]);
    }
  }, [displayCategoryHistory, categoryPage, categoryItemsPerPage]);

  const handleCategoryPagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setCategoryPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setCategoryItemsPerPage(pagingValue.value);
        setCategoryPage(1);
      }
    }
  };

  const handleCategoryRequestSort = (property) => {
    const isAsc = categoryOrderBy === property && categoryOrderBy === 'asc';
    setCategoryOrder(isAsc ? 'desc' : 'asc');
    setCategoryOrderBy(property);
    const sortedData = [...displayCategoryHistory].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setDisplayCategoryHistory([...sortedData]);
  };

  // Result Paging 기능에서 사용하는 데이터
  const [resultPaginatedData, setResultPaginatedData] = useState([]);
  const [resultPage, setResultPage] = useState(1);
  const [resultItemsPerPage, setResultItemsPerPage] = useState(20);
  const [resultOrder, setResultOrder] = useState('asc');
  const [resultOrderBy, setResultOrderBy] = useState('');

  // Result Paging 처리
  useEffect(() => {
    if (displayResultHistory && displayResultHistory.length > 0) {
      const startIndex = (resultPage - 1) * resultItemsPerPage;
      const endIndex = startIndex + resultItemsPerPage;
      const newPaginatedData = displayResultHistory.slice(startIndex, endIndex);
      setResultPaginatedData([...newPaginatedData]);
    } else {
      setResultPaginatedData([]);
    }
  }, [displayResultHistory, resultPage, resultItemsPerPage]);

  const handleResultPagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setResultPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setResultItemsPerPage(pagingValue.value);
        setResultPage(1);
      }
    }
  };

  const handleResultRequestSort = (property) => {
    const isAsc = resultOrderBy === property && resultOrderBy === 'asc';
    setResultOrder(isAsc ? 'desc' : 'asc');
    setResultOrderBy(property);
    const sortedData = [...displayResultHistory].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setDisplayResultHistory([...sortedData]);
  };

  // Tab state
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  // history back 이전 페이지
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/TabCategoryList', { replace: true, state:
      { move: true,
        targetComponent: 'TabCategoryList',
        message:
        {
          param: param,
        }
      }
    });
  };

  // Result History 저장 일자 링크 클릭 시 Result 상세 정보 화면으로 이동
  const handleResultHistoryClick = (row) => {
    let openComponent = 'HistoryDetailContents';

    navigate('/TabCategoryList', {
      replace: true, state: {
        move: true,
        targetComponent: openComponent,
        message: {
          result: row,
          regionList: regionList,
          regionPlatformList: regionPlatformList,
          beforeResultHistory: resultHistory,
        }
      }
    });
  };

  // Button Data
  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      width: 107,
      onClick: resetFilter,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      onClick: searchHistory,
    },
  ];

  // Snapshot Download
  const [isSnapshotPopupOpen, setIsSnapshotPopupOpen] = useState(false);

  const handleSnapshotDownload = () => {
    setIsSnapshotPopupOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='' onClick={handleBack}>
                Tab/Category History
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {/* 2411011 Snapshot Download 버튼 위치변경 */}
            <Button
              color='tertiary'
              type='outline'
              size='medium'
              label='Snapshot Download'
              isIcon={false}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={handleSnapshotDownload}
            />

            {tabValue === 0 && (
              <div className='tab-panel '>
                {/* 선택한 카테고리 정보 출력 영역 */}
                {/* 필터영역 */}
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <Box className='form-area left'>
                      {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                      <Box className='component-wrap col-5'>
                        {/* Device type */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-device'
                            size='large'
                            label='Device type'
                            shrink={true}
                            required={false}
                            selectOptions={deviceTypeOptions}
                            disabled={false}
                            selected={deviceTypeFilter}
                            onChange={onChangeDeviceType}
                          />
                        </Box>

                        {/* Region */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            selectOptions={regionOptions}
                            disabled={false}
                            selected={regionFilter}
                            onChange={onChangeRegion}
                          />
                        </Box>

                        {/* Platform Version */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-Platform'
                            size='large'
                            shrink={true}
                            label='Platform Version'
                            selectOptions={platformVersionOptions}
                            disabled={false}
                            selected={platformVersionFilter}
                            onChange={onChangePlatformVersion}
                          />
                        </Box>
                      </Box>

                      {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                      <Box className='component-wrap col-5'>
                        {/* Status */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-Status'
                            size='large'
                            shrink={true}
                            label='Status'
                            selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...statusOptions]}
                            disabled={false}
                            selected={statusFilter}
                            onChange={onChangeStatus}
                          />
                        </Box>
                        {/* Enable */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-Enable'
                            size='large'
                            shrink={true}
                            label='Enable'
                            selectOptions={enableOptions}
                            disabled={false}
                            isFirstSelected={true}
                            selected={enableFilter}
                            onChange={onChangeEnable}
                          />
                        </Box>
                        <Box component='div' className='col auto'>
                          <DatePicker
                            format='YYYY-MM-DD'
                            label='Date'
                            size='large'
                            singleDate={true}
                            rangeDate={false}
                            wave={false}
                            value={searchDateFilter}
                            onChange={(newValue) => setSearchDateFilter(newValue)}
                          />

                          <Button color='tertiary' type='outline' size='medium' label='Today' isIcon={false} onClick={todayClick} autosize />
                        </Box>
                      </Box>
                    </Box>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                <Box className='white-box white-box-wrap'>
                  <div className='box-content'>
                    <Box className='white-box-content-top-area'>
                      <Box className='left-area'>
                        <SearchField
                          id='search-1'
                          label='tab.no or tab.Name'
                          size='medium'
                          maxWidth='230px'
                          placeholder='Please enter a search term'
                          required={false}
                          shrink={false}
                          onChange={(event) => handleSearchTabHistory(event.target.value)}
                        />
                      </Box>
                      <Box className='right-area'>
                        <Button
                          color='tertiary'
                          type='outline'
                          size='small'
                          label='Download'
                          isIcon={false}
                          onClick={tabExcelDownload}
                          autosize
                        />
                      </Box>
                    </Box>
                    <Box className='content-inner-wrap'>
                      <CustomTableContainer className='table-container'>
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {HistoryTabTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <Box display='flex' alignContent='center'>
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                      <TableSortLabel
                                        active={tabOrderBy === column.id}
                                        direction={tabOrderBy === column.id ? tabOrder : 'desc'}
                                        onClick={() => handleTabRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </Box>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={tabOrderBy === column.id}
                                          direction={tabOrderBy === column.id ? tabOrder : 'desc'}
                                          onClick={() => handleTabRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {tabPaginatedData.map((row, index) => (
                              <TableRow className='tr' key={row.id || index}>
                                {HistoryTabTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      row.crtDate
                                    ) : colIndex === 1 ? (
                                      row.tabCode
                                    ) : colIndex === 2 ? (
                                      row.tabName
                                    ) : colIndex === 3 ? (
                                      row.event
                                    ) : colIndex === 4 ? (
                                      row.useFlag === 'Y' ? 'On' : 'Off'
                                    ) : colIndex === 5 ? (
                                      row.username
                                    ) : (
                                      row.idx
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    </Box>

                    {/* Pagination */}
                    <Pagination count={Math.ceil(displayTabHistory.length / tabItemsPerPage)} id='pagination-01' selected={tabItemsPerPage} onChangeHandle={handleTabPagination} page={tabPage} />
                  </div>
                </Box>
              </div>
            )}

            {tabValue === 1 && (
              <div className='tab-panel '>
                {/* 선택한 카테고리 정보 출력 영역 */}
                {/* 필터영역 */}
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <Box className='form-area left'>
                      {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                      <Box className='component-wrap col-5'>
                        {/* Device type */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyCategory-device'
                            size='large'
                            label='Device type'
                            shrink={true}
                            required={false}
                            selectOptions={deviceTypeOptions}
                            disabled={false}
                            selected={deviceTypeFilter}
                            onChange={onChangeDeviceType}
                          />
                        </Box>

                        {/* Region */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyCategory-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            selectOptions={regionOptions}
                            disabled={false}
                            selected={regionFilter}
                            onChange={onChangeRegion}
                          />
                        </Box>

                        {/* Platform Version */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyCategory-Platform'
                            size='large'
                            shrink={true}
                            label='Platform Version'
                            selectOptions={platformVersionOptions}
                            disabled={false}
                            selected={platformVersionFilter}
                            onChange={onChangePlatformVersion}
                          />
                        </Box>

                        {/* Category Code */}
                        <Box component='div' className='col'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder='Category Code'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={onChangeCategoryCode}
                          />
                        </Box>

                        {/* Category Name */}
                        <Box component='div' className='col'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder='Category Name'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={onChangeCategoryName}
                          />
                        </Box>
                      </Box>

                      {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                      <Box className='component-wrap col-5'>
                        {/* Status */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyCategory-Status'
                            size='large'
                            shrink={true}
                            label='Status'
                            selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...statusOptions]}
                            disabled={false}
                            selected={statusFilter}
                            onChange={onChangeStatus}
                          />
                        </Box>
                        {/* Enable */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyCategory-Enable'
                            size='large'
                            shrink={true}
                            label='Enable'
                            selectOptions={enableOptions}
                            disabled={false}
                            isFirstSelected={true}
                            selected={enableFilter}
                            onChange={onChangeEnable}
                          />
                        </Box>
                        <Box component='div' className='col auto'>
                          <DatePicker
                            format='YYYY-MM-DD'
                            label='Date'
                            size='large'
                            singleDate={true}
                            rangeDate={false}
                            wave={false}
                            value={searchDateFilter}
                            onChange={(newValue) => setSearchDateFilter(newValue)}
                          />

                          <Button color='tertiary' type='outline' size='medium' label='Today' isIcon={false} autosize />
                        </Box>
                      </Box>
                    </Box>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                <Box className='white-box white-box-wrap'>
                  <div className='box-content'>
                    <Box className='white-box-content-top-area'>
                      <Box className='left-area'>
                        <SearchField
                          id='search-2'
                          label='category.no or category.Name'
                          size='medium'
                          maxWidth='230px'
                          placeholder='Please enter a search term'
                          required={false}
                          shrink={false}
                          onChange={(event) => handleSearchCategoryHistory(event.target.value)}
                        />
                      </Box>
                      <Box className='right-area'>
                        <Button color='tertiary'
                          type='outline'
                          size='small'
                          label='Download'
                          isIcon={false}
                          onClick={categoryExcelDownload}
                          autosize
                        />
                      </Box>
                    </Box>
                    <Box className='content-inner-wrap'>
                      <CustomTableContainer className='table-container'>
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {HistoryCategoryTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <Box display='flex' alignContent='center'>
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                      <TableSortLabel
                                        active={categoryOrderBy === column.id}
                                        direction={categoryOrderBy === column.id ? categoryOrder : 'desc'}
                                        onClick={() => handleCategoryRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </Box>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={categoryOrderBy === column.id}
                                          direction={categoryOrderBy === column.id ? categoryOrder : 'desc'}
                                          onClick={() => handleCategoryRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {categoryPaginatedData.map((row, index) => (
                              <TableRow className='tr' key={row.id || index}>
                                {HistoryCategoryTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      row.crtDate
                                    ) : colIndex === 1 ? (
                                      row.tabCode
                                    ) : colIndex === 2 ? (
                                      row.tabName
                                    ) : colIndex === 3 ? (
                                      row.categoryCode
                                    ) : colIndex === 4 ? (
                                      row.categoryName
                                    ) : colIndex === 5 ? (
                                      row.contentType
                                    ) : colIndex === 6 ? (
                                      row.mappingType
                                    ) : colIndex === 7 ? (
                                      row.categoryOrder
                                    ) : colIndex === 8 ? (
                                      row.useFlag === 'Y' ? 'On' : 'Off'
                                    ) : colIndex === 9 ? (
                                      row.event
                                    ) : colIndex === 10 ? (
                                      row.username
                                    ) : (
                                      row.idx
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    </Box>

                    {/* Pagination */}
                    <Pagination count={Math.ceil(displayCategoryHistory.length / categoryItemsPerPage)} id='pagination-02' selected={categoryItemsPerPage} onChangeHandle={handleCategoryPagination} page={categoryPage} />
                  </div>
                </Box>
              </div>
            )}

            {tabValue === 2 && (
              <div className='tab-panel '>
                {/* 선택한 카테고리 정보 출력 영역 */}
                {/* 필터영역 */}
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <Box className='form-area left'>
                      {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                      <Box className='component-wrap col-5'>
                        {/* Device type */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyTab-device'
                            size='large'
                            label='Device type'
                            shrink={true}
                            required={false}
                            selectOptions={deviceTypeOptions}
                            disabled={false}
                            selected={deviceTypeFilter}
                            onChange={onChangeDeviceType}
                          />
                        </Box>

                        {/* Region */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyContent-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            selectOptions={regionOptions}
                            disabled={false}
                            selected={regionFilter}
                            onChange={onChangeRegion}
                          />
                        </Box>

                        {/* Platform Version */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyContent-Platform'
                            size='large'
                            shrink={true}
                            label='Platform Version'
                            selectOptions={platformVersionOptions}
                            disabled={false}
                            selected={platformVersionFilter}
                            onChange={onChangePlatformVersion}
                          />
                        </Box>

                        {/* Category Code */}
                        <Box component='div' className='col'>
                          <TextField
                            id='historyContent-code'
                            type='outlined'
                            size='large'
                            placeholder='Category Code'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={onChangeCategoryCode}
                          />
                        </Box>

                        {/* Category Name */}
                        <Box component='div' className='col'>
                          <TextField
                            type='outlined'
                            size='large'
                            placeholder='Category Name'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={onChangeCategoryName}
                          />
                        </Box>
                      </Box>

                      {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                      <Box className='component-wrap col-5'>
                        {/* Status */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyContent-Status'
                            size='large'
                            shrink={true}
                            label='Status'
                            selectOptions={[{id: 'All', value: 'All', option: 'All', type: 'text'}, ...statusOptions]}
                            disabled={false}
                            selected={statusFilter}
                            onChange={onChangeStatus}
                          />
                        </Box>
                        {/* Enable */}
                        <Box component='div' className='col'>
                          <SelectBox
                            id='historyContent-Enable'
                            size='large'
                            shrink={true}
                            label='Enable'
                            selectOptions={enableOptions}
                            disabled={false}
                            isFirstSelected={true}
                            selected={enableFilter}
                            onChange={onChangeEnable}
                          />
                        </Box>
                        <Box component='div' className='col auto'>
                          <DatePicker
                            format='YYYY-MM-DD'
                            label='Date'
                            size='large'
                            singleDate={true}
                            rangeDate={false}
                            wave={false}
                            value={searchDateFilter}
                            onChange={(newValue) => setSearchDateFilter(newValue)}
                          />

                          <Button color='tertiary' type='outline' size='medium' label='Today' isIcon={false} autosize />
                        </Box>
                      </Box>
                    </Box>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                <Box className='white-box white-box-wrap'>
                  <div className='box-content'>
                    <Box className='white-box-content-top-area'>
                      <Box className='left-area'>
                        <SearchField
                          id='search-3'
                          label='content.no or content.Name'
                          size='medium'
                          maxWidth='230px'
                          placeholder='Please enter a search term'
                          required={false}
                          shrink={false}
                          onChange={(event) => handleSearchResultHistory(event.target.value)}
                        />
                      </Box>
                      <Box className='right-area'>
                        <Button
                          color='tertiary'
                          type='outline'
                          size='small'
                          label='Download'
                          isIcon={false}
                          onClick={contentExcelDownload}
                          autosize
                        />
                      </Box>
                    </Box>
                    <Box className='content-inner-wrap'>
                      <CustomTableContainer className='table-container'>
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {HistoryContentTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <Box display='flex' alignContent='center'>
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                      <TableSortLabel
                                        active={resultOrderBy === column.id}
                                        direction={resultOrderBy === column.id ? resultOrder : 'desc'}
                                        onClick={() => handleResultRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </Box>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={resultOrderBy === column.id}
                                          direction={resultOrderBy === column.id ? resultOrder : 'desc'}
                                          onClick={() => handleResultRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {resultPaginatedData.map((row, index) => (
                              <TableRow className='tr' key={row.id || index}>
                                {HistoryContentTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align}>
                                    {colIndex === 0 ? (
                                      <>
                                        <span onClick={() => handleResultHistoryClick(row)} style={{ color: '#2196f3', cursor: 'pointer' }} >
                                          {row.crtDate}
                                        </span>
                                      </>
                                    ) : colIndex === 1 ? (
                                      row.tabCode
                                    ) : colIndex === 2 ? (
                                      row.tabName
                                    ) : colIndex === 3 ? (
                                      row.categoryCode
                                    ) : colIndex === 4 ? (
                                      row.categoryName
                                    ) : colIndex === 5 ? (
                                      row.mappingType
                                    ) : colIndex === 6 ? (
                                      mappingStatusName(row.status)
                                    ) : colIndex === 7 ? (
                                    <Box display='flex'>
                                      <Typography component={'p'} className='result-value'>
                                        <Typography component={'span'} className='label'>
                                        Deleted
                                        </Typography>
                                        <Typography component={'span'} className='val-deleted'>
                                        {row.deleteCnt}
                                        </Typography>
                                      </Typography>
                                      <Box component={'span'} margin={'0 4px'}>
                                        /
                                      </Box>
                                      <Typography component={'p'} className='result-value'>
                                        <Typography component={'span'} className='label'>
                                        Created
                                        </Typography>
                                        <Typography component={'span'} className='val-created'>
                                        {row.createCnt}
                                        </Typography>
                                      </Typography>
                                      <Box component={'span'} margin={'0 4px'}>
                                        /
                                      </Box>
                                      <Typography component={'p'} className='result-value'>
                                        <Typography component={'span'} className='label'>
                                        Updated
                                        </Typography>
                                        <Typography component={'span'} className='val-updated'>
                                        {row.updateCnt}
                                        </Typography>
                                      </Typography>
                                    </Box>
                                    ) : colIndex === 8 ? (
                                      row.username
                                    ) : (
                                      row.idx
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    </Box>

                    {/* Pagination */}
                    <Pagination count={Math.ceil(displayResultHistory.length / resultItemsPerPage)} id='pagination-03' selected={resultItemsPerPage} onChangeHandle={handleResultPagination} page={resultPage} />
                  </div>
                </Box>
              </div>
            )}
          </div>
        </Box>
      </Box>
      {isSnapshotPopupOpen && <PopSnapshotDownload type={'tabCategory'} deviceType={deviceTypeFilter} region={regionFilter} platformVersionFilter={platformVersionOptions} close={setIsSnapshotPopupOpen} />}
    </Box>
  );
}

HistoryContents.propTypes = {
  param: PropTypes.object.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  param: state.homeapp.param,
  regionList: state.homeapp.regionList,
  regionPlatformList: state.homeapp.regionPlatformList,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContents);
