import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styled } from '@mui/material/styles';
import TextField from 'components/component/BasicTextField';
import { Box, Paper } from '@mui/material';
import 'dayjs/locale/ko';
import dayjs from 'dayjs';
import calendarIconLight from 'assets/images/icon/ic_calendar_24_outline.svg';
import calendarIconDark from 'assets/images/icon/ic_calendar_24_outline_dark.svg';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

dayjs.extend(isBetweenPlugin);

// 주간 선택을 위한 CustomPickersDay 컴포넌트
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
  '& .MuiPickersDay-root': {
    '&[aria-colindex="1"]': {
      borderRadius: '8px 0 0 8px',
    },
    '&[aria-colindex="7"]': {
      borderRadius: '0 8px 8px 0',
    },
  },
  ...(isSelected && {
    backgroundColor: '#a7c4dd',
    color: '#fff',
    '&:hover, &:focus': {
      '&[aria-colindex="1"]': {
        borderRadius: '8px 0 0 8px',
      },
      '&[aria-colindex="7"]': {
        borderRadius: '0 8px 8px 0',
      },
    },
    '&.selected-week': {
      backgroundColor: '#a3bdda',
      borderRadius: 0,

      '&.MuiPickersDay-today': {
        backgroundColor: '#a3bdda',
      },
      '&[aria-colindex="1"]': {
        borderRadius: '8px 0 0 8px',
      },
      '&[aria-colindex="7"]': {
        borderRadius: '0 8px 8px 0',
      },
    },
  }),
  ...(isHovered && {
    color: '#fff',

    '& .MuiPickersDay-root': {
      borderRadius: 0,
      backgroundColor: '#a3bdda',
    },

    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.primary.dark,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
  }),

  // 일요일 스타일
  ...(day.day() === 0 && {
    color: '#B91C1C',
    borderRadius: '8px 0 0 8px',
  }),
  // 토요일 스타일
  ...(day.day() === 6 && {
    color: '#2196F3',
    borderRadius: '0 8px 8px 0',
  }),
}));

// 테마 설정
const theme = createTheme({
  components: {
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersMonth-root': {
            flexBasis: '32%',

            '& .MuiPickersMonth-monthButton': {
              fontSize: '14px',
              color: '#000',
              borderRadius: '4px',
              '.dark &': {
                color: '#b1b1b1',
              },

              '&:hover': {
                backgroundColor: '#ABB6C2',
                color: '#fff',
              },
              '&.Mui-selected': {
                backgroundColor: '#3AA8FF',
                color: '#fff',
              },
            },
            '& .Mui-disabled': {
              color: '#c6c6c6',
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',

          '.MuiFormLabel-root': {
            '.MuiInputLabel-asterisk': {
              color: ' #a50034',
              fontSize: '16px',
            },
          },

          '& .MuiInputLabel-shrink': {
            fontSize: '14px',
            padding: '0 3px',
            color: '#6B6B6B',
            backgroundColor: '#fff',

            '.dark &': {
              background: '#1C1C1C',
              color: '#fff',
            },
          },

          '& .MuiInputBase-root': {
            '.dark &': {
              background: '#1b1b1b',
              color: '#fff',
            },

            //  property - Disabled
            '&.Mui-disabled': {
              backgroundColor: 'rgba(240, 236, 228, 0.10)',
              '&:not(.MuiIconButton-root)': {
                background: '#ECECEC',

                '.dark &': {
                  backgroundColor: 'rgba(240, 236, 228, 0.10)',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#DFDFDF',
              },
            },
            //  property - readOnly
            '&.Mui-readOnly': {
              backgroundColor: '#F0ECE4',
              '.dark &': {
                backgroundColor: 'rgba(240, 236, 228, 0.10)',
                color: '#fff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
              },
            },
          },

          //  Size - Large
          '& .MuiInputBase-sizeLarge ': {
            '& .MuiInputBase-input': {
              padding: '10px 12px',
              fontSize: '14px',
            },
          },
          //  Size - Small
          '& .MuiInputBase-sizeSmall ': {
            '& .MuiInputBase-input': {
              padding: '4px 12px',
              fontSize: '14px',
            },
          },
          //  Size - Medium
          '& .MuiInputBase-input': {
            padding: '6px 12px',
            fontSize: '14px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(107, 107, 107, 0.60)',

            '.dark &': {
              border: '1px solid #484848',
            },
          },
          '& .MuiOutlinedInput-root': {
            '&:hover:not(.Mui-disabled)': {
              '.dark &': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
              },
            },
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b91c1c',
          },
        },
      },
    },
  },
});

// 같은 주에 있는지 확인하는 함수
const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }
  return dayA.isSame(dayB, 'week');
};

// 주의 시작일과 종료일을 구하는 함수
const getWeekRange = (date) => {
  if (!date) return '';
  const start = date.startOf('week');
  const end = date.endOf('week');
  return `${start.format('YYYY.MM.DD')} ~ ${end.format('YYYY.MM.DD')}`;
};

// 주간 선택을 위한 Day 컴포넌트
function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return <CustomPickersDay {...other} day={day} sx={{ px: 2.5 }} disableMargin selected={false} isSelected={isInSameWeek(day, selectedDay)} isHovered={isInSameWeek(day, hoveredDay)} />;
}

function BasicDatePicker({
  size = 'medium',
  disabled = false,
  readOnly = false,
  error = false,
  id,
  label,
  width,
  maxWidth,
  required,
  singleDate,
  rangeDate,
  isAfterDate = false,
  selectAllDate = false,
  maxDate, // YYYY-MM-DD
  minDate, // YYYY-MM-DD
  gap,
  wave,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  defaultValue = dayjs(),
  weeklyDate = false,
  monthlyDate = false,
  onChange, // 통계 페이지에서 사용
  dateBefore = false, // 통계 페이지에서 사용하고, 오늘 이전 날짜 선택 가능 여부
  ...props
}) {
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [endError, setEndError] = useState(false);

  useEffect(() => {
    handleStartDateChange(startDate);
    handleEndDateChange(endDate);
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    if (weeklyDate) {
      const newDate = dateBefore ? dayjs().subtract(1, 'week') : dayjs();
      handleWeeklyDateChange(newDate);
    }
    // eslint-disable-next-line
  }, [weeklyDate]);

  useEffect(() => {
    if (monthlyDate) {
      const newDate = dateBefore ? dayjs().subtract(1, 'month') : dayjs();
      handleMonthChange(newDate);
    }
    // eslint-disable-next-line
  }, [monthlyDate]);

  const handleStartDateChange = (newValue) => {
    setFromDate(newValue);
    if (onChangeStartDate) {
      onChangeStartDate(newValue);
    }
    if (toDate && newValue.isAfter(toDate)) {
      setEndError(true);
    } else {
      setEndError(false);
    }
  };
  const handleEndDateChange = (newValue) => {
    setToDate(newValue);
    if (onChangeEndDate) {
      onChangeEndDate(newValue);
    }
    if (fromDate && newValue.isBefore(fromDate)) {
      setEndError(true);
    } else {
      setEndError(false);
    }
  };

  //monthly
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const handleMonthChange = (newValue) => {
    setSelectedDate(newValue);
    onChange(newValue);
  };

  // weekly
  const [hoveredDay, setHoveredDay] = useState(null);
  const [value, setValue] = useState(dayjs());
  const [weekRange, setWeekRange] = useState(getWeekRange(dayjs()));

  // 주간 날짜 변경 핸들러
  const handleWeeklyDateChange = (newValue) => {
    setValue(newValue);
    setWeekRange(getWeekRange(newValue));

    onChange(newValue.startOf('week'));
  };

  const getMaxDate = () => {
    if (selectAllDate) {
      return dayjs('9999-12-31');
    }
    if (maxDate) {
      return dayjs(maxDate);
    }
    if (isAfterDate) {
      return dayjs().add(10, 'year');
    }
    return dayjs();
  }

  const getMinDate = () => {
    if (selectAllDate) {
      return dayjs('1000-01-01');
    }
    if (minDate) {
      return dayjs(minDate);
    }
    if (isAfterDate) {
      return dayjs().add(1, 'day');
    }
    return dayjs().subtract(10, 'year');
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {globalStyles}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
          {singleDate && !rangeDate && (
            <DatePicker
              id={id}
              label={label}
              format='YYYY-MM-DD'
              slotProps={{
                calendarHeader: { format: 'YYYY/MM' },
                textField: { size, error: Boolean(error), required },
              }}
              width={width}
              sx={{ maxWidth: maxWidth }}
              disabled={disabled}
              readOnly={readOnly}
              defaultValue={dateBefore ? defaultValue.subtract(1, 'day') : defaultValue}
              slots={{ openPickerIcon: DateIcon }}
              maxDate={getMaxDate()}
              minDate={getMinDate()}
              PaperProps={{
                component: CustomPaper,
              }}
              onChange={onChange}
              {...props}
            />
          )}

          {/* Weekly Date */}
          {weeklyDate && (
            <Box className='weeklyPicker-container'>
              <DatePicker
                label=''
                onChange={handleWeeklyDateChange}
                slots={{
                  day: Day,
                  openPickerIcon: DateIcon,
                }}
                slotProps={{
                  day: (ownerState) => ({
                    selectedDay: value,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                    className: isInSameWeek(ownerState.day, value) ? 'selected-week' : '',
                  }),
                  textField: {
                    size,
                    inputProps: {
                      placeholder: ' ',
                      value: '', // 선택한 날짜를 표시하지 않음
                    },
                  },
                  popper: {
                    className: 'weekly-calendar-pop',
                  },
                }}
                sx={{ maxWidth: maxWidth }}
                disabled={disabled}
                readOnly={readOnly}
                maxDate={dayjs()}
              />
              <Box className='weekly-date-text'>{weekRange}</Box>
            </Box>
          )}

          {/* Monthly Date */}
          {monthlyDate && (
            <Box className='monthlyPicker-container'>
              <DatePicker
                views={['year', 'month']}
                className='input-date-picker'
                sx={{ maxWidth: maxWidth, width: width }}
                format='MM/YYYY'
                label='Monthly'
                value={selectedDate}
                onChange={(newValue) => {
                  handleMonthChange(newValue);
                }}
                singleDate={true}
                maxDate={dayjs()}
                slots={{
                  Textfield: (params) => <TextField {...params} size={size} />,
                  openPickerIcon: DateIcon,
                }}
                openTo='month'
                defaultView='month'
              />
            </Box>
          )}

          {/* Range Date */}
          {rangeDate && (
            <Box className='rangePicker-container' sx={{ gap }}>
              <DatePicker
                label='Start Date'
                format='YYYY-MM-DD'
                value={fromDate}
                width={width}
                sx={{ maxWidth: maxWidth }}
                slotProps={{
                  calendarHeader: { format: 'YYYY/MM' },
                  textField: { size, error: Boolean(error), required },
                }}
                disabled={disabled}
                readOnly={readOnly}
                onChange={handleStartDateChange}
                defaultValue={defaultValue}
                slots={{ openPickerIcon: DateIcon }}
                InputLabelProps={{ shrink: true }}
                PaperProps={{
                  component: CustomPaper,
                }}
                {...props}
              />
              {wave && <span className='wave'>~</span>}
              <DatePicker
                label='End Date'
                format='YYYY-MM-DD'
                value={toDate}
                width={width}
                sx={{ maxWidth: maxWidth }}
                slotProps={{
                  calendarHeader: { format: 'YYYY/MM' },
                  textField: { size, error: endError, required },
                }}
                disabled={disabled}
                readOnly={readOnly}
                minDate={fromDate}
                onChange={handleEndDateChange}
                defaultValue={defaultValue}
                slots={{ openPickerIcon: DateIcon }}
                PaperProps={{
                  component: CustomPaper,
                }}
                InputLabelProps={{ shrink: true }}
                {...props}
              />
            </Box>
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const globalStyles = (
  <GlobalStyles
    styles={{
      '.MuiDateCalendar-root': {
        height: 'auto',
        maxHeight: 'none',
        '.dark &': {
          background: '#1C1C1C',
          border: '1px solid rgba(228, 231, 234, 0.10)',
        },
        '.MuiPickersCalendarHeader-root': {
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '18px',
          color: '#333',
          marginBottom: '24px',
          padding: 0,

          '.dark &': {
            background: '#1B1B1B',
          },

          '.MuiPickersCalendarHeader-labelContainer': {
            marginRight: 'auto',
            width: '100%',
            justifyContent: 'center',

            '.dark &': {
              '& .MuiPickersCalendarHeader-switchViewButton': {
                'svg path': {
                  color: '#fff',
                  fill: '#fff',
                  stroke: '#fff',
                },
              },
            },

            '.MuiButtonBase-root': {
              display: 'inline-block',
              marginRight: 'initial',
            },
          },
          '.MuiPickersCalendarHeader-label': {
            fontSize: '16px',
            fontWeight: 700,
            color: '#000',

            '.dark &': {
              color: '#fff',
            },
          },
          '.MuiPickersArrowSwitcher-root': {
            width: 0,
            '& .MuiButtonBase-root': {
              position: 'absolute',
              borderRadius: '50%',
              width: '32px',
              height: '32px',
            },
            '& .MuiIconButton-edgeEnd': {
              left: '20%',
              top: '3px',
              '.dark &': {
                width: '32px',
                height: '32px',
                backgroundImage: '',
                backgroundPosition: '50%',
                'svg path': {
                  color: '#fff',
                },
              },
            },
            '& .MuiIconButton-edgeStart': {
              right: '22%',
              top: '3px',
              '.dark &': {
                width: '32px',
                height: '32px',
                backgroundImage: '',
                backgroundPosition: '50%',
                'svg path': {
                  color: '#fff',
                },
              },
            },
          },
          '& .MuiPickersCalendarHeader-switchViewButton': {},
        },
        '.MuiPickersDay-root': {
          fontSize: '14px',
          '&.Mui-disabled': {
            color: '#c6c6c6',

            '&.selected-week': {
              color: '#606060',
            },
          },
        },
      },

      '.MuiDayCalendar-header': {
        '.MuiDayCalendar-weekDayLabel': {
          fontSize: '12px',
          color: '#6B6B6B',

          '.dark &': {
            color: '#fff',
          },

          '&:first-of-type': {
            color: '#b91c1c',
          },
          '&:last-child': {
            color: '#2196F3',
          },
        },
      },

      // 날짜
      '.MuiPickersDay-root': {
        fontSize: '14px',
        color: '#6B6B6B',
        borderRadius: '8px',

        '&.selected-week': {
          '.dark &': {
            color: '#fff !important',
          },
        },

        '.dark &': {
          color: '#fff',
        },
        '&.Mui-disabled': {
          color: '#c6c6c6',
          opacity: 0.7,

          '.dark &': {
            color: '#6C6C6C !important',
          },

          '&:not(.Mui-selected)': {
            color: '#000',
          },
        },
        '&:not(.Mui-selected)': {
          color: '#000',

          '.dark &': {
            color: '#b1b1b1 ',
          },
        },

        '&.MuiPickersDay-root[aria-colindex="1"]': {
          color: '#B91C1C',

          '&.Mui-selected': {
            color: '#fff',
          },
        },
        '&.MuiPickersDay-root[aria-colindex="7"]': {
          color: '#2196F3',

          '&.Mui-selected': {
            color: '#fff',
          },
        },
        '&.MuiPickersDay-today': {
          background: 'transparent',
          color: '#000',
          border: 0,
          fontWeight: 700,

          '.dark &': {
            color: '#fff',
          },
        },
      },

      // Year
      '.MuiYearCalendar-root': {
        maxHeight: '230px',
        '.MuiPickersYear-root': {
          '.MuiPickersYear-yearButton': {
            fontSize: '12px',

            '.dark &': {
              color: '#B1B1B1',
            },
          },
        },
      },
    }}
  />
);

const DateIcon = styled('span')({
  display: 'block',
  width: '24px',
  height: '24px',
  backgroundImage: `url(${calendarIconLight})`,
  backgroundRepeat: 'no-repeat',

  '.dark &': {
    backgroundImage: `url(${calendarIconDark})`,
  },
});

const CustomPaper = styled(Paper)({
  backgroundColor: 'lightblue',
  padding: '10px',
});

export default BasicDatePicker;
