import React, { useState, useEffect } from 'react';
import { getRequest } from 'utils/Request';
import BasicSwitches from 'components/component/BasicSwitch';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Dialog from 'components/component/BasicDialog';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { DelMinus, AddPlus, CloseIcon24Black } from 'components/component/BasicIcon';
import { saveEditTab } from 'pages/Organization/TabCategory/api/restApi';

import { Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  Divider,
  List,
  ListItem,
} from '@mui/material';

const uploadedFiles = [
  {
    name: 'Logo_1.png',
    path: '',
  },
];
const themeUploadedFiles = [
  {
    name: 'CP A_Bulk.png',
    path: '',
  },
];

// 메인 컨텐츠
function PopEditTab(props) {
  const { tabInfo, tabSkeletonTypeOptions, tabLocationTypeOptions, tabTypeOptions, multiLangOptions, onClose, handelSetShowSnakBar, callTabCategoryList } = props;
  const [editTab, setEditTab] = useState({
    idx: tabInfo.idx,
    deviceType: tabInfo.deviceType,
    status: tabInfo.status,
    region: tabInfo.region,
    platformVersion: tabInfo.platformVersion,
    tabCode: tabInfo.tabCode,
    type: tabInfo.type,
    skeletonType: tabInfo.skeletonType,
    locationType: tabInfo.locationType,
    categoryFlag: tabInfo.categoryFlag,
    randomOrderFlag: tabInfo.randomOrderFlag,
    themeApply: tabInfo.themeApply ? tabInfo.themeApply : 'N',
    bgImg: tabInfo.bgImg,
    bgImgDelete: 'N',
    tabImg: tabInfo.tabImg,
    tabImgDelete: 'N',
    title: tabInfo.title ? tabInfo.title : '',
    description: tabInfo.description ? tabInfo.description : '',
    useFlag: tabInfo.useFlag,
  });

  // 해당 Tab의 Multi Language 정보를 저장
  const [editLangList, setEditLangList] = React.useState([{ langCode: 'en', name: '' }]);
  // Tab Image 임시저장 객체
  const [tabImageTemp, setTabImageTemp] = useState(null);
  const [tabImageActualSize, setTabImageActualSize] = useState({ width: 0, height: 0 });
  // Background Image 임시저장 객체
  const [bgImageTemp, setBgImageTemp] = useState(null);
  const [bgImageActualSize, setBgImageActualSize] = useState({ width: 0, height: 0 });

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  // Save버튼 클릭 시 탭 생성
  const clickSave = async event => {
    event.preventDefault();

    // EN 코드의 name을 탭 명으로 사용
    // EN 코드 name 유효성 검사
    const englishName = editLangList.filter(n => n.langCode === 'en');
    if (!englishName[0].name) {
      alert('Please enter the English tab name.');
      return;
    }
    // Tab Code 공백 체크
    var pattern = /\s/g;
    if (editTab.tabCode.match(pattern)) {
      alert('Tab Code에 공백이 존재합니다.');
      return;
    }
    // 동일한 MultiLanguage Code 가 존재할 경우
    const langCodes = editLangList.map(n => {
      return n.langCode;
    })
    const duplicateLangCodeCheck = langCodes.filter((n, i) => langCodes.indexOf(n) !== i);
    if (duplicateLangCodeCheck.length > 0) {
      alert('The same language code cannot be used. : ' + duplicateLangCodeCheck[0]);
      return;
    }

    const tabData = { ...editTab, tabName: englishName[0].name };
    const res = await saveEditTab(tabData, editLangList);

    handleClose();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    callTabCategoryList();
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: clickSave,
    },
  ];

  useEffect(() => {
    getTabMultiLanguage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

  }, [editLangList]);

  // 저장된 tab image 랜더링 시 사이즈 체크
  useEffect(() => {
    const img = new Image();
    img.src = editTab.tabImg;
    img.onload = () => {
      setTabImageActualSize({ width: img.width, height: img.height });
    };
  }, [editTab.tabImg]);

  // 저장된 tab image 랜더링 시 사이즈 체크
  useEffect(() => {
    const img = new Image();
    img.src = editTab.bgImg;
    img.onload = () => {
      setBgImageActualSize({ width: img.width, height: img.height });
    };
  }, [editTab.bgImg]);

  // 해당 탭의 MultiLanguage 정보 호출
  const getTabMultiLanguage = async () => {
    const body = await getRequest(`/home-app/tab/${tabInfo.idx}`, null);
    if (body && body.result === 'SUCCESS') {
      if (body.data) {
        if (body.data.tabMultiLangs.length > 0) {
          // 탭의 다국어 정보 호출 시 기 등록된 정보가 있을때만 editLangList에 적재
          setEditLangList(body.data.tabMultiLangs);
        }
      }
    }
  };


  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 Start */
  // Tab MultiLanguage 값
  const onChangeMultiLang = (i, type, value) => {
    let newData = [...editLangList];
    newData[i][type] = value;
    setEditLangList(newData);
  };
  // Tab Type 값
  const onChangeType = (value) => {
    setEditTab((pevState) => ({
      ...pevState,
      type: value,
    }));
  };
  // Tab LocationType 값
  const onChangeLocationType = (value) => {
    setEditTab((pevState) => ({
      ...pevState,
      locationType: value,
    }));
  };
  // Tab SkeletonType 값
  const onChangeSkeletonType = (value) => {
    setEditTab((pevState) => ({
      ...pevState,
      skeletonType: value,
    }));
  };
  // Tab enable 값
  const onChangeEnable = (checked) => {
    const value = checked ? "Y" : "N";
    setEditTab((pevState) => ({
      ...pevState,
      useFlag: value,
    }));
  };
  // Tab randomOrderFlag 값
  const onChangeRandomOrderFlag = (checked) => {
    const value = checked ? "Y" : "N";
    setEditTab((pevState) => ({
      ...pevState,
      randomOrderFlag: value,
    }));
  };
  // Tab categoryFlag 값
  const onChangeCategoryFlag = (checked) => {
    const value = checked ? "Y" : "N";
    setEditTab((pevState) => ({
      ...pevState,
      categoryFlag: value,
    }));
  };
  // Tab themeApply 값
  const onChangeThemeApply = (checked) => {
    const value = checked ? "Y" : "N";
    setEditTab((pevState) => ({
      ...pevState,
      themeApply: value,
    }));
  };
  // Tab title 값
  const onChangeTitle = (event) => {
    const value = event.target.value;
    setEditTab((pevState) => ({
      ...pevState,
      title: value,
    }));
  };
  // Tab description 값
  const onChangeDescription = (event) => {
    const value = event.target.value;
    setEditTab((pevState) => ({
      ...pevState,
      description: value,
    }));
  };
  // Tab Image 값
  const onChangeTabImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTabImageTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setTabImageActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setEditTab((prevState) => ({
      ...prevState,
      tabImg: file,
      tabImgDelete: 'N',
    }));
  };
  // Background Image 값
  const onChangeBgImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBgImageTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setBgImageActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setEditTab((prevState) => ({
      ...prevState,
      bgImg: file,
      bgImgDelete: 'N',
    }));
  };
  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 End */

  const fileNameParsing = (imageUrl) => {
    const params = new URLSearchParams(imageUrl);
    const filePath = params.get('file_path');
    const imageName = filePath ? filePath.split('/').pop() : '';
    return imageName;
  }

  const removeTabImage = () => {
    setEditTab((prevState) => ({
      ...prevState,
      tabImg: '',
      tabImgDelete: 'Y',
    }));
    setTabImageTemp('');
    setTabImageActualSize({ width: 0, height: 0 });
  }
  const removeBgImage = () => {
    setEditTab((prevState) => ({
      ...prevState,
      bgImg: '',
      bgImgDelete: 'Y',
    }));
    setBgImageTemp('');
    setBgImageActualSize({ width: 0, height: 0 });
  }
  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Tab Detail' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            {/* Tab Name */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12} mb={1}>
                  <List className='info-list-container'>
                    <React.Fragment key='1'>
                      <ListItem className='list-item'>
                        <Typography className='label'>Region</Typography>
                        <Typography className='value'>{editTab.region}</Typography>
                      </ListItem>
                      {true && <Divider className='divider' />}
                    </React.Fragment>
                    <React.Fragment key='2'>
                      <ListItem className='list-item'>
                        <Typography className='label'>Device Type</Typography>
                        <Typography className='value'>{editTab.deviceType}</Typography>
                      </ListItem>
                      {true && <Divider className='divider' />}
                    </React.Fragment>
                    <React.Fragment key='3'>
                      <ListItem className='list-item'>
                        <Typography className='label'>Platform Version</Typography>
                        <Typography className='value'>{editTab.platformVersion}</Typography>
                      </ListItem>
                      {true && <Divider className='divider' />}
                    </React.Fragment>
                  </List>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Enable */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Enable
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={editTab.useFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeEnable}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={editTab.useFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography className='field-label bold required'>Tab Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  {editLangList.map((n, i) => (
                    <Box component='div' className='field-box flex-start' mb={1}>
                      <SelectBox
                        id='select-tab-name-region-3'
                        fullWidth
                        size='large'
                        maxWidth='170px'
                        required={true}
                        selectOptions={multiLangOptions}
                        disabled={i === 0 ? true : false}
                        label=''
                        selected={n.langCode}
                        onChange={e => {
                          onChangeMultiLang(i, 'langCode', e);
                        }}
                        shrink
                      />
                      <TextField
                        type='outlined'
                        size='large'
                        required={false}
                        placeholder='Input Tab Name'
                        value={n.name}
                        label=''
                        autoComplete='off'
                        onChange={e => {
                          onChangeMultiLang(i, 'name', e.target.value);
                        }}
                        InputLabelProps={{ shrink: false }}
                      />
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={i === 0 ? AddPlus : DelMinus}
                        iconPos='center'
                        onClick={() => {
                          i === 0 ?
                          setEditLangList(editLangList.concat({ langCode: 'en', name: '' }))
                          :
                          setEditLangList(editLangList.filter((n2, i2) => i2 !== i))
                        }}
                        autosize
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box flex-start'>
                    <SelectBox
                      id='select-04'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabTypeOptions}
                      disabled={false}
                      required={true}
                      label='Type'
                      isFirstSelected={true}
                      selected={editTab.type}
                      onChange={onChangeType}
                      shrink
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} mb={1}>
                  {/* Location Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabLocationTypeOptions}
                      disabled={false}
                      required={true}
                      label='Location Type'
                      isFirstSelected={true}
                      selected={editTab.locationType}
                      onChange={onChangeLocationType}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' sx={{ width: '90px' }}>
                      Random
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={editTab.randomOrderFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeRandomOrderFlag}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={editTab.randomOrderFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Skeleton Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabSkeletonTypeOptions}
                      disabled={false}
                      required={true}
                      label='Skeleton Type'
                      selected={editTab.skeletonType}
                      onChange={onChangeSkeletonType}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Category Flag */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Category Flag
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={editTab.categoryFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeCategoryFlag}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={editTab.categoryFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} mb={1}>
                  <Box className='field-box'>
                    <FileUpload
                      id='tabImg'
                      buttonLabel='Upload'
                      size='large'
                      label='Tab Icon'
                      placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                      onFileChange={(file) => onChangeTabImageUpload(file)}
                      accept='.jpg,.png,'
                      isSubText
                      subText=''
                      value={ editTab.tabImg.name || fileNameParsing(editTab.tabImg) }
                      InputLabelProps={{ shrink: true }}
                    />
                    <Typography component='p' className='upload-info-text'>
                      Recommended Size : <strong>78 x 78</strong> , Actual Image Size: {' '}
                      <strong style={{ color: 'red' }}>
                        {`${tabImageActualSize.width}px X ${tabImageActualSize.height}px`}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  {/* 업로드 정보 */}
                  <Box className='upload-box'>
                    <div className='upload-total-count'>Upload File</div>
                    {uploadedFiles.map((file, i) => (
                      <Box component='div' className='upload-file-list' key={`uplload-field-${i}`}>
                        <Box className='box-content' display='flex'>
                          <Box className='left-area'>
                            <CustomImage
                              src={tabImageTemp ? tabImageTemp : editTab.tabImg}
                              fit={false}
                              wrapWidth='120px'
                              wrapHeight='64px'
                              alt=''
                              rounded
                            />
                          </Box>
                          <Box className='right-area'>
                            <Box component='div' className='upload-info-wrap'>
                              <Typography component='p' className='file-name'>
                                {/* file.name */}
                              </Typography>
                              <Box component='ul' className='file-list-area'>
                                <Box component='li' className='item'>
                                  Recommended Size : 78 x 78{' '}
                                </Box>
                                <Box component='li' className='item'>
                                  Actual Image Size: 78 x 78
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <IconButton className='btn-close'>
                          <CloseIcon24Black
                            onClick={() => {
                              removeTabImage();
                            }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Theme */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <h3 className='sub-title'>Theme</h3>
                </Grid>
                <Grid item xs={12} mb={1}>
                  {/* Theme Apply */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Theme Apply
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={editTab.themeApply === 'Y' ? true : false}
                      setSwitchOnOff={onChangeThemeApply}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={editTab.themeApply === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
                {/* Title */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Title'
                      defaultValue={editTab.title}
                      onChange={onChangeTitle}
                      label='Title'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
                {/* Description */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Description'
                      defaultValue={editTab.description}
                      onChange={onChangeDescription}
                      label='Description'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} mb={1}>
                <Box className='field-box'>
                  <FileUpload
                    id='bgImg'
                    buttonLabel='Upload'
                    size='large'
                    label='Background Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    onFileChange={(file) => onChangeBgImageUpload(file)}
                    accept='.jpg,.png,'
                    isSubText
                    subText=''
                    value={ editTab.bgImg.name || fileNameParsing(editTab.bgImg) }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong> , Actual Image Size: {' '}
                      <strong style={{ color: 'red' }}>
                        {`${bgImageActualSize.width}px X ${bgImageActualSize.height}px`}
                      </strong>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* 업로드 정보 */}
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {themeUploadedFiles.map((file, i) => (
                    <Box component='div' className='upload-file-list' key={`uploaded-background-${i}`}>
                      <Box className='box-content' display='flex'>
                        <Box className='left-area'>
                          <CustomImage
                            src={bgImageTemp ? bgImageTemp : editTab.bgImg}
                            fit={false}
                            wrapWidth='280px'
                            wrapHeight='64px'
                            alt=''
                            rounded
                          />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {/* file.name */}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: 78 x 78
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close'>
                        <CloseIcon24Black
                          onClick={() => {
                            removeBgImage();
                          }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditTab;
