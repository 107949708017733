import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Pagination from 'components/component/BasicPagination';
import {Box, Dialog} from '@mui/material';
import ExtraFilter from './component/ExtraFilter';
import VodTable from './component/VodTable';
import {MetaVodTh} from './data/MetaData';
import {BASE_URL} from 'constants/index';
import {getHeaders} from 'utils/Header';
import {RefreshIcon} from 'components/component/BasicIcon';
import {useNavigate} from "react-router-dom";
import SelectBox from "components/component/BasicSelectBox";
import TextField from "components/component/BasicTextField";
import CustomButtonGroup from "components/component/BasicButtonGroup";
import Loading from 'components/component/Loading';

function MetadataVod (props) {
  const navigate = useNavigate();
  const {serviceCountry, providerByCountry, type, message, preferredCountry, tabValue} = props;

  // filter values
  const [filterRegionList, setFilterRegionList] = useState([]);
  const [filterCountryList, setFilterCountryList] = useState([]);
  const [filterProviderList, setFilterProviderList] = useState([]);
  const [filterContentTypeList, setFilterContentTypeList] = useState([]);

  // selected filter values
  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState(preferredCountry)
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedContentType, setSelectedContentType] = useState('tvshow');
  const [selectedVodId, setSelectedVodId] = useState('');
  const [selectedVodTitle, setSelectedVodTitle] = useState('');

  // original data
  const [originalDataList, setOriginalDataList] = useState([]);

  // display data
  const [dataList, setDataList] = useState([]);

  // 화면에 보여 줄 데이터가 없는 경우
  const [isEmptyData, setEmptyData] = useState(false);

  // 검색에서 입력한 값
  const [searchTerm, setSearchTerm] = useState('');

  // 로딩바를 보여 줄 때 사용
  const [isDisplayData, setDisplayData] = useState(false);

  // popup
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // paging
  const [paginatedData, setPaginatedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // 타이밍 이슈로 인해서 예외 처리 추가
  const [isAfterFirstRequest, setAfterFirstRequest] = useState(false);

  useEffect(() => {
    if (message) {
      if (message.filters) {
        setSelectedRegion(message ? message.filters.selectedRegion : selectedRegion);
        setSelectedCountry(message ? message.filters.selectedCountry : selectedCountry);
        setSelectedProvider(message ? message.filters.selectedProvider : selectedProvider);
        setSelectedContentType(message ? message.filters.selectedContentType : selectedContentType);
      }
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (providerByCountry && serviceCountry) {
      const updatedRegionList = ['All', ...Array.from(
        new Set(serviceCountry.map(item => item.ricCode))).sort()];
      const updatedSelectRegionList = updatedRegionList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      setFilterRegionList(updatedSelectRegionList);

      const updatedCountryList = providerByCountry.map(
          item => item.countryCode).sort();
      const updatedSelectCountryList = updatedCountryList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      setFilterCountryList(updatedSelectCountryList);

      let tempProviderList = providerByCountry.find(
          item => item.countryCode === preferredCountry)?.providerList.map(
          item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);
      setSelectedProvider(updatedProviderList[0].value);

      setFilterContentTypeList(['tvshow', 'movie', 'livestream'].map(
        item => ({id: item, value: item, option: item, type: 'text'})));
    }
    // eslint-disable-next-line
  }, [providerByCountry, serviceCountry]);

  useEffect(() => {
    if (filterRegionList.length > 0 && filterCountryList.length > 0 && filterProviderList.length > 0 && !isAfterFirstRequest) {
      searchMetadata();
      setAfterFirstRequest(true);
    }
    // eslint-disable-next-line
  }, [filterRegionList, filterCountryList, filterProviderList]);

  useEffect(() => {
    if (selectedRegion && serviceCountry) {
      let tempCountryList = [];

      if (selectedRegion === 'All') {
        tempCountryList = serviceCountry.map(item => item.countryCode).sort();
      } else {
        const tempRegion = message?.filters?.selectedRegion ? message?.filters?.selectedRegion : selectedRegion;

        tempCountryList = serviceCountry.filter(
          item => item.ricCode === tempRegion).map(item => item.countryCode).sort();
      }

      setFilterCountryList(tempCountryList.map(
          item => ({id: item, value: item, option: item, type: 'text'})));

      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedRegion === 'All' ? selectedCountry : tempCountryList[0];
      setSelectedCountry(tempCountry);
    }
    // eslint-disable-next-line
  }, [selectedRegion, serviceCountry]);

  useEffect(() => {
    if (selectedCountry && providerByCountry) {
      const tempCountry = message?.filters?.selectedCountry ? message?.filters?.selectedCountry : selectedCountry;

      const tempProviderList = providerByCountry.find(
        item => item.countryCode === tempCountry)?.providerList.map(
        item => item.providerName).sort();

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text'
        }))
      ];
      setFilterProviderList(updatedProviderList);

      const tempProvider = message?.filters?.selectedProvider ? message?.filters?.selectedProvider : updatedProviderList[0].value;
      setSelectedProvider(tempProvider);
    }
    // eslint-disable-next-line
  }, [selectedCountry, providerByCountry]);

  useEffect(() => {
    if (searchTerm) {
      const excludeKeyList = ['logoUrl', 'representativeImageList'];

      const filteredData = originalDataList.filter((item) => {
        return Object.entries(item)
          .filter(([key, value]) => !excludeKeyList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(searchTerm));
      });

      setDataList([...filteredData]);
      if (filteredData.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    } else {
      setDataList([...originalDataList]);
      if (originalDataList.length === 0) {
        setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      } else {
        setEmptyData(false);
      }
    }
  }, [searchTerm, originalDataList]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = dataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayData(true);
    }
  }, [dataList, page, itemsPerPage]);

  useEffect(() => {
    if (isEmptyData) {
      setPaginatedData([]);
    }
  }, [isEmptyData]);

  const searchMetadata = () => {
    setDisplayData(false);
    setPage(1);
    setDataList([]);
    setOriginalDataList([]);
    let param = `countryCode=${selectedCountry}&providerName=${selectedProvider}&contentType=${selectedContentType}&vodId=${selectedVodId}&vodTitle=${selectedVodTitle}`;
    let url = `${BASE_URL}/metadata/vods?${param.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setOriginalDataList(body.data);

        if (body.data && body.data.length === 0) {
          setEmptyData(true);
          setDisplayData(true);
        }
      } else {
        throw new Error(`[Metadata] vod request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  };

  const handleReset = () => {
    if (message?.filters) {
      message.filters = null;
    }

    setSelectedRegion('All');
    setSelectedCountry(preferredCountry);
    setSelectedProvider('All');
    setSelectedContentType('tvshow');
    setSelectedVodId('');
    setSelectedVodTitle('');
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
      onClick: handleReset,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: searchMetadata,
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectedRegion = (region) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedRegion(region);
  };

  const handleSelectedCountry = (country) => {
    if (message?.filters) {
      message.filters = null;
    }

    const tempProviderList = providerByCountry.find(
      item => item.countryCode === country)?.providerList.map(
      item => item.providerName).sort();

    const updatedProviderList = [
      ...tempProviderList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      }))
    ];
    setFilterProviderList(updatedProviderList);

    setSelectedProvider(tempProviderList[0].providerName);

    setSelectedCountry(country);
  };

  const handleSelectedProvider = (provider) => {
    if (message?.filters) {
      message.filters = null;
    }
    setSelectedProvider(provider);
  };

  const handleSelectedContentType = (value) => {
    setSelectedContentType(value);
  };

  const handleSelectedVodId = (value) => {
    setSelectedVodId(value);
  };

  const handleSelectedVodTitle = (value) => {
    setSelectedVodTitle(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleAction = (contentId) => {
    const navigateTo = type === 'common' ? '/Metadata' : '/MetadataEdit';
    const targetComponent = type === 'common' ? 'MetadataVodDetail' : 'MetadataVodEdit';
    navigate(navigateTo,
    {
      replace: true,
      state: {
        move: true,
        targetComponent: targetComponent,
        message: {
          contentId: contentId,
          countryCode: selectedCountry,
          tabValue: tabValue,
          type: type,
          filters: {
            selectedRegion: selectedRegion,
            selectedCountry: selectedCountry,
            selectedProvider: selectedProvider,
            selectedContentType: selectedContentType,
          }
        }
      }
    });
  };

  return (
    <>
      <div className='tab-panel'>
        <div className='panel-wrap'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-6'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-filter-region'
                      size='large'
                      shrink={true}
                      label='Region'
                      required={false}
                      selectOptions={filterRegionList}
                      disabled={false}
                      onChange={value => handleSelectedRegion(value)}
                      selected={selectedRegion}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-filter-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={filterCountryList}
                      disabled={false}
                      onChange={value => handleSelectedCountry(value)}
                      selected={selectedCountry}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-filter-cp-name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      selectOptions={filterProviderList}
                      disabled={false}
                      onChange={value => handleSelectedProvider(value)}
                      selected={selectedProvider}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-filter-content-type'
                      size='large'
                      shrink={true}
                      label='Type'
                      selectOptions={filterContentTypeList}
                      disabled={false}
                      onChange={value => handleSelectedContentType(value)}
                      selected={selectedContentType}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='vod-filter-vod-id'
                      type='outlined'
                      size='large'
                      placeholder='VOD ID'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{shrink: false}}
                      value={selectedVodId}
                      onChange={value => handleSelectedVodId(value.target.value)}
                    />
                  </Box>
                  <Box component='div' className='col'>
                    <TextField
                      id='vod-filter-vod-title'
                      type='outlined'
                      size='large'
                      placeholder='VOD Title'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{shrink: false}}
                      value={selectedVodTitle}
                      onChange={value => handleSelectedVodTitle(value.target.value)}
                    />
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons}/>
              </div>
            </Box>
          </Box>
          {isDisplayData ? (
            <Box className='white-box white-box-wrap'>
              <div className='box-content'>
                <ExtraFilter
                  total={dataList.length}
                  data={originalDataList}
                  type='vod'
                  searchPlaceholder='Please enter a search term'
                  onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
                />
                <Box className='content-inner-wrap'>
                  <VodTable
                    columns={MetaVodTh}
                    rows={paginatedData}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    type={type}
                    handleAction={handleAction}
                  />
                </Box>
                <Pagination
                  id='pagination-01'
                  count={Math.ceil(dataList.length / itemsPerPage)}
                  selected={itemsPerPage}
                  onChangeHandle={handlePagination}
                  page={page}
                />
              </div>
            </Box>
          ) : (
            <Loading/>
          )}
        </div>
      </div>
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </>
  );
}

const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
  providerByCountry: state.common.provider.country,
});

export default connect(mapStateToProps)(MetadataVod);