import React, { useEffect, useState } from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import Loading from 'components/component/Loading';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

import {
  Box,
  Grid,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import { downloadZip } from 'pages/Main/Support/DownloadAll';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

const StatusOption = [
  { id: 'Saved-Testing', value: 'S-T', option: 'Saved-Testing', type: 'text' },
  { id: 'Saved-Published', value: 'S-P', option: 'Saved-Published', type: 'text' },
  { id: 'Testing', value: 'T', option: 'Testing', type: 'text' },
  { id: 'Published', value: 'P', option: 'Published', type: 'text' },
];

const SnapshotTitle = [
  {
    id: 'checkbox',
    align: 'left',
    label: '',
    width: '10%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'lastChgDate',
    align: 'left',
    label: 'Change Date',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastModifier',
    align: 'left',
    label: 'Last Modifier',
    width: '50%',
    checkbox: false,
    sortable: true,
  }
];

function PopSnapshotDownload(props) {
  const { close, type, country, channelMapFilter, deviceType, region, platformVersionFilter } = props;

  const [statusFilter, setStatusFilter] = useState([]);
  const [secondFilter, setSecondFilter] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedSecond, setSelectedSecond] = useState('');

  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(7, 'day')); // 7일 전
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  // 서버에서 받은 원본 데이터
  const [originalSnapshotData, setOrinalSnapshotData] = useState([]);

  // 화면에서 사용할 데이터
  const [displaySnapshotData, setDisplaySnapshotData] = useState([]);

  // 팝업창에서 사용
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 서버에서 가져온 데이터가 없는 경우 체크
  const [isEmptyData, setEmptyData] = useState(false);

  // 로딩바를 보여 줄 때 사용
  const [isDisplayFlag, setDisplayFlag] = useState(false);

  useEffect(() => {
    setStatusFilter([...StatusOption]);
    setSelectedStatus(StatusOption[0].value);

    if (type === 'channelMap') {
      setSecondFilter([...channelMapFilter]);
      setSelectedSecond(channelMapFilter[0].value);
    } else if (type === 'tabCategory') {
      setSecondFilter([...platformVersionFilter]);
      setSelectedSecond(platformVersionFilter[0].value);
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    setDisplaySnapshotData([...originalSnapshotData]);
    if (originalSnapshotData.length === 0) {
      setEmptyData(true);  // 화면에 보여 줄 것이 없을 때
      setDisplayFlag(true);
    } else {
      setEmptyData(false);
    }
  }, [originalSnapshotData]);

  // Paging 처리
  useEffect(() => {
    if (displaySnapshotData && displaySnapshotData.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = displaySnapshotData.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setDisplayFlag(true);
    }
  }, [displaySnapshotData, page, itemsPerPage]);

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...displaySnapshotData].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDisplaySnapshotData([...sortedData]);
  };

  const handleSearch = () => {
    let apiUrl = '';
    setPage(1);
    setDisplayFlag(false);
    setEmptyData(false);

    if (type === 'channelMap') {
      const param = `country=${country}&status=${selectedStatus}&channelMapId=${selectedSecond}&startDate=${selectedStartDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate.format('YYYY-MM-DD')}`;
      apiUrl = `${BASE_URL}/snapshot/channel-map-snapshot-list?${param}`;
    } else if (type === 'tabCategory') {
      const param = `deviceType=${deviceType}&region=${region}&status=${selectedStatus}&platformVersion=${selectedSecond}&startDate=${selectedStartDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate.format('YYYY-MM-DD')}`;
      apiUrl = `${BASE_URL}/snapshot/tab-category-snapshot-list?${param}`;
    } else {
      console.error('Unknown type');
      return;
    }

    fetch(apiUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const modifySnapshotList = body.data.snapshotList.map(item => ({
          ...item,
          checked: false
        }));
        setOrinalSnapshotData([...modifySnapshotList]);
      } else {
        throw new Error(`[Snapshot] ${apiUrl} request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
      setDisplayFlag(true);
    });
  };

  // 상단에 있는 전체 check box 선택 toggle
  const handleAllChecked = (event) => {
    setDisplaySnapshotData((prev) =>
      prev.map((item) => ({
        ...item,
        checked: event.target.checked
      }))
    );
  };

  // Check box 선택
  const handleCheckedItem = (event, snapshotId) => {
    setDisplaySnapshotData((prev) =>
      prev.map((item) =>
        item.snapshotId === snapshotId ? { ...item, checked: event.target.checked } : item
      )
    );
  };

  const handleClose = () => {
    close();
  };

  const handleDownload = async () => {
    const checkedItem = displaySnapshotData.filter(item => item.checked);

    if (checkedItem.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select the snapshot to download.');
      setAlertOpen(true);
      return;
    }

    const files = checkedItem.map((item, index) => ({
      id: index,
      fileName: item.snapshotUrl.split('/').pop().trim(),
      filePath: item.snapshotUrl,
    }));

    close();

    const response = await downloadZip(files);

    if (response !== 'SUCCESS') {
      setAlertTitle('Warning');
      setAlertMessage(response);
      setAlertOpen(true);
    }
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Download',
      type: 'box',
      color: 'primary',
      onClick: handleDownload
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Snapshot Download' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='large' // large, medium, small
        contentTitle={type === 'channelMap' ? `Country: ${country}` : `Device Type: ${deviceType} Region: ${region}`}
        bottomDivider={true}
        className=''
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} mb={1}>
                <Box className='field-wrap' display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Box display={'flex'} gap={1}>
                    <Box component='div' className='field-box' width={160}>
                      <SelectBox
                        id='Status'
                        fullWidth
                        size='large'
                        label='Status'
                        required={false}
                        selectOptions={statusFilter}
                        disabled={false}
                        selected={selectedStatus}
                        onChange={setSelectedStatus}
                      />
                    </Box>
                    <Box component='div' className='field-box' width={160}>
                      <SelectBox
                        id='SecondFilter'
                        fullWidth
                        size='large'
                        label={type === 'channelMap' ? 'Channel Map' : 'Platform Version'}
                        required={false}
                        selectOptions={secondFilter}
                        disabled={false}
                        selected={selectedSecond}
                        onChange={setSelectedSecond}
                      />
                    </Box>
                    <Box component='div' className='field-box'>
                      <DatePicker
                        className='input-date-picker'
                        format='MM/DD/YYYY'
                        size='large'
                        maxWidth='210px'
                        singleDate={false}
                        rangeDate={true}
                        wave={true}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        onChangeStartDate={setSelectedStartDate}
                        onChangeEndDate={setSelectedEndDate}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Button color='primary' type='box' size='medium' label='Search' isIcon={false} onClick={handleSearch}/>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {isDisplayFlag ? (
              <Box mt={2} className='snapshot-pop'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {SnapshotTitle.map((column, index) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                <Box
                                  component='div'
                                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                >
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                    style={{ width: '24px' }}
                                    checked={displaySnapshotData.length > 0 && displaySnapshotData.every((item) => item.checked)}
                                    onChange={(event) => handleAllChecked(event)}
                                  />
                                </Box>
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {isEmptyData ? (
                        <NoRecords>
                          No records to display
                        </NoRecords>
                      ) : (
                        paginatedData.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {SnapshotTitle.map((column, colIndex) => (
                              <TableCell key={column.id} className='td' align={column.align}>
                                {colIndex === 0 ? (
                                  <CheckBox
                                    label=''
                                    value='value'
                                    defaultChecked={false}
                                    checked={td.checked}
                                    onChange={(event) => handleCheckedItem(event, td.snapshotId)}
                                />
                                ) : colIndex === 1 ? (
                                  td.lastChgDate
                                ) : colIndex === 2 ? (
                                  td.lastModifier
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                <Pagination count={Math.ceil(displaySnapshotData.length / itemsPerPage)} id='pagination-01' selected={itemsPerPage} onChangeHandle={handlePagination} page={page} />
              </Box>
            ) : (
              <Loading />
            )}
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
    </div>
  );
}

export default PopSnapshotDownload;
