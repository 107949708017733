import React, { useState, useEffect, useRef, useCallback, createContext, memo } from 'react';
import { FixedSizeList as List } from 'react-window';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextLink from 'components/component/BasicTextLink';
import SelectBox from 'components/component/BasicSelectBox';
import BasicSwitches from 'components/component/BasicSwitch';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextField from 'components/component/BasicTextField';
import CustomList from 'components/component/BasicList';
import CustomImage from 'components/component/BasicImage';
import Tag from 'components/component/BasicTag';
import SearchField from 'components/component/BasicSearchField';
import BasicDialog from 'components/component/BasicDialog';
import SortLabel from 'components/component/BasicTableSortLabel';
import PopChannelAdd from 'pages/Organization/ChannelMapping/PopChannelAdd';
import PopCheckDialog from 'pages/Organization/ChannelMapping/PopCheckDialog';
import PopDMAGroup from 'pages/Organization/ChannelMapping/PopDMAGroupNameOutput';

import { Box, Typography, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { getCountry } from 'actions/common/countryActions';
import { getRequest, postRequest } from 'utils/Request';
import { BtnArrowRight } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/Valid';
import { defaultPopupType, FeedChannelHeader, SavedChannelHeader } from 'pages/Organization/ChannelMapping/data/DefaultData';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

// GNB 상태 감지를 위한 Context 추가
const GnbContext = createContext();

// 디바운스 유틸리티 함수 추가
const debounce = (func, wait) => {
  let timeout;
  const debouncedFunction = (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
  debouncedFunction.cancel = () => clearTimeout(timeout);
  return debouncedFunction;
};

function ChannelMapping({ getCountry, country }) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [popupType, setPopupType] = useState(defaultPopupType);
  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
  };

  // thead 관련 state와 ref
  const [tableWidth, setTableWidth] = useState(0);
  const [thWidths, setThWidths] = useState({});
  const tableHeadRef = useRef(null);
  const containerRef = useRef(null);
  const [listHeight, setListHeight] = useState(700); // 초기값 설정
  const [isOpenArray, setIsOpenArray] = useState([true, true, true]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // th 너비 계산 함수
  const calculateThWidths = useCallback(() => {
    if (tableHeadRef.current) {
      const thElements = tableHeadRef.current.querySelectorAll('.th');
      const widths = {};
      thElements.forEach((th, index) => {
        widths[index] = th.getBoundingClientRect().width;
      });
      setThWidths(widths);
    }
  }, []);

  // thead 전체 너비 계산 함수
  const calculateTableWidth = useCallback(() => {
    if (tableHeadRef.current) {
      const width = tableHeadRef.current.getBoundingClientRect().width;
      setTableWidth(width);
      calculateThWidths();
    }
  }, [calculateThWidths]);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTableWidth();
      setTableWidth((prev) => {
        const current = tableHeadRef.current?.getBoundingClientRect().width || prev;
        return current;
      });
    }, 100);
    const debouncedResize = debounce(() => {
      calculateTableWidth();
    }, 200);
    window.addEventListener('resize', debouncedResize);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel();
    };
  }, [drawerOpen, calculateTableWidth]);

  // ---------------------------- sorting ----------------------------

  const togglePanel = useCallback(
    (index) => {
      setIsOpenArray((prev) => prev.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
      // debounce된 업데이트 함수 사용
      const debouncedUpdate = debounce(() => {
        calculateThWidths();
        calculateTableWidth();
      }, 100);
      debouncedUpdate();
      return () => {
        debouncedUpdate.cancel();
      };
    },
    [calculateThWidths, calculateTableWidth]
  );

  const channelMapStatusList = [
    { id: 'statusT', value: 'T', option: 'Saved - Testing', type: 'text' },
    { id: 'statusP', value: 'P', option: 'Saved - Published', type: 'text' },
  ];

  // ---------------------------- Init ----------------------------
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        setListHeight(containerHeight - 60); // 헤더 높이 빼기 (필요에 따라 조절)
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const feedChannelSort = (col, desc) => {
    setSortedFeedChannelRows(sortRows([...feedChannelList], col, desc));
  };

  const savedChannelSort = (col, desc) => {
    setSavedChannelMapChannelRows(sortRows([...savedChannelMapChannelRows], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // ---------------------------- Feed Info ----------------------------
  const [feedCountry, setFeedCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [feedProviderList, setFeedProviderList] = useState([]);
  const [selectedFeedProvider, setSelectedFeedProvider] = useState({ providerId: '', providerName: '' });
  const [feedChannelList, setFeedChannelList] = useState([]);
  const [feedChannelCount, setFeedChannelCount] = useState(0);
  const [sortedFeedChannelRows, setSortedFeedChannelRows] = useState([]);
  const [feedChannelCheckedList, setFeedChannelCheckedList] = useState([]);
  const [isEnableSwitchOn, setIsEnableSwitchOn] = useState(false);
  const [feedSearchField, setFeedSearchField] = useState('');
  const [isFeedChannelAll, setIsFeedChannelAll] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      getFeedProviderList(selectedCountry);
      let mapList = feedCountry.find((f) => f.id === selectedCountry).channelMapList;
      setChannelMapList(setChannelMapOption(mapList));
      if (!isEmpty(mapList)) {
        setSelectedChannelMap(mapList[0].channelMapId);
        setSelectedChannelMapStatus('T');
      }
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (isEmpty(country)) {
      getCountry();
    } else {
      let countryFilter = country.serviceCountry.map((n) => {
        const { countryCode, channelMapList } = n;
        return {
          id: countryCode,
          value: countryCode,
          option: countryCode,
          type: 'text',
          channelMapList: channelMapList,
        };
      });
      setFeedCountry(countryFilter);
      if (!isEmpty(countryFilter)) {
        setSelectedCountry('US');
      }
      let mapList = country.serviceCountry.find((f) => f.countryCode === 'US').channelMapList;
      setChannelMapList(setChannelMapOption(mapList));
    }
    // eslint-disable-next-line
  }, [country]);

  const getFeedProviderList = async (country) => {
    const body = await getRequest(`/channel-map/feed-provider?countryCode=${country}`, null);
    if (body && body.result === 'SUCCESS') {
      let feedList = body.data.map((item, index) => {
        const { providerIdx, serviceProviderId, providerId, providerName, rcvDate } = item;
        if (index === 0) {
          setSelectedFeedProvider({ providerId: providerId, providerName: providerName });
        }
        return {
          id: providerIdx,
          value: providerId,
          primary: !isEmpty(serviceProviderId) ? `[${serviceProviderId}-${providerId}] ${providerName}` : `[${providerId}] ${providerName}`,
          secondary: rcvDate,
          checked: index === 0 ? true : false,
          providerId: providerId,
          providerName: providerName,
        };
      });
      setFeedProviderList(feedList);
    }
  };

  const setSelectedFeedProviderHandler = (providerId) => {
    if (!isEmpty(providerId) && !isEmpty(feedProviderList)) {
      let feedProvider = feedProviderList.find((f) => f.providerId === providerId);
      setSelectedFeedProvider({ providerId: feedProvider.providerId, providerName: feedProvider.providerName });
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedFeedProvider)) {
      getFeedProviderChannelList(selectedFeedProvider.providerId, selectedCountry);
    }
    // eslint-disable-next-line
  }, [selectedFeedProvider.providerId]);

  const getFeedProviderChannelList = async (providerId, country) => {
    const body = await getRequest(`/channel-map/feed-channel?countryCode=${country}&providerId=${providerId}`, null);
    if (body && body.result === 'SUCCESS') {
      let channelList = body.data.map((item, index) => {
        const {
          channelIdx,
          channelId,
          channelName,
          channelNumber,
          logoUrl,
          genreName,
          countryCode,
          providerIdx,
          providerId,
          providerName,
          dmaRank,
          dmaGroupSet,
          dmaGroupCode,
          dmaGroupName,
          dmaFlag,
          dmaList,
        } = item;
        return {
          id: channelIdx,
          feedId: providerId.concat(channelIdx),
          channelIdx: channelIdx,
          channelId: channelId,
          channelNumber: channelNumber,
          channelName: channelName,
          logoUrl: logoUrl,
          providerIdx: providerIdx,
          providerId: providerId,
          providerName: providerName,
          genreName: genreName,
          dmaRank: dmaRank,
          dmaGroupSet: dmaGroupSet,
          dmaGroupCode: dmaGroupCode,
          dmaGroupName: dmaGroupName,
          dmaFlag: dmaFlag,
          dmaList: dmaList,
          countryCode: countryCode,
          isOrganized: false,
        };
      });
      setOrganizedSortedFeedChannelRows([...channelList], savedChannelMapChannelRows);
      setIsFeedChannelAll(false);
      setFeedChannelCheckedList([]);
      setIsEnableSwitchOn(false);
      setFeedSearchField('');
      setFeedChannelList([...channelList]);
    }
  };

  const setChannelMapOption = (mapList) => {
    return mapList.map((n) => {
      const { channelMapId } = n;
      return {
        id: channelMapId,
        value: channelMapId,
        option: channelMapId,
        type: 'text',
      };
    });
  };

  useEffect(() => {
    let contentList = [];
    let filteredData = [];
    if (isEnableSwitchOn) {
      contentList = [...feedChannelList].filter((feed) => !isOrganized(feed, savedChannelMapChannelRows));
    } else {
      contentList = [...feedChannelList];
    }

    if (!isEmpty(feedSearchField)) {
      const includeFieldList = ['channelNumber', 'channelName', 'channelId'];
      let text = feedSearchField.toLowerCase();
      filteredData = contentList.filter((item) => {
        return Object.entries(item)
          .filter(([key]) => includeFieldList.includes(key))
          .values(item)
          .some((value) => String(value).toLowerCase().includes(text));
      });
    } else {
      filteredData = [...contentList];
    }
    setSortedFeedChannelRows([...filteredData]);
    // eslint-disable-next-line
  }, [feedSearchField, isEnableSwitchOn]);

  const isOrganized = (feedRow, savedRows) => {
    return !isEmpty(savedRows.find((saved) => feedRow.channelId === saved.channelId));
  };

  useEffect(() => {
    getFeedChannelRowCount(sortedFeedChannelRows);
    // eslint-disable-next-line
  }, [sortedFeedChannelRows]);

  const getFeedChannelChecked = (feedId) => {
    if (isEmpty(feedChannelCheckedList)) {
      return false;
    } else {
      return feedChannelCheckedList.includes(feedId);
    }
  };

  const setFeedChannelAll = (e) => {
    if (!isEmpty(sortedFeedChannelRows) && !isEmpty(sortedFeedChannelRows.filter((f) => !f.isOrganized))) {
      if (e.target.checked) {
        setFeedChannelCheckedList(sortedFeedChannelRows.filter((f) => !f.isOrganized).map((f) => f.feedId));
      } else {
        setFeedChannelCheckedList([]);
      }
      setIsFeedChannelAll(e.target.checked);
    }
  };

  const setFeedChannelCheck = (e) => {
    e.preventDefault();
    let feedCheckList = [];
    if (!isEmpty(feedChannelCheckedList)) {
      feedCheckList = [...feedChannelCheckedList];
    }
    if (e.target.checked) {
      feedCheckList.push(e.target.value);
    } else {
      feedCheckList = feedCheckList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;
    let sortedFeed = [];

    if (!isEmpty(feedCheckList) && !isEmpty(sortedFeedChannelRows)) {
      sortedFeed = sortedFeedChannelRows.filter((f) => !f.isOrganized);
      if (feedCheckList.length === sortedFeed.length) {
        allCheck = true;
      }
    }
    setIsFeedChannelAll(allCheck);
    setFeedChannelCheckedList(feedCheckList);
  };

  const getFeedChannelRowCount = (feedChannelList) => {
    setFeedChannelCount(isEmpty(feedChannelList) ? 0 : [...feedChannelList].length);
  };

  const setFeedChannelRow = ({ index, style, data }) => {
    const row = sortedFeedChannelRows[index];
    return (
      <TableRow key={`feed-row-${row.feedId}`} style={{ ...style, display: 'flex' }} className={`custom-table-row${row.isOrganized ? ' tr disabled' : ''}`}>
        {FeedChannelHeader.map((column, colIndex) => (
          <TableCell key={`${column.id}-${row.feedId}`} align={column.align} sx={{ width: column.width }} className='custom-table-cell td'>
            {column.labelId === 'logoUrl' ? (
              <Box display='flex' gap='3px'>
                <CheckBox
                  label=''
                  value={row.feedId}
                  checked={isEmpty(feedChannelCheckedList) ? false : getFeedChannelChecked(row.feedId)}
                  disabled={row.isOrganized}
                  onChange={(event) => setFeedChannelCheck(event)}
                />
                <CustomImage src={row.logoUrl ? row.logoUrl : ''} wrapWidth={'70px'} wrapHeight={'35px'} rounded />
              </Box>
            ) : column.labelId === 'channelName' ? (
              <React.Fragment key={`feed-name-fragment-${row.id}`}>
                {row.dmaFlag === 'Y' && <Tag type='testing' label='DMA' badgeType='' />} {row.channelName && <Typography component='span'>{row.channelName}</Typography>}{' '}
              </React.Fragment>
            ) : (
              row[column.labelId]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  // ---------------------------- Selected Channel Map ----------------------------
  const [channelMapList, setChannelMapList] = useState([]);
  const [selectedChannelMap, setSelectedChannelMap] = useState('');
  const [selectedChannelMapStatus, setSelectedChannelMapStatus] = useState('');
  const [addContentCount, setAddContentCount] = useState(0);
  const [savedChannelMapChannelRows, setSavedChannelMapChannelRows] = useState([]);
  const [savedChannelCheckedList, setSavedChannelCheckedList] = useState([]);
  const [isSavedChannelAll, setIsSavedChannelAll] = useState(false);
  // Sdk Guide
  const [isChannelMapGuide, setIsChannelMapGuide] = useState(false);
  const [sdkGuideVersion, setSdkGuideVersion] = useState('');
  const [sdkGuideDevice, setSdkGuideDevice] = useState('');
  // dma
  const [dmaChannel, setDmaChannel] = useState({});

  useEffect(() => {
    if (!isEmpty(selectedChannelMap) && !isEmpty(selectedChannelMapStatus)) {
      getChannelMapContentList(selectedCountry, selectedChannelMap, selectedChannelMapStatus);
      setDeployButton(false);
    }
    // eslint-disable-next-line
  }, [selectedChannelMap, selectedChannelMapStatus]);

  const getChannelMapContentList = async (country, channelMap, status) => {
    const body = await getRequest(`/channel-map/${channelMap}/channel?countryCode=${country}&saveType=${status}`, null);
    if (body && body.result === 'SUCCESS') {
      let contentList = body.data.channel.map((item, index) => {
        const {
          channelMapIdx,
          channelMapId,
          channelIdx,
          channelId,
          channelName,
          channelNumber,
          providerId,
          providerName,
          majorChannelNumber,
          savedMajorChannelNumber,
          logoUrl,
          saveType,
          genreName,
          dmaRank,
          dmaGroupSet,
          dmaGroupName,
          dmaGroupCode,
          dmaFlag,
          dmaList,
          countryCode,
          changeDate,
          changeId,
        } = item;
        return {
          id: channelMapIdx,
          savedId: 'saved_'.concat(channelMapIdx),
          channelMapIdx: channelMapIdx,
          channelMapId: channelMapId,
          channelIdx: channelIdx,
          channelId: channelId,
          channelNumber: channelNumber,
          channelName: channelName,
          majorChannelNumber: majorChannelNumber,
          savedMajorChannelNumber: savedMajorChannelNumber,
          providerId: providerId,
          providerName: providerName,
          logoUrl: logoUrl,
          genreName: genreName,
          dmaRank: dmaRank,
          dmaGroupSet: dmaGroupSet,
          dmaGroupCode: dmaGroupCode,
          dmaGroupName: dmaGroupName,
          dmaFlag: dmaFlag,
          dmaList: dmaList,
          countryCode: countryCode,
          saveType: saveType,
          changeDate: changeDate,
          changeId: changeId,
          editAction: 'unchanged',
        };
      });

      setSavedChannelMapChannelRows(contentList);
      setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, contentList);

      if (!isEmpty(body.data.guide)) {
        const text = { fromVersion: '', toVersion: '', isLastVersion: false };
        let serviceDevice = '';
        let texList = [];
        body.data.guide
          .filter((sdk) => sdk.channelMapId === channelMap)
          .forEach((sdk) => {
            serviceDevice = sdk.serviceDevice;
            if (text.fromVersion === '') {
              text.fromVersion = sdk.fromVersion;
              text.toVersion = sdk.toVersion;
              text.isLastVersion = sdk.rowNumber === 1;
            } else if (text.fromVersion === sdk.toVersion) {
              text.fromVersion = sdk.fromVersion;
            } else {
              texList.push({ fromVersion: text.fromVersion, toVersion: text.toVersion, isLastVersion: text.isLastVersion });
              text.fromVersion = sdk.fromVersion;
              text.toVersion = sdk.toVersion;
              text.isLastVersion = sdk.rowNumber === 1;
            }
            if (text.fromVersion !== '') {
              texList.push(text);
            }
            if (texList.length > 0) {
              let versionList = '';
              texList.forEach((m) => {
                if (m.isLastVersion) {
                  versionList = `(${m.fromVersion} ~ )`;
                } else {
                  versionList = `(${m.fromVersion} ~ ${m.toVersion})`.concat(versionList !== '' ? ', ' : '').concat(versionList);
                }
              });
              setSdkGuideVersion(` version ${versionList} .`);
              let guideServiceDevice = `[${serviceDevice}]`;
              setIsChannelMapGuide(true);
              setSdkGuideDevice(guideServiceDevice);
            } else {
              setIsChannelMapGuide(false);
              setSdkGuideVersion('');
              setSdkGuideDevice('');
            }
          });
      } else {
        setIsChannelMapGuide(false);
        setSdkGuideVersion('');
        setSdkGuideDevice('');
      }
    }
  };

  useEffect(() => {
    setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, savedChannelMapChannelRows);
    // eslint-disable-next-line
  }, [feedChannelList]);

  const setOrganizedSortedFeedChannelRows = (feed, saved) => {
    if (!isEmpty(feed)) {
      feed.forEach((f) => {
        f.isOrganized = isOrganized(f, saved);
      });
      setSortedFeedChannelRows(feed);
    }
  };

  const setSavedChannelAll = (e) => {
    if (!isEmpty(savedChannelMapChannelRows)) {
      if (e.target.checked) {
        setSavedChannelCheckedList([...savedChannelMapChannelRows].map((f) => f.savedId));
      } else {
        setSavedChannelCheckedList([]);
      }
      setIsSavedChannelAll(e.target.checked);
    }
  };

  const getSavedChannelChecked = (id) => {
    if (isEmpty(savedChannelCheckedList)) {
      return false;
    } else {
      return savedChannelCheckedList.includes(id);
    }
  };

  const setSavedChannelCheck = (e) => {
    let savedCheckList = [];
    if (!isEmpty(savedChannelCheckedList)) {
      savedCheckList = [...savedChannelCheckedList];
    }
    if (e.target.checked) {
      savedCheckList.push(e.target.value);
    } else {
      savedCheckList = savedCheckList.filter((f) => f !== e.target.value);
    }
    let allCheck = false;

    if (!isEmpty(savedCheckList) && !isEmpty(savedChannelMapChannelRows)) {
      let sortedChannel = [...savedChannelMapChannelRows];
      if (savedCheckList.length === sortedChannel.length) {
        allCheck = true;
      }
    }
    setIsSavedChannelAll(allCheck);
    setSavedChannelCheckedList(savedCheckList);
  };

  const savedChannelNumberHandler = (e, savedId) => {
    e.preventDefault();
    if (!isEmpty(e.target.value)) {
      if (!isFinite(e.target.value)) {
        return;
      }
    }
    // TOD
    savedChannelMapChannelRows.forEach((f) => {
      if (f.savedId === savedId) {
        f.majorChannelNumber = e.target.value;
        if (f.editAction !== 'added') {
          if (e.target.value !== f.savedMajorChannelNumber.toString()) {
            f.editAction = 'update';
            setDeployButton(true);
          } else {
            f.editAction = 'unchanged';
            setDeployButton(false);
          }
        }
        return;
      }
    });
    setSavedChannelMapChannelRows([...savedChannelMapChannelRows]);
  };

  const openDmaPopUp = (row) => {
    setPopupType({ ...defaultPopupType, dma: true });
    let list = [];
    list.push({
      channelIdx: row.channelIdx,
      channelNumber: row.channelNumber,
      channelName: row.channelName,
      channelId: row.channelId,
      dmaGroupName: row.dmaGroupName,
    });
    if (!isEmpty(row.dmaList)) {
      row.dmaList.forEach((f) => {
        list.push({
          channelIdx: f.channelIdx,
          channelNumber: row.channelNumber,
          channelName: f.channelName,
          channelId: f.channelId,
          dmaGroupName: f.dmaGroupName,
        });
      });
    }
    setDmaChannel(list);
  };

  // 체크박스와 컬럼 표시 상태를 하나의 state로 관리
  const [columnVisibility, setColumnVisibility] = useState(
    SavedChannelHeader.reduce((acc, item) => {
      acc[item.id] = item.display;
      return acc;
    }, {})
  );

  const [firstColumn, setFirstColumn] = useState(SavedChannelHeader.find((f) => f.display === true).id);

  // 체크박스 변경 핸들러
  const handleColumnToggle = (event, columnId) => {
    let newState;
    setColumnVisibility((prev) => {
      // 체크 해제하려는 경우
      if (event.target.checked) {
        // 체크하는 경우
        newState = {
          ...prev,
          [columnId]: true,
        };
        // 테이블 너비 재계산을 위한 debounce 함수 호출
        const debouncedUpdate = debounce(() => {
          calculateThWidths();
          calculateTableWidth();
        }, 100);
        debouncedUpdate();
        return newState;
      } else {
        if (prev[columnId]) {
          const checkedCount = Object.values(prev).filter(Boolean).length;
          if (checkedCount > 4) {
            newState = {
              ...prev,
              [columnId]: false,
            };
            // 테이블 너비 재계산을 위한 debounce 함수 호출
            const debouncedUpdate = debounce(() => {
              calculateThWidths();
              calculateTableWidth();
            }, 100);
            debouncedUpdate();
            return newState;
          }
          return prev; // 6개 이하로는 체크 해제 불가
        }
      }
    });

    if (!isEmpty(newState)) {
      setFirstColumn(SavedChannelHeader.find((f) => newState[f.id] === true).id);
    }
  };

  const SavedChannelRow = memo(
    ({ index, style, data }) => {
      const { setFocusIndex, focusIndex } = data;
      const ref = useRef(null);
      useEffect(() => {
        if (index === focusIndex) {
          ref.current.focus();
        }
      }, [focusIndex, index]);
      const row = savedChannelMapChannelRows[index];
      return (
        <TableRow key={`saved-row-${row.savedId}`} style={{ ...style, display: 'flex', width: tableWidth }} className={`custom-table-row ${row.editAction !== 'unchanged' ? 'unsaved' : ''}`}>
          {SavedChannelHeader.map((column, colIndex) => (
            <TableCell
              key={`${column.id}-${row.savedId}`}
              align={column.align}
              style={{
                width: thWidths[colIndex] || column.width,
                minWidth: thWidths[colIndex] || column.width,
                display: !columnVisibility[column.id] ? 'none' : 'flex',
              }}
              className={`custom-table-cell ${!columnVisibility[column.id] ? 'hidden' : ''}`}>
              <Box display='flex' gap='3px'>
                {firstColumn === column.id && (
                  <CheckBox
                    label=''
                    value={row.savedId}
                    checked={isEmpty(savedChannelCheckedList) ? false : getSavedChannelChecked(row.savedId)}
                    onChange={(event) => setSavedChannelCheck(event)}
                    disabled={false}
                  />
                )}
                <React.Fragment>
                  {column.labelId === 'logoUrl' ? (
                    <CustomImage src={row.logoUrl ? row.logoUrl : ''} wrapWidth={70} wrapHeight={35} alt='' rounded />
                  ) : column.labelId === 'channelId' ? (
                    <TextLink href='' text={row.channelId} />
                  ) : column.labelId === 'majorChannelNumber' ? (
                    <TextField
                      inputRef={ref}
                      type='outlined'
                      size='medium'
                      placeholder=''
                      value={row.majorChannelNumber}
                      maxLength={4}
                      readOnly={false}
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                      onChange={(event) => savedChannelNumberHandler(event, row.savedId)}
                      onFocus={() => setFocusIndex(index)}
                    />
                  ) : column.labelId === 'dmaFlag' ? (
                    <React.Fragment>
                      {!isEmpty(row.dmaFlag) && row.dmaFlag === 'Y' && (
                        <Button
                          style={{
                            display: !isEmpty(row.dmaFlag) && row.dmaFlag === 'Y' ? 'none' : 'block',
                          }}
                          disabled={!isEmpty(row.dmaFlag) && row.dmaFlag === 'Y' ? false : true}
                          color={'primary'}
                          type='box'
                          size='small'
                          label='DMA'
                          isIcon={false}
                          Icon=''
                          iconPos={undefined}
                          autosize
                          onClick={() => openDmaPopUp(row)}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    row[column.labelId]
                  )}
                </React.Fragment>
              </Box>
            </TableCell>
          ))}
        </TableRow>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.index === nextProps.index && prevProps.style.top === nextProps.style.top && prevProps.style.height === nextProps.style.height;
    }
  );

  // ---------------------------- organization ----------------------------
  const feedToSavedChannel = () => {
    if (!isEmpty(feedChannelCheckedList) && !isEmpty(feedChannelList)) {
      let checkedList = feedChannelList.filter((f) => feedChannelCheckedList.includes(f.feedId));
      let addCount = addContentCount;
      let addList = [];
      checkedList.forEach((feed) => {
        const add = {
          id: -1,
          savedId: feed.feedId.concat(++addCount),
          channelMapIdx: -1,
          channelMapId: selectedChannelMap,
          channelIdx: feed.channelIdx,
          channelId: feed.channelId,
          channelNumber: feed.channelNumber,
          channelName: feed.channelName,
          majorChannelNumber: feed.channelNumber,
          logoUrl: feed.logoUrl,
          providerIdx: feed.providerIdx,
          providerId: feed.providerId,
          providerName: feed.providerName,
          genreName: feed.genreName,
          dmaRank: feed.dmaRank,
          dmaGroupSet: feed.dmaGroupSet,
          dmaGroupCode: feed.dmaGroupCode,
          dmaGroupName: feed.dmaGroupName,
          dmaFlag: feed.dmaFlag,
          dmaList: feed.dmaList,
          saveType: selectedChannelMapStatus,
          countryCode: feed.countryCode,
          changeDate: '',
          changeId: 'new',
          editAction: 'added',
        };
        addList.push(add);
        setAddContentCount(addCount);
      });
      let toSavedList = [...addList, ...savedChannelMapChannelRows];
      setFeedChannelCheckedList([]);
      setIsFeedChannelAll(false);
      setSavedChannelMapChannelRows([...toSavedList]);
      setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, toSavedList);
    }
    setDeployButton(true);
  };

  const openChannelRemoveDialog = () => {
    if (!isEmpty(savedChannelCheckedList)) {
      setPopupType({ ...defaultPopupType, remove: true, title: 'Delete Saved Channel', content: 'Do you want to delete saved channels? (You must save it for it to take effect.)' });
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const savedChannelRemove = () => {
    if (!isEmpty(savedChannelCheckedList)) {
      closeDialog();
    }
    let contentList = [...savedChannelMapChannelRows];
    let excludedList = contentList.filter((f) => !savedChannelCheckedList.includes(f.savedId));
    setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, excludedList);
    setSavedChannelMapChannelRows(excludedList);
    setSavedChannelCheckedList([]);
    setIsSavedChannelAll(false);
    setDeployButton(true);
    closeDialog();
  };

  // saved Dialog
  const openPreviewDialog = () => {
    setPopupType({ ...defaultPopupType, preview: true });
  };

  const openSavedDialog = () => {
    setPopupType({ ...defaultPopupType, save: true, title: 'Saved Channel', content: 'Would you like to save your channel?' });
  };

  const openChannelAddDialog = () => {
    setPopupType({ ...defaultPopupType, add: true });
  };

  // saved channel save
  const savedChannelSave = async () => {
    closeDialog();
    let saveList = [...savedChannelMapChannelRows];

    const requestBody = {
      countryCode: selectedCountry,
      channelMapId: selectedChannelMap,
      saveType: selectedChannelMapStatus,
      contentList: saveList,
    };

    const body = await postRequest(`/channel-map/${selectedChannelMap}/channel`, requestBody, null);
    let title = '';
    let content = '';
    if (body && body.result === 'SUCCESS') {
      getChannelMapContentList(selectedCountry, selectedChannelMap, selectedChannelMapStatus);
      setDeployButton(false);
      title = body.result;
      content = 'Saved successfully.';
    } else if (body && body.result === 'FAILED') {
      title = body.result;
      content = body.message;
    } else {
      title = body.error;
      content = 'Contact your administrator!';
    }
    setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
  };

  // ---------------------------- Button Data ----------------------------
  const defaultButton = {
    buttonId: 'buttonId',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'Button',
  };
  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButton',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const closeButton = {
    ...defaultButton,
    buttonId: 'closeButton',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };
  const channelRemoveButton = {
    ...defaultButton,
    buttonId: 'channelRemove',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    onClick: savedChannelRemove,
  };
  const channelSaveButton = {
    ...defaultButton,
    buttonId: 'channelRemove',
    color: 'primary',
    type: 'box',
    label: 'OK',
    autosize: true,
    disabled: false,
    onClick: savedChannelSave,
  };

  const channelRemoveActionButtons = [cancelButton, channelRemoveButton];
  const channelSaveActionButtons = [cancelButton, channelSaveButton];
  const alarmActionButtons = [closeButton];

  // Left Button
  const bulkUploadLink = () => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'BulkUpload',
        message: {
          countryCode: selectedCountry,
        },
      },
    });
  };

  const bulkButton = {
    ...defaultButton,
    buttonId: 'bulk',
    type: 'outline',
    size: 'medium',
    label: 'Bulk Upload',
    onClick: bulkUploadLink,
  };
  const AddButton = {
    ...defaultButton,
    buttonId: 'added',
    label: 'Add',
    autosize: true,
    onClick: openChannelAddDialog,
  };
  const removeButton = {
    ...defaultButton,
    buttonId: 'removed',
    label: 'Delete',
    fontWeight: 'bold',
    autosize: true,
    onClick: openChannelRemoveDialog,
  };
  const channelMapEditButton = [bulkButton, AddButton, removeButton];

  const channelMapHistoryLink = () => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'ChannelMapHistory',
        message: {
          countryFilter: feedCountry,
          pageMode: 'init',
          historyMode: 'channelMap',
          countryCode: 'US',
          startDate: null,
          endDate: null,
          channelMapParameter: {
            countryCode: selectedCountry,
            channelMapId: selectedChannelMap,
            saveType: selectedChannelMapStatus,
          },
        },
      },
    });
  };

  // Right Button
  const reservationLink = () => {
    navigate('/ChannelMapping', {
      replace: true,
      state: {
        move: true,
        targetComponent: 'Reservation',
        message: {
          countryFilter: feedCountry,
          pageMode: 'init',
          countryCode: 'US',
          endDate: null,
          channelMapParameter: {
            countryCode: selectedCountry,
            channelMapId: selectedChannelMap,
            saveType: selectedChannelMapStatus,
          },
        },
      },
    });
  };
  const historyButton = {
    ...defaultButton,
    type: 'box',
    label: 'History',
    autosize: true,
    onClick: channelMapHistoryLink,
  };
  const reservationButton = {
    ...defaultButton,
    type: 'box',
    label: 'Reservation List',
    autosize: true,
    onClick: reservationLink,
  };
  const saveButton = {
    ...defaultButton,
    type: 'box',
    label: 'Save',
    autosize: true,
    tooltip: true,
    tooltipText: 'Please save when modifying the channel name.',
    disabled: false,
    onClick: openSavedDialog,
  };
  const testingButton = {
    ...defaultButton,
    color: 'primary',
    type: 'box',
    label: 'Testing',
    autosize: true,
    onClick: openPreviewDialog,
  };
  const publishedButton = {
    ...defaultButton,
    color: 'primary',
    type: 'box',
    label: 'Published',
    autosize: true,
    onClick: openPreviewDialog,
  };

  const setDeployButton = (isSaved) => {
    let button = { ...saveButton, onClick: openSavedDialog };
    let save = { ...button, color: isSaved ? 'primary' : 'tertiary', disabled: !isSaved };
    let deployButton = { ...testingButton, color: !isSaved ? 'primary' : 'tertiary', disabled: isSaved };
    if (selectedChannelMapStatus === 'P') {
      deployButton = { ...publishedButton, color: !isSaved ? 'primary' : 'tertiary', disabled: isSaved };
    }
    setChannelMapSaveButton([historyButton, reservationButton, save, deployButton]);
  };

  const [channelMapSaveButton, setChannelMapSaveButton] = useState([]);
  const [focusIndex, setFocusIndex] = useState(null);
  const itemData = {
    focusIndex,
    setFocusIndex,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addManualChannel = (manualChannel) => {
    if (!isEmpty(manualChannel)) {
      let toSavedList = [...manualChannel, ...savedChannelMapChannelRows];
      setFeedChannelCheckedList([]);
      setIsFeedChannelAll(false);
      setSavedChannelMapChannelRows([...toSavedList]);
      setOrganizedSortedFeedChannelRows(sortedFeedChannelRows, toSavedList);
      setDeployButton(true);
    }
  };

  return (
    <GnbContext.Provider value={{ drawerOpen, setDrawerOpen }}>
      <Box display='flex' className='wrapper'>
        <Box className='main-container' component='main' sx={{ flexGrow: 1 }}>
          {/* BreadCrumb Area */}
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Typography className='title'>Channel Mapping</Typography>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu} />
              </div>
            </div>
          </div>
          <Box className='content-wrap channel-mapping' component='div'>
            <Box className='white-box-container align-hor'>
              {/* Feed Info */}
              <Box className={`white-box white-box-wrap feed-info ${isOpenArray[0] ? 'expanded' : 'collapsed'}`} sx={{ overflow: 'initial' }}>
                <Button className='btn-expand' onClick={() => togglePanel(0)} />
                <Box className='fold-content'>
                  {/* Box Title Area */}
                  <Box className='title-wrap'>
                    <Box className='left-area'>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2' className='box-title-text'>
                          Feed Info
                        </Typography>
                      </Box>
                    </Box>
                    <Box className='right-area'></Box>
                  </Box>
                  <Box className='box-content'>
                    <div className='inner-wrap'>
                      {!isEmpty(feedCountry) && (
                        <SelectBox
                          id='select-02'
                          fullWidth
                          size='large'
                          selectOptions={feedCountry}
                          disabled={false}
                          isFirstSelected={true}
                          label='Country'
                          selected={selectedCountry}
                          onChange={setSelectedCountry}
                          shrink
                        />
                      )}
                      {/* feed-list-container 위치수정 */}
                      <div className='feed-list-container '>
                        <div className='list-area'>
                          <div className='list-box'>
                            {!isEmpty(feedProviderList) && <CustomList type='radio' items={feedProviderList} changeValueHandler={setSelectedFeedProviderHandler} name='feed-info-group-2' />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
              {/* Select Feed: */}
              <Box className={`white-box white-box-wrap  select-feed ${isOpenArray[1] ? 'expanded' : 'collapsed'}`} sx={{ overflow: 'initial' }}>
                <Button className='btn-expand' onClick={() => togglePanel(1)} />
                <Box className='fold-content'>
                  <Box className='title-wrap space-between'>
                    <Box className='left-area'>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography className='box-title-text title-label' variant='h2'>
                          Select Feed:
                          <Typography className='title-value' component='span'>
                            {selectedFeedProvider.providerName}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className='right-area'>
                      <Box display='flex' className='count-all'>
                        <Typography variant='span' className='text label'>
                          Showing All
                        </Typography>
                        <Typography variant='span' className='text value'>
                          {feedChannelCount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='box-content'>
                    <div className='inner-wrap'>
                      <Box className='view-filter'>
                        <Box className='left-area'>
                          <BasicSwitches
                            disabled={false}
                            onOffMode={isEnableSwitchOn}
                            setSwitchOnOff={setIsEnableSwitchOn}
                            isLabelText={true}
                            className='switch-wrap'
                            onText='Exclude Applied Channels'
                            offText='Exclude Applied Channels'
                          />
                        </Box>
                        <Box className='right-area' width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                          <SearchField
                            id='feed-search-field'
                            placeholder='Please enter a search term'
                            label='Ch.no or ch.Name'
                            maxWidth='230px'
                            size='medium'
                            required={false}
                            shrink={false}
                            value={feedSearchField}
                            onChange={(event) => setFeedSearchField(event.target.value)}
                          />
                        </Box>
                      </Box>
                      {/* Table */}
                      <CustomTableContainer className='table-container infinite-scroll-table' sx={{ position: 'relative' }}>
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {FeedChannelHeader.map((column) => (
                                <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                                  <Box display='flex' alignContent='center'>
                                    {column.checkbox && (
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        checked={isFeedChannelAll}
                                        onChange={setFeedChannelAll}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                    )}
                                    <SortLabel
                                      labelText={column.label}
                                      labelId={column.labelId}
                                      sortable={column.sortable}
                                      orderBy={orderBy}
                                      setOrderBy={setOrderBy}
                                      order={order}
                                      setOrder={setOrder}
                                      sorting={feedChannelSort}
                                    />
                                  </Box>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          {/* table Body */}
                          {isEmpty(sortedFeedChannelRows) ? (
                            <TableBody className='tbody'>
                              <TableRow className='tr'>
                                <TableCell colSpan={4} className={'td noData'} align='center'>
                                  <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                    <Typography>Please select a CP</Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <TableBody className='tbody'>
                              <List
                                height={listHeight} // 전체 테이블 높이
                                itemCount={sortedFeedChannelRows.length} // 데이터 길이
                                itemSize={88} // 각 행의 높이 (이 값은 행의 높이에 맞게 설정)
                                width='100%' // 전체 테이블 너비
                                position='absolute'
                                className='infinite-scroll-tbody bottom-y'>
                                {setFeedChannelRow}
                              </List>
                            </TableBody>
                          )}
                        </Table>
                      </CustomTableContainer>
                    </div>
                    {!isEmpty(sortedFeedChannelRows) ? (
                      <div className='bottom-shadow-wrap'>
                        <Box display='flex' justifyContent='flex-end'>
                          <Button color='primary' type='box' size='medium' label='Select Ch Input' isIcon={true} iconPos={'right'} Icon={BtnArrowRight} disabled={false} onClick={feedToSavedChannel} />
                        </Box>
                      </div>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              </Box>
              {/* Selected Channel Map */}
              <Box className='white-box white-box-wrap select-channel' sx={{ overflow: 'initial' }}>
                {/* fold-content 클래스 추가하였습니다.  */}
                <Box className='fold-content'>
                  {/* Box Title Area */}
                  <Box className='title-wrap space-between' style={{ padding: '0.8rem 2.4rem 1.2rem' }}>
                    <Box className='left-area'>
                      <Box>
                        <Typography className='box-title-text title-label' variant='h2'>
                          Selected Channel Map
                        </Typography>
                        {isChannelMapGuide ? (
                          <Typography component='p' className='box-title-bottom-text'>
                            This channel map will be applied to <span className='font-red'>{sdkGuideDevice}</span> platform <span className='font-red'>{sdkGuideVersion}</span>
                          </Typography>
                        ) : (
                          <Typography component='p' className='box-title-bottom-text'>
                            <br />{' '}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className='right-area'>
                      <Box display='flex' className='count-all'>
                        <Typography variant='span' className='text label'>
                          Showing All
                        </Typography>
                        <Typography variant='span' className='text value'>
                          {isEmpty(savedChannelMapChannelRows) ? 0 : savedChannelMapChannelRows.length}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <div className='box-content'>
                    {/* inner-wrap 클래스 추가하였습니다.  */}
                    <Box className='inner-wrap'>
                      <Box className='white-box-content-top-area'>
                        <Box className='component-align-wrap' justifyContent='flex-end'>
                          <Box className='field-item' sx={{ maxWidth: '200px' }}>
                            <SelectBox
                              id='select-channel-map'
                              size='medium'
                              maxWidth='200px'
                              shrink={true}
                              label='Channel Map List'
                              required={false}
                              disabled={false}
                              isFirstSelected={true}
                              placeholder=''
                              selectOptions={channelMapList}
                              selected={selectedChannelMap}
                              onChange={setSelectedChannelMap}
                            />
                          </Box>
                          <Box className='field-item' sx={{ maxWidth: '200px' }}>
                            <SelectBox
                              id='select-channel-map-status'
                              size='medium'
                              maxWidth='200px'
                              shrink={true}
                              label='Channel Map Status'
                              required={false}
                              selectOptions={channelMapStatusList}
                              disabled={false}
                              isFirstSelected={true}
                              placeholder=''
                              selected={selectedChannelMapStatus}
                              onChange={setSelectedChannelMapStatus}
                            />
                          </Box>
                          <Box className='field-item auto'>
                            <div className='type-button-dropdown'>
                              <Button color='' type='only-icon' size='small' label='' isIcon={false} className='btn-layer-popup' onClick={handleClick} />
                              <StyledMenu
                                id='demo-customized-menu'
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className='btn-drop-paper'
                                PaperProps={{
                                  sx: {
                                    maxHeight: '230px',
                                  },
                                }}>
                                <li>
                                  <div className='text-label'>
                                    <span>Add or remove columns(At least 4)</span>
                                  </div>
                                </li>
                                {SavedChannelHeader.map((list) => (
                                  <MenuItem key={list.id}>
                                    <CheckBox label={list.label} value={list.label} checked={columnVisibility[list.id]} onChange={(event) => handleColumnToggle(event, list.id)} />
                                  </MenuItem>
                                ))}
                              </StyledMenu>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                      {/* Table */}
                      <CustomTableContainer className='table-container infinite-scroll-table' sx={{ position: 'relative' }}>
                        <Table className='custom-table layout-clear tbl-channel-mapping' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead' ref={tableHeadRef}>
                            <TableRow className='tr'>
                              {SavedChannelHeader.map((column, index) => (
                                <TableCell
                                  key={column.id}
                                  className={`th ${!columnVisibility[column.id] ? 'hidden' : ''}`}
                                  align={column.align}
                                  style={{
                                    width: column.width,
                                    display: !columnVisibility[column.id] ? 'none' : 'table-cell',
                                  }}>
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    {firstColumn === column.id && (
                                      <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          checked={isSavedChannelAll}
                                          onChange={setSavedChannelAll}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                      </Box>
                                    )}
                                    <SortLabel
                                      labelText={column.label}
                                      labelId={column.labelId}
                                      sortable={column.sortable}
                                      orderBy={orderBy}
                                      setOrderBy={setOrderBy}
                                      order={order}
                                      setOrder={setOrder}
                                      sorting={savedChannelSort}
                                    />
                                  </Box>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          {/* table Body */}
                          {isEmpty(savedChannelMapChannelRows) ? (
                            <TableBody className='tbody'>
                              <TableRow className='tr'>
                                <TableCell colSpan={8} className={'td noData'} align='center'>
                                  <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                    <Typography>Please set up the Channel Map</Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <List
                              height={listHeight}
                              itemCount={savedChannelMapChannelRows.length}
                              itemSize={88}
                              width={tableWidth || '100%'}
                              position='absolute'
                              className='infinite-scroll-tbody bottom-y'
                              overscanCount={5}
                              style={{
                                minWidth: tableWidth || '100%',
                                overflowX: 'hidden',
                              }}
                              itemData={itemData}>
                              {SavedChannelRow}
                            </List>
                          )}
                        </Table>
                      </CustomTableContainer>
                      {/* // Table */}
                    </Box>
                    <div className='bottom-shadow-wrap'>
                      <Box display='flex' justifyContent={'space-between'}>
                        <CustomButtonGroup buttons={channelMapEditButton} justifyContent='flex-start' gap='4px' />
                        {!isEmpty(channelMapSaveButton) && <CustomButtonGroup buttons={channelMapSaveButton} justifyContent='flex-end' gap='4px' />}
                      </Box>
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {popupType.remove && (
          <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={channelRemoveActionButtons} />
        )}
        {popupType.save && (
          <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={channelSaveActionButtons} />
        )}
        {popupType.add && (
          <PopChannelAdd
            isPopUpOpen={popupType.add}
            countryCode={selectedCountry}
            channelMapId={selectedChannelMap}
            saveType={selectedChannelMapStatus}
            savedChannel={savedChannelMapChannelRows}
            addManualChannelHandler={addManualChannel}
            closeHandler={closeDialog}></PopChannelAdd>
        )}
        {popupType.preview && (
          <PopCheckDialog
            isDialogOpen={popupType.preview}
            previewCountry={selectedCountry}
            previewChannelMapId={selectedChannelMap}
            previewSaveType={selectedChannelMapStatus}
            closeHandler={closeDialog}></PopCheckDialog>
        )}
        {popupType.alarm && <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={alarmActionButtons} />}
        {popupType.dma && <PopDMAGroup isDialogOpen={popupType.dma} closeHandler={closeDialog} dmaList={dmaChannel}></PopDMAGroup>}
      </Box>
    </GnbContext.Provider>
  );
}

ChannelMapping.propTypes = {};

const mapStateToProps = (state) => ({
  country: state.common.country,
});

const mapDispatchToProps = (dispatch) => ({
  getCountry: () => dispatch(getCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelMapping);
