import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { Box, Typography, Link, Divider, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem } from '@mui/material';
import { getRequest, putRequest, multiRequest } from 'utils/Request';
import SortLabel from 'components/component/BasicTableSortLabel';
import Dialog from 'components/component/BasicDialog';
// Data
import { PrimaryInfoIcon } from 'components/component/BasicIcon';
import { selectOption, chCountListData } from 'pages/Organization/ChannelMapping/data/ChannelMappingData';

import { BulkUploadHeader, defaultPopupType, defaultButton } from 'pages/Organization/ChannelMapping/data/DefaultData';
import { isEmpty } from 'utils/Valid';
import { getExcelFile } from 'utils/ExcelDownload';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .success': {
    color: '#0077B5',
  },
  '& .fail': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

function BulkUploadSaved({ darkMode, message }) {
  const [channelMapCountryCode, setChannelMapCountryCode] = useState('');
  const [channelMapStatus, setChannelMapStatus] = useState('T');
  const [bulkFileName, setBulkFileName] = useState('');
  const [isUpload, setIsUpload] = useState(true);
  //result.put("channelMapList", channelMapList);
  //result.put("saveType", saveType);
  const [bulkUpload, setBulkUpload] = useState({ uploadCount: 0, failCount: 0, successCount: 0, saveType: '', channelMapList: [] });
  const [bulkUploadList, setBulkUploadList] = useState([]);
  const [displayUploadList, setDisplayUploadList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPageNavigate, setIsPageNavigate] = useState(false);

  const [popupType, setPopupType] = useState(defaultPopupType);

  useEffect(() => {
    if (message) {
      setChannelMapCountryCode(message.countryCode);
      setChannelMapStatus('T');
      setBulkFileName('');
    }
  }, [message]);

  useEffect(() => {
    if (channelMapStatus !== bulkUpload.saveType) {
      setIsUpload(false);
    }
    // eslint-disable-next-line
  }, [channelMapStatus]);

  const ChannelMapStatusOption = [
    { id: 'BulkStatus1', value: 'T', option: 'Testing', type: 'text' },
    { id: 'BulkStatus2', value: 'P', option: 'Published', type: 'text' },
  ];

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Bulk Upload'],
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const dataSorting = (col, desc) => {
    let list = sortRows([...bulkUploadList], col, desc);
    setBulkUploadList(list);
    setPageNavigate(list, currentPage, pageSize);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const paginationHandler = (paging) => {
    if (paging) {
      if (paging.type === 'page') {
        setPageNavigate(bulkUploadList, paging.value, pageSize);
      } else if (paging.type === 'size') {
        setPageNavigate(bulkUploadList, 1, paging.value);
      }
    }
  };

  const setPageNavigate = (list, current, size) => {
    if (!isEmpty(list)) {
      setIsPageNavigate(true);
      const startIndex = (current - 1) * size;
      const endIndex = startIndex + size;
      const displayList = list.slice(startIndex, endIndex);
      setDisplayUploadList([...displayList]);
    } else {
      setIsPageNavigate(false);
      setDisplayUploadList([]);
    }
    setCurrentPage(current);
    setPageSize(size);
  };

  const [openSearch, setOpenSearch] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const channelMapExcelDownload = async () => {
    if (!isEmpty(channelMapCountryCode) && !isEmpty(channelMapStatus)) {
      const body = await getRequest(`/channel-map/excel?countryCode=${channelMapCountryCode}&saveType=${channelMapStatus}`, null);
      if (body && body.result === 'SUCCESS') {
        getExcelFile(body.data);
      }
    }
  };

  // Table view line
  const onFileChangeHandler = async (file) => {
    if (isEmpty(channelMapCountryCode) || isEmpty(channelMapStatus)) {
      return;
    }
    if (file) {
      let ext = file.name.split('.').pop().toLowerCase();
      if (!['xls', 'xlsx', 'csv'].includes(ext)) {
        alert('xls, xlsx 파일만 업로드 할수 있습니다.');
        return;
      }
      setBulkFileName(file.name);
      let requestBody = new FormData();
      requestBody.append('countryCode', channelMapCountryCode);
      requestBody.append('saveType', channelMapStatus);
      requestBody.append('bulkFile', file);

      const body = await multiRequest('POST', `/channel-map/bulk`, requestBody);
      if (body && body.result === 'SUCCESS') {
        setBulkUploadList(body.data.list);
        setBulkUpload({
          uploadCount: body.data.list.length,
          failCount: body.data.failCount,
          successCount: body.data.successCount,
          saveType: body.data.saveType,
          channelMapList: body.data.channelMapList,
        });
        setPageNavigate(body.data.list, 1, pageSize);
        setIsUpload(true);
      } else {
        setBulkUploadList([]);
        setDisplayUploadList([]);
        setBulkUpload({ uploadCount: 0, failCount: 0, successCount: 0, saveType: '', channelMapList: [] });
        setIsUpload(false);
      }
    }
  };

  const saveBulkChannelMap = async () => {
    let title = '';
    let content = '';
    if (!isEmpty(bulkUploadList) && !isEmpty(bulkUpload.saveType) && bulkUpload.successCount > 0 && bulkUpload.failCount === 0) {
      const requestBody = {
        countryCode: channelMapCountryCode,
        saveType: channelMapStatus,
      };
      const body = await putRequest(`/channel-map/bulk`, requestBody, null);
      if (body && body.result === 'SUCCESS') {
        title = 'Success';
        content = 'Saved successfully.';
        setPopupType({ ...defaultPopupType, success: true, title: title, content: content });
      } else {
        if (body && body.result === 'FAILED') {
          title = 'Error';
          content = body.message;
        } else {
          title = 'Error';
          content = 'Contact your administrator!';
        }
        setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
      }
    }
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const successCloseDialog = () => {
    setPopupType(defaultPopupType);
    setBulkUploadList([]);
    setDisplayUploadList([]);
    setBulkUpload({ uploadCount: 0, failCount: 0, successCount: 0, saveType: '', channelMapList: [] });
    setIsUpload(false);
  };

  const cancelButton = {
    ...defaultButton,
    buttonId: 'cancelButtonId',
    label: 'Cancel',
    autosize: true,
    disabled: false,
    onClick: closeDialog,
  };

  const saveButton = {
    ...defaultButton,
    buttonId: 'saveButtonId',
    color: 'primary',
    type: 'box',
    label: 'Save',
    autosize: true,
    disabled: false,
    onClick: saveBulkChannelMap,
  };

  const successButton = {
    ...defaultButton,
    buttonId: 'successButtonId',
    label: 'Close',
    autosize: true,
    disabled: false,
    onClick: successCloseDialog,
  };
  const openSavePopup = () => {
    if (!isEmpty(bulkUploadList) && !isEmpty(bulkUpload.saveType) && bulkUpload.successCount > 0 && bulkUpload.failCount === 0) {
      let saveTypeText = bulkUpload.saveType === 'P' ? 'Saved for published' : 'Saved for Testing';
      let text1 = `Status: Would you like to save it to [${saveTypeText}] ?`;
      let text2 = ' All previously saved data will be deleted and Excel data will be saved.';
      let text3 = ` [${isEmpty(bulkUpload.channelMapList) ? '' : bulkUpload.channelMapList.join()}]`;
      setPopupType({
        ...defaultPopupType,
        save: true,
        title: 'Save',
        content: text1.concat(text2).concat(text3),
      });
    }
  };
  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container bulk-upload' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Bulk Upload
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <Box className='info-output-container'>
                <Box className='left-area'>
                  {/* 카테고리 정보 출력 영역 */}
                  <List className='info-list-container'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Country</Typography>
                      <Typography className='value'>{channelMapCountryCode}</Typography>
                    </ListItem>
                    <Divider className='divider' />
                  </List>
                </Box>
                <Box className='right-area'></Box>
              </Box>
            </Box>
            <Box className='right-area'></Box>
          </Box>
          <Box className='white-box single-box filter-box'>
            <Box gap={1} className='bulk-container'>
              <Box className='left-area'>
                <Box display='flex' gap={1}>
                  <SelectBox
                    id='Status-01'
                    fullWidth
                    required={false}
                    size='large'
                    maxWidth='244px'
                    selectOptions={ChannelMapStatusOption}
                    disabled={false}
                    label='Status'
                    isFirstSelected={false}
                    selected={channelMapStatus}
                    onChange={setChannelMapStatus}
                    shrink
                  />
                  <Button color='primary' type='box' size='medium' label='Download Testing channel Map' isIcon={false} autosize onClick={channelMapExcelDownload} />
                </Box>
              </Box>

              <Box className='right-area'>
                <Box className='upload-area' display='flex' alignItems='center' sx={{ width: '540px' }}>
                  <FileUpload
                    id='bulkFileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                    buttonLabel='Upload'
                    size='large'
                    placeholder='File Upload(Only .xlsx files can be uploaded.)'
                    value={bulkFileName}
                    onFileChange={(file) => onFileChangeHandler(file)}
                    accept='.xls, .xlsx, .csv'
                    style={{ width: '100%' }}
                  />

                  {/* Icon Tooltip */}
                  <Tooltip placement='top' title='Tooltip' disabled={true}>
                    <IconButton size='small' sx={{ width: '24px', height: '24px', marginLeft: '24px' }}>
                      <PrimaryInfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <List className='notice-text-list'>
                  <ListItem className='list-item'>*All existing data will be deleted, and Data from excel will be uploaded.</ListItem>
                  <ListItem className='list-item'>*Always download the latest channel map to app,modify, or delete channels.</ListItem>
                  <ListItem className='list-item'>
                    *The existing channel map will be downloaded in Testing/Published version, and when the file is uploaded, the data will be saved in the selected status
                  </ListItem>
                  <ListItem className='list-item'>*Items marked with * in excel are mandatory and cannot be left blank.</ListItem>
                  <ListItem className='list-item'>*Items marked with ** in excel are mandatory for DMA channels and cannot be left blank.</ListItem>
                </List>
              </Box>
            </Box>
          </Box>
          {isUpload && (
            <Box className='white-box white-box-wrap'>
              <div className='box-content'>
                <Box className='white-box-content-top-area' alignItems='flex-end'>
                  {/* left-area */}
                  <Box className='left-area column'>
                    <Box display='flex' gap={1}>
                      <Box className='total-count'>
                        <Box component='span' className='label'>
                          Total
                        </Box>
                        <Box component='span' className='value'>
                          {bulkUpload.uploadCount}
                        </Box>
                      </Box>
                      <Box className='sum-count-container'>
                        <Typography component='span' mr={1} className='text'>
                          Upload result :
                        </Typography>
                        <List className='count-list'>
                          <ListItem className='count-item'>
                            Success <span className='count-value green'>{bulkUpload.successCount}</span>
                          </ListItem>
                          <ListItem className='count-item' sx={{ margin: '0 8px' }}>
                            |
                          </ListItem>
                          <ListItem className='count-item'>
                            Failed <span className='count-value red'>{bulkUpload.failCount}</span>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                    <Box display='flex' gap={1}>
                      <SelectBox
                        id='select-01'
                        maxWidth='180px'
                        fullWidth
                        size='medium'
                        selectOptions={selectOption}
                        disabled={false}
                        label=''
                        isFirstSelected={true}
                        open={openSearch}
                        setOpen={setOpenSearch}
                        shrink
                      />
                      <SearchField id='search-1' placeholder='Please enter a search term' label='Ch.no or ch.Name' maxWidth='230px' size='medium' required={false} shrink={false} />
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Button
                      color='primary'
                      type='box'
                      size='small'
                      label='Save'
                      disabled={bulkUpload.successCount > 0 && bulkUpload.failCount === 0 ? false : true}
                      isIcon={false}
                      autosize
                      onClick={openSavePopup}
                    />
                    <div className='type-button-dropdown'>
                      <Button color='' type='only-icon' size='small' label='' isIcon={false} className='btn-layer-popup' onClick={handleClick} />
                      <StyledMenu
                        id='demo-customized-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className='btn-drop-paper'
                        PaperProps={{
                          sx: {
                            maxHeight: '230px',
                          },
                        }}>
                        <li>
                          {/* Channel Count  */}
                          <div className='text-label'>
                            <span>
                              Add or remove columns <br /> (Channel map id)
                            </span>
                          </div>
                        </li>
                        {chCountListData.map((list) => (
                          <MenuItem key={list.id}>{list.type === 'checkbox' && <CheckBox label={list.label} value={list.label} defaultChecked={false} />}</MenuItem>
                        ))}
                      </StyledMenu>
                    </div>
                  </Box>
                  {/* right-area */}
                </Box>
                <Box className='content-inner-wrap'>
                  {/* Table */}
                  <CustomTableContainer className='table-container'>
                    <Table className='custom-table' aria-label='table' stickyHeader>
                      {/* table Head */}
                      <TableHead className='thead'>
                        <TableRow className='tr'>
                          {BulkUploadHeader.map((column) => (
                            <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                              <SortLabel
                                labelText={column.label}
                                labelId={column.labelId}
                                sortable={column.sortable}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                sorting={dataSorting}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* table Body */}
                      <TableBody className='tbody'>
                        {displayUploadList.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {BulkUploadHeader.map((column, colIndex) => (
                              <CellType key={column.id} className='td' align={column.align}>
                                {column.labelId === 'bulkResult' ? (
                                  <span className={td[column.labelId] === 'S' ? 'success' : 'fail'}>{td[column.labelId] === 'S' ? 'Success' : `Fail (${td.bulkResultMessage})`}</span>
                                ) : (
                                  td[column.labelId]
                                )}
                              </CellType>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CustomTableContainer>
                </Box>
                {isPageNavigate && (
                  <Pagination count={Math.ceil(bulkUploadList.length / pageSize)} id='reservation-pagination' selected={pageSize} onChangeHandle={paginationHandler} page={currentPage} />
                )}
              </div>
            </Box>
          )}
        </Box>
      </Box>
      {/*
      Toast Pop-Up
      severity: default, error, warning, info, success
      */}
      <Snackbar severity='warning' message='Status 와 파일명_상태가 일치해야합니다.' open={false} duration={1000} bottom={'8%'} darkMode={darkMode} />
      {(popupType.save || popupType.success) && (
        <Dialog
          title={popupType.title}
          contentTitle={popupType.content}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={closeDialog}
          buttons={popupType.save ? [cancelButton, saveButton] : popupType.success ? [successButton] : []}
        />
      )}
    </Box>
  );
}
export default BulkUploadSaved;
