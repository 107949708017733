import React, { useState, useEffect } from 'react';
import BasicSwitches from 'components/component/BasicSwitch';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Dialog from 'components/component/BasicDialog';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { DelMinus, AddPlus, CloseIcon24Black } from 'components/component/BasicIcon';

import { saveCreateTab } from 'pages/Organization/TabCategory/api/restApi';

import { Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

const uploadedFiles = [
  {
    name: 'Logo_1.png',
    path: '',
  },
];
const themeUploadedFiles = [
  {
    name: 'CP A_Bulk.png',
    path: '',
  },
];

// 메인 컨텐츠
function PopCreateTab(props) {
  const { regionList, deviceType, region, platformVersion, status, tabCodeOptions, tabSkeletonTypeOptions, tabLocationTypeOptions, tabTypeOptions, multiLangOptions, onClose, handelSetShowSnakBar, callTabCategoryList } = props;
  const [createTab, setCreateTab] = useState({
    deviceType: deviceType,
    region: region,
    platformVersion: platformVersion,
    status: status,
    tabCode: tabCodeOptions[0].value,
    type: tabTypeOptions[0].value,
    skeletonType: tabSkeletonTypeOptions[0].value,
    locationType: tabLocationTypeOptions[0].value,
    linkType: '',
    categoryFlag: 'Y',
    themeApply: 'N',
    randomOrderFlag: 'N',
    bgImg: '',
    tabImg: '',
    title: '',
    description: '',
    tabOrder: 0,
  });

  // 해당 Tab의 Multi Language 정보를 저장
  const [editLangList, setEditLangList] = React.useState([{ langCode: 'en', name: '' }]);
  // Tab Image 임시저장 객체
  const [tabImageTemp, setTabImageTemp] = useState(null);
  const [tabImageActualSize, setTabImageActualSize] = useState({ width: 0, height: 0 });
  // Background Image 임시저장 객체
  const [bgImageTemp, setBgImageTemp] = useState(null);
  const [bgImageActualSize, setBgImageActualSize] = useState({ width: 0, height: 0 });
  // device selectBox
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  // region selectBox
  const [regionOptions, setRegionOptions] = useState([]);

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  // region selectBox 전체 목록 값
  useEffect(() => {
    const re = regionList
      .filter(n => n.deviceType === createTab.deviceType)
      .map(n => n.list)
      .reduce((acc, curr) => acc.concat(curr), []);
    const upre = re.map(n => {
      const newItem = {
        id: n.region,
        value: n.region,
        option: n.region,
        type: 'text',
      }
      return newItem;
    });
    setRegionOptions(upre);
    // eslint-disable-next-line
  }, [createTab.deviceType]);

  // deviceType selectBox 전체 목록 값
  useEffect(() => {
    const device = regionList.map(n => {
      const newItem = {
        id: n.deviceType,
        value: n.deviceType,
        option: n.deviceType,
        type: 'text',
        list: n.list
      }
      return newItem;
    });
    setDeviceTypeOptions(device);
    // eslint-disable-next-line
  }, [regionList]);

  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 Start */
  // Tab Device Type 값
  const onChangeDeviceType = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      deviceType: value,
    }));
  };
  // Tab Region 값
  const onChangeRegion = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      region: value,
    }));
  };
  // Tab Platform Version 값
  const onChangePlatformVersion = (event) => {
    const value = event.target.value;
    setCreateTab((pevState) => ({
      ...pevState,
      platformVersion: value,
    }));
  };
  // Tab Code 값
  const onChangeTabCode = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      tabCode: value,
    }));
  };
  // Tab MultiLanguage 값
  const onChangeMultiLang = (i, type, value) => {
    let newData = [...editLangList];
    newData[i][type] = value;
    setEditLangList(newData);
  };
  // Tab Type 값
  const onChangeType = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      type: value,
    }));
  };
  // Tab LocationType 값
  const onChangeLocationType = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      locationType: value,
    }));
  };
  // Tab SkeletonType 값
  const onChangeSkeletonType = (value) => {
    setCreateTab((pevState) => ({
      ...pevState,
      skeletonType: value,
    }));
  };
  // Tab categoryFlag 값
  const onChangeCategoryFlag = (checked) => {
    const value = checked ? "Y" : "N";
    setCreateTab((pevState) => ({
      ...pevState,
      categoryFlag: value,
    }));
  };
  // Tab themeApply 값
  const onChangeThemeApply = (checked) => {
    const value = checked ? "Y" : "N";
    setCreateTab((pevState) => ({
      ...pevState,
      themeApply: value,
    }));
  };
  // Tab randomOrderFlag 값
  const onChangeRandomOrderFlag = (checked) => {
    const value = checked ? "Y" : "N";
    setCreateTab((pevState) => ({
      ...pevState,
      randomOrderFlag: value,
    }));
  };
  // Tab title 값
  const onChangeTitle = (event) => {
    const value = event.target.value;
    setCreateTab((pevState) => ({
      ...pevState,
      title: value,
    }));
  };
  // Tab description 값
  const onChangeDescription = (event) => {
    const value = event.target.value;
    setCreateTab((pevState) => ({
      ...pevState,
      description: value,
    }));
  };
  // Tab Image 값
  const onChangeTabImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTabImageTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setTabImageActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setCreateTab((prevState) => ({
      ...prevState,
      tabImg: file,
    }));
  };
  // Background Image 값
  const onChangeBgImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBgImageTemp(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setBgImageActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setCreateTab((prevState) => ({
      ...prevState,
      bgImg: file,
    }));
  };
  /* 탭 정보 수정 시 editTab 객체에 값을 변경 한다 End */

  // 이미지 X 버튼 클릭 시
  const removeTabImage = () => {
    setCreateTab((prevState) => ({
      ...prevState,
      tabImg: '',
    }));
    setTabImageTemp('');
    setTabImageActualSize({ width: 0, height: 0 });
  }

  // 이미지 X 버튼 클릭 시
  const removeBgImage = () => {
    setCreateTab((prevState) => ({
      ...prevState,
      bgImg: '',
    }));
    setBgImageTemp('');
    setBgImageActualSize({ width: 0, height: 0 });
  }

  // Save버튼 클릭 시 탭 생성
  const clickSave = async event => {
    event.preventDefault();

    // EN 코드의 name을 탭 명으로 사용
    // EN 코드 name 유효성 검사
    const englishName = editLangList.filter(n => n.langCode === 'en');
    if (!englishName[0].name) {
      alert('Please enter the English tab name.');
      return;
    }

    if (!createTab.platformVersion) {
      alert('platform version is required value.');
      return;
    }
    // Tab Code 공백 체크
    var pattern = /\s/g;
    if (createTab.tabCode.match(pattern)) {
      alert('Tab Code에 공백이 존재합니다.');
      return;
    }
    // device가 tv일 때 platformVersion(x.x.x) 포맷 체크
    if (createTab.deviceType === 'tv') {
      if (!validatedVersion(createTab.platformVersion)) {
        alert('[CREATE TAB] Web os version format is incorrect.');
        return;
      }
    }
    // 동일한 MultiLanguage Code 가 존재할 경우
    const langCodes = editLangList.map(n => {
      return n.langCode;
    })
    const duplicateLangCodeCheck = langCodes.filter((n, i) => langCodes.indexOf(n) !== i);
    if (duplicateLangCodeCheck.length > 0) {
      alert('The same language code cannot be used. : ' + duplicateLangCodeCheck[0]);
      return;
    }

    const tabData = { ...createTab, tabName: englishName[0].name };
    const res = await saveCreateTab(tabData, editLangList);

    handleClose();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    callTabCategoryList();
  }

  const validatedVersion = text => {
    const pattern = /\d{1,3}.\d{1,3}.\d{1,3}/;
    return pattern.test(text);
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: clickSave,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Create Tab' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={6} mb={1}>
                {/* Device Type */}
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-02'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={deviceTypeOptions}
                    disabled={false}
                    required={true}
                    label='Device Type'
                    selected={deviceType}
                    onChange={onChangeDeviceType}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Region */}
              <Grid item xs={6} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={regionOptions}
                    disabled={false}
                    label='Region'
                    selected={region}
                    onChange={onChangeRegion}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Platform Version */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={true}
                    placeholder='ex) 4.3.0'
                    defaultValue={createTab.platformVersion}
                    onChange={onChangePlatformVersion}
                    label='Platform Version'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* Tab Code */}
            <Grid item xs={6} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='select-02'
                  fullWidth
                  size='large'
                  width='100%'
                  selectOptions={tabCodeOptions}
                  disabled={false}
                  required={true}
                  label='Tab Code'
                  isFirstSelected={true}
                  selected={createTab.tabCode}
                  onChange={onChangeTabCode}
                  shrink
                />
                <Typography component='p' className='input-sub-text' sx={{ paddingLeft: '14px' }}>
                  When there are no selectable tab codes, please contact us via Harmony.
                </Typography>
              </Box>
            </Grid>
            {/* Tab MultiLanguage */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography className='field-label bold required'>Tab Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  {editLangList.map((n, i) => (
                    <Box component='div' className='field-box flex-start' mb={1}>
                      <SelectBox
                        id='select-tab-name-region-3'
                        fullWidth
                        size='large'
                        maxWidth='170px'
                        required={true}
                        selectOptions={multiLangOptions}
                        disabled={i === 0 ? true : false}
                        label=''
                        selected={n.langCode}
                        onChange={e => {
                          onChangeMultiLang(i, 'langCode', e);
                        }}
                        shrink
                      />
                      <TextField
                        type='outlined'
                        size='large'
                        required={false}
                        placeholder='Input Tab Name'
                        value={n.name}
                        label=''
                        autoComplete='off'
                        onChange={e => {
                          onChangeMultiLang(i, 'name', e.target.value);
                        }}
                        InputLabelProps={{ shrink: false }}
                      />
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={i === 0 ? AddPlus : DelMinus}
                        iconPos='center'
                        onClick={() => {
                          i === 0 ?
                          setEditLangList(editLangList.concat({ langCode: 'en', name: '' }))
                          :
                          setEditLangList(editLangList.filter((n2, i2) => i2 !== i))
                        }}
                        autosize
                      />
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box flex-start'>
                    <SelectBox
                      id='select-04'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabTypeOptions}
                      disabled={false}
                      required={true}
                      label='Type'
                      isFirstSelected={true}
                      selected={createTab.tabType}
                      onChange={onChangeType}
                      shrink
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} mb={1}>
                  {/* Location Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabLocationTypeOptions}
                      disabled={false}
                      required={true}
                      label='Location Type'
                      isFirstSelected={true}
                      selected={createTab.locationType}
                      onChange={onChangeLocationType}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Category Flag */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Random
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={createTab.randomOrderFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeRandomOrderFlag}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={createTab.randomOrderFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Skeleton Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={tabSkeletonTypeOptions}
                      disabled={false}
                      required={true}
                      label='Skeleton Type'
                      isFirstSelected={true}
                      selected={createTab.skeletonType}
                      onChange={onChangeSkeletonType}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Category Flag */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Category Flag
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={createTab.categoryFlag === 'Y' ? true : false}
                      setSwitchOnOff={onChangeCategoryFlag}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={createTab.categoryFlag === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} mb={1}>
                  <Box className='field-box'>
                    <FileUpload
                      id='tabImg'
                      buttonLabel='Upload'
                      size='large'
                      label='Tab Icon'
                      placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                      onFileChange={(file) => onChangeTabImageUpload(file)}
                      accept='.jpg,.png,'
                      isSubText
                      subText=''
                      value={ createTab.tabImg === '' ? '' : createTab.tabImg.name }
                      InputLabelProps={{ shrink: true }}
                    />
                    <Typography component='p' className='upload-info-text'>
                      Recommended Size : <strong>78 x 78</strong> , Actual Image Size: {' '}
                      <strong style={{ color: 'red' }}>
                        {`${tabImageActualSize.width}px X ${tabImageActualSize.height}px`}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  {/* 업로드 정보 */}
                  <Box className='upload-box'>
                    <div className='upload-total-count'>Upload File</div>
                    {uploadedFiles.map((file, i) => (
                      <Box component='div' className='upload-file-list' key={`uplload-field-${i}`}>
                        <Box className='box-content' display='flex'>
                          <Box className='left-area'>
                            <CustomImage
                              src={tabImageTemp ? tabImageTemp : createTab.tabImg}
                              fit={false}
                              wrapWidth='120px'
                              wrapHeight='64px'
                              alt=''
                              rounded
                            />
                          </Box>
                          <Box className='right-area'>
                            <Box component='div' className='upload-info-wrap'>
                              <Typography component='p' className='file-name'>
                                {/* file.name */}
                              </Typography>
                              <Box component='ul' className='file-list-area'>
                                <Box component='li' className='item'>
                                  Recommended Size : 78 x 78{' '}
                                </Box>
                                <Box component='li' className='item'>
                                  Actual Image Size: 78 x 78
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <IconButton className='btn-close'>
                          <CloseIcon24Black
                            onClick={() => {
                              removeTabImage();
                            }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Theme */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <h3 className='sub-title'>Theme</h3>
                </Grid>
                <Grid item xs={12} mb={1}>
                  {/* Theme Apply */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Theme Apply
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={createTab.themeApply === 'Y' ? true : false}
                      setSwitchOnOff={onChangeThemeApply}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                      value={createTab.themeApply === 'Y' ? true : false}
                    />
                  </Box>
                </Grid>
                {/* Title */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Title'
                      defaultValue={createTab.title}
                      onChange={onChangeTitle}
                      label='Title'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
                {/* Description */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Description'
                      defaultValue={createTab.description}
                      onChange={onChangeDescription}
                      label='Description'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} mb={1}>
                <Box className='field-box'>
                  <FileUpload
                    id='bgImg'
                    buttonLabel='Upload'
                    size='large'
                    label='Background Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    onFileChange={(file) => onChangeBgImageUpload(file)}
                    accept='.jpg,.png,'
                    isSubText
                    subText=''
                    value={ createTab.bgImg === '' ? '' : createTab.bgImg.name }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong> , Actual Image Size: {' '}
                      <strong style={{ color: 'red' }}>
                        {`${bgImageActualSize.width}px X ${bgImageActualSize.height}px`}
                      </strong>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* 업로드 정보 */}
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {themeUploadedFiles.map((file, i) => (
                    <Box component='div' className='upload-file-list' key={`uploaded-background-${i}`}>
                      <Box className='box-content' display='flex'>
                        <Box className='left-area'>
                          <CustomImage
                            src={bgImageTemp ? bgImageTemp : createTab.bgImg}
                            fit={false}
                            wrapWidth='280px'
                            wrapHeight='64px'
                            alt=''
                            rounded
                            />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {/* file.name */}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: 78 x 78
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close'>
                        <CloseIcon24Black
                          onClick={() => {
                            removeBgImage();
                          }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopCreateTab;
