import React, { useEffect, useMemo, useRef, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';
import { getAnTestServiceSearchData, getTabCategoryAction, deleteAnTestService, endAnTestService } from '../ANTestService/api/restApi';

import PropTypes from 'prop-types';

import {
  Box,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  AnTestServiceDeviceTypeOption,
  StatusOption,
  AnTestTh,
  AnServiceTh,
  PlatformVersionOption,
} from 'pages/Organization/ANTestService/data/ANTestServicedata';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';
import { connect } from 'react-redux';
import PopAddANTestService from './PopAddANTestService';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BasicSnackbar from 'components/component/BasicSnackbar';
import BasicDialog from 'components/component/BasicDialog';
import PopEditANTestService from './PopEditANTestService';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoRecords = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #999;
  text-align: center;
`;

// 메인 컨텐츠
function ANTestService(props) {
  const { message = {}, regionCountryList, type } = props;
  const { mainFilteringData } = message;
  const header = type === 'antest' ? AnTestTh : AnServiceTh
  const prevTypeRef = useRef(type);
  const isTypeChange = useRef(false);
  const navigate = useNavigate();
  // 테이블 내림, 오름차순
  const [tabCategoryList, setTabCategoryList] = useState([]);
  const countrySelectOptions = useMemo(() => {
    const countryCdSet = new Set();
    regionCountryList.forEach(item => countryCdSet.add(item.countryCode));

    return Array.from(countryCdSet, countryCd => ({
      id: countryCd,
      value: countryCd,
      option: countryCd,
      type: 'text'
    })).sort((a, b) => a.id.localeCompare(b.id));
  }, [regionCountryList]);

  const defaultCountry = mainFilteringData?.country
    ? mainFilteringData.country
    : !regionCountryList || Object.keys(regionCountryList).length === 0
      ? "US"
      : countrySelectOptions[countrySelectOptions.length - 1]
        .value;

  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const defaultDeviceType = mainFilteringData?.deviceType
    ? mainFilteringData.deviceType
    : AnTestServiceDeviceTypeOption.length > 0
      ? AnTestServiceDeviceTypeOption[0].value
      : "";
  const [selectedDeviceType, setSelectedDeviceType] = useState(defaultDeviceType);

  const tabSelectOptions = useMemo(() => {
    const tab = new Set();
    const options = tabCategoryList
      .map((tabData) => {
        const combinedValue = tabData.tabCode + ' ' + tabData.tabName;
        if (!tab.has(combinedValue)) {
          tab.add(combinedValue);
          return {
            id: combinedValue,
            value: tabData.tabCode,
            option: combinedValue,
            type: "text",
          };
        }
        return null;
      })
      .filter(Boolean);

    options.sort((a, b) => a.option.localeCompare(b.option));

    const allOption = { id: 'all', value: 'all', option: 'All', type: 'text' };
    if (!options.some(option => option.value === 'all')) {
      options.unshift(allOption);
    }
    return options;
  }, [tabCategoryList]);

  const defaultTab = mainFilteringData?.tab
    ? mainFilteringData.tab
    : tabSelectOptions.length > 0
      ? tabSelectOptions[0].value
      : "";
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const categorySelectOptions = useMemo(() => {
    let options = [];
    const cat = new Set();

    if (selectedTab === 'all') {
      options = tabCategoryList.flatMap(tab =>
        tab.categoryDetails
          .map((categoryData) => {
            const combinedValue = categoryData.categoryCode + ' ' + categoryData.categoryName;
            if (!cat.has(combinedValue)) {
              cat.add(combinedValue);
              return {
                id: combinedValue,
                value: categoryData.categoryCode,
                option: combinedValue,
                type: "text",
              };
            }
            return null;
          })
          .filter(Boolean)
      );
    } else {
      options = selectedTab && (tabCategoryList.find(n => n.tabCode === selectedTab) || { categoryDetails: [] }).categoryDetails
        .map((categoryData) => {
          const combinedValue = categoryData.categoryCode + ' ' + categoryData.categoryName;
          if (!cat.has(combinedValue)) {
            cat.add(combinedValue);
            return {
              id: combinedValue,
              value: categoryData.categoryCode,
              option: combinedValue,
              type: "text",
            };
          }
          return null;
        })
        .filter(Boolean);
    }
    options.sort((a, b) => a.option.localeCompare(b.option));
    const allOption = { id: 'all', value: 'all', option: 'All', type: 'text' };
    if (!options.some(option => option.value === 'all')) {
      options.unshift(allOption);
    }
    return options;
  }, [selectedTab, tabCategoryList]);


  const defaultCategory = mainFilteringData?.category
    ? mainFilteringData.category
    : categorySelectOptions.length > 0
      ? categorySelectOptions[0].value
      : "";
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);

  const platformVersionSelectOptions = useMemo(() => {
    let options = [];
    if (selectedDeviceType in PlatformVersionOption) {
      options = PlatformVersionOption[selectedDeviceType];
    }
    const allOption = { id: 'all', value: 'all', option: 'All', type: 'text' };
    if (!options.some(option => option.value === 'all')) {
      options.unshift(allOption);
    }
    return options;
  }, [selectedDeviceType]);

  const defaultPlatformVersion = mainFilteringData?.platformVersion
    ? mainFilteringData.platformVersion
    : (platformVersionSelectOptions.length > 0
      ? platformVersionSelectOptions[0].value
      : "");

  const [selectedPlatformVersion, setSelectedPlatformVersion] = useState(defaultPlatformVersion);

  const defaultStatus = mainFilteringData?.status ? mainFilteringData.status : StatusOption[0].value;
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

  const defaultTitle = mainFilteringData?.titleData ? mainFilteringData.titleData : '';
  const [title, setTitle] = useState(defaultTitle);

  const defaultStartDate = mainFilteringData?.startDate ? dayjs(mainFilteringData.startDate.$d) : dayjs(null);
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);

  const defaultEndDate = mainFilteringData?.endDate ? dayjs(mainFilteringData.endDate.$d) : dayjs(null);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);

  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [actionDialog, setActionDialog] = useState("");
  const [editAnTestService, setEditAnTestService] = useState([]);
  const [endAnTestServiceIdx, setEndAnTestServiceIdx] = useState("");
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: type === 'antest' ? 'AN Test' : 'AN Service',
  };

  const [sFlag, setSFlag] = useState(false);
  const [params, setParams] = useState({
    order_type: "asc",
    order: "areaOrder",
    deviceType: defaultDeviceType,
    platformVersion: defaultPlatformVersion,
    country: defaultCountry,
    status: defaultStatus,
    abTestTitle: "",
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    tabCodeName: defaultTab,
    cateCodeName: defaultCategory,
    page: 1,
    size: 10,
  });

  const [checkedANTestService, setCheckedANTestService] = useState([]);
  const [anTestServiceList, setAnTestServiceList] = useState({
    data: [],
    total: 0,
  });

  const onClickReset = () => {
    setSelectedCountry('US');
    setSelectedDeviceType('all');
    setSelectedTab('all');
    setSelectedCategory('all');
    setSelectedPlatformVersion('all');
    setSelectedStatus('all');
    setTitle('');
    setSelectedStartDate(dayjs(null));
    setSelectedEndDate(dayjs(null));
  }

  const onClickSearch = () => {
    getAnTestServiceData();
  }

  const getAnTestServiceData = async (paramValues = null) => {
    const searchParams = paramValues ? {
      ...paramValues,
      deviceType: paramValues.deviceType,
      platformVersion: paramValues.platformVersion,
      country: paramValues.country,
      status: paramValues.status,
      ...(type === 'antest'
        ? { abTestTitle: paramValues.abTestTitle }
        : { anServiceTitle: paramValues.abTestTitle }
      ),
      startDate: paramValues.startDate,
      endDate: paramValues.endDate,
      tabCodeName: paramValues.tabCodeName,
      cateCodeName: paramValues.cateCodeName,
    } : {
      ...params,
      deviceType: selectedDeviceType,
      platformVersion: selectedPlatformVersion,
      country: selectedCountry,
      status: selectedStatus,
      ...(type === 'antest'
        ? { abTestTitle: title }
        : { anServiceTitle: title }
      ),
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      tabCodeName: selectedTab,
      cateCodeName: selectedCategory,
    };

    const res = await getAnTestServiceSearchData(searchParams, type);
    setCheckedANTestService([]);
    setAnTestServiceList(res);
    setParams({
      ...(paramValues || params),
      page: 1,
    });
  };

  const handelTitleClick = (rowData) => {
    const selectedRow = sortedTdRows.find(row => row.idx === rowData.idx);
    setEditAnTestService(selectedRow);
    if (selectedRow) {
      setActionDialog('Edit');
    }
  }

  const onSegmentClick = (rowData) => {
    navigate(type === 'antest' ? '/ANTest' : '/ANService', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestServiceSegment',
        message: {
          selectedRow: rowData,
          mainFilteringData: {
            country: selectedCountry,
            deviceType: selectedDeviceType,
            tab: selectedTab,
            category: selectedCategory,
            platformVersion: selectedPlatformVersion,
            status: selectedStatus,
            titleData: title,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
          }
        }
      }
    });
  }

  // Button Data
  const buttons = [ // refresh filter & search an test data
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      width: 107,
      onClick: onClickReset
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      onClick: onClickSearch
    },
  ];

  const BoxButtons = [  // delete or add an test data
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',
      isIcon: false,
      autosize: true,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Add',
      autosize: true,
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc",
    }));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  let sortedTdRows = sortRows(
    [...anTestServiceList.data],
    params.order,
    params.order_type
  );
  const count = Math.ceil(anTestServiceList.total / params.size);
  const total = anTestServiceList.total || 0;
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedTdRows = sortedTdRows.splice(pageIndex, sizeIndex);

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handleCheckBox = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = anTestServiceList.data.filter(row => row.status === 'done').map((row) => row.idx);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedANTestService, parseInt(event.target.name)];
    } else if (!event.target.checked) {
      idxs = checkedANTestService.filter(
        (row) => row !== parseInt(event.target.name)
      );
    }
    setCheckedANTestService(idxs);
  };

  const handleButtonClick = (label) => {
    setActionDialog(label);
  };

  const handleEndButtonClick = (data) => {
    setEndAnTestServiceIdx(data.idx);
    handleButtonClick('End');
  };

  const customCrudButtons = useMemo(() => {
    return BoxButtons.map((crudButton) => {
      crudButton.onClick = () => handleButtonClick(crudButton.label);
      if (crudButton.label === "Delete") {
        crudButton.disabled = checkedANTestService.length !== 0 ? false : true;
      }
      return crudButton;
    });
    // eslint-disable-next-line
  }, [checkedANTestService]);

  const onChangeCountry = (value) => {
    setSelectedCountry(value);
  }

  const onChangeDeviceType = (value) => {
    setSelectedDeviceType(value);
    setSelectedTab(tabSelectOptions[0].value);
    setSelectedCategory(categorySelectOptions[0].value);
    setSelectedPlatformVersion(platformVersionSelectOptions[0].value);
  }

  const onChangeTab = (value) => {
    setSelectedTab(value);
    setSelectedCategory(categorySelectOptions[0].value);
  }

  const getTabCategoryData = async (values = null) => {
    const deviceType = values ? values.deviceType : selectedDeviceType;
    const country = values ? values.country : selectedCountry;
    const platformVersion = values ? values.platformVersion : selectedPlatformVersion;
    const res = await getTabCategoryAction(deviceType, country, platformVersion, type);
    setTabCategoryList(res.data);
  };

  useEffect(() => {
    if (!isTypeChange.current) {
      getTabCategoryData();
    } else {
      isTypeChange.current = false;
    }
    // eslint-disable-next-line
  }, [selectedDeviceType, selectedCountry, selectedPlatformVersion, prevTypeRef]);

  useEffect(() => {
    getAnTestServiceData();
    // eslint-disable-next-line
  }, [prevTypeRef]);

  const handelSetShowSnackBar = ({ open, message, severity }) => {
    setShowSnackBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  const handleSnackbarClose = () => {
    setShowSnackBar({ open: false, message: "", severity: "" });
  };

  const onDeleteOKBtn = async () => {
    const res = await deleteAnTestService(checkedANTestService, type);
    setActionDialog("");
    getAnTestServiceData();
    setShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setParams({ ...params });
  };

  const onEndAnTestServiceOKBtn = async () => {
    const res = await endAnTestService(endAnTestServiceIdx, type);
    setActionDialog("");
    getAnTestServiceData();
    setShowSnackBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setParams({ ...params });
  };

  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const onActionCancelEndANTestServiceBtn = () => {
    setActionDialog("");
  };

  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  const endAnTestServiceActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelEndANTestServiceBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onEndAnTestServiceOKBtn,
    },
  ];

  const handelDefaultValue = () => {
    setSelectedDeviceType(defaultDeviceType);
    setSelectedTab(defaultTab);
    setSelectedCategory(defaultCategory);
    setSelectedPlatformVersion(defaultPlatformVersion);
    setSelectedStatus(defaultStatus);
    setTitle('');
    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
  }

  useEffect(() => {
    if (tabSelectOptions && selectedTab) {
      // 현재 tabSelectOptions에 현재 선택값이 없다면, index 0 인 All이 선택되도록 설정
      if (!tabSelectOptions.some(option => option.value === selectedTab)) {
        setSelectedTab(tabSelectOptions[0].value);
      }
    }
  }, [tabSelectOptions, selectedTab]);

  useEffect(() => {
    if (categorySelectOptions && selectedCategory) {
      // 현재 categorySelectOptions 현재 선택값이 없다면, index 0 인 All이 선택되도록 설정
      if (!categorySelectOptions.some(option => option.value === selectedCategory)) {
        setSelectedCategory(categorySelectOptions[0].value);
      }
    }
  }, [categorySelectOptions, selectedCategory]);

  useEffect(() => {
    if (sFlag) {
      getAnTestServiceData();
      setSFlag(false);
    }
    // eslint-disable-next-line
  }, [sFlag]);

  useEffect(() => {
    if (prevTypeRef.current !== type) {
      isTypeChange.current = true;
      const defaultValues = {
        country: 'US',
        deviceType: AnTestServiceDeviceTypeOption.length > 0
          ? AnTestServiceDeviceTypeOption[0].value
          : '',
        tab: 'all',
        category: 'all',
        platformVersion: 'all',
        status: StatusOption[0].value,
        title: '',
        startDate: dayjs(null),
        endDate: dayjs(null)
      };
      const defaultParams = {
        order_type: "asc",
        order: "areaOrder",
        deviceType: defaultValues.deviceType,
        platformVersion: defaultValues.platformVersion,
        country: defaultValues.country,
        status: defaultValues.status,
        abTestTitle: defaultValues.title,
        startDate: defaultValues.startDate,
        endDate: defaultValues.endDate,
        tabCodeName: defaultValues.tab,
        cateCodeName: defaultValues.category,
        page: 1,
        size: 10,
      };
      setSelectedCountry(defaultValues.country);
      setSelectedDeviceType(defaultValues.deviceType);
      setSelectedTab(defaultValues.tab);
      setSelectedCategory(defaultValues.category);
      setSelectedPlatformVersion(defaultValues.platformVersion);
      setSelectedStatus(defaultValues.status);
      setTitle(defaultValues.title);
      setSelectedStartDate(defaultValues.startDate);
      setSelectedEndDate(defaultValues.endDate);
      setCheckedANTestService([]);
      setParams(defaultParams);
      setAnTestServiceList({
        data: [],
        total: 0,
      });
      setTimeout(() => {
        getAnTestServiceData(defaultParams);
        getTabCategoryData({
          deviceType: defaultValues.deviceType,
          country: defaultValues.country,
          platformVersion: defaultValues.platformVersion
        });
      }, 0);
    }
    prevTypeRef.current = type;
    // eslint-disable-next-line
  }, [type]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                {type === 'antest' ? 'AN Test' : 'AN Service'}
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={countrySelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedCountry}
                      onChange={onChangeCountry}
                    />
                  </Box>

                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Device'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={AnTestServiceDeviceTypeOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedDeviceType}
                      onChange={onChangeDeviceType}
                    />
                  </Box>

                  {/* Tab */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Tab'
                      size='large'
                      shrink={true}
                      label='Tab'
                      selectOptions={tabSelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedTab}
                      onChange={onChangeTab}
                    />
                  </Box>

                  {/* Category */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Category'
                      size='large'
                      shrink={true}
                      label='Category'
                      selectOptions={categorySelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedCategory}
                      onChange={(value) => setSelectedCategory(value)}
                    />
                  </Box>

                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-PlatformV'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={platformVersionSelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedPlatformVersion}
                      onChange={(value) => setSelectedPlatformVersion(value)}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedStatus}
                      onChange={(value) => setSelectedStatus(value)}
                    />
                  </Box>

                  {/* Title */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Title'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: false }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Box>

                  {/* DatePicker */}
                  <Box component='div' className='col auto'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth='210px'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      onChangeStartDate={(value) => setSelectedStartDate(value)}
                      onChangeEndDate={(value) => setSelectedEndDate(value)}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        {total}
                      </Box>
                    </Box>
                    <Typography className='sm-sub-text' ml={3}>
                      {type === 'antest' ? 'UTC Based' : 'Local Time Based'}
                    </Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={customCrudButtons} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {header.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={checkedANTestService.length !== 0 && anTestServiceList.data.filter(item => item.status === 'done').length === checkedANTestService.length}
                                  onChange={handleCheckBox}
                                  indeterminate={checkedANTestService.length !== 0 && anTestServiceList.data.filter(item => item.status === 'done').length !== checkedANTestService.length}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                {column.sortable && <TableSortLabel
                                  active={params.order === column.id}
                                  direction={params.order === column.id ? params.order_type : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>}
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={params.order === column.id}
                                    direction={params.order === column.id ? params.order_type : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.length === 0 ? (
                        <NoRecords>
                          No records to display
                        </NoRecords>
                      ) : (
                        sortedTdRows.map((td, index) => (
                          <TableRow className='tr' key={td.id || index}>
                            {header.map((column, colIndex) => (
                              <TableCell key={column.id} className='td' align={column.align}>
                                {colIndex === 0 ? (
                                  <Box display='flex'>
                                    <CheckBox
                                      label=''
                                      value={td['idx']}
                                      onChange={handleCheckBox}
                                      checked={checkedANTestService.indexOf(td['idx']) > -1}
                                      disabled={td.status !== 'done'}
                                      defaultChecked={false}
                                    />
                                    <span
                                      onClick={() => handelTitleClick(td)}
                                      style={{ color: '#2196f3', cursor: 'pointer' }}
                                    >
                                      {td['title']}
                                    </span>
                                  </Box>
                                ) : colIndex === 8 ? (
                                  <span className={td['status'] === 'ready' ? 'ready' : td['status'] === 'done' ? 'done' : 'testing'}>
                                    {td['status'] === 'ready' ? 'Ready' : td['status'] === 'done' ? 'Done' : 'Testing'}
                                  </span>
                                ) : colIndex === 9 ? (
                                  <Box display='flex' gap={1}>
                                    <Button
                                      key={`${index}-end-btn`}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='End'
                                      disabled={dayjs(new Date()) >= dayjs(new Date(td['forcedEndTime']))}
                                      isIcon={false}
                                      autosize
                                      onClick={() => handleEndButtonClick(td)}
                                    />
                                    <Button
                                      key={`${index}-seg-btn`}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='Segment'
                                      disabled={false}
                                      isIcon={false}
                                      autosize
                                      onClick={() => onSegmentClick(td)}
                                    />
                                  </Box>
                                ) : (
                                  td[column.id]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
      <div>
        {actionDialog === 'Add' && (
          <PopAddANTestService
            onClose={() => setActionDialog('')}
            selectedCountry={selectedCountry}
            handelSetShowSnackBar={handelSetShowSnackBar}
            handelDefaultValue={handelDefaultValue}
            setSFlag={setSFlag}
            regionCountryList={regionCountryList}
            type={type}
          />
        )}
      </div>
      <div>
        {actionDialog === 'Edit' && (
          <PopEditANTestService
            onClose={() => setActionDialog('')}
            handelSetShowSnackBar={handelSetShowSnackBar}
            editAnTestService={editAnTestService}
            handelDefaultValue={handelDefaultValue}
            setSFlag={setSFlag}
            type={type}
          />
        )}
      </div>
      <div>
        {(actionDialog === 'Delete') && <BasicDialog
          title={type === 'antest' ? 'Delete AN Test' : 'Delete AN Service'}
          contentTitle={' Are you sure to delete?'}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={() => setActionDialog('')}
          buttons={deleteActionButtons}
        />}
      </div>
      <div>
        {(actionDialog === 'End') && <BasicDialog
          title={type === 'antest' ? 'End AN Test' : 'End AN Service'}
          contentTitle={' Are you sure to End?'}
          size={'small'}
          closeX={true}
          open={true}
          handleClose={() => setActionDialog('')}
          buttons={endAnTestServiceActionButtons}
        />}
      </div>
      <div>
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnackBar.severity} message={showSnackBar.message} open={showSnackBar.open} duration={5000} />
      </div>
      <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} page={params.page} />
    </Box>
  );
}

ANTestService.propTypes = {
  regionCountryList: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  regionCountryList: state.common.country.regionCountry ? state.common.country.regionCountry : [],
});

export default connect(mapStateToProps)(ANTestService);