import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import CustomImage from 'components/component/BasicImage';
import TextField from 'components/component/BasicTextField';
import CustomRadio from 'components/component/BasicRadio';
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import DatePicker from 'components/component/BasicDatePicker';
import 'react-quill/dist/quill.snow.css';

import { IconButton, Grid, Typography, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import { CloseIcon24Black } from 'components/component/BasicIcon';
import { connect } from "react-redux";
import BasicSelectCheckBox from "components/component/BasicSelectCheckBox";
import dayjs from "dayjs";
import { BASE_URL } from "constants/index";
import {getHeaders, getHeadersFormData} from "utils/Header";
// 메인 컨텐츠
function PopAddNotificationTV({ name, onClose, afterSave, country, publishStatus }) {

  const handleClose = () => {
    onClose();
  };

  // Upload File
  // eslint-disable-next-line no-unused-vars
  const [uploadImage, setUploadImage] = useState(true);

  // Radio Data
  const radioOptions = [
    { id: 'Text', checked: true, value: 'Text', label: 'Text', name: 'radio' },
    { id: 'Text and Image', checked: false, value: 'Text and Image', label: 'Text and Image', name: 'radio' },
    { id: 'Image', checked: false, value: 'Image', label: 'Image', name: 'radio' },
  ];

  const getNotificationTypeByOption = (option) => {
    switch (option) {
      case 'Text':
        return 'text';
      case 'Text and Image':
        return 'textImage';
      case 'Image':
        return 'image';
      default:
        return 'text';
    }
  }

  // radio
  const initialCheckedValue = radioOptions.find((option) => option.checked)?.value || 'Text';
  const [selectedValue, setSelectedValue] = useState(initialCheckedValue);
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [paramCountry, setParamCountry] = useState([]);
  const [countryChannelMapInputFields, setCountryChannelMapInputFields] = useState([{ id: 1, country: ' ', paramChannelMap: [], selectedChannelMaps: [] }]);
  const [counter, setCounter] = useState(1);
  const [titleInput, setTitleInput] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [multiLangInputFields, setMultiLangInputFields] = useState([{ id: 1, language: ' ', title: '', content: '' }]);
  const [multiLangCounter, setMultiLangCounter] = useState(1);
  const [paramLangCodes, setParamLangCodes] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState({src: '', width: 0, height: 0});
  const [deeplinkUrl, setDeeplinkUrl] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    fetch(`${BASE_URL}/code-management/all-lang-code`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setParamLangCodes(body.data.map(item => ({
          id: item,
          value: item,
          option: item,
          type: 'text',
        })));
      }
    })
  }, []);

  const addNotification = () => {
    // 국가 선택해야 함 / 국가별 최소 1개 이상의 채널맵 선택해야 함
    for (let field of countryChannelMapInputFields) {
      if (field.country === ' ' || field.selectedChannelMaps.length === 0) {
        setAlertTitle('Failed');
        setAlertMessage('Please select country and channel map.');
        setAlertOpen(true);
        return;
      }
    }
    if (titleInput === '') {
      setAlertTitle('Failed');
      setAlertMessage('Please input title.');
      setAlertOpen(true);
      return;
    }
    if (startDate > endDate) {
      setAlertTitle('Failed');
      setAlertMessage('Start date must be earlier than end date.');
      setAlertOpen(true);
      return;
    }
    if ((selectedValue === 'Image' || selectedValue === 'Text and Image') && !imageFile) {
      setAlertTitle('Failed');
      setAlertMessage('Please upload image.');
      setAlertOpen(true);
      return;
    }
    // multi-language 영역은 Text, Text and Image 일 때만 필수
    if (selectedValue === 'Text' || selectedValue === 'Text and Image') {
      for (let field of multiLangInputFields) {
        if (field.language === ' ' || field.title === '') {
          setAlertTitle('Failed');
          setAlertMessage('Please input language and title.');
          setAlertOpen(true);
          return;
        }
      }
    }

    const data = {
      title: titleInput,
      version: dayjs().format('YYYYMMDD-HHmmss'),
      publishStatus: publishStatus,
      deviceType: 'tv',
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      deeplinkUrl: deeplinkUrl,
      enable: 'N',
      type: getNotificationTypeByOption(selectedValue),
      countryList: countryChannelMapInputFields.map(field => field.country),
      channelMaps: countryChannelMapInputFields.map(field => field.selectedChannelMaps).flat(),
      multiLangPayloads: multiLangInputFields.map(field => ({ langCode: field.language, title: field.title, content: field.content })),
    }

    const formData = new FormData();
    formData.append('notification', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    formData.append('image', imageFile);

    fetch(`${BASE_URL}/device-notification/notifications`, {
      method: 'POST',
      headers: getHeadersFormData(),
      body: formData
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success');
        setAlertMessage('Notification added successfully.');
        setAlertOpen(true);
      } else {
        setAlertTitle('Failed');
        setAlertMessage('Failed to add notification.');
        setAlertOpen(true);
      }
    })
    .finally(() => {
      afterSave();
    })

  }

  useEffect(() => {
    if (country.serviceCountry) {
      const existingCountries = countryChannelMapInputFields.map(field => field.country);
      const countries = country.serviceCountry.map(item => ({
        id: item.countryCode,
        value: item.countryCode,
        option: item.countryCode,
        type: 'text',
        disabled: existingCountries.includes(item.countryCode),
      }))
      countries.sort((a, b) => a.value.localeCompare(b.value));
      setParamCountry(countries);
    }
    // eslint-disable-next-line
  }, [country, countryChannelMapInputFields]);

  const handleAddCountry = () => {
    setCountryChannelMapInputFields([...countryChannelMapInputFields, {id: counter + 1, country: ' ',
      paramChannelMap: [], selectedChannelMaps: [] }]);
    setCounter(counter + 1);
  }

  const handleRemoveCountry = (id) => {
    setCountryChannelMapInputFields(countryChannelMapInputFields.filter(field => field.id !== id));
  }

  const handleCountryChange = (id, value) => {
    const newInputFields = countryChannelMapInputFields.map(inputField => {
      if (inputField.id === id) {
        return {...inputField, country: value, paramChannelMap: getCountryChannelMaps(value)};
      }
      return inputField;
    });
    setCountryChannelMapInputFields(newInputFields);
  }

  const handleChannelMapCheck = (id, selectedChannelMaps) => {
    const newInputFields = countryChannelMapInputFields.map(inputField => {
      if (inputField.id === id) {
        return { ...inputField, selectedChannelMaps };
      }
      return inputField;
    });
    setCountryChannelMapInputFields(newInputFields);
  };

  const getCountryChannelMaps = (countryCode) => {
    if (country.serviceCountry) {
      const target = country.serviceCountry.find(item => item.countryCode === countryCode);
      if (target) {
        return [{id: 'all', value: 'all', option: 'All', type: 'checkbox'},
          ...target.channelMapList.map(item => item.channelMapId)
            .map(item => ({id: item, value: item, option: item, type: 'checkbox'}))];
      }
    }
  }

  const handleAddMultiLang = () => {
    setMultiLangInputFields([...multiLangInputFields, {id: multiLangCounter + 1, language: ' ', title: '', content: ''}]);
    setMultiLangCounter(multiLangCounter + 1);
  }

  const handleRemoveMultiLang = (id) => {
    setMultiLangInputFields(multiLangInputFields.filter(field => field.id !== id));
  }

  const handleInputChange = (id, field, value) => {
    const newInputFields = multiLangInputFields.map(inputField => {
      if (inputField.id === id) {
        return {...inputField, [field]: value};
      }
      return inputField;
    });
    setMultiLangInputFields(newInputFields);
  }

  const handleImageUpload = (file) => {
    setImageFile(file);
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setImagePreview({src: img.src, width: img.width, height: img.height});
    }
  }

  const handleRemoveImage = () => {
    setImageFile(null);
    setImagePreview({src: '', width: 0, height: 0});
  }

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: addNotification
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
    if (alertTitle === 'Success') {
      handleClose();
    }
  }

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Dialog
        open={true}
        closeX={true}
        title='Add Notification' // Popup Title
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            {/*Country/Channel Map */}
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12}>
                <Typography className='field-label bold required' mb={1}>
                  Country/Channel Map
                </Typography>
                {/* Country/Channel Map */}
                {countryChannelMapInputFields.map((inputField, index) => (
                  <Box component='div' className='field-box flex-start' key={index}>
                    <SelectBox
                      id='select-country-1'
                      size='large'
                      maxWidth='170px'
                      required={true}
                      selectOptions={paramCountry}
                      disabled={false}
                      label=''
                      shrink
                      selected={inputField.country}
                      onChange={(newValue) => handleCountryChange(inputField.id, newValue)}
                    />
                    <BasicSelectCheckBox
                      id='select-map-1'
                      size='large'
                      maxWidth='310px'
                      required={true}
                      selectOptions={inputField.paramChannelMap}
                      disabled={false}
                      label=''
                      shrink
                      multiple={true}
                      onChange={(newValue) => handleChannelMapCheck(inputField.id, newValue)}
                    />
                    {inputField.id === 1 ? (
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={AddPlus}
                        iconPos='center'
                        autosize
                        onClick={handleAddCountry}
                      />
                    ) : (
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={DelMinus}
                        iconPos='center'
                        autosize
                        onClick={() => handleRemoveCountry(inputField.id)}
                      />
                    )}
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography className='field-label bold required' mb={1}>
                  Type
                </Typography>
                <Box mb={2}>
                  <RadioGroup value={selectedValue} onChange={handleRadioChange} className='align-h'>
                    {radioOptions.map((option) => (
                      <Box key={option.value} sx={{ mr: 6 }}>
                        <CustomRadio
                          value={option.value}
                          label={option.label}
                          onChange={handleRadioChange}
                          disabled={option.disabled}
                          name={name}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </Box>
              </Grid>
              {/* Title */}
              <Grid item xs={12}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Title'
                    label='Title'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)}
                  />
                </Box>
              </Grid>
              {/* Start Date */}
              <Grid item xs={6} mt={1}>
                <Box component='div' className='field-box'>
                  <DatePicker size='large' label='Start Date' shrink singleDate={true} selectAllDate={true}
                              value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                </Box>
              </Grid>
              {/* End Date */}
              <Grid item xs={6} mt={1}>
                <Box component='div' className='field-box'>
                  <DatePicker size='large' label='End Date' shrink singleDate={true} selectAllDate={true}
                              value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                </Box>
              </Grid>
              {/*Image */}
              {/*  Upload File */}
              {/* Radio - Text and Image 선택시에만 노출 */}
              {selectedValue !== 'Text' && (
                <>
                  <Grid item xs={12} mt={1}>
                    <Box className='field-box'>
                      <FileUpload
                          id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                          buttonLabel='Upload'
                          size='large'
                          label='Image'
                          placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                          onFileChange={handleImageUpload}
                          accept='.jpg,.png,'
                          required={true}
                          InputLabelProps={{ shrink: true }}
                      />
                      <Typography component='p' className='upload-info-text'>
                        Recommended Size : <strong>78 x 78</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Box className='upload-box'>
                      <Box component='div' className='upload-file-list'>
                        <div className='upload-total-count'>Upload File</div>
                        {uploadImage ? (
                          <Box className='box-content align-row' display='flex'>
                            <Box className='left-area'>
                              <CustomImage src={imagePreview.src} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                            </Box>
                            <Box className='right-area'>
                              <Box component='div' className='upload-info-wrap'>
                                <Typography component='p' className='file-name'>
                                  {imageFile ? imageFile.name : ''}
                                </Typography>
                                <Box component='ul' className='file-list-area'>
                                  <Box component='li' className='item'>
                                    Recommended Size : 78 x 78{' '}
                                  </Box>
                                  <Box component='li' className='item'>
                                    Actual Image Size: {imagePreview.width} x {imagePreview.height}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          ''
                        )}
                        <IconButton className='btn-close' onClick={handleRemoveImage}>
                          <CloseIcon24Black />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                  {/* Deep Link URL* */}
                  <Grid item xs={12} mt={2}>
                    <Box component='div' className='field-box flex-start'>
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder='http://'
                        label='Deep Link URL'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                        value={deeplinkUrl}
                        onChange={(e) => setDeeplinkUrl(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12} mt={2}>
                <Typography className={`field-label bold ${(selectedValue === 'Text' || selectedValue === 'Text and Image') ? 'required' : ''}`}>
                  Language
                </Typography>
              </Grid>
              {multiLangInputFields.map((inputField, index) => (
                <>
                  <Grid item xs={12} mt={1}>
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='select-tab-name-region-1'
                        size='large'
                        maxWidth='170px'
                        required={true}
                        selectOptions={paramLangCodes}
                        disabled={false}
                        label=''
                        shrink
                        selected={inputField.language}
                        onChange={(newValue) => handleInputChange(inputField.id, 'language', newValue)}
                      />
                      <TextField
                        type='outlined'
                        size='large'
                        placeholder='Title'
                        label=''
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: false }}
                        value={inputField.title}
                        onChange={(e) => handleInputChange(inputField.id, 'title', e.target.value)}
                      />
                      {inputField.id === 1 ? (
                        <Button
                          color='tertiary'
                          type='outline icon'
                          size='medium'
                          label=''
                          isIcon={true}
                          Icon={AddPlus}
                          iconPos='center'
                          autosize
                          onClick={handleAddMultiLang}
                        />
                      ) : (
                        <Button
                          color='tertiary'
                          type='outline icon'
                          size='medium'
                          label=''
                          isIcon={true}
                          Icon={DelMinus}
                          iconPos='center'
                          autosize
                          onClick={() => handleRemoveMultiLang(inputField.id)}
                        />
                      )}
                    </Box>
                  </Grid>
                  {selectedValue !== 'Image' && (
                    <Grid item xs={12}>
                      <TextField
                        type='outlined'
                        size='large'
                        label=''
                        placeholder='Content'
                        multiline
                        readOnly={false}
                        rows={6} // 텍스트 영역의 기본 행 수
                        variant='outlined' // 테두리 스타일
                        fullWidth // 전체 너비 사용
                        className='textarea'
                        value={inputField.content}
                        onChange={(e) => handleInputChange(inputField.id, 'content', e.target.value)}
                      />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PopAddNotificationTV);
